import { COLORS } from 'constants/colors'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 40px;
  }
`

export const TitleRow = styled.div<{ withButtons?: boolean }>`
  display: flex;
  ${({ withButtons }) => withButtons && 'justify-content: space-between'};
  align-items: center;
  margin-bottom: 24px;
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
  color: ${COLORS.text.default};
`

export const TitleDivider = styled.div`
  width: 1px;
  height: 24px;
  margin: 0 12px;
  background: #d9ecf6;

  border-radius: 1px;
`

export const ViewAllLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${COLORS.primary.medium};
`
