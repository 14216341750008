import RadioButton from 'components/ui/FormElements/RadioButton'
import RangeCalendar from 'components/ui/RangeCalendar'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { ShiurimFilters } from '../index'
import { RadioGroup } from './styled'

type Props = { filters: ShiurimFilters; onChangeFilters: (filters: ShiurimFilters) => void }

export enum PERIOD_OPTIONS {
  ALL_TIME = 'All time',
  LAST_WEEK = 'Last week',
  LAST_MONTH = 'Last month',
  CUSTOM = 'Custom',
}

function PeriodFilter({ filters, onChangeFilters }: Props) {
  const [selectedOption, setSelectedOption] = useState<PERIOD_OPTIONS>(
    filters.period?.option || PERIOD_OPTIONS.ALL_TIME
  )
  const [rangeDates, setRangeDates] = useState<dayjs.Dayjs[]>(
    filters.period?.startDate && filters.period?.endDate
      ? [dayjs(filters.period?.startDate), dayjs(filters.period?.endDate)]
      : []
  )

  useEffect(() => {
    setSelectedOption(filters.period?.option || PERIOD_OPTIONS.ALL_TIME)
  }, [filters])

  useEffect(() => {
    if (selectedOption === PERIOD_OPTIONS.ALL_TIME) {
      const newFilters = { ...filters }
      delete newFilters.period

      onChangeFilters(newFilters)
    }

    if (selectedOption === PERIOD_OPTIONS.LAST_MONTH) {
      onChangeFilters({
        ...filters,
        period: {
          option: selectedOption,
          startDate: dayjs().add(-1, 'month').toDate(),
          endDate: dayjs().toDate(),
        },
      })
    }

    if (selectedOption === PERIOD_OPTIONS.LAST_WEEK) {
      onChangeFilters({
        ...filters,
        period: {
          option: selectedOption,
          startDate: dayjs().add(-1, 'week').toDate(),
          endDate: dayjs().toDate(),
        },
      })
    }

    if (selectedOption === PERIOD_OPTIONS.CUSTOM && rangeDates.length === 2) {
      onChangeFilters({
        ...filters,
        period: {
          option: selectedOption,
          startDate: rangeDates[0].toDate(),
          endDate: rangeDates[1].toDate(),
        },
      })
    }
  }, [selectedOption, rangeDates, onChangeFilters])

  const onChangeRangeDate = (date: dayjs.Dayjs) => {
    if (rangeDates[0] && !rangeDates[1]) {
      if (date.isBefore(rangeDates[0])) {
        return setRangeDates((prev) => [date.startOf('D'), ...prev])
      }

      return setRangeDates((prev) => [...prev, date.endOf('D')])
    }

    return setRangeDates([date.startOf('D')])
  }
  return (
    <>
      <RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
        <RadioButton value={PERIOD_OPTIONS.ALL_TIME}>All time</RadioButton>
        <RadioButton value={PERIOD_OPTIONS.LAST_WEEK}>Last week</RadioButton>
        <RadioButton value={PERIOD_OPTIONS.LAST_MONTH}>Last month</RadioButton>
        <RadioButton value={PERIOD_OPTIONS.CUSTOM}>Custom</RadioButton>
      </RadioGroup>
      {selectedOption === PERIOD_OPTIONS.CUSTOM && (
        <RangeCalendar rangeDates={rangeDates} onChangeDate={onChangeRangeDate} />
      )}
    </>
  )
}

export default PeriodFilter
