import backgroundSrc from 'assets/img/greeting-background.png'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 40px;
  padding: 24px 56px 24px 24px;

  background-image: url(${backgroundSrc});
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0.5px solid #d9ecf6;
  border-radius: 12px;

  @media ${device.laptopL} {
    flex-direction: column;
    padding: 24px;
  }
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  @media ${device.laptopL} {
    flex-direction: column;

    margin-bottom: 10px;
  }
`

export const Divider = styled.div`
  flex-shrink: 0;
  width: 1px;
  height: 86px;
  margin: 0 24px;

  background-color: #d9ecf6;
  border-radius: 1px;

  @media ${device.laptopL} {
    display: none;
  }
`
