import { Checkbox } from 'antd'
import Search from 'components/ui/FormElements/Search'
import Loader from 'components/ui/Loader'
import { getAllSubTopics, getAllSubTopicsVariables } from 'graphql/query/__generated__/getAllSubTopics'
import { GET_ALL_SUBTOPICS } from 'graphql/query/getAllSubTopics'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { ChangeEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { EmptyContainer, EmptyText } from '../../../Home/styled'
import { ShiurimFilters } from '../index'
import { DownArrow } from '../styled'
import { Item, List, ShowMoreText, ShowMoreWrapper, Title } from './styled'

type Props = { filters: ShiurimFilters; noTopics?: boolean; onChangeFilters: (filters: ShiurimFilters) => void }

const initialPagination = {
  take: 6,
  page: 1,
}

function SubtopicFilter({ filters, noTopics, onChangeFilters }: Props) {
  const { id } = useParams()
  const [search, setSearch] = useState('')
  const searchDebounced = useDebouncedValue(search, 300)
  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  useEffect(() => {
    setPage(initialPagination.page)
  }, [search])

  const { data, loading, fetchMore } = useQuery<getAllSubTopics, getAllSubTopicsVariables>(GET_ALL_SUBTOPICS, {
    variables: {
      ...initialPagination,
      searchParams: { topics: noTopics ? [id] : undefined, title: searchDebounced },
    },
  })

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getAllSubTopics: {
            ...previousQueryResult.getAllSubTopics,
            ...fetchMoreResult.getAllSubTopics,
            items: [...previousQueryResult.getAllSubTopics.items, ...fetchMoreResult.getAllSubTopics.items],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const onChangeSubTopics = (subTopicId: string, subTopicTitle: string, checked: boolean) => {
    if (checked) {
      onChangeFilters({
        ...filters,
        subtopics: [...(filters.subtopics || []), { title: subTopicTitle, id: subTopicId }],
      })
    } else {
      onChangeFilters({ ...filters, subtopics: [...(filters.subtopics?.filter((el) => el.id !== subTopicId) || [])] })
    }
  }

  const items = data?.getAllSubTopics?.items
  return (
    <div>
      <Search
        placeholder="Search sub-topics..."
        value={search}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <List>
        {loading ? (
          <Loader />
        ) : !items?.length ? (
          <EmptyContainer>
            <EmptyText>No sub-topics found</EmptyText>
          </EmptyContainer>
        ) : (
          <>
            {items?.map(({ subtopicId, title }) => (
              <Item key={subtopicId}>
                <Title>{title}</Title>
                <Checkbox
                  checked={!!filters.subtopics?.find((el) => el.id === subtopicId)}
                  onChange={(e) => onChangeSubTopics(subtopicId, title, e.target.checked)}
                />
              </Item>
            ))}
            {loadingNext ? (
              <Loader noHeight />
            ) : (
              data?.getAllSubTopics?.meta?.next && (
                <ShowMoreWrapper onClick={onFetchMore}>
                  <ShowMoreText>Show more</ShowMoreText>
                  <DownArrow />
                </ShowMoreWrapper>
              )
            )}
          </>
        )}
      </List>
    </div>
  )
}

export default SubtopicFilter
