import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

export const MainWrapper = styled.div`
  display: flex;

  @media ${device.laptopL} {
    flex-direction: column;
  }
`

export const Wrapper = styled.div`
  width: calc(100% / 2);
  padding: 24px;

  border-radius: 12px;
  border: 1px solid #ebebeb;

  &:first-child {
    margin-right: 14px;

    @media ${device.laptopL} {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }

  @media ${device.laptopL} {
    width: 100%;
  }
`

export const Title = styled.p`
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: ${COLORS.text.default};

  margin-bottom: 24px;
`

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 24px 24px 24px 32px;

  border: 1px solid #ebebeb;
  border-radius: 12px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media ${device.laptopL} {
    padding: 14px;
  }
`

export const ItemContent = styled.div`
  width: calc(100% - 32px - 24px); // 100% - EditButton width - margin
`

export const ItemLabel = styled.p`
  margin-bottom: 12px;

  font-size: 16px;
  line-height: 1.2;
  color: #677fa9;
`

export const ItemValue = styled.p<{ bio?: boolean }>`
  width: 100%;

  font-size: 18px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  ${({ bio }) =>
    bio &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ActionWrapper = styled.div`
  margin-left: 24px;
`

export const EditButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #d9ecf6;
  border-radius: 50%;

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-color: ${COLORS.primary.medium};
  }
`
