import { ReactComponent as ArrowDownIcon } from 'assets/icons/down-arrow.svg'
import { ArrowIconTogglerWrapper } from 'components/common/ArrowIconToggler'
import PopoverCalendar from 'components/ui/PopoverCalendar'
import dayjs from 'dayjs'
import { useState } from 'react'

import SliderCalendar from '../SliderCalendar'
import { Month, MonthWrapper, TodayBtn, TopRow, Wrapper } from './styled'

type Props = {
  selectedDate: dayjs.Dayjs
  onChangeDate: (date: dayjs.Dayjs) => void
}

function CalendarSection({ selectedDate, onChangeDate }: Props) {
  const [isFullCalendalOpen, setIsFullCalendalOpen] = useState(false)

  const onTodayClick = () => onChangeDate(dayjs().startOf('D'))
  return (
    <Wrapper className="calendar-section">
      <TopRow>
        <PopoverCalendar
          open={isFullCalendalOpen}
          onOpenChange={setIsFullCalendalOpen}
          selectedDate={selectedDate}
          onChangeDate={onChangeDate}
        >
          <MonthWrapper>
            <Month>{selectedDate.toDate().toLocaleDateString('en-US', { month: 'long' })}</Month>
            <ArrowIconTogglerWrapper isOpen={isFullCalendalOpen}>
              <ArrowDownIcon />
            </ArrowIconTogglerWrapper>
          </MonthWrapper>
        </PopoverCalendar>
        <TodayBtn onClick={onTodayClick}>Today</TodayBtn>
      </TopRow>
      <SliderCalendar selectedDate={selectedDate} onChangeDate={onChangeDate} />
    </Wrapper>
  )
}

export default CalendarSection
