import { Button } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.3;
  color: ${COLORS.text.default};
  text-align: center;

  margin-top: 40px;
  margin-bottom: 12px;
`

export const SubmitButton = styled(Button)`
  width: 100%;
`
