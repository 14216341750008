import { Flex } from 'components/common/Flex'
import { getAnnouncementsWithPagination_getAnnouncementsWithPagination_items } from 'graphql/query/__generated__/getAnnouncementsWithPagination'

import { DateText, Image, LabelText, LabelWrapper, Text, Title, Wrapper } from './styled'

type Props = getAnnouncementsWithPagination_getAnnouncementsWithPagination_items & { showLabel?: boolean }

function LiveEventCard({ image, title, text, eventDate, showLabel }: Props) {
  return (
    <Wrapper>
      <Image src={`${process.env.REACT_APP_AWS_S3_URL}${image}`} />
      <Flex justifyBetween alignCenter>
        {showLabel && (
          <LabelWrapper>
            <LabelText>Live event</LabelText>
          </LabelWrapper>
        )}
        {eventDate && (
          <DateText>
            {new Date(eventDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </DateText>
        )}
      </Flex>

      <Title>{title}</Title>
      <Text>{text}</Text>
    </Wrapper>
  )
}

export default LiveEventCard
