import { ReactComponent as NoDataIcon } from 'assets/icons/bookmark-big.svg'
import loaderSrc from 'assets/icons/loader.gif'
import Loader from 'components/ui/Loader'
import NoData from 'components/ui/NoData'
import {
  getBookmarksWithPagination,
  getBookmarksWithPaginationVariables,
  getBookmarksWithPagination_getBookmarksWithPagination_items,
} from 'graphql/query/__generated__/getBookmarksWithPagination'
import { GET_BOOKMARKS_WITH_PAGINATION } from 'graphql/query/getBookmarksWithPagination'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'

import { useQuery } from '@apollo/client'

import BookmarkCard from '../../Cards/BookmarkCard'
import EditNoteBookmarkModal from '../../Modals/EditNoteBookmarkModal'
import { LoaderWrapper, Wrapper } from './styled'

type Props = { search: string }

type Bookmark = getBookmarksWithPagination_getBookmarksWithPagination_items

const initialPagination = {
  take: 30,
  page: 1,
}

function BookmarksList({ search }: Props) {
  const { user } = useAuth()

  const [page, setPage] = useState(initialPagination.page)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [openedBookmark, setOpenedBookmark] = useState<Bookmark>()

  const { data, loading, fetchMore, refetch } = useQuery<
    getBookmarksWithPagination,
    getBookmarksWithPaginationVariables
  >(GET_BOOKMARKS_WITH_PAGINATION, {
    variables: { ...initialPagination, text: search },
    fetchPolicy: 'network-only',
    skip: !user,
  })

  const onFetchMore = () => {
    fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getBookmarksWithPagination: {
            ...previousQueryResult.getBookmarksWithPagination,
            ...fetchMoreResult.getBookmarksWithPagination,
            items: [
              ...previousQueryResult.getBookmarksWithPagination.items,
              ...fetchMoreResult.getBookmarksWithPagination.items,
            ],
          },
        }
      },
    })
    setPage((prev) => prev + 1)
  }

  const openModal = (bookmark: Bookmark) => {
    setIsModalOpen(true)
    setOpenedBookmark(bookmark)
  }

  const closeModal = async (deleted?: boolean) => {
    setIsModalOpen(false)
    setOpenedBookmark(undefined)

    if (deleted) {
      setPage(initialPagination.page)
      await refetch()
    }
  }

  const itemsLength = data?.getBookmarksWithPagination?.items?.length

  if (!itemsLength && loading) return <Loader />
  if (!itemsLength)
    return (
      <NoData
        icon={<NoDataIcon />}
        title={user ? (search ? 'Nothing found' : "You haven't added any bookmarks yet") : 'Nothing here... yet'}
        description={
          user
            ? 'To create a bookmark, open the lecture and add a bookmark to it'
            : 'Create an account to add and edit bookmarks about the lectures'
        }
      />
    )

  return (
    <>
      <Wrapper
        dataLength={itemsLength || 0}
        next={onFetchMore}
        hasMore={data?.getBookmarksWithPagination?.meta?.next}
        loader={null}
        scrollableTarget="scrollable-wrapper"
      >
        {data?.getBookmarksWithPagination?.items?.map((el) => (
          <BookmarkCard key={el.bookmarkId} onClick={() => openModal(el)} {...el} />
        ))}
      </Wrapper>
      {loading && (
        <LoaderWrapper>
          <img src={loaderSrc} />
        </LoaderWrapper>
      )}
      <EditNoteBookmarkModal
        open={isModalOpen}
        id={openedBookmark?.bookmarkId}
        text={openedBookmark?.text}
        timecode={openedBookmark?.timecode}
        shiur={openedBookmark?.shiur}
        onClose={closeModal}
      />
    </>
  )
}

export default BookmarksList
