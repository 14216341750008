import VerificationCodeSentModal from 'components/business/Auth/Modals/VerificationCodeSentModal'
import { useState } from 'react'

import Completed from './Completed'
import EmailInput from './EmailInput'
import VerificationCodeInput from './VerificationCodeInput'

type Props = { open: boolean; onClose: () => void }

enum STEPS {
  EMAIL_INPUT = 'email_input',
  EMAIL_SENT = 'email_sent',
  VERIFICATION_CODE_INPUT = 'verification_code_input',
  COMPLETED = 'completed',
}

function ChangeEmailModal(props: Props) {
  const [email, setEmail] = useState('')
  const [step, setStep] = useState<STEPS>(STEPS.EMAIL_INPUT)

  const showVerificationCodeSent = (newEmail: string) => {
    setEmail(newEmail)
    setStep(STEPS.EMAIL_SENT)
  }

  const showEnterVerificationCode = async () => {
    setStep(STEPS.VERIFICATION_CODE_INPUT)
  }

  const showCompleted = async () => {
    setStep(STEPS.COMPLETED)
  }

  if (step === STEPS.EMAIL_INPUT) return <EmailInput nextStep={showVerificationCodeSent} {...props} />

  if (step === STEPS.EMAIL_SENT) return <VerificationCodeSentModal {...props} onComplete={showEnterVerificationCode} />

  if (step === STEPS.VERIFICATION_CODE_INPUT)
    return <VerificationCodeInput {...props} email={email} nextStep={showCompleted} />

  if (step === STEPS.COMPLETED) return <Completed {...props} onComplete={props.onClose} />
}

export default ChangeEmailModal
