import AuthRoot from 'pages/Auth'
import AccountSetup from 'pages/Auth/AccountSetup'
import EmailNotifications from 'pages/Auth/EmailNotifications'
import FollowSeries from 'pages/Auth/FollowSeries'
import ForgotPassword from 'pages/Auth/ForgotPassword'
import Login from 'pages/Auth/Login'
import Registration from 'pages/Auth/Registration'
import VerifyEmail from 'pages/Auth/VerifyEmail'
import Main from 'pages/Main'
import Bookmarks from 'pages/Main/Bookmarks'
import Browse from 'pages/Main/Browse'
import Series from 'pages/Main/Browse/Series'
import SeriesDetailed from 'pages/Main/Browse/SeriesDetailed'
import Shiurim from 'pages/Main/Browse/Shiurim'
import TopicDetailed from 'pages/Main/Browse/TopicDetailed'
import Topics from 'pages/Main/Browse/Topics'
import Community from 'pages/Main/Community'
import Announcements from 'pages/Main/Community/Announcements'
import KnowledgeBase from 'pages/Main/Community/KnowledgeBase'
import Donate from 'pages/Main/Donate'
import DonateMobile from 'pages/Main/DonateMobile'
import DonationHistory from 'pages/Main/DonationHistory'
import Favorite from 'pages/Main/Favorite'
import FollowedSeries from 'pages/Main/FollowedSeries'
import History from 'pages/Main/History'
import Home from 'pages/Main/Home'
import Following from 'pages/Main/Home/Following'
import NewShiurim from 'pages/Main/Home/NewShiurim'
import Recommended from 'pages/Main/Home/Recommended'
import Notes from 'pages/Main/Notes'
import PrivacyPolicy from 'pages/Main/PrivacyPolicy'
import Profile from 'pages/Main/Profile'
import Support from 'pages/Main/Support'
import TermsOfUse from 'pages/Main/TermsOfUse'
import { createBrowserRouter } from 'react-router-dom'

import AuthorizedRoute from './AuthorizedRoute'

export const ROUTE_PATH = {
  REGISTRATION: '/registration',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_EMAIL: '/verify-email',
  ACCOUNT_SETUP: '/account-setup',
  FOLLOW_SERIES: '/follow-series',
  NOTIFICATIONS_SETUP: '/notifications-setup',
  HOME: '/home',
  PROFILE: '/profile',
  DAILY_SHIURIM: '/daily-shiurim',
  RECOMMENDED: '/recommended',
  FOLLOWING: '/following',
  BROWSE: '/browse',
  SERIES: '/series',
  SERIES_DETAILED: '/series/:id',
  SHIURIM: '/shiurim',
  TOPICS: '/topics',
  TOPIC_DETAILED: '/topic/:id',
  NOTES: '/notes',
  BOOKMARKS: '/bookmarks',
  SAVED: '/saved-shiurim',
  FOLLOWED_SERIES: '/followed-series',
  HISTORY: '/history',
  DONATE: '/donate',
  DONATE_MOBILE: '/donate-mobile',
  COMMUNITY: '/community',
  KNOWLEDGE_BASE: '/knowledge-base',
  ANNOUNCEMENTS: '/announcements',
  DONATION_HISTORY: '/donation-history',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
  SUPPORT: '/support',
}

const router = createBrowserRouter([
  {
    element: <AuthRoot />,
    children: [
      { path: ROUTE_PATH.REGISTRATION, element: <Registration /> },
      { path: ROUTE_PATH.LOGIN, element: <Login /> },
      { path: ROUTE_PATH.FORGOT_PASSWORD, element: <ForgotPassword /> },
      { path: ROUTE_PATH.VERIFY_EMAIL, element: <VerifyEmail /> },
      { path: ROUTE_PATH.ACCOUNT_SETUP, element: <AccountSetup /> },
      { path: ROUTE_PATH.FOLLOW_SERIES, element: <FollowSeries /> },
      { path: ROUTE_PATH.NOTIFICATIONS_SETUP, element: <EmailNotifications /> },
    ],
  },
  { path: ROUTE_PATH.TERMS_OF_USE, element: <TermsOfUse /> },
  { path: ROUTE_PATH.PRIVACY_POLICY, element: <PrivacyPolicy /> },
  { path: ROUTE_PATH.SUPPORT, element: <Support /> },
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: ROUTE_PATH.PROFILE,
        element: (
          <AuthorizedRoute>
            <Profile />
          </AuthorizedRoute>
        ),
      },
      { path: ROUTE_PATH.HOME, element: <Home /> },
      { path: ROUTE_PATH.RECOMMENDED, element: <Recommended /> },
      { path: ROUTE_PATH.DAILY_SHIURIM, element: <NewShiurim /> },
      {
        path: ROUTE_PATH.FOLLOWING,
        element: (
          <AuthorizedRoute>
            <Following />
          </AuthorizedRoute>
        ),
      },
      {
        path: ROUTE_PATH.BROWSE,
        element: <Browse />,
      },
      { path: ROUTE_PATH.SERIES, element: <Series /> },
      { path: ROUTE_PATH.SERIES_DETAILED, element: <SeriesDetailed /> },
      { path: ROUTE_PATH.SHIURIM, element: <Shiurim /> },
      { path: ROUTE_PATH.TOPICS, element: <Topics /> },
      { path: ROUTE_PATH.TOPIC_DETAILED, element: <TopicDetailed /> },
      { path: ROUTE_PATH.NOTES, element: <Notes /> },
      { path: ROUTE_PATH.BOOKMARKS, element: <Bookmarks /> },
      {
        path: ROUTE_PATH.SAVED,
        element: <Favorite />,
      },
      {
        path: ROUTE_PATH.FOLLOWED_SERIES,
        element: <FollowedSeries />,
      },
      {
        path: ROUTE_PATH.HISTORY,
        element: <History />,
      },
      {
        path: ROUTE_PATH.DONATE,
        element: <Donate />,
      },
      {
        path: ROUTE_PATH.DONATE_MOBILE,
        element: <DonateMobile />,
      },
      {
        path: ROUTE_PATH.COMMUNITY,
        element: <Community />,
      },
      {
        path: ROUTE_PATH.KNOWLEDGE_BASE,
        element: <KnowledgeBase />,
      },
      {
        path: ROUTE_PATH.ANNOUNCEMENTS,
        element: <Announcements />,
      },
      {
        path: ROUTE_PATH.DONATION_HISTORY,
        element: <DonationHistory />,
      },
    ],
  },
])

export default router
