import { Form as ANTDForm, InputNumber as ANTDInputNumber, Button } from 'antd'
import appBackgroundSrc from 'assets/img/donate-app-banner.png'
import shiurBackgroundSrc from 'assets/img/donate-shiur-banner.png'
import mobileBackgroundSrc from 'assets/img/greeting-background.png'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ left?: boolean }>`
  overflow: hidden;
  width: 100%;
  flex: 1;
  ${({ left }) => left && 'margin-right: 14px'};

  border-radius: 12px;
  border: 1px solid #ebebeb;
`

export const Banner = styled.div<{ app?: boolean }>`
  padding: 24px;

  background-image: url(${({ app }) => (app ? appBackgroundSrc : shiurBackgroundSrc)});
  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${device.laptop} {
    background-image: url(${mobileBackgroundSrc});
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;

  margin-bottom: 16px;

  border-radius: 12px;
  border: 1px solid #d9ecf6;
  background: #f4fbff;
`

export const Title = styled.p`
  margin-bottom: 6px;

  font-size: 24px;
  line-height: 1.3;
  font-weight: 500;
  color: ${COLORS.text.default};
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: #677fa9;
`

export const Content = styled.div`
  padding: 24px;
`

export const Form = styled(ANTDForm)`
  width: 100%;
`

export const FormItem = styled(ANTDForm.Item)<{ isCheckbox?: boolean; isButton?: boolean; isInRow?: boolean }>`
  .ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 8px;
  }

  .ant-form-item-control-input {
    min-height: auto;
  }

  ${({ isButton }) =>
    isButton &&
    css`
      .ant-form-item-control,
      .ant-input-affix-wrapper,
      .ant-input {
        background-color: #ffffff;
        color: ${COLORS.text.default};
        cursor: pointer;
      }
      .ant-input,
      .ant-input-affix-wrapper {
        &:hover {
          border-color: #239cd9;
          border-inline-end-width: 1px;
        }
      }
    `}
  &:first-child {
    margin-bottom: 20px;
  }

  &:not(:last-child) {
    margin-bottom: ${({ isCheckbox }) => (isCheckbox ? '10px' : '16px')};
  }

  &:last-child {
    margin: 32px 0 0;
  }

  ${({ isInRow }) =>
    isInRow &&
    css`
      width: 100%;
      margin: 0 !important;

      &:last-child {
        margin-left: 12px !important;
      }
    `}
`

export const ShiurRowWrapper = styled.div`
  margin-bottom: 16px;
`

export const InputNumber = styled(ANTDInputNumber)`
  width: 100%;
`

export const SubmitButton = styled(Button)`
  width: 100%;
`

export const ExtraText = styled.p`
  color: #677fa9;
  font-size: 12px;
  line-height: 1.3;

  margin-top: 10px;
`

export const ExtraAccent = styled.span`
  color: ${COLORS.primary.medium};
`
