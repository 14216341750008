import authSlideOne from 'assets/img/auth-slide-one.png'
import authSlideThree from 'assets/img/auth-slide-three.png'
import authSlideTwo from 'assets/img/auth-slide-two.png'
import { Autoplay, Pagination } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import { Image, SlideContent, Subtitle, SwiperStyled, TextWrapper, Title } from './styled'

function OnboardingSlider() {
  return (
    <SwiperStyled
      modules={[Pagination, Autoplay]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
    >
      <SwiperSlide>
        <SlideContent>
          <Image src={authSlideOne} />
          <TextWrapper>
            <Title>
              Unlock a World of
              <br />
              Torah Wisdom
            </Title>
            <Subtitle>
              Path4Life is your gateway to daily Torah insights, conveniently delivered to your fingertips. Dive into a
              rich tapestry of Jewish topics, connect with tradition, and embark on a journey of growth and inspiration.
            </Subtitle>
          </TextWrapper>
        </SlideContent>
      </SwiperSlide>
      <SwiperSlide>
        <SlideContent>
          <Image src={authSlideTwo} />
          <TextWrapper>
            <Title>
              Dive into a World of
              <br />
              Jewish Topics
            </Title>
            <Subtitle>
              Immerse yourself in daily 4-minute shiurim spanning dozens of topics. From the Parsha and Daf Yomi and
              Mussar and History, our platform offers a treasure trove of insights. Discover something new every day.
            </Subtitle>
          </TextWrapper>
        </SlideContent>
      </SwiperSlide>
      <SwiperSlide>
        <SlideContent>
          <Image src={authSlideThree} />
          <TextWrapper>
            <Title>
              Explore Path4Life
              <br />
              Amazing Features
            </Title>
            <Subtitle>
              With our intuitive features, customize your learning path by following topics, taking insightful notes,
              adding bookmarks, and sharing your favorite shiurim . Your Torah journey, your way.
            </Subtitle>
          </TextWrapper>
        </SlideContent>
      </SwiperSlide>
    </SwiperStyled>
  )
}

export default OnboardingSlider
