import { Modal as ANTDModal, ModalProps } from 'antd'
import { ReactComponent as CloseIcon } from 'assets/icons/cross.svg'

type Props = ModalProps

function Modal({ children, ...props }: Props) {
  return <ANTDModal closeIcon={<CloseIcon />} children={children} {...props} />
}

export default Modal
