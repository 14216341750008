import { getAllTopics_getAllTopics_items } from 'graphql/query/__generated__/getAllTopics'
import { useNavigate } from 'react-router-dom'

import { Count, Title, Wrapper } from './styled'

type Props = getAllTopics_getAllTopics_items

function TopicCard({ topicId, title, shiurimCount }: Props) {
  const navigate = useNavigate()

  const onClick = () => {
    navigate(`/topic/${topicId}?title=${title}`)
  }
  return (
    <Wrapper onClick={onClick}>
      <Title>{title}</Title>
      <Count>{Number(shiurimCount)} Shiurim</Count>
    </Wrapper>
  )
}

export default TopicCard
