import KnowledgeBaseMain from 'components/business/Main/Community/KnowledgeBase'
import Header from 'components/ui/Header'
import { Wrapper } from 'pages/Main/styled'

function KnowledgeBase() {
  return (
    <>
      <Header isBackButton title="Knowledge Base" />
      <Wrapper id="scrollable-wrapper">
        <KnowledgeBaseMain />
      </Wrapper>
    </>
  )
}

export default KnowledgeBase
