import { Gender } from '__generated__/globalTypes'
import GetMobileAppModal from 'components/business/Main/Modals/GetMobileAppModal'
import Player from 'components/business/Main/Player'
import Loader from 'components/ui/Loader'
import SideBar from 'components/ui/Sidebar'
import { deviceWidth } from 'constants/responsive'
import { useAuth } from 'hooks/useAuth'
import { useHeaderHeight } from 'hooks/useHeaderHeight'
import { usePlayer } from 'hooks/usePlayer'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { Content, MainWrapper } from './styled'

function Main() {
  const { headerHeight } = useHeaderHeight()
  const { isInitialized } = usePlayer()
  const { user, isLoading } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isMobileDevice = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  const [isGetMobileAppModalOpen, setIsGetMobileAppModalOpen] = useState(
    isMobileDevice && localStorage.getItem('noRepeatDownloadMobileApp') !== 'true'
  )

  const onCloseGetMobileAppModal = (noRepeat: boolean) => {
    if (noRepeat) localStorage.setItem('noRepeatDownloadMobileApp', 'true')
    setIsGetMobileAppModalOpen(false)
  }

  const isUserSetupFinished =
    user?.username &&
    (user?.gender === Gender.MALE || user?.gender === Gender.FEMALE) &&
    user?.allowNotify !== null &&
    user?.isSeriesFollowed

  useEffect(() => {
    if (user && !isUserSetupFinished) {
      navigate(ROUTE_PATH.ACCOUNT_SETUP)
    }
  }, [isUserSetupFinished, navigate, user])

  useEffect(() => {
    if (pathname === '/') navigate(ROUTE_PATH.HOME)
  }, [navigate, pathname])

  const isMobile = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])

  if (isLoading) return <Loader fullHeight />

  if (pathname === ROUTE_PATH.DONATE_MOBILE) return <Outlet />

  return (
    <MainWrapper hasSider={!isMobile} isPlayerShown={isInitialized} headerHeight={headerHeight}>
      {!isMobile && <SideBar />}
      <Content>
        <Outlet />
      </Content>
      {isGetMobileAppModalOpen && (
        <GetMobileAppModal open={isGetMobileAppModalOpen} onClose={onCloseGetMobileAppModal} />
      )}
      {isInitialized && <Player />}
    </MainWrapper>
  )
}

export default Main
