import { SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_SHIURIM_FROM_FOLLOWED = gql`
  ${SHIUR_FRAGMENT}
  query getShiurimFromFollowed($take: Int!, $page: Int!, $offset: Boolean, $searchParams: ShiurSearchParams) {
    getShiurimFromFollowed(take: $take, page: $page, offset: $offset, searchParams: $searchParams) {
      items {
        ...ShiurimInfo
      }
      meta {
        total
        next
      }
    }
  }
`
