import { gql } from '@apollo/client'

export const GET_ARTICLES_WITH_PAGINATION = gql`
  query getArticlesWithPagination($take: Int!, $page: Int!, $title: String) {
    getArticlesWithPagination(take: $take, page: $page, title: $title) {
      items {
        articleId
        title
        text
        previewText
        publicationDate
        image
        minutesToRead
      }
      meta {
        total
        next
      }
    }
  }
`
