import { useEffect, useRef, useState } from 'react'

export enum DOWNLOADING_STATES {
  IDLE = 'idle',
  DOWNLOADING = 'downloading',
  DOWNLOADED = 'downloaded',
}

export const useDownloadAudio = (title: string, url: string) => {
  const controllerRef = useRef<AbortController | null>()

  const [downloadState, setDownloadState] = useState(DOWNLOADING_STATES.IDLE)
  const [downloadingProgress, setDownloadingProgress] = useState(0)

  useEffect(() => {
    setDownloadState(DOWNLOADING_STATES.IDLE)
  }, [url])

  const handleDownloadingProgress = async (response: Response) => {
    const reader = response.body.getReader()

    // get total length
    const contentLength = +response.headers.get('Content-Length')

    let receivedLength = 0 // received that many bytes at the moment
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      receivedLength += value.length

      setDownloadingProgress(Math.round((receivedLength / contentLength) * 100))
    }
  }

  const handleDownloadFile = async (response: Response) => {
    const blob = await response.blob()
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${title}.mp3`)

    document.body.appendChild(link)

    link.click()

    link.parentNode.removeChild(link)
  }

  const download = async () => {
    setDownloadState(DOWNLOADING_STATES.DOWNLOADING)

    try {
      const controller = new AbortController()
      controllerRef.current = controller
      const response = await fetch(`${process.env.REACT_APP_AWS_S3_URL}${url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'audio/mp3',
        },
        signal: controllerRef.current?.signal,
      })
      await handleDownloadingProgress(response.clone())
      await handleDownloadFile(response.clone())

      setDownloadState(DOWNLOADING_STATES.DOWNLOADED)
    } catch (e) {
      setDownloadState(DOWNLOADING_STATES.IDLE)
      console.error('Download Error: ', e.message)
    }

    controllerRef.current = null
    setDownloadingProgress(0)
  }

  const abortDownload = () => controllerRef.current.abort()

  return { download, abortDownload, downloadState, downloadingProgress }
}
