import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { deviceWidth } from 'constants/responsive'

import QuickFilter, { QUICK_FILTER_TYPE } from '../QuickFilter'
import { ShiurimFilters } from '../index'
import { ButtonsWrapper, Divider, FilterButton, ResetButton } from './styled'

type Props = {
  filters: ShiurimFilters
  newFilters: ShiurimFilters
  filtersCount: number
  noTopics?: boolean
  noSeries?: boolean
  withQuickFilters?: boolean
  onOpen: () => void
  onReset: () => void
  onResetOne: (filterName: keyof ShiurimFilters) => void
  onApply: () => void
  onChangeFilters: (filters: ShiurimFilters) => void
}

function ButtonsAndQuickFilters({
  filters,
  newFilters,
  filtersCount,
  noTopics,
  noSeries,
  withQuickFilters,
  onOpen,
  onReset,
  onResetOne,
  onApply,
  onChangeFilters,
}: Props) {
  const isMobile = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])
  return (
    <ButtonsWrapper>
      {withQuickFilters ? (
        <>
          <FilterButton isActive={!!filtersCount} onClick={onOpen}>
            <FilterIcon /> {isMobile ? '' : 'Filters'}
            {filtersCount && !isMobile ? `・${filtersCount}` : ''}
          </FilterButton>
          <QuickFilter
            type={QUICK_FILTER_TYPE.PERIOD}
            filters={filters}
            newFilters={newFilters}
            onChangeFilters={onChangeFilters}
            onApply={onApply}
            onResetOne={onResetOne}
          />
          {!noSeries && (
            <QuickFilter
              type={QUICK_FILTER_TYPE.SERIES}
              filters={filters}
              newFilters={newFilters}
              onChangeFilters={onChangeFilters}
              onApply={onApply}
              onResetOne={onResetOne}
            />
          )}
          {!noTopics && (
            <QuickFilter
              type={QUICK_FILTER_TYPE.TOPIC}
              filters={filters}
              newFilters={newFilters}
              onChangeFilters={onChangeFilters}
              onApply={onApply}
              onResetOne={onResetOne}
            />
          )}
          <QuickFilter
            type={QUICK_FILTER_TYPE.SUB_TOPIC}
            filters={filters}
            newFilters={newFilters}
            noTopics={noTopics}
            onChangeFilters={onChangeFilters}
            onApply={onApply}
            onResetOne={onResetOne}
          />
          {!!filtersCount && (
            <>
              <Divider />
              <ResetButton onClick={onReset}>Reset all</ResetButton>
            </>
          )}
        </>
      ) : (
        <>
          {!!filtersCount && !isMobile && (
            <>
              <ResetButton onClick={onReset}>Reset all</ResetButton>
              <Divider />
            </>
          )}
          <FilterButton isActive={!!filtersCount} onClick={onOpen}>
            <FilterIcon />
            &nbsp;Filters{filtersCount ? `・${filtersCount}` : ''}
          </FilterButton>
          {!withQuickFilters && !!filtersCount && isMobile && (
            <>
              <Divider />
              <ResetButton onClick={onReset}>Reset all</ResetButton>
            </>
          )}
        </>
      )}
    </ButtonsWrapper>
  )
}

export default ButtonsAndQuickFilters
