/* eslint-disable @typescript-eslint/ban-types */

function throttle(func: Function, ms = 300) {
  let ready = true
  return (...args: any) => {
    if (!ready) {
      return
    }

    ready = false
    func(...args)
    setTimeout(() => {
      ready = true
    }, ms)
  }
}

export default throttle
