import { ReactComponent as EditIcon } from 'assets/icons/edit-primary.svg'
import ChangeEmailModal from 'components/business/Main/Modals/ChangeEmailModal'
import ChangePasswordModal from 'components/business/Main/Modals/ChangePasswordModal'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'

import { ActionWrapper, EditButton, Item, ItemContent, ItemLabel, ItemValue, Title, Wrapper } from '../styled'
import Notification from './Notification'

function Account() {
  const { user } = useAuth()
  const { email } = user || {}

  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false)
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)

  const onOpenChangeEmailModal = () => setIsChangeEmailModalOpen(true)
  const onCloseChangeEmailModal = () => setIsChangeEmailModalOpen(false)

  const onOpenChangePasswordModal = () => setIsChangePasswordModalOpen(true)
  const onCloseChangePasswordModal = () => setIsChangePasswordModalOpen(false)

  return (
    <>
      <Wrapper>
        <Title>Account and notifications settings</Title>
        <ul>
          <Item>
            <ItemContent>
              <ItemLabel>Email</ItemLabel>
              <ItemValue>{email}</ItemValue>
            </ItemContent>
            <ActionWrapper>
              <EditButton onClick={onOpenChangeEmailModal}>
                <EditIcon />
              </EditButton>
            </ActionWrapper>
          </Item>
          <Item>
            <ItemContent>
              <ItemLabel>Password</ItemLabel>
              <ItemValue>••••••••••</ItemValue>
            </ItemContent>
            <ActionWrapper>
              <EditButton onClick={onOpenChangePasswordModal}>
                <EditIcon />
              </EditButton>
            </ActionWrapper>
          </Item>
          <Notification />
        </ul>
      </Wrapper>
      {isChangeEmailModalOpen && <ChangeEmailModal open={isChangeEmailModalOpen} onClose={onCloseChangeEmailModal} />}
      {isChangePasswordModalOpen && (
        <ChangePasswordModal open={isChangePasswordModalOpen} onClose={onCloseChangePasswordModal} />
      )}
    </>
  )
}

export default Account
