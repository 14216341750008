import { gql } from '@apollo/client'

export const SERIES_IN_SHIUR_FRAGMENT = gql`
  fragment SeriesInfoInShiurim on SeriesOutputDto {
    seriesId
    coverUrl
    title
    subtitle
    titleColor
  }
`

export const SHIUR_FRAGMENT = gql`
  ${SERIES_IN_SHIUR_FRAGMENT}
  fragment ShiurimInfo on ShiurimOutputDto {
    shiurId
    audioUrl
    orderedNum
    title
    subtitle
    duration
    listenedDuration
    topic {
      topicId
      title
    }
    subtopicTitles
    createdAt
    publicationDate
    series {
      ...SeriesInfoInShiurim
    }
    favoriteInfo {
      isFavorite
    }
  }
`

export const SERIES_FRAGMENT = gql`
  fragment SeriesInfo on SeriesOutputDto {
    seriesId
    coverUrl
    title
    subtitle
    titleColor
    shiurimCount
    totalDuration
    followInfo {
      isFollowed
    }
  }
`
