import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const MainInfo = styled.div`
  width: calc(100% - 200px); // 100% - width of play elem and 3 dots
  display: flex;
  align-items: center;

  @media ${device.laptop} {
    width: calc(100% - 45px); // 100% - width of play elem
  }
`

export const Image = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 8px;

  object-fit: cover;
  object-position: center;

  border-radius: 12px;
`

export const ContentWrapper = styled.div`
  width: calc(100% - 50px); // 100% - width of image
`

export const TitleRow = styled.span`
  display: flex;
  align-items: center;

  margin-bottom: 6px;
`

export const Number = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #696b7b;

  white-space: nowrap;

  margin-right: 2px;

  white-space: nowrap;
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Description = styled.p`
  width: 165px;
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.hint};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const SeriesTitle = styled.span<{ color?: string }>`
  color: ${({ color }) => color || COLORS.text.default};

  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    opacity: 0.7;
  }
`

export const PlayButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  margin-right: 8px;

  background: ${COLORS.primary.dark};
  box-shadow: 0px 0px 20px rgba(42, 76, 96, 0.1);
  border-radius: 50%;

  cursor: pointer;
`

export const PlayerDescription = styled.p`
  width: 150px;
  font-size: 12px;
  color: ${COLORS.text.default};
`
