import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div<{ loading: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ loading }) => loading && 'height: 100%'};
  max-height: 500px;
  border: 1px solid #ebebeb;
  border-radius: 12px;

  padding: 25px 24px;
  margin-bottom: 14px;
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  margin-bottom: 10px;
`
