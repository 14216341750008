import PaymentModal from 'components/business/Main/Donate/PaymentModal'
import { Values } from 'components/business/Main/Donate/SponsorShiur'
import { createPaymentCardForm } from 'components/business/Main/Donate/createPaymentCardForm'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import LocalStorage from 'utils/localStorage'

function DonateMobile() {
  const [searchParams] = useSearchParams()

  const isApp = searchParams.get('isApp')
  const donationType = searchParams.get('donationType')
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const amount = searchParams.get('amount')
  const comment = searchParams.get('comment')
  const isAnonymous = searchParams.get('isAnonymous')
  const isInMemory = searchParams.get('isInMemory')
  const inMemoryOf = searchParams.get('inMemoryOf')
  const fullName = searchParams.get('fullName')
  const email = searchParams.get('email')
  const series = searchParams.get('series')
  const token = searchParams.get('token')

  const cardFormRef = useRef<CardForm>()
  const [isCardFormCreated, setIsCardFormCreated] = useState(false)

  useEffect(() => {
    if (cardFormRef.current) return
    LocalStorage.token = token

    cardFormRef.current = createPaymentCardForm()
    setIsCardFormCreated(true)
  }, [token])

  const values = {
    donationType,
    date: startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : undefined,
    amount: Number(amount),
    comment,
    isAnonymous: isAnonymous === 'true',
    isInMemory: isInMemory === 'true',
    inMemoryOf,
    fullName,
    email,
    series,
  } as Values

  return (
    <>{isCardFormCreated && <PaymentModal open isApp={isApp === 'true'} values={values} cardForm={cardFormRef} />}</>
  )
}

export default DonateMobile
