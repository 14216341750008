import { SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_SHIURIM_ORDERED_BY_DATE = gql`
  ${SHIUR_FRAGMENT}
  query getShiurimOrderedByDate($date: DateTime!, $take: Int!, $page: Int!, $offset: Boolean) {
    getShiurimOrderedByDate(date: $date, take: $take, page: $page, offset: $offset) {
      items {
        ...ShiurimInfo
      }
      meta {
        total
        next
      }
    }
  }
`
