import { DocumentNode } from 'graphql'
import { ComponentType, FC, PropsWithChildren, createContext, useContext, useState } from 'react'

import { OperationVariables } from '@apollo/client'

export type QueryInfo = { query: DocumentNode; variables: OperationVariables }

type Context = { queryInfo: QueryInfo; setQueryInfo: (queryInfo: QueryInfo) => void }

const QueryInfoContext = createContext<Context>({ queryInfo: null, setQueryInfo: () => void 0 })

export const QueryInfoProvider = ({ children }: PropsWithChildren) => {
  const [queryInfo, setQueryInfo] = useState(null)

  return <QueryInfoContext.Provider value={{ queryInfo, setQueryInfo }}>{children}</QueryInfoContext.Provider>
}

export const useQueryInfo = () => {
  const context = useContext(QueryInfoContext)
  if (!context) {
    throw new Error('useQueryInfo must be used within a QueryInfoProvider')
  }
  return context
}

export const withQueryInfo =
  <P extends object>(Component: ComponentType<P>): FC<P> =>
  ({ ...props }: P) =>
    (
      <QueryInfoProvider>
        <Component {...(props as P)} />
      </QueryInfoProvider>
    )
