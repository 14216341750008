import NotesList from 'components/business/Main/Lists/NotesList'
import Header from 'components/ui/Header'
import { ReactComponent as NotesIcon } from 'components/ui/Sidebar/icons/active/notes.svg'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { ChangeEvent, useState } from 'react'

import { Wrapper } from '../styled'

function Notes() {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 300)

  const searchProps = {
    searchValue: search,
    onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    searchPlaceholder: 'Search notes...',
  }

  return (
    <>
      <Header icon={<NotesIcon />} title="Notes" searchProps={searchProps} />
      <Wrapper id="scrollable-wrapper">
        <NotesList search={debouncedSearch} />
      </Wrapper>
    </>
  )
}

export default Notes
