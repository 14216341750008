import { message } from 'antd'
import { PAGES } from 'components/business/Main/Modals/AddNoteBookmarkModal'
import { BookmarkSaved, NoteSaved } from 'components/business/Main/Player/icons'
import { SuccessMessageLink, SuccessMessageTitle } from 'components/business/Main/Player/styled'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { configureMessagePlacement } from './usePlayerModals'

export const useNoteBookmarkModal = () => {
  const navigate = useNavigate()

  const [isAddNoteBookmarkModalOpen, setIsAddNoteBookmarkModalOpen] = useState(false)

  const onOpenAddNoteBookmarkModal = () => setIsAddNoteBookmarkModalOpen(true)
  const onCloseAddNoteBookmarkModal = (updated?: boolean, page?: PAGES) => {
    setIsAddNoteBookmarkModalOpen(false)

    const isBookmark = page === PAGES.BOOKMARK

    if (updated) {
      configureMessagePlacement()
      message.open({
        icon: isBookmark ? <BookmarkSaved /> : <NoteSaved />,
        content: (
          <>
            <SuccessMessageTitle>{isBookmark ? 'Bookmark added' : 'Note added'}</SuccessMessageTitle>
            <SuccessMessageLink onClick={() => navigate(isBookmark ? ROUTE_PATH.BOOKMARKS : ROUTE_PATH.NOTES)}>
              View
            </SuccessMessageLink>
          </>
        ),
      })
    }
  }

  return { isAddNoteBookmarkModalOpen, onOpenAddNoteBookmarkModal, onCloseAddNoteBookmarkModal }
}
