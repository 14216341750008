import { gql } from '@apollo/client'

export const GET_NOTIFICATIONS = gql`
  query getNotifications($take: Int!, $page: Int!) {
    getNotifications(take: $take, page: $page) {
      items {
        notificationId
        createdAt
        title
        text
        shiurs {
          shiurId
          orderedNum
          title
          subtitle
          duration
          series {
            seriesId
            coverUrl
            title
          }
        }
        announcement {
          announcementId
          type
        }
        article {
          articleId
        }
      }
      meta {
        total
        next
      }
    }
  }
`
