import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 14px 27px 0px;
`

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
`

export const MonthWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`

export const Month = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  margin-right: 12px;
`

export const TodayBtn = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #677fa9;

  padding: 6px 14px;
  border: 1px solid #ebebeb;
  border-radius: 20px;

  cursor: pointer;

  transition: all 0.2s;
  &:hover {
    color: ${COLORS.primary.medium};
    border-color: ${COLORS.primary.medium};
  }
`
