import { ReactComponent as Icon } from 'assets/icons/completed.svg'
import InfoModal from 'components/ui/Modal/InfoModal'

type Props = { open: boolean; onClose: () => void; onComplete: () => void }

function Completed({ open, onClose, onComplete }: Props) {
  return (
    <InfoModal
      open={open}
      onCancel={onClose}
      onBtnClick={onComplete}
      btnText={'GOT IT'}
      icon={<Icon />}
      title="Email change completed"
    />
  )
}

export default Completed
