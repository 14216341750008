import { Slider } from 'antd'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

import { AutomaticallyPlayIcon, NextIcon, PreviousIcon } from './icons'

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 94px;
  width: 100dvw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 35px 14px 14px;

  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  z-index: 1;

  & > * {
    flex: 1;

    @media ${device.laptop} {
      width: calc(100% / 3);
    }
  }

  @media ${device.laptop} {
    height: auto;
    padding: 14px;
  }
`

export const PlayerWrapper = styled.div`
  margin: 0 10px;
`

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 10px;

  & > * {
    flex-shrink: 0;
    &:first-child {
      margin-right: 30px;

      @media ${device.laptop} {
        margin-right: 10px;
      }
      @media ${device.tablet} {
        margin-right: 0;
      }
    }
    &:last-child {
      margin-left: 30px;

      @media ${device.laptop} {
        margin-left: 10px;
      }
      @media ${device.tablet} {
        margin-left: 0;
      }
    }

    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: ${COLORS.primary.medium};
    }
  }

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`

export const PrevButton = styled(PreviousIcon)<{ disabled: boolean }>`
  margin-right: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #677fa9;
      pointer-events: none;
    `}
`

export const NextButton = styled(NextIcon)<{ disabled: boolean }>`
  margin-left: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #677fa9;
      pointer-events: none;
    `}
`

export const MainButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin: 0 20px;

  border-radius: 50%;
  background-color: ${COLORS.primary.dark};

  @media ${device.laptop} {
    margin: 0 12px;
  }

  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.primary.medium};
  }
`

export const AutomaticallyPlayButton = styled(AutomaticallyPlayIcon)<{ enabled: boolean; disabled: boolean }>`
  color: ${({ enabled }) => (enabled ? COLORS.primary.medium : 'currentColor')};
  ${({ disabled }) =>
    disabled &&
    css`
      color: #677fa9;
      pointer-events: none;
    `}
`

export const PlaybackRateText = styled.p<{ isChanged: boolean }>`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;

  color: ${({ isChanged }) => (isChanged ? COLORS.primary.medium : COLORS.text.default)};
  user-select: none;

  @media ${device.tablet} {
    display: none;
  }
`

export const DurationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SliderProgressLine = styled(Slider)`
  width: 468px;
  height: 2px;
  margin: 0 10px;
  padding: 0;

  @media ${device.laptop} {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100dvw;
  }

  .ant-slider-rail {
    width: 468px;
    background-color: #d2dde2 !important;

    @media ${device.laptop} {
      width: 100dvw;
    }
  }
  .ant-slider-track {
    width: 468px;
    background-color: #0f1653 !important;

    @media ${device.laptop} {
      width: 100dvw;
    }
  }

  .ant-slider-handle {
    width: 8px;
    height: 8px;

    background-color: #0f1653;
    border-radius: 50%;

    &::before,
    &::after {
      content: none;
    }

    @media ${device.laptop} {
      display: none;
    }
  }
`

export const DurationText = styled.p`
  width: 30px;
  font-size: 12px;
  line-height: 1.3;
  color: #677fa9;
  text-align: center;

  user-select: none;

  @media ${device.laptop} {
    display: none;
  }
`

export const SuccessMessageTitle = styled.p`
  width: 100%;
  min-width: 200px;
  font-size: 14px;
  line-height: 1.2;
  color: #ffffff;

  text-align: left;
`

export const SuccessMessageLink = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #ffffff;

  cursor: pointer;
`
