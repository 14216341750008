import { Spin } from 'antd'
import Loader from 'components/ui/Loader'
import { getAnnouncementsWithPagination_getAnnouncementsWithPagination_items } from 'graphql/query/__generated__/getAnnouncementsWithPagination'

import LiveEventCard from '../../Cards/LiveEventCard'
import { EmptyContainer, EmptyText } from '../../Home/styled'
import { LoaderWrapper, Wrapper } from './styled'

type Props = {
  items?: getAnnouncementsWithPagination_getAnnouncementsWithPagination_items[]
  onFetchMore?: () => void
  next?: boolean
  loading: boolean
  loadingNext: boolean
}

function LiveEventsList({ items, onFetchMore, next, loading, loadingNext }: Props) {
  if (loading) return <Loader noHeight />

  const isItems = !!items?.length
  return (
    <>
      <Wrapper
        dataLength={items?.length || 0}
        next={onFetchMore}
        hasMore={next}
        loader={null}
        grid={isItems}
        scrollableTarget="scrollable-wrapper"
      >
        {!isItems ? (
          <EmptyContainer>
            <EmptyText>No live events</EmptyText>
          </EmptyContainer>
        ) : (
          items?.map((el) => <LiveEventCard key={el.announcementId} {...el} />)
        )}
        {loadingNext && (
          <LoaderWrapper>
            <Spin />
          </LoaderWrapper>
        )}
      </Wrapper>
    </>
  )
}

export default LiveEventsList
