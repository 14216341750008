import TextArea from 'components/ui/FormElements/TextArea'
import ActionModal from 'components/ui/Modal/ActionModal'
import { useAuth } from 'hooks/useAuth'
import { ChangeEvent, useState } from 'react'

type Props = { open: boolean; onClose: () => void }

function EditBioModal({ open, onClose }: Props) {
  const { user, updateUserInfo } = useAuth()

  const [bio, setBio] = useState(user?.bio)
  const [loading, setLoading] = useState(false)

  const onSave = async () => {
    setLoading(true)

    await updateUserInfo({ username: user.username, gender: user.gender, bio })
    onClose()

    setLoading(false)
  }

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => setBio(e.target.value)

  const isSaveButtonDisabled = !bio
  return (
    <ActionModal
      width={400}
      open={open}
      onCancel={onClose}
      onBtnClick={onSave}
      btnLoading={loading}
      disabled={isSaveButtonDisabled}
      btnText="SAVE"
      title="Edit bio"
    >
      <TextArea maxLength={180} showCount rows={4} value={bio} onChange={onChange} />
    </ActionModal>
  )
}

export default EditBioModal
