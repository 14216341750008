import { device } from 'constants/responsive'
import styled from 'styled-components'

export const TabsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;

  @media ${device.laptopL} {
    overflow: auto;
    margin-bottom: 10px;
  }
`

export const FiltersWrapper = styled.div`
  @media ${device.laptopL} {
    margin-bottom: 20px;
  }
`
