import { Popover as ANTDPopover, Input, PopoverProps } from 'antd'
import styled from 'styled-components'

const MyPopover = ({ className, ...props }: PopoverProps) => <ANTDPopover {...props} overlayClassName={className} />

export const Popover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    overflow: hidden;
    padding: 0;
    border: 1px solid #d9ecf6;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
`

export const SeriesInput = styled(Input)`
  max-height: 50px;
`

export const SeriesImage = styled.img`
  width: 30px;
  height: 30px;

  object-position: center;
  object-fit: cover;

  border-radius: 50%;
`
