import { PropsWithChildren, useEffect, useState } from 'react'

import PeriodFilter, { PERIOD_OPTIONS } from '../PeriodFilter'
import SeriesFilter from '../SeriesFilter'
import SubtopicFilter from '../SubtopicFilter'
import TopicFilter from '../TopicFilter'
import { ShiurimFilters } from '../index'
import { DownArrow, ResetButton, SubmitButton } from '../styled'
import { ButtonsWrapper, ContentWrapper, QuickFilterButton, QuickFilterPopover } from './styled'

export enum QUICK_FILTER_TYPE {
  PERIOD = 'Period',
  SERIES = 'Series',
  TOPIC = 'Topic',
  SUB_TOPIC = 'Sub-topic',
}

const typeToFilterName: { [key in QUICK_FILTER_TYPE]: keyof ShiurimFilters } = {
  [QUICK_FILTER_TYPE.PERIOD]: 'period',
  [QUICK_FILTER_TYPE.SERIES]: 'series',
  [QUICK_FILTER_TYPE.TOPIC]: 'topics',
  [QUICK_FILTER_TYPE.SUB_TOPIC]: 'subtopics',
}

type Props = PropsWithChildren<{
  type: QUICK_FILTER_TYPE
  filters: ShiurimFilters
  newFilters: ShiurimFilters
  noTopics?: boolean
  onResetOne: (filterName: keyof ShiurimFilters) => void
  onApply: () => void
  onChangeFilters: (filters: ShiurimFilters) => void
}>

function QuickFilter({ type, filters, newFilters, noTopics, onResetOne, onApply, onChangeFilters }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const typeToComponent = {
    [QUICK_FILTER_TYPE.PERIOD]: <PeriodFilter filters={newFilters} onChangeFilters={onChangeFilters} />,
    [QUICK_FILTER_TYPE.SERIES]: <SeriesFilter filters={newFilters} onChangeFilters={onChangeFilters} />,
    [QUICK_FILTER_TYPE.TOPIC]: <TopicFilter filters={newFilters} onChangeFilters={onChangeFilters} />,
    [QUICK_FILTER_TYPE.SUB_TOPIC]: (
      <SubtopicFilter filters={newFilters} noTopics={noTopics} onChangeFilters={onChangeFilters} />
    ),
  }

  useEffect(() => {
    onChangeFilters(filters)
  }, [filters, isOpen, onChangeFilters])

  const onReset = () => {
    onResetOne(typeToFilterName[type])
    onToggleVisibility()
  }
  const onApplyClick = () => {
    onApply()
    onToggleVisibility()
  }

  const onToggleVisibility = () => setIsOpen(!isOpen)

  const currentNewFilter = newFilters[typeToFilterName[type]]
  const isPeriodNewFilter = !Array.isArray(currentNewFilter)
  const isButtonsShown = !currentNewFilter
    ? false
    : isPeriodNewFilter
    ? currentNewFilter?.option !== PERIOD_OPTIONS.ALL_TIME && !!currentNewFilter?.endDate
    : !!currentNewFilter?.length

  const currentFilter = filters[typeToFilterName[type]]
  const isPeriodFilter = !Array.isArray(currentFilter)
  const isActive = !!(isPeriodFilter ? currentFilter?.startDate : currentFilter?.length)

  const title = isPeriodFilter ? currentFilter?.option : currentFilter?.[0]?.title
  const count = isPeriodFilter || currentFilter?.length - 1 === 0 ? '' : `+${currentFilter?.length - 1}` || ''
  return (
    <QuickFilterPopover
      placement="bottom"
      trigger="click"
      open={isOpen}
      onOpenChange={setIsOpen}
      destroyTooltipOnHide
      content={
        <ContentWrapper isButtonsShown={isButtonsShown}>
          {typeToComponent[type]}
          {isButtonsShown && (
            <ButtonsWrapper>
              <SubmitButton type="primary" onClick={onApplyClick}>
                APPLY
              </SubmitButton>
              <ResetButton onClick={onReset}>RESET</ResetButton>
            </ButtonsWrapper>
          )}
        </ContentWrapper>
      }
    >
      <QuickFilterButton isActive={isActive} onClick={onToggleVisibility}>
        {`${type}${isActive ? `: ${title} ${count}` : ''}`}
        <DownArrow isActive={isOpen} />
      </QuickFilterButton>
    </QuickFilterPopover>
  )
}

export default QuickFilter
