import { Popover, PopoverProps, Progress, Slider } from 'antd'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

import { DownloadCancelIcon } from '../icons'

export const AdditionalActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    transition: all 0.2s;
    color: #677fa9;

    &:hover {
      color: ${COLORS.primary.medium};
    }

    &:not(:last-child) {
      margin-right: 18px;

      @media ${device.laptopL} {
        margin-right: 12px;
      }
    }

    cursor: pointer;
  }
`

const MyPopover = ({ className, ...props }: PopoverProps) => <Popover {...props} overlayClassName={className} />

export const VolumePopover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 15px 12px;
    border: 1px solid #d9ecf6;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
  }
`

export const VolumeSlider = styled(Slider)`
  width: 2px;
  height: 90px;
  margin: 0;
  padding: 0;

  .ant-slider-rail {
    width: 2px;
    background-color: #d2dde2 !important;
  }
  .ant-slider-track {
    width: 2px;
    background-color: #0f1653 !important;
  }

  .ant-slider-handle {
    width: 8px;
    height: 8px;

    background-color: #0f1653;
    border-radius: 50%;

    inset-inline-start: -2.85px;

    &::before,
    &::after {
      content: none;
    }
  }

  .ant-tooltip {
    display: none;
  }
`

export const DownloadProgressWrapper = styled.div`
  position: relative;
`

export const DownloadProgress = styled(Progress)`
  &.ant-progress.ant-progress-circle .ant-progress-inner {
    background-color: #eaf7fe;
  }
  .ant-progress-circle-trail {
    stroke: #d2dde2;
  }
`

export const CancelIcon = styled(DownloadCancelIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
