import { SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_ONE_SHIURIM = gql`
  ${SHIUR_FRAGMENT}
  query getOneShiurim($shiurId: String!) {
    getOneShiurim(shiurId: $shiurId) {
      ...ShiurimInfo
    }
  }
`
