import { ReactComponent as PlayIcon } from 'assets/icons/play-big.svg'
import { ReactComponent as PlayingIcon } from 'assets/icons/playing-black.svg'
import { ReactComponent as PlayedIcon } from 'assets/icons/tick-circle.svg'
import { Flex } from 'components/common/Flex'
import dayjs from 'dayjs'
import { getShiurimOrderedByDate_getShiurimOrderedByDate_items } from 'graphql/query/__generated__/getShiurimOrderedByDate'
import { convertMsInSecs } from 'helpers/convertMsInSecs'
import { getDateWithoutTimezone } from 'helpers/getDateWithoutTimezone'
import { usePlayer } from 'hooks/usePlayer'
import { useQueryInfo } from 'hooks/useQueryInfo'
import { useEffect, useState } from 'react'

import { ShiurimAutoPlayInfo } from '../../Cards/ShiurimCard'
import { Image, ImageContainer, MetaInfo, Number, PlayerController, Subtitle, Title, TitleRow, Wrapper } from './styled'

type Props = getShiurimOrderedByDate_getShiurimOrderedByDate_items & { index: number }

function ShiurimCard({ index, ...shiur }: Props) {
  const { queryInfo } = useQueryInfo()
  const [shiurQueryInfo] = useState(queryInfo)

  const { id, isPlaying, duration: playerDuration, progress, playerRef, play, pause } = usePlayer()
  const { shiurId, orderedNum, title, subtitle, publicationDate, series, duration, listenedDuration } = shiur

  const isCurrentInitialized = id === shiurId
  const isCurrentPlaying = isPlaying && isCurrentInitialized

  const shiurimAutoPlayInfo: ShiurimAutoPlayInfo = { ...shiurQueryInfo, index }

  useEffect(() => {
    if (
      shiur.listenedDuration &&
      dayjs.duration(shiur.duration - shiur.listenedDuration).asSeconds() > 1 &&
      isCurrentInitialized &&
      playerRef?.current
    ) {
      playerRef.current.seekTo(Math.round(convertMsInSecs(shiur.listenedDuration)), 'seconds')
    }
  }, [isCurrentInitialized, playerRef])

  const togglePlayer = async () => {
    if (isCurrentPlaying) {
      return pause()
    }

    if (isCurrentInitialized && !isPlaying) {
      return await play()
    }

    if (!isCurrentInitialized) {
      await play(shiur, shiurimAutoPlayInfo)
    }
  }

  return (
    <Wrapper onClick={togglePlayer}>
      <ImageContainer>
        <Image src={`${process.env.REACT_APP_AWS_S3_URL}${series.coverUrl}`} />
        <PlayerController>{isCurrentPlaying ? <PlayingIcon /> : <PlayIcon />}</PlayerController>
      </ImageContainer>
      <TitleRow>
        {!!orderedNum && <Number>Shiur {orderedNum}.</Number>}
        <Title>{title}</Title>
      </TitleRow>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <MetaInfo>
        {dayjs.duration(shiur.duration - shiur.listenedDuration).asSeconds() < 1 &&
        (!isCurrentPlaying || (playerDuration - progress?.playedSeconds === 0 && progress?.playedSeconds !== 0)) ? (
          <Flex alignCenter>
            Played&nbsp;
            <PlayedIcon />
          </Flex>
        ) : (
          <>
            {getDateWithoutTimezone(publicationDate).toDate().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}{' '}
            ·{' '}
            {isCurrentInitialized
              ? `${dayjs
                  .duration(playerDuration - progress?.playedSeconds || convertMsInSecs(duration), 's')
                  .format('m:ss')} min left`
              : listenedDuration
              ? `${dayjs.duration(convertMsInSecs(duration - listenedDuration), 'seconds').format('m:ss')} min left`
              : `${dayjs.duration(convertMsInSecs(duration), 'seconds').format('m:ss')} min`}
          </>
        )}
      </MetaInfo>
    </Wrapper>
  )
}

export default ShiurimCard
