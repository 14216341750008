import { AnnouncementType } from '__generated__/globalTypes'
import LiveEventCard from 'components/business/Main/Cards/LiveEventCard'
import PublicLectureCard from 'components/business/Main/Cards/PublicLectureCard'
import SpecialPodcastCard from 'components/business/Main/Cards/SpecialPodcastCard'
import Loader from 'components/ui/Loader'
import { deviceWidth } from 'constants/responsive'
import {
  getAnnouncementsWithPagination,
  getAnnouncementsWithPaginationVariables,
} from 'graphql/query/__generated__/getAnnouncementsWithPagination'
import { GET_ANNOUNCEMENTS_WITH_PAGINATION } from 'graphql/query/getAnnouncementsWithPagination'
import { useAuth } from 'hooks/useAuth'
import { ROUTE_PATH } from 'routes'

import { useQuery } from '@apollo/client'

import { List, Title, TitleDivider, TitleRow, ViewAllFakeLink, ViewAllLink } from './styled'

type Props = { openRegisterToContinueModal: () => void }

const typeToComponent = {
  [AnnouncementType.EVENT]: LiveEventCard,
  [AnnouncementType.LECTURE]: PublicLectureCard,
  [AnnouncementType.PODCAST]: SpecialPodcastCard,
}

function Announcements({ openRegisterToContinueModal }: Props) {
  const { user } = useAuth()

  const isMobile = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])

  const { data, loading } = useQuery<getAnnouncementsWithPagination, getAnnouncementsWithPaginationVariables>(
    GET_ANNOUNCEMENTS_WITH_PAGINATION,
    { variables: { page: 1, take: isMobile ? 1 : 5 } }
  )

  return (
    <>
      <TitleRow>
        <Title>Announcements</Title>
        <TitleDivider />
        {user ? (
          <ViewAllLink to={ROUTE_PATH.ANNOUNCEMENTS}>View all</ViewAllLink>
        ) : (
          <ViewAllFakeLink onClick={openRegisterToContinueModal}>View all</ViewAllFakeLink>
        )}
      </TitleRow>
      <List>
        {loading ? (
          <Loader />
        ) : (
          data?.getAnnouncementsWithPagination?.items?.map((el) => {
            const Card = typeToComponent[el.type]
            return <Card key={el.announcementId} showLabel {...el} />
          })
        )}
      </List>
    </>
  )
}

export default Announcements
