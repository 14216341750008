import { SponsorshipPeriod } from '__generated__/globalTypes'

import { donateTypeToPriceApp, donateTypeToPriceShiur } from './DonateTypeSelect'

export const calculateAmountOfDays = (amount: number, isApp: boolean) => {
  const donateTypeToPrice = isApp ? donateTypeToPriceApp : donateTypeToPriceShiur

  const months = Math.floor(amount / donateTypeToPrice[SponsorshipPeriod.monthly])
  const weeks = Math.floor(
    (amount - months * donateTypeToPrice[SponsorshipPeriod.monthly]) / donateTypeToPrice[SponsorshipPeriod.weekly]
  )
  const days = Math.floor(
    (amount -
      months * donateTypeToPrice[SponsorshipPeriod.monthly] -
      weeks * donateTypeToPrice[SponsorshipPeriod.weekly]) /
      donateTypeToPrice[SponsorshipPeriod.day]
  )

  return months * 30 + weeks * 7 + days || 0
}
