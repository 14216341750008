import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 400px;
  height: 540px;
  padding: 20px 24px;

  @media ${device.laptopL} {
    width: 350px;
    height: 400px;
  }
`

export const CloseIcon = styled(CrossIcon)`
  position: absolute;
  top: 20px;
  right: 24px;

  cursor: pointer;
`

export const Heading = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  padding-bottom: 20px;

  border-bottom: 1px solid #ebebeb;
`
