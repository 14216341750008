import { SERIES_IN_SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_ALL_USER_SHIUR_LISTENINGS = gql`
  ${SERIES_IN_SHIUR_FRAGMENT}
  query getAllUserShiurListenings($take: Int!, $page: Int!, $title: String) {
    getAllUserShiurListenings(take: $take, page: $page, title: $title) {
      items {
        shiurId
        audioUrl
        orderedNum
        title
        subtitle
        duration
        listenedDuration
        topic {
          topicId
          title
        }
        subtopicTitles
        createdAt
        publicationDate
        series {
          ...SeriesInfoInShiurim
        }
        favoriteInfo {
          isFavorite
        }
        listenedAt
      }
      meta {
        total
        next
      }
    }
  }
`
