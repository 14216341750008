import { BROWSE_TABS } from 'pages/Main/Browse'

import { Item, Text, Wrapper } from './styled'

type Props = {
  selectedTab: BROWSE_TABS
  onChangeTab: (tab: BROWSE_TABS) => void
  results?: {
    [BROWSE_TABS.ALL]: number
    [BROWSE_TABS.SERIES]: number
    [BROWSE_TABS.SHIURIM]: number
    [BROWSE_TABS.TOPICS]: number
  }
}

function Tabs({ selectedTab, onChangeTab, results }: Props) {
  const tabs = Object.values(BROWSE_TABS)
  return (
    <Wrapper>
      {tabs.map((el) => (
        <Item onClick={() => onChangeTab(el)} selected={selectedTab === el} key={el}>
          <Text>{`${el}${results ? `・${results[el]}` : ''}`}</Text>
        </Item>
      ))}
    </Wrapper>
  )
}

export default Tabs
