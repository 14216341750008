import { useState } from 'react'

import Completed from './Completed'
import InputEmailAndText from './InputEmailAndText'
import { Content, Title, Wrapper } from './styled'

enum STEPS {
  INPUT = 'input',
  COMPLETED = 'completed',
}

function Support() {
  const [email, setEmail] = useState('')
  const [step, setStep] = useState<STEPS>(STEPS.INPUT)

  const onQuestionSent = (email: string) => {
    if (email) setEmail(email)
    setStep(STEPS.COMPLETED)
  }

  const onCompleted = () => {
    setEmail('')
    setStep(STEPS.INPUT)
  }

  return (
    <Wrapper>
      <Title>Support Form</Title>
      <Content>
        {step === STEPS.INPUT ? (
          <InputEmailAndText nextStep={onQuestionSent} />
        ) : (
          <Completed email={email} onCompleted={onCompleted} />
        )}
      </Content>
    </Wrapper>
  )
}

export default Support
