import { SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_ANNOUNCEMENTS_WITH_PAGINATION = gql`
  ${SHIUR_FRAGMENT}
  query getAnnouncementsWithPagination($take: Int!, $page: Int!, $type: AnnouncementType, $title: String) {
    getAnnouncementsWithPagination(take: $take, page: $page, type: $type, title: $title) {
      items {
        announcementId
        type
        title
        text
        publicationDate
        image
        eventDate
        shiur {
          ...ShiurimInfo
        }
      }
      meta {
        total
        next
      }
    }
  }
`
