import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const EmailText = styled.span`
  color: ${COLORS.text.default};
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 28px;
`

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  text-align: center;

  color: ${COLORS.primary.dark};
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;

  color: #696b7b;

  margin: 12px 0 20px;
`
