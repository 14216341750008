import { InputProps } from 'antd'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'

import { SearchInput } from './styled'

type Props = InputProps & { header?: boolean }

function Search({ header, ...props }: Props) {
  return <SearchInput header={header} allowClear prefix={<SearchIcon />} {...props} />
}

export default Search
