import { SponsorshipPeriod } from '__generated__/globalTypes'
import dayjs from 'dayjs'

import { HDate } from '@hebcal/core'

export const getDateValue = (donationType: SponsorshipPeriod, date: [dayjs.Dayjs, dayjs.Dayjs]) => {
  return date?.[0] && date?.[1]
    ? donationType === SponsorshipPeriod.day
      ? new Date(date[0].toDate()).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      : donationType === SponsorshipPeriod.weekly
      ? `${date[0].format('MMM D')}-${date[1].format('D, YYYY')}`
      : donationType === SponsorshipPeriod.monthly
      ? `${new HDate(date[0].toDate()).getMonthName()}, ${new HDate(date[0].toDate()).getFullYear()}`
      : date[1].format('MMMM, YYYY')
    : undefined
}
