import { Collapse, Drawer } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled(Drawer)`
  border-radius: 12px 0px 0px 12px;

  .ant-drawer-header {
    padding: 24px 24px 0 24px;
    border-bottom: none;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .ant-drawer-title {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 500;
    color: ${COLORS.text.default};
  }
  .ant-drawer-close {
    margin: 0;
  }

  .ant-drawer-footer {
    padding: 16px 24px;
    border-radius: 12px 12px 0px 0px;
  }
`

export const CollapseStyled = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    padding: 16px 0px;
  }

  .ant-collapse-header-text {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: ${COLORS.text.default};
  }

  & > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-block: 0;
    padding: 0;
  }
`
