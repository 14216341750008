import { Spin } from 'antd'
import { ReactComponent as NextIcon } from 'assets/icons/slider-arrow-next.svg'
import { ReactComponent as PreviousIcon } from 'assets/icons/slider-arrow-previous.svg'
import { Flex } from 'components/common/Flex'
import dayjs from 'dayjs'
import {
  getShiurimFromFollowed,
  getShiurimFromFollowedVariables,
} from 'graphql/query/__generated__/getShiurimFromFollowed'
import { GET_SHIURIM_FROM_FOLLOWED } from 'graphql/query/getShiurimFromFollowed'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { Ref, useEffect, useMemo, useRef, useState } from 'react'
import { ROUTE_PATH } from 'routes'
import { Swiper, SwiperClass, SwiperRef } from 'swiper/react'

import { useQuery } from '@apollo/client'

import PlayAllShiursButton from '../../Shiurim/PlayAllShiursButton'
import ShiurimCard from '../ShiurimCard'
import { EmptyContainer, EmptyText } from '../styled'
import {
  ButtonsContainer,
  ListWrapper,
  SliderButton,
  SwiperSlide,
  Title,
  TitleDivider,
  TitleRow,
  ViewAllLink,
  Wrapper,
} from './styled'

function Following() {
  const { setQueryInfo } = useQueryInfo()

  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false)
  const swiperRef: Ref<SwiperRef> = useRef()

  const variables: getShiurimFromFollowedVariables = useMemo(
    () => ({
      take: 25,
      page: 1,
      searchParams: {
        dateOrderType: 'DESC',
      },
    }),
    []
  )
  const { data, loading } = useQuery<getShiurimFromFollowed, getShiurimFromFollowedVariables>(
    GET_SHIURIM_FROM_FOLLOWED,
    { variables }
  )

  useEffect(() => {
    setQueryInfo({
      query: GET_SHIURIM_FROM_FOLLOWED,
      variables,
    })
  }, [setQueryInfo, variables])

  const onSlideChange = (swiper: SwiperClass) => {
    if (swiper.isBeginning) setIsPrevBtnDisabled(true)
    if (swiper.isEnd) setIsNextBtnDisabled(true)

    if (!swiper.isBeginning) setIsPrevBtnDisabled(false)
    if (!swiper.isEnd) setIsNextBtnDisabled(false)
  }

  const items = data?.getShiurimFromFollowed?.items
  return (
    <Wrapper>
      <Flex justifyBetween alignCenter>
        <TitleRow>
          <Flex alignCenter>
            <Title>Following</Title>
            <TitleDivider />
            <ViewAllLink to={ROUTE_PATH.FOLLOWING}>View all</ViewAllLink>
          </Flex>
          <PlayAllShiursButton date={dayjs()} isFollowing />
        </TitleRow>
        {!loading && !!items?.length && (
          <ButtonsContainer>
            <SliderButton disabled={isPrevBtnDisabled} onClick={() => swiperRef.current.swiper.slidePrev()}>
              <PreviousIcon />
            </SliderButton>
            <SliderButton disabled={isNextBtnDisabled} onClick={() => swiperRef.current.swiper.slideNext()}>
              <NextIcon />
            </SliderButton>
          </ButtonsContainer>
        )}
      </Flex>
      <ListWrapper>
        {loading ? (
          <EmptyContainer>
            <Spin />
          </EmptyContainer>
        ) : items?.length ? (
          <Swiper
            slidesPerView="auto"
            slidesPerGroupAuto
            ref={swiperRef}
            spaceBetween={14}
            onInit={onSlideChange}
            onReachBeginning={onSlideChange}
            onReachEnd={onSlideChange}
            onSlideChange={onSlideChange}
          >
            {items.map((el, i) => (
              <SwiperSlide key={el.shiurId}>
                <ShiurimCard index={i} {...el} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <EmptyContainer>
            <EmptyText>No following shiurim</EmptyText>
          </EmptyContainer>
        )}
      </ListWrapper>
    </Wrapper>
  )
}

export default withQueryInfo(Following)
