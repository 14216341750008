import { ReactComponent as TimeIcon } from 'assets/icons/timecode-player.svg'
import { Flex } from 'components/common/Flex'
import dayjs from 'dayjs'
import { getArticlesWithPagination_getArticlesWithPagination_items } from 'graphql/query/__generated__/getArticlesWithPagination'
import { useState } from 'react'

import KnowledgeBaseDrawer from '../../Community/KnowledgeBaseDrawer'
import { Content, DateText, DurationText, DurationWrapper, Image, Text, Title, Wrapper } from './styled'

type Props = getArticlesWithPagination_getArticlesWithPagination_items

function KnowledgeBaseCard(props: Props) {
  const [open, setOpen] = useState(false)

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const { image, title, previewText, minutesToRead, publicationDate } = props
  return (
    <>
      <Wrapper onClick={onOpen}>
        <Image src={`${process.env.REACT_APP_AWS_S3_URL}${image}`} />
        <Content>
          <Title>{title}</Title>
          <Text>{previewText.length > 90 ? `${previewText.slice(0, 90)}...` : previewText}</Text>
          <Flex alignCenter>
            <DurationWrapper>
              <TimeIcon />
              <DurationText>{dayjs.duration(minutesToRead, 'm').format('m')} min read</DurationText>
            </DurationWrapper>
            <DateText>
              {new Date(publicationDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </DateText>
          </Flex>
        </Content>
      </Wrapper>
      <KnowledgeBaseDrawer {...props} open={open} onClose={onClose} />
    </>
  )
}

export default KnowledgeBaseCard
