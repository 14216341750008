import { ReactComponent as CloseIcon } from 'assets/icons/cross.svg'
import { ReactComponent as TimeIcon } from 'assets/icons/timecode-player.svg'
import { Flex } from 'components/common/Flex'
import dayjs from 'dayjs'
import { getArticlesWithPagination_getArticlesWithPagination_items } from 'graphql/query/__generated__/getArticlesWithPagination'
import parse, { Element, HTMLReactParserOptions, attributesToProps, domToReact } from 'html-react-parser'

import {
  A,
  BlockquoteLeft,
  BlockquoteRight,
  BlockquoteWrapper,
  CloseWrapper,
  Content,
  DateText,
  Divider,
  DurationText,
  DurationWrapper,
  H1,
  H2,
  H3,
  H4,
  H5,
  IMG,
  Image,
  OL,
  P,
  STRONG,
  Title,
  UL,
  Wrapper,
} from './styled'

type Props = getArticlesWithPagination_getArticlesWithPagination_items & {
  open: boolean
  onClose: () => void
}

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    const element = domNode as Element

    if (element.type === 'tag') {
      switch (element.name) {
        case 'h1':
          return <H1>{domToReact(element.children, options)}</H1>

        case 'h2':
          return <H2>{domToReact(element.children, options)}</H2>

        case 'h3':
          return <H3>{domToReact(element.children, options)}</H3>

        case 'h4':
          return <H4>{domToReact(element.children, options)}</H4>

        case 'h5':
          return <H5>{domToReact(element.children, options)}</H5>

        case 'p':
          return <P>{domToReact(element.children, options)}</P>

        case 'strong':
          return <STRONG>{domToReact(element.children, options)}</STRONG>

        case 'ul':
          return <UL>{domToReact(element.children, options)}</UL>

        case 'ol':
          return <OL>{domToReact(element.children, options)}</OL>

        case 'img':
          return <IMG {...attributesToProps(element.attribs)} />

        case 'a':
          return (
            <A target="blank" {...attributesToProps(element.attribs)}>
              {domToReact(element.children, options)}
            </A>
          )

        case 'blockquote':
          return (
            <BlockquoteWrapper>
              <BlockquoteLeft />
              {domToReact(element.children, options)}
              <BlockquoteRight />
            </BlockquoteWrapper>
          )
      }
    }
  },
}

function KnowledgeBaseDrawer({ open, title, image, minutesToRead, publicationDate, text, onClose }: Props) {
  return (
    <Wrapper placement="right" width={610} closeIcon={<CloseIcon />} onClose={onClose} open={open}>
      <CloseWrapper onClick={onClose}>
        <CloseIcon />
      </CloseWrapper>
      <Image src={`${process.env.REACT_APP_AWS_S3_URL}${image}`} />
      <Content>
        <Title>{title}</Title>
        <Flex alignCenter>
          <DurationWrapper>
            <TimeIcon />
            <DurationText>{dayjs.duration(minutesToRead, 'm').format('m')} min read</DurationText>
          </DurationWrapper>
          <DateText>
            {new Date(publicationDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </DateText>
        </Flex>
        <Divider />
        {parse(text, options)}
      </Content>
    </Wrapper>
  )
}

export default KnowledgeBaseDrawer
