import { ReactComponent as NoDataIcon } from 'assets/icons/saved-big.svg'
import ShiurimList from 'components/business/Main/Lists/ShiurimList'
import Header from 'components/ui/Header'
import Loader from 'components/ui/Loader'
import NoData from 'components/ui/NoData'
import { ReactComponent as SavedIcon } from 'components/ui/Sidebar/icons/active/saved.svg'
import {
  getAllUserFavoriteShiurs,
  getAllUserFavoriteShiursVariables,
} from 'graphql/query/__generated__/getAllUserFavoriteShiurs'
import { GET_ALL_USER_FAVORITE_SHIURS } from 'graphql/query/getAllUserFavoriteShiurs'
import { useAuth } from 'hooks/useAuth'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { ChangeEvent, useState } from 'react'

import { useQuery } from '@apollo/client'

import { Wrapper } from '../styled'

const initialPagination = {
  take: 30,
  page: 1,
}

function Favorite() {
  const { user } = useAuth()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 300)

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore, refetch } = useQuery<getAllUserFavoriteShiurs, getAllUserFavoriteShiursVariables>(
    GET_ALL_USER_FAVORITE_SHIURS,
    { variables: { title: debouncedSearch, ...initialPagination }, fetchPolicy: 'network-only', skip: !user }
  )

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getAllUserFavoriteShiurs: {
            ...previousQueryResult.getAllUserFavoriteShiurs,
            ...fetchMoreResult.getAllUserFavoriteShiurs,
            items: [
              ...previousQueryResult.getAllUserFavoriteShiurs.items,
              ...fetchMoreResult.getAllUserFavoriteShiurs.items,
            ],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const searchProps = {
    searchValue: search,
    onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    searchPlaceholder: 'Search shiurim...',
  }

  const itemsLength = data?.getAllUserFavoriteShiurs?.items?.length

  return (
    <>
      <Header icon={<SavedIcon />} title="Saved Shiurim" searchProps={searchProps} />
      <Wrapper id="scrollable-wrapper">
        {!itemsLength && loading ? (
          <Loader />
        ) : !itemsLength ? (
          <NoData
            icon={<NoDataIcon />}
            title={user ? "You haven't saved any Shiurim yet" : 'Nothing here... yet'}
            description={
              user
                ? 'Store your favorite lectures inside this section'
                : 'Create an account to track your listening history'
            }
          />
        ) : (
          <ShiurimList
            items={data?.getAllUserFavoriteShiurs?.items}
            next={data?.getAllUserFavoriteShiurs?.meta?.next}
            loadingNext={loadingNext}
            refetch={refetch}
            onFetchMore={onFetchMore}
            loading={loading}
          />
        )}
      </Wrapper>
    </>
  )
}

export default Favorite
