import NewShiurim from '../NewSiurim'
import Recommended from '../Recommended'
import { Wrapper } from './styled'

type Props = { openRegisterToContinueModal: () => void }

function NewAndRecommended({ openRegisterToContinueModal }: Props) {
  return (
    <Wrapper>
      <NewShiurim openRegisterToContinueModal={openRegisterToContinueModal} />
      <Recommended openRegisterToContinueModal={openRegisterToContinueModal} />
    </Wrapper>
  )
}

export default NewAndRecommended
