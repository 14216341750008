import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${device.laptop} {
    flex-direction: column;
  }
`

export const ImageContainer = styled.div`
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
  margin-right: 28px;

  border-radius: 24px;

  @media ${device.laptop} {
    margin-right: 0;
    margin-bottom: 12px;
  }
`

export const Image = styled.img`
  width: 240px;
  height: 240px;

  object-fit: cover;
  object-position: center;

  @media ${device.laptop} {
    width: 200px;
    height: 200px;
  }
`

export const SponsorsSection = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 0px;

  height: 58px;
`

export const SponsorLabelWrapper = styled.div`
  width: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
`

export const SponsorLabel = styled.p`
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
`

export const SponsorNameWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
`

export const SponsorName = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #ffffff;

  margin-bottom: 2px;
`

export const SponsorInHonor = styled.p`
  font-size: 10px;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.5);
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TitleRow = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;

  @media ${device.laptop} {
    justify-content: center;
  }
`

export const Number = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-right: 2px;

  white-space: nowrap;
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;

  color: ${COLORS.text.default};
`

export const Subtitle = styled.p`
  font-size: 18px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  margin-bottom: 15px;

  @media ${device.laptop} {
    text-align: center;
  }
`

export const List = styled.ul`
  & > *:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
`

export const Item = styled.li`
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Label = styled.p`
  width: 100px;
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.hint};
  margin-right: 10px;
`

export const Value = styled.p`
  width: 240px;
  font-size: 16px;
  line-height: 1.25;
  text-align: right;
  color: ${COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const SeriesValue = styled.p<{ color?: string }>`
  width: 240px;
  font-size: 16px;
  line-height: 1.25;
  text-align: right;
  color: ${({ color }) => color || COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    opacity: 0.7;
  }
`
