import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

export const Header = styled.div<{ noTopPadding?: boolean }>`
  padding: ${({ noTopPadding }) => (noTopPadding ? 0 : '18px')} 15px 8px;
  border-bottom: 1px solid #ebebeb;
`

export const Title = styled.p<{ heading?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: ${COLORS.text.default};

  margin-bottom: ${({ heading }) => (heading ? '15px' : '8px')};
`

export const WeekDaysRow = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 30px;
  justify-items: center;
  padding: 0px 11px;
`

export const WeekDay = styled.p`
  font-size: 12px;
  line-height: 1.3;
  color: #696b7b;
`

export const MainWrapper = styled.div`
  height: 340px;
  overflow: auto;
  padding: 20px 0px;

  @media ${device.tablet} {
    height: 300px;
  }
`

export const MonthWrapper = styled.div`
  padding: 0px 16px;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
`

export const DaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 22px;
  grid-row-gap: 10px;
  justify-items: center;
  align-items: center;
  padding: 12px 8px;

  border-top: 1px solid #ebebeb;

  @media ${device.tablet} {
    grid-column-gap: 12px;
    padding: 12px 0px;
  }
`

export const Day = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.default};
  text-align: center;

  transition: all 0.2s;
`

export const DayWrapper = styled.div<{ selected?: boolean }>`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover > ${Day} {
    color: ${COLORS.primary.medium};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${COLORS.primary.medium};
      border-radius: 50%;

      & > ${Day}, &:hover > ${Day} {
        font-weight: 500;
        color: #ffffff;
      }
    `}
`
