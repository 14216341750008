import Loader from 'components/ui/Loader'
import {
  getSeriesOrderedByShiurCount,
  getSeriesOrderedByShiurCountVariables,
} from 'graphql/query/__generated__/getSeriesOrderedByShiurCount'
import { GET_SERIES_ORDERED_BY_SHIUR_COUNT } from 'graphql/query/getSeriesOrderedByShiurCount'

import { useQuery } from '@apollo/client'

import SeriesCard from '../../Cards/SeriesCard'
import { EmptyContainer, EmptyText } from '../../Home/styled'
import { List, Title, Wrapper } from './styled'

function PopularSeries() {
  const { data, loading } = useQuery<getSeriesOrderedByShiurCount, getSeriesOrderedByShiurCountVariables>(
    GET_SERIES_ORDERED_BY_SHIUR_COUNT,
    { variables: { take: 3, page: 1 } }
  )

  const items = data?.getSeriesOrderedByShiurCount?.items
  const isItems = !loading && !!items?.length
  return (
    <Wrapper>
      <Title>Other popular Series</Title>
      <List grid={isItems}>
        {loading ? (
          <Loader />
        ) : !items?.length ? (
          <EmptyContainer>
            <EmptyText>No series</EmptyText>
          </EmptyContainer>
        ) : (
          items.map((el) => <SeriesCard key={el.seriesId} showCount {...el} />)
        )}
      </List>
    </Wrapper>
  )
}

export default PopularSeries
