import { Progress } from 'antd'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

type Sizes = { small?: boolean; big?: boolean }

type Props = { playing?: boolean; listened?: boolean } & Sizes

export const Wrapper = styled.div<Props>`
  overflow: hidden;
  position: relative;
  padding: ${({ small, big }) => (small ? '14px' : big ? '24px' : '16px')};
  border: ${({ playing, listened }) => (playing || listened ? '1px solid #EAF7FE' : '1px solid #ebebeb')};
  border-radius: 12px;
  background-color: ${({ playing, listened }) => (playing || listened) && '#F4FBFF'};

  ${({ playing }) =>
    playing &&
    css`
      &::before {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 4px;
        background-color: ${COLORS.primary.medium};

        content: ' ';
      }
    `}

  transition: all 0.2s;
  &:hover {
    border-color: ${COLORS.primary.light};
  }

  cursor: pointer;
`

export const LeftWrapper = styled.div`
  width: calc(100% - 70px); // 100% - width of 3 dots elem
  display: flex;
  align-items: center;
`

export const Image = styled.img<Sizes>`
  height: ${({ small, big }) => (small ? '84px' : big ? '169px' : '124px')};
  width: ${({ small, big }) => (small ? '84px' : big ? '169px' : '124px')};

  margin-right: ${({ small, big }) => (small ? '12px' : big ? '16px' : '16px')};

  object-fit: cover;
  object-position: center;

  border-radius: 12px;

  @media ${device.laptop} {
    width: 70px;
    height: 70px;
  }
`

export const ContentWrapper = styled.div<Sizes>`
  width: calc(100% - ${({ small, big }) => (small ? '84px' : big ? '169px' : '124px')});

  @media ${device.laptop} {
    width: calc(100% - 70px); // 100% - width of image
  }
`

export const TitleRow = styled.div<Sizes>`
  display: flex;
  align-items: center;

  margin-bottom: ${({ big }) => (big ? '12px' : '8px')};

  @media ${device.laptop} {
    margin-bottom: 4px;
  }
`

export const Number = styled.p<Sizes>`
  font-weight: 600;
  font-size: ${({ small, big }) => (small ? '16px' : big ? '20px' : '18px')};
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-right: ${({ big }) => (big ? '4px' : '2px')};

  white-space: nowrap;

  @media ${device.laptop} {
    font-size: 16px;
  }
`

export const Title = styled.p<Sizes>`
  font-weight: 600;
  font-size: ${({ small, big }) => (small ? '16px' : big ? '20px' : '18px')};
  line-height: 1.2;
  color: ${COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${device.laptop} {
    font-size: 16px;
  }
`

export const Subtitle = styled.p<Sizes>`
  font-size: ${({ small, big }) => (small ? '14px' : big ? '18px' : '16px')};
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-bottom: ${({ big }) => (big ? '24px' : '12px')};

  @media ${device.laptop} {
    font-size: 14px;
    margin-bottom: 6px;
  }
`

export const Description = styled.p<Sizes>`
  font-size: ${({ big }) => (big ? '18px' : '16px')};
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-bottom: ${({ big }) => (big ? '24px' : '12px')};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${device.laptop} {
    font-size: 14px;
    margin-bottom: 6px;
  }
`

export const SeriesTitle = styled.span<{ color?: string }>`
  color: ${({ color }) => color || COLORS.text.default};

  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    opacity: 0.7;
  }
`

export const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

export const PlayButtonWrapper = styled.div<Sizes>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${({ small, big }) => (small ? '26px' : big ? '40px' : '32px')};
  height: ${({ small, big }) => (small ? '26px' : big ? '40px' : '32px')};
  margin-right: ${({ small, big }) => (small ? '8px' : big ? '10px' : '12px')};

  background: ${COLORS.primary.dark};
  box-shadow: 0px 0px 20px rgba(42, 76, 96, 0.1);
  border-radius: 50%;

  cursor: pointer;

  @media ${device.laptop} {
    width: 26px;
    height: 26px;
  }
`

export const PlayerDescription = styled.p<Sizes>`
  flex-shrink: 0;
  font-size: ${({ big }) => (big ? '16px' : '14px')};
  line-height: 1.3;
  color: ${COLORS.text.default};

  @media ${device.laptop} {
    font-size: 12px;
  }
`

export const ActionsContainer = styled.div<Sizes>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ small, big }) => (big || small ? 'flex-end' : 'space-between')};
  align-items: flex-end;

  margin-left: 8px;
  margin-bottom: 5px;

  svg {
    cursor: pointer;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 12px;
  }
`

export const ProgressLine = styled(Progress)`
  max-width: 250px;
  width: calc(100% - 210px);
  flex-shrink: 0;

  margin: 0 10px;

  @media ${device.laptop} {
    width: calc(100% - 150px);
  }

  &,
  .ant-progress-outer,
  .ant-progress-bg {
    line-height: 1px;
    height: 4px !important;
  }
`
