import dayjs from 'dayjs'
import { Ref, useEffect, useState } from 'react'
import { Swiper, SwiperClass, SwiperRef } from 'swiper/react'

import { CalendarWrapper, Day, ItemWrapper, SwiperSlide, WeekDay } from './styled'

type Props = {
  swiperRef: Ref<SwiperRef>
  selectedDate: Date
  onChangeDate: (date: Date) => void
  onSlideChange: (swiper: SwiperClass) => void
}

function Calendar({ swiperRef, selectedDate, onChangeDate, onSlideChange }: Props) {
  const [previous30Days, setPrevious30Days] = useState<dayjs.Dayjs[]>([])

  const getPrevious30Days = () => {
    const arr = []

    for (let i = 0; i > -30; i--) {
      const date = dayjs().add(i, 'day')

      if (date.day() !== 6) {
        arr.push(date)
      }
    }

    setPrevious30Days(arr.reverse())
  }

  useEffect(() => {
    getPrevious30Days()
  }, [])

  return (
    <CalendarWrapper>
      <Swiper
        ref={swiperRef}
        initialSlide={30}
        slidesPerGroup={6}
        slidesPerView={6}
        spaceBetween={10}
        onSlideChange={onSlideChange}
      >
        {previous30Days.map((el) => (
          <SwiperSlide key={el.toISOString()}>
            <ItemWrapper
              onClick={() => onChangeDate(el.toDate())}
              selected={dayjs(selectedDate).isSame(el, 'date')}
              today={el.isToday()}
            >
              <WeekDay>{el.toDate().toLocaleDateString('en-US', { weekday: 'short' })}</WeekDay>
              <Day>{el.date()}</Day>
            </ItemWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </CalendarWrapper>
  )
}

export default Calendar
