import { ReactComponent as CloseIcon } from 'assets/icons/cross.svg'
import { ReactComponent as MoreIconBig } from 'assets/icons/dots-big.svg'
import { ReactComponent as SavedIcon } from 'assets/icons/saved.svg'
import { COLORS } from 'constants/colors'
import { deviceWidth } from 'constants/responsive'
import { addShiurToFavorite, addShiurToFavoriteVariables } from 'graphql/mutation/__generated__/addShiurToFavorite'
import {
  removeShiurFromFavorite,
  removeShiurFromFavoriteVariables,
} from 'graphql/mutation/__generated__/removeShiurFromFavorite'
import { ADD_SHIUR_TO_FAVORITE } from 'graphql/mutation/addShiurToFavorite'
import { REMOVE_SHIUR_FROM_FAVORITE } from 'graphql/mutation/removeShiurFromFavorite'
import { getOneShiurim, getOneShiurimVariables } from 'graphql/query/__generated__/getOneShiurim'
import { GET_ONE_SHIURIM } from 'graphql/query/getOneShiurim'
import { useAuth } from 'hooks/useAuth'
import { DOWNLOADING_STATES } from 'hooks/useDownloadAudio'
import { usePlayer } from 'hooks/usePlayer'

import { useMutation, useQuery } from '@apollo/client'

import {
  AddNote,
  DownloadFinishedIcon,
  DownloadIcon,
  QueueIcon,
  SaveIcon,
  ShareIcon,
  VolumeIcon,
} from '../../Player/icons'
import ShiurActionsPopover from '../../Shiurim/ShiurActionsPopover'
import PlayerQueuePopover from '../PlayerQueuePopover'
import {
  AdditionalActionsWrapper,
  CancelIcon,
  DownloadProgress,
  DownloadProgressWrapper,
  VolumePopover,
  VolumeSlider,
} from './styled'

type Props = {
  onOpenAddNoteBookmarkModal: () => void
  onOpenPlaybackRateModal: () => void
  downloadState: DOWNLOADING_STATES
  downloadingProgress: number
  download: () => void
  abortDownload: () => void
  playbackRate: number
  volume: number
  setVolume: (volume: number) => void
  share: () => void
  openRegisterToContinueModal: () => void
}

function PlayerAdditionalActions({
  onOpenAddNoteBookmarkModal,
  onOpenPlaybackRateModal,
  downloadState,
  downloadingProgress,
  download,
  abortDownload,
  playbackRate,
  volume,
  setVolume,
  share,
  openRegisterToContinueModal,
}: Props) {
  const { shiur, destroy } = usePlayer()
  const { user } = useAuth()

  const { data, loading: getOneShiurimLoading } = useQuery<getOneShiurim, getOneShiurimVariables>(GET_ONE_SHIURIM, {
    variables: { shiurId: shiur.shiurId },
  })

  const [addShiurToFavorite, { loading: addShiurToFavoriteLoading }] = useMutation<
    addShiurToFavorite,
    addShiurToFavoriteVariables
  >(ADD_SHIUR_TO_FAVORITE)
  const [removeShiurFromFavorite, { loading: removeShiurFromFavoriteLoading }] = useMutation<
    removeShiurFromFavorite,
    removeShiurFromFavoriteVariables
  >(REMOVE_SHIUR_FROM_FAVORITE)

  const onToggleFavorite = async () => {
    if (addShiurToFavoriteLoading || removeShiurFromFavoriteLoading || getOneShiurimLoading) return

    if (data?.getOneShiurim?.favoriteInfo?.isFavorite ?? shiur.favoriteInfo.isFavorite) {
      await removeShiurFromFavorite({ variables: { shiurId: shiur.shiurId } })
    } else {
      await addShiurToFavorite({ variables: { shiurId: shiur.shiurId } })
    }
  }

  const isTabletOrLess = window.innerWidth < Number(deviceWidth.laptop.match(/(\d+)/)[0])
  if (isTabletOrLess)
    return (
      <AdditionalActionsWrapper>
        <PlayerQueuePopover>
          <QueueIcon onClick={user ? undefined : openRegisterToContinueModal} />
        </PlayerQueuePopover>
        <ShiurActionsPopover
          shiur={data?.getOneShiurim || shiur}
          index={0}
          full
          player
          onSaveToFavorite={onToggleFavorite}
          playbackRate={playbackRate}
          onOpenPlaybackRate={onOpenPlaybackRateModal}
        >
          <MoreIconBig onClick={user ? undefined : openRegisterToContinueModal} />
        </ShiurActionsPopover>
        <CloseIcon onClick={destroy} />
      </AdditionalActionsWrapper>
    )

  return (
    <AdditionalActionsWrapper>
      {data?.getOneShiurim?.favoriteInfo?.isFavorite ?? shiur.favoriteInfo.isFavorite ? (
        <SavedIcon onClick={user ? onToggleFavorite : openRegisterToContinueModal} />
      ) : (
        <SaveIcon onClick={user ? onToggleFavorite : openRegisterToContinueModal} />
      )}
      <AddNote onClick={user ? onOpenAddNoteBookmarkModal : openRegisterToContinueModal} />
      <PlayerQueuePopover>
        <QueueIcon onClick={user ? undefined : openRegisterToContinueModal} />
      </PlayerQueuePopover>
      {downloadState === DOWNLOADING_STATES.DOWNLOADING ? (
        <DownloadProgressWrapper onClick={abortDownload}>
          <DownloadProgress
            type="circle"
            strokeWidth={10}
            strokeColor={COLORS.primary.medium}
            size={24}
            percent={downloadingProgress}
            showInfo={false}
          />
          <CancelIcon />
        </DownloadProgressWrapper>
      ) : downloadState === DOWNLOADING_STATES.DOWNLOADED ? (
        <DownloadFinishedIcon onClick={download} />
      ) : (
        <DownloadIcon onClick={user ? download : openRegisterToContinueModal} />
      )}
      <ShareIcon onClick={user ? share : openRegisterToContinueModal} />
      <VolumePopover
        trigger="hover"
        placement="top"
        content={<VolumeSlider vertical tooltip={{ open: false }} value={volume} onChange={setVolume} />}
      >
        <VolumeIcon />
      </VolumePopover>
    </AdditionalActionsWrapper>
  )
}

export default PlayerAdditionalActions
