import { COLORS } from 'constants/colors'
import styled from 'styled-components'
import { SwiperSlide } from 'swiper/react'

export const Wrapper = styled.div`
  margin-bottom: 40px;
`

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 27px;
`

export const Heading = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: ${COLORS.text.hint};
`

export const ViewAll = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${COLORS.text.default};

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: ${COLORS.primary.medium};
  }
`

export const ListWrapper = styled.div`
  height: 100px;
`

export const Image = styled.img`
  width: 100px;
  height: 100px;

  object-fit: cover;
  object-position: center;

  border: 2px solid transparent;
  border-radius: 50%;

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-color: ${COLORS.primary.light};
  }
`

export const Slide = styled(SwiperSlide)`
  width: fit-content;
`
