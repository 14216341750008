import dayjs from 'dayjs'
import {
  getShiurimFromFollowed,
  getShiurimFromFollowedVariables,
} from 'graphql/query/__generated__/getShiurimFromFollowed'
import {
  getShiurimOrderedByDate,
  getShiurimOrderedByDateVariables,
} from 'graphql/query/__generated__/getShiurimOrderedByDate'
import { GET_SHIURIM_FROM_FOLLOWED } from 'graphql/query/getShiurimFromFollowed'
import { GET_SHIURIM_ORDERED_BY_DATE } from 'graphql/query/getShiurimOrderedByDate'
import { usePlayer } from 'hooks/usePlayer'
import { QueryInfo } from 'hooks/useQueryInfo'

import { useLazyQuery } from '@apollo/client'

import { ShiurimAutoPlayInfo } from '../../Cards/ShiurimCard'
import { PlayIcon } from '../../Player/icons'
import { Button, Loader, Wrapper } from './styled'

type Props = {
  isPage?: boolean
  isFollowing?: boolean
  date: dayjs.Dayjs
}

function PlayAllShiursButton({ isPage, isFollowing, date }: Props) {
  const { bulkAddToQueue, play } = usePlayer()

  const [getShiurimOrderedByDate, { loading: getShiurimOrderedByDateLoading }] = useLazyQuery<
    getShiurimOrderedByDate,
    getShiurimOrderedByDateVariables
  >(GET_SHIURIM_ORDERED_BY_DATE)

  const [getShiurimFromFollowed, { loading: getShiurimFromFollowedLoading }] = useLazyQuery<
    getShiurimFromFollowed,
    getShiurimFromFollowedVariables
  >(GET_SHIURIM_FROM_FOLLOWED)

  const onPlay = async () => {
    if (isFollowing) {
      const variables = {
        searchParams: {
          startDate: date.toDate().toDateString(),
          endDate: date.add(1, 'day').toDate().toDateString(),
        },
        take: 50,
        page: 1,
      }
      const { data } = await getShiurimFromFollowed({
        variables,
      })
      const items = data.getShiurimFromFollowed.items
      const queueItems = [...items]
      queueItems.shift()

      const shiurQueryInfo: QueryInfo = { query: GET_SHIURIM_FROM_FOLLOWED, variables }
      const itemsWithShiurimAutoPlayInfo = queueItems.map((el, index) => {
        const shiurAutoPlayInfo: ShiurimAutoPlayInfo = { ...shiurQueryInfo, index }
        return { ...el, shiurAutoPlayInfo }
      })

      bulkAddToQueue(itemsWithShiurimAutoPlayInfo)

      if (items.length) {
        await play(items[0])
      }
    } else {
      const variables = { date: date.toDate().toDateString(), take: 50, page: 1 }
      const { data } = await getShiurimOrderedByDate({
        variables,
      })
      const items = data.getShiurimOrderedByDate.items

      const queueItems = [...items]
      queueItems.shift()

      const shiurQueryInfo: QueryInfo = { query: GET_SHIURIM_ORDERED_BY_DATE, variables }
      const itemsWithShiurimAutoPlayInfo = queueItems.map((el, index) => {
        const shiurAutoPlayInfo: ShiurimAutoPlayInfo = { ...shiurQueryInfo, index }
        return { ...el, shiurAutoPlayInfo }
      })

      bulkAddToQueue(itemsWithShiurimAutoPlayInfo)

      if (items.length) {
        await play(items[0])
      }
    }
  }

  const loading = getShiurimFromFollowedLoading || getShiurimOrderedByDateLoading
  return (
    <Wrapper isPage={isPage}>
      <Button type="primary" onClick={onPlay}>
        {isFollowing && !isPage ? "Play all today's" : 'Play all'}
        {loading ? <Loader size="small" /> : <PlayIcon />}
      </Button>
    </Wrapper>
  )
}

export default PlayAllShiursButton
