import { COLORS } from 'constants/colors'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const TitleRow = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 24px;
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: ${COLORS.text.default};
`

export const TitleDivider = styled.div`
  width: 1px;
  height: 24px;
  margin: 0 12px;
  background: #d9ecf6;

  border-radius: 1px;
`

export const ViewAllLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${COLORS.primary.medium};
`

export const ViewAllFakeLink = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${COLORS.primary.medium};

  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #69b1ff;
  }
`

export const List = styled.div`
  height: 100%;

  & > :not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 20px;

    border-bottom: 1px solid #d9ecf6;
  }
`
