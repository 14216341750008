import Following from 'components/business/Main/Home/Following'
import NewAndRecommended from 'components/business/Main/Home/NewAndRecommended'
import TopBanners from 'components/business/Main/Home/TopBanners'
import UnlockAllFeaturesBanner from 'components/business/Main/Home/UnlockAllFeaturesBanner'
import Header from 'components/ui/Header'
import AlertModal from 'components/ui/Modal/AlertModal'
import { ReactComponent as HomeIcon } from 'components/ui/Sidebar/icons/active/home.svg'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { Wrapper } from '../styled'

function Home() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)
  return (
    <>
      <Header icon={<HomeIcon />} title="Home" />
      <Wrapper>
        <TopBanners />
        {user && <Following />}
        <NewAndRecommended openRegisterToContinueModal={openRegisterToContinueModal} />
        {!user && <UnlockAllFeaturesBanner openRegisterToContinueModal={openRegisterToContinueModal} />}

        <AlertModal
          open={isRegisterToContinueModalOpen}
          onCancel={closeRegisterToContinueModal}
          onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
          onSecondaryBtnClick={closeRegisterToContinueModal}
          title="Register to Continue"
          description="To unlock all features you need to create an account"
          primaryBtnText="REGISTER"
          secondaryBtnText="NOT NOW"
        />
      </Wrapper>
    </>
  )
}

export default Home
