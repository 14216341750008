import { useAuth } from 'hooks/useAuth'
import React, { useState } from 'react'

import Completed from './Completed'
import InputEmailAndText from './InputEmailAndText'

type Props = { open: boolean; onClose: () => void }

enum STEPS {
  INPUT = 'input',
  COMPLETED = 'completed',
}

function QuestionsAndCommentsModal(props: Props) {
  const { user } = useAuth()

  const [email, setEmail] = useState(user?.email || '')
  const [step, setStep] = useState<STEPS>(STEPS.INPUT)

  const onQuestionSent = (email: string) => {
    if (email) setEmail(email)
    setStep(STEPS.COMPLETED)
  }

  switch (step) {
    case STEPS.INPUT:
      return <InputEmailAndText nextStep={onQuestionSent} {...props} />

    case STEPS.COMPLETED:
      return <Completed onComplete={props.onClose} email={email} {...props} />
  }
}

export default QuestionsAndCommentsModal
