import { usePlayer } from 'hooks/usePlayer'
import { memo, useEffect, useRef, useState } from 'react'
import Marquee from 'react-fast-marquee'

import { Conatiner, Image, Info, Number, ShiurWrapper, Subtitle, SubtitleRow, Title, TitleRow } from './styled'

type Props = {
  onOpenInfoModal: () => void
}

const PlayerShiurInfo = ({ onOpenInfoModal }: Props) => {
  const { shiur } = usePlayer()

  const [isTitleScrolling, setIsTitleScrolling] = useState(false)
  const [isMarqueePlaying, setIsMarqueePlaying] = useState(false)
  const containerRef = useRef(null)
  const titleRowRef = useRef(null)

  let pauseTimeout: NodeJS.Timer

  useEffect(() => {
    const container = containerRef.current
    const titleRow = titleRowRef.current

    if (container?.scrollWidth > container?.clientWidth || titleRow?.scrollWidth > container?.scrollWidth) {
      setIsTitleScrolling(true)
      setIsMarqueePlaying(true)
    } else {
      setIsTitleScrolling(false)
      setIsMarqueePlaying(false)

      clearTimeout(pauseTimeout)
    }
  }, [shiur])

  const pause = () => {
    setIsMarqueePlaying(false)
    pauseTimeout = setTimeout(() => {
      setIsMarqueePlaying(true)
    }, 2000)
  }

  return (
    <ShiurWrapper>
      <Image src={`${process.env.REACT_APP_AWS_S3_URL}${shiur?.series?.coverUrl}`} />
      <Conatiner ref={containerRef}>
        {isTitleScrolling ? (
          <Marquee pauseOnHover gradient gradientWidth={3} play={isMarqueePlaying} onCycleComplete={pause}>
            <TitleRow ref={titleRowRef}>
              {!!shiur?.orderedNum && <Number>Shiur {shiur?.orderedNum}.</Number>}
              <Title>{shiur?.title}&nbsp;</Title>
            </TitleRow>
          </Marquee>
        ) : (
          <TitleRow ref={titleRowRef}>
            {!!shiur?.orderedNum && <Number>Shiur {shiur?.orderedNum}.</Number>}
            <Title>{shiur?.title}&nbsp;</Title>
          </TitleRow>
        )}
        <SubtitleRow>
          {shiur?.subtitle && <Subtitle>{shiur?.subtitle}</Subtitle>}
          <Info onClick={onOpenInfoModal} />
        </SubtitleRow>
      </Conatiner>
    </ShiurWrapper>
  )
}

export default memo(PlayerShiurInfo)
