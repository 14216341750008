import { Spin } from 'antd'
import Loader from 'components/ui/Loader'
import { getSeriesWithPagination_getSeriesWithPagination_items } from 'graphql/query/__generated__/getSeriesWithPagination'

import SeriesCard, { SeriesSize } from '../../Cards/SeriesCard'
import { EmptyContainer, EmptyText } from '../../Home/styled'
import { LoaderWrapper, Wrapper } from './styled'

type Props = {
  items?: getSeriesWithPagination_getSeriesWithPagination_items[]
  size?: SeriesSize
  showCount?: boolean
  showAddBtn?: boolean
  onFetchMore?: () => void
  next?: boolean
  loading: boolean
  loadingNext: boolean
}

function SeriesList({ items, size, showCount, showAddBtn = true, onFetchMore, next, loading, loadingNext }: Props) {
  const isSmall = size === 'small'
  const isBig = size === 'big'
  const isHuge = size === 'huge'
  const isItems = !!items?.length

  if (loading) return <Loader noHeight />
  return (
    <>
      <Wrapper
        small={isSmall}
        big={isBig}
        huge={isHuge}
        dataLength={items?.length || 0}
        next={onFetchMore}
        hasMore={next}
        loader={null}
        grid={isItems}
        scrollableTarget="scrollable-wrapper"
      >
        {!isItems ? (
          <EmptyContainer>
            <EmptyText>No series</EmptyText>
          </EmptyContainer>
        ) : (
          items?.map((el) => (
            <SeriesCard
              key={el.seriesId}
              size={size}
              selected={el.followInfo.isFollowed}
              showAddBtn={showAddBtn}
              showCount={showCount}
              {...el}
            />
          ))
        )}
        <LoaderWrapper>
          <Spin spinning={loadingNext} />
        </LoaderWrapper>
      </Wrapper>
    </>
  )
}

export default SeriesList
