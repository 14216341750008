import { notification } from 'antd'
import { COLORS } from 'constants/colors'
import { ROUTE_PATH } from 'routes'

export const sendSomethingWrongNotification = () => {
  notification.config({ maxCount: 1 })
  notification.error({
    message: 'Something went wrong!',
    description: (
      <>
        Please try again later or{' '}
        <a
          style={{ color: COLORS.primary.medium }}
          href={`${process.env.REACT_APP_URL}${ROUTE_PATH.SUPPORT}`}
          target="_blank"
          rel="noreferrer"
        >
          contact support
        </a>
        !
      </>
    ),
  })
}
