import { getSeriesWithPagination_getSeriesWithPagination_items } from 'graphql/query/__generated__/getSeriesWithPagination'
import { useState } from 'react'

import { DownArrow } from '../../Shiurim/ShiurimFilter/styled'
import { FormItem } from '../styled'
import Content from './Content'
import { Popover, SeriesImage, SeriesInput } from './styled'

type Series = getSeriesWithPagination_getSeriesWithPagination_items

type Props = {
  value?: Series
  onChange: (value: Series) => void
}

function SeriesPopover({ value, onChange }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const onToggleVisibility = () => setIsOpen(!isOpen)

  const onSelect = (series: Series) => {
    onChange(series)
    setIsOpen(false)
  }
  return (
    <Popover
      trigger="click"
      open={isOpen}
      onOpenChange={setIsOpen}
      destroyTooltipOnHide
      content={<Content onSelect={onSelect} />}
    >
      <FormItem name="series" isInRow isButton>
        <div onClick={onToggleVisibility}>
          <SeriesInput
            placeholder={`Select Series...`}
            suffix={<DownArrow isActive={isOpen} />}
            prefix={
              value ? (
                <SeriesImage src={value ? `${process.env.REACT_APP_AWS_S3_URL}${value.coverUrl}` : undefined} />
              ) : undefined
            }
            style={{ pointerEvents: 'none' }}
            value={value?.title}
            disabled
          />
        </div>
      </FormItem>
    </Popover>
  )
}

export default SeriesPopover
