import { deviceWidth } from 'constants/responsive'
import { usePlayer } from 'hooks/usePlayer'

import NavigationMenu, { NavigationMenuKeys } from '../NavigationMenu'
import { Logo, LogoSmall } from './icons'
import { LogoLink, Sider } from './styled'

function SideBar() {
  const { isInitialized } = usePlayer()

  const isCollapsed = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])

  return (
    <Sider width={246} collapsedWidth={60} isPlayerShown={isInitialized} collapsed={isCollapsed}>
      <LogoLink to={NavigationMenuKeys.HOME}>{isCollapsed ? <LogoSmall /> : <Logo />}</LogoLink>
      <NavigationMenu />
    </Sider>
  )
}
export default SideBar
