import Loader from 'components/ui/Loader'
import { getAppSponsors } from 'graphql/query/__generated__/getAppSponsors'
import { GET_APP_SPONSORS } from 'graphql/query/getAppSponsors'
import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import { Content, Description, Header, Title, Wrapper } from './styled'

enum DONATION_PERIOD {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

const donationPeriodToLabel = {
  [DONATION_PERIOD.DAY]: 'DAILY',
  [DONATION_PERIOD.WEEK]: 'WEEKLY',
  [DONATION_PERIOD.MONTH]: 'MONTHLY',
}

function Sponsor() {
  const [currentSponsorType, setCurrentSponsorType] = useState(DONATION_PERIOD.DAY)
  const [currentSponsorIndex, setCurrentSponsorIndex] = useState(0)

  const { data, loading } = useQuery<getAppSponsors>(GET_APP_SPONSORS, { fetchPolicy: 'network-only' })

  useEffect(() => {
    if (!data?.getAppSponsors) return

    const interval = setInterval(() => {
      if (
        data.getAppSponsors[currentSponsorType].length === 0 ||
        currentSponsorIndex === data.getAppSponsors[currentSponsorType].length - 1
      ) {
        switch (currentSponsorType) {
          case DONATION_PERIOD.DAY:
            setCurrentSponsorType(DONATION_PERIOD.WEEK)
            break

          case DONATION_PERIOD.WEEK:
            setCurrentSponsorType(DONATION_PERIOD.MONTH)
            break

          default:
            setCurrentSponsorType(DONATION_PERIOD.DAY)
        }

        return setCurrentSponsorIndex(0)
      }

      setCurrentSponsorIndex((prev) => prev + 1)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [currentSponsorIndex, currentSponsorType, data?.getAppSponsors])

  const currentSponsor = data?.getAppSponsors?.[currentSponsorType]?.[currentSponsorIndex]

  return (
    <Wrapper>
      <Header>{donationPeriodToLabel[currentSponsorType]} SPONSOR</Header>
      <Content>
        {loading ? (
          <Loader />
        ) : !currentSponsor ? (
          <Title></Title>
        ) : (
          <>
            <Title>{currentSponsor.isAnonymous ? 'Anonymous' : currentSponsor.fullName}</Title>
            {currentSponsor.inMemoryOf && <Description>{currentSponsor.inMemoryOf}</Description>}
          </>
        )}
      </Content>
    </Wrapper>
  )
}

export default Sponsor
