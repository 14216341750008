import { Form, Input } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import { sendQuestion, sendQuestionVariables } from 'graphql/mutation/__generated__/sendQuestion'
import { SEND_QUESTION } from 'graphql/mutation/sendQuestion'
import { ErrorText } from 'pages/Auth/styled'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'

import { Divider, SubmitButton, TextArea, Wrapper } from './styled'

type Props = { nextStep: (email: string) => void }

function InputEmailAndText({ nextStep }: Props) {
  const [form] = useForm()
  const email = useWatch('email', form)

  const [error, setError] = useState('')
  const [isErrors, setIsErrors] = useState(false)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)

  const [sendQuestion] = useMutation<sendQuestion, sendQuestionVariables>(SEND_QUESTION)

  const validateAndUpdateState = useCallback(() => {
    form
      .validateFields()
      .then(() => setIsErrors(false))
      .catch(() => {
        setIsErrors(true)
      })
  }, [form])

  useEffect(() => {
    if (email) {
      validateAndUpdateState()
    }
  }, [email, validateAndUpdateState])

  const onSave = async () => {
    setLoading(true)
    setError('')

    try {
      await sendQuestion({ variables: { question: text, email } })
      nextStep(email)
    } catch (e) {
      setError(e.message)
    }

    setLoading(false)
  }

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value)

  const isDisabled = !(text && email && !isErrors)
  return (
    <Wrapper>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Form.Item
          name="email"
          required
          rules={[
            { required: true, message: '' },
            {
              type: 'email',
              message: 'Incorrect email',
            },
          ]}
        >
          <Input name="email" placeholder="Enter your email address" />
        </Form.Item>
      </Form>
      <Divider />

      <TextArea
        maxLength={300}
        placeholder="Ask any question. We will email you a response..."
        value={text}
        onChange={onChange}
      />
      {error && <ErrorText>{error}</ErrorText>}
      <SubmitButton type="primary" loading={loading} disabled={isDisabled} onClick={onSave}>
        SEND
      </SubmitButton>
    </Wrapper>
  )
}

export default InputEmailAndText
