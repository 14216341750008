import { Gender } from '__generated__/globalTypes'
import { Input, Radio } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import ActionModal from 'components/ui/Modal/ActionModal'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'

import { Form, FormItem, RadioGroup } from './styled'

type Props = { open: boolean; onClose: () => void }

function EditNameModal({ open, onClose }: Props) {
  const { user, updateUserInfo } = useAuth()
  const [form] = useForm()

  const username = useWatch('username', { form })
  const gender = useWatch('gender', { form })

  const [isErrors, setIsErrors] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (username) {
      form
        .validateFields()
        .then(() => setIsErrors(false))
        .catch(() => {
          setIsErrors(true)
        })
    }
  }, [username, form])

  const onSave = async () => {
    setLoading(true)

    await updateUserInfo({ username, gender })
    onClose()

    setLoading(false)
  }

  const isSaveButtonDisabled = !username || !gender || isErrors
  return (
    <ActionModal
      width={400}
      open={open}
      onCancel={onClose}
      onBtnClick={onSave}
      btnLoading={loading}
      disabled={isSaveButtonDisabled}
      btnText="SAVE"
      title="Edit Name"
    >
      <Form form={form} initialValues={{ username: user.username, gender: user.gender }} layout="vertical">
        <FormItem
          name="username"
          label="Full name"
          validateTrigger={form.getFieldError('username').length ? 'onChange' : 'onBlur'}
          required={false}
          rules={[
            {
              required: true,
              message: '',
            },
            { pattern: new RegExp('^[-\\sa-zA-Z]+$'), message: 'Incorrect full name' },
          ]}
        >
          <Input placeholder="Enter your full name" maxLength={100} />
        </FormItem>
        <FormItem name="gender" label="Select how to address">
          <RadioGroup>
            <Radio.Button value={Gender.MALE}>Mr.</Radio.Button>
            <Radio.Button value={Gender.FEMALE}>Mrs.</Radio.Button>
          </RadioGroup>
        </FormItem>
      </Form>
    </ActionModal>
  )
}

export default EditNameModal
