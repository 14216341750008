import { COLORS } from 'constants/colors'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid #ebebeb;
  border-radius: 12px;

  padding: 25px 24px;
  margin-bottom: 14px;
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  margin-bottom: 20px;
`

export const List = styled.div<{ grid: boolean }>`
  ${({ grid }) =>
    grid &&
    css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 14px;
    `}
`
