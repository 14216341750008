import { useAuth } from 'hooks/useAuth'

import { HDate, Sedra } from '@hebcal/core'

import { DateItem, DateSection, Title, Wrapper } from './styled'

function Greeting() {
  const { user } = useAuth()

  const jewishDate = new HDate()
  const weekday = new Date().toLocaleDateString('en-US', { weekday: 'long' })
  const parsha = new Sedra(jewishDate.getFullYear(), false).get(jewishDate).join(' ')
  const jewishDateNumber = jewishDate.getDate()
  const jewishMonth = jewishDate.getMonthName()
  const monthAndDay = new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit' })

  return (
    <Wrapper>
      <Title>{user ? `Hello, ${user?.username}!` : 'Welcome to Path4life!'} </Title>
      <DateSection>
        <DateItem>{weekday}</DateItem>
        <DateItem>{`Parshas ${parsha}`}</DateItem>
        <DateItem>{`${jewishDateNumber} ${jewishMonth}`}</DateItem>
        <DateItem>{monthAndDay}</DateItem>
      </DateSection>
    </Wrapper>
  )
}

export default Greeting
