import { ShiurimAutoPlayInfo } from 'components/business/Main/Cards/ShiurimCard'
import dayjs from 'dayjs'
import { GET_ALL_SHIURIMS } from 'graphql/query/getAllShiurims'
import { GET_SHIURIM_ORDERED_BY_DATE } from 'graphql/query/getShiurimOrderedByDate'
import { sendSomethingWrongNotification } from 'helpers/sendSomethingWrongNotification'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import { OperationVariables, useLazyQuery } from '@apollo/client'

import { Context, QueueItem, Shiur } from './usePlayer'
import { QueryInfo } from './useQueryInfo'

const QUEUE_FETCH_COUNT = 10

export const useFetchNewShiursForAutoPlay = (state: Context, setState: Dispatch<SetStateAction<Context>>) => {
  const [requestsQueue, setRequestsQueue] = useState([])
  const [processing, setProcessing] = useState(false)

  const shiurAutoPlayLastFetchInfo = useRef<QueryInfo & { next: boolean }>(null)

  const [autoPlayQuery] = useLazyQuery(state.shiurAutoPlayInfo?.query || GET_ALL_SHIURIMS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const processQueue = async () => {
      if (requestsQueue.length === 0 || processing) return

      // Take the first item from the queue
      const item = requestsQueue[0]

      // Set processing to true to prevent concurrent calls
      setProcessing(true)

      // Call your asynchronous function
      await fetchNewShiursForAutoPlay(item.shiurAutoPlayInfo, item.fromNext)

      // Remove the processed item from the queue
      setRequestsQueue((prevQueue) => prevQueue.slice(1))
    }

    processQueue()
  }, [requestsQueue, processing])

  useEffect(() => {
    if (state.isAutoPlayEnabled && !state.shiurAutoPlayInfo.fromPrev) {
      if (state.shiurAutoPlayInfo.fromNext) {
        return setRequestsQueue((prevQueue) => [
          ...prevQueue,
          { shiurAutoPlayInfo: state.shiurAutoPlayInfo, fromNext: state.shiurAutoPlayInfo.fromNext },
        ])
      }

      fetchNewShiursForAutoPlay(state.shiurAutoPlayInfo, state.shiurAutoPlayInfo.fromNext)
    }
  }, [state.shiurAutoPlayInfo])

  const getVariablesForQueryByDate = (autoPlayInfo: ShiurimAutoPlayInfo, fetchMore?: boolean) => {
    const oldVariables = autoPlayInfo.variables

    let newVariables: OperationVariables
    if (fetchMore) {
      newVariables = {
        ...shiurAutoPlayLastFetchInfo.current.variables,
        page: 1,
        date: dayjs(shiurAutoPlayLastFetchInfo.current.variables.date).add(-1, 'day'),
      }
    } else {
      newVariables = {
        ...oldVariables,
        take: 50,
        page: oldVariables.page * oldVariables.take - oldVariables.take + autoPlayInfo.index + 2,
        offset: true,
      }
    }

    return newVariables
  }

  const getVariablesForRegularQuery = (autoPlayInfo: ShiurimAutoPlayInfo, fetchMore?: boolean) => {
    const oldVariables = autoPlayInfo.variables

    let newVariables: OperationVariables
    if (fetchMore) {
      newVariables = {
        ...shiurAutoPlayLastFetchInfo.current.variables,
        page: shiurAutoPlayLastFetchInfo.current.variables.page + QUEUE_FETCH_COUNT,
      }
    } else {
      newVariables = {
        ...oldVariables,
        take: QUEUE_FETCH_COUNT,
        page: autoPlayInfo.index + 2,
        offset: true,
      }
    }

    return newVariables
  }

  const fetchNewShiursForAutoPlay = useCallback(
    async (shiurAutoPlayInfo: ShiurimAutoPlayInfo, fetchMore?: boolean) => {
      if (shiurAutoPlayLastFetchInfo.current && !shiurAutoPlayLastFetchInfo.current.next) return

      const autoPlayInfo = shiurAutoPlayInfo || state.shiurAutoPlayInfo

      const isQueryByDate = autoPlayInfo.query === GET_SHIURIM_ORDERED_BY_DATE
      const getVariables = isQueryByDate ? getVariablesForQueryByDate : getVariablesForRegularQuery

      let fetchMoreForQueryByDate = fetchMore
      let resultQueueArr: QueueItem[] = []

      const fetchNewQueue = async () => {
        const newVariables = getVariables(autoPlayInfo, fetchMoreForQueryByDate)

        try {
          const { data } = await autoPlayQuery({
            variables: newVariables,
          })

          const keyName = Object.keys(data)[0]

          shiurAutoPlayLastFetchInfo.current = {
            query: autoPlayInfo.query,
            variables: newVariables,
            next: isQueryByDate
              ? !dayjs(newVariables.date).add(-1, 'day').isBefore('2020', 'year')
              : data[keyName].meta.next,
          }

          const newQueue = data[keyName].items.map((el: Shiur, index: number) => ({
            ...el,
            shiurAutoPlayInfo: {
              query: shiurAutoPlayLastFetchInfo.current.query,
              variables: shiurAutoPlayLastFetchInfo.current.variables,
              index,
            },
          }))

          return newQueue
        } catch (e) {
          sendSomethingWrongNotification()
        } finally {
          setProcessing(false)
        }
      }

      resultQueueArr = [...resultQueueArr, ...(await fetchNewQueue())]

      while (isQueryByDate && state.queue.length - state.positionInQueue + resultQueueArr.length < 10) {
        fetchMoreForQueryByDate = true
        resultQueueArr = [...resultQueueArr, ...(await fetchNewQueue())]
      }

      setState((prev) => ({
        ...prev,
        positionInQueue: fetchMore ? prev.positionInQueue : 0,
        queue: fetchMore ? [...prev.queue, ...resultQueueArr] : resultQueueArr,
      }))
    },
    [autoPlayQuery, setState, state.positionInQueue, state.queue.length, state.shiurAutoPlayInfo]
  )

  return fetchNewShiursForAutoPlay
}
