import { Table } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const StyledTable = styled(Table)`
  th.ant-table-cell {
    color: ${COLORS.primary.medium} !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 1.4 !important;
    background: none !important;

    &::before {
      content: none !important;
    }
  }

  td.ant-table-cell {
    color: ${COLORS.text.default} !important;
    font-size: 14px !important;
    line-height: 1.3 !important;

    vertical-align: middle !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item {
    min-width: 35px !important;
    height: 35px !important;
    line-height: 35px !important;
  }

  .ant-pagination-item {
    line-height: 33px !important;
  }
`

export const SeriesText = styled.p<{ color?: string }>`
  color: ${({ color }) => (color ? color : COLORS.text.default)};

  transition: all 0.3s;
  &:hover {
    opacity: 0.6;
  }

  cursor: pointer;
`

export const StatusText = styled.p<{ success?: boolean }>`
  padding: 2px 4px;

  color: white;
  text-align: center;

  border-radius: 12px;
  background-color: ${({ success }) => (success ? '#0082CD' : '#EBB341')};
`
