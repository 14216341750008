/* eslint-disable eqeqeq */
import { Spin } from 'antd'
import { ReactComponent as NextIcon } from 'assets/icons/slider-arrow-next.svg'
import { ReactComponent as PreviousIcon } from 'assets/icons/slider-arrow-previous.svg'
import TopicCard from 'components/business/Main/Cards/TopicCard'
import { EmptyContainer, EmptyText } from 'components/business/Main/Home/styled'
import { Flex } from 'components/common/Flex'
import { getAllTopics_getAllTopics } from 'graphql/query/__generated__/getAllTopics'
import { Ref, useRef, useState } from 'react'
import { ROUTE_PATH } from 'routes'
import { Swiper, SwiperClass, SwiperRef } from 'swiper/react'

import { Title, TitleDivider, TitleRow, ViewAllLink } from '../styled'
import { ButtonsContainer, ListWrapper, SliderButton, SwiperSlide } from './styled'

type Props = { data?: getAllTopics_getAllTopics; loading: boolean; showCount: boolean }

function Topics({ data, loading, showCount }: Props) {
  const swiperRef: Ref<SwiperRef> = useRef()
  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false)

  const onSlideChange = (swiper: SwiperClass) => {
    if (swiper.isBeginning) setIsPrevBtnDisabled(true)
    if (swiper.isEnd) setIsNextBtnDisabled(true)

    if (!swiper.isBeginning) setIsPrevBtnDisabled(false)
    if (!swiper.isEnd) setIsNextBtnDisabled(false)
  }

  const isItems = !!data?.items?.length

  return (
    <div>
      <TitleRow withButtons>
        <Flex alignCenter>
          <Title>Topics {data?.meta?.total != null && showCount ? `・ ${data.meta.total}` : ''}</Title>
          <TitleDivider />
          <ViewAllLink to={ROUTE_PATH.TOPICS}>View all</ViewAllLink>
        </Flex>
        {!loading && isItems && (
          <ButtonsContainer>
            <SliderButton disabled={isPrevBtnDisabled} onClick={() => swiperRef.current.swiper.slidePrev()}>
              <PreviousIcon />
            </SliderButton>
            <SliderButton disabled={isNextBtnDisabled} onClick={() => swiperRef.current.swiper.slideNext()}>
              <NextIcon />
            </SliderButton>
          </ButtonsContainer>
        )}
      </TitleRow>
      <ListWrapper>
        {loading ? (
          <EmptyContainer>
            <Spin />
          </EmptyContainer>
        ) : isItems ? (
          <Swiper
            slidesPerView="auto"
            slidesPerGroupAuto
            ref={swiperRef}
            spaceBetween={14}
            onInit={onSlideChange}
            onReachBeginning={onSlideChange}
            onReachEnd={onSlideChange}
            onSlideChange={onSlideChange}
          >
            {data.items.map((el) => (
              <SwiperSlide key={el.topicId}>
                <TopicCard {...el} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <EmptyContainer>
            <EmptyText>No topics</EmptyText>
          </EmptyContainer>
        )}
      </ListWrapper>
    </div>
  )
}

export default Topics
