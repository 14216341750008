import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 12px;

  border: 1px solid #ebebeb;
  border-radius: 12px;

  cursor: pointer;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;

  margin-right: 10px;

  border-radius: 50%;
  background-color: #eaf7fe;
`

export const Content = styled.div`
  flex: 1;
`

export const Title = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: #696b7b;

  margin-bottom: 2px;
`

export const TitleAccent = styled.span`
  font-weight: 500;
  color: ${COLORS.text.default};
`

export const Subtitle = styled.p<{ isLast?: boolean }>`
  font-size: 12px;
  line-height: 1.3;
  color: #696b7b;

  ${({ isLast }) => !isLast && 'margin-bottom: 16px'};
`

export const ShiurimImage = styled.img`
  width: 40px;
  height: 40px;

  &:nth-child(n + 7) {
    margin-top: 8px;
  }

  @media ${device.laptopL} {
    &:nth-child(n + 6) {
      margin-top: 8px;
    }
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  object-fit: cover;
  object-position: center;

  border-radius: 8px;

  cursor: pointer;
`

export const CountLeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  &:nth-child(n + 7) {
    margin-top: 8px;
  }

  @media ${device.laptopL} {
    &:nth-child(n + 6) {
      margin-top: 8px;
    }
  }

  background-color: #eaf7fe;
  border-radius: 8px;

  cursor: pointer;
`

export const CountLeftText = styled.p`
  color: ${COLORS.primary.medium};
  font-size: 14px;
  line-height: 1.3;
`

export const ShiurimTitle = styled.p`
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: ${COLORS.text.default};

  margin-bottom: 2px;
`

export const ShiurimSubtitle = styled.p`
  font-size: 10px;
  line-height: 1.2;
  color: #696b7b;
`

export const ShiurimDuration = styled.p`
  font-size: 12px;
  line-height: 1.3;
  color: ${COLORS.text.default};
`
