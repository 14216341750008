import { Switch, message } from 'antd'
import { ReactComponent as NotificationsIcon } from 'assets/icons/notification-saved.svg'
import { SuccessMessageTitle } from 'components/business/Main/Player/styled'
import {
  userNotificationsManage,
  userNotificationsManageVariables,
} from 'graphql/mutation/__generated__/userNotificationsManage'
import { USER_NOTIFICATIONS_MANAGE } from 'graphql/mutation/userNotificationsManage'
import { useAuth } from 'hooks/useAuth'
import { usePlayer } from 'hooks/usePlayer'
import { useState } from 'react'

import { useMutation } from '@apollo/client'

import { ActionWrapper, Item, ItemLabel } from '../../styled'
import { NotificationsDescription, NotificationsTitle } from '../styled'

function Notification() {
  const { user, checkAuth } = useAuth()
  const { isInitialized } = usePlayer()
  const { allowNotify } = user || {}

  const [isNotificationSettingsLoading, setIsNotificationSettingsLoading] = useState(false)

  const [notificationsManage] = useMutation<userNotificationsManage, userNotificationsManageVariables>(
    USER_NOTIFICATIONS_MANAGE
  )

  const onChangeNotificationSettings = async (checked: boolean) => {
    setIsNotificationSettingsLoading(true)

    await notificationsManage({ variables: { allowNotify: checked } })
    await checkAuth()

    message.config({
      maxCount: 1,
      top: isInitialized ? window.innerHeight - 170 : window.innerHeight - 100,
      getContainer: () => document.querySelector('#root'),
    })
    message.open({
      icon: <NotificationsIcon />,
      content: <SuccessMessageTitle>Notification settings saved</SuccessMessageTitle>,
    })

    setIsNotificationSettingsLoading(false)
  }
  return (
    <Item>
      <div>
        <ItemLabel>Notification</ItemLabel>
        <NotificationsTitle>Email notifications</NotificationsTitle>
        <NotificationsDescription>Get emails about new shiurim</NotificationsDescription>
      </div>
      <ActionWrapper>
        <Switch loading={isNotificationSettingsLoading} checked={allowNotify} onChange={onChangeNotificationSettings} />
      </ActionWrapper>
    </Item>
  )
}

export default Notification
