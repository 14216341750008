import { Spin } from 'antd'
import Loader from 'components/ui/Loader'
import { getAllTopics_getAllTopics_items } from 'graphql/query/__generated__/getAllTopics'

import TopicCard from '../../Cards/TopicCard'
import { EmptyContainer, EmptyText } from '../../Home/styled'
import { LoaderWrapper, Wrapper } from './styled'

type Props = {
  items?: getAllTopics_getAllTopics_items[]
  onFetchMore?: () => void
  next?: boolean
  loading: boolean
  loadingNext: boolean
}

function TopicList({ items, onFetchMore, next, loading, loadingNext }: Props) {
  if (loading) return <Loader noHeight />

  const isItems = !!items?.length
  return (
    <>
      <Wrapper
        dataLength={items?.length || 0}
        next={onFetchMore}
        hasMore={next}
        loader={null}
        grid={isItems}
        scrollableTarget="scrollable-wrapper"
      >
        {!isItems ? (
          <EmptyContainer>
            <EmptyText>No topics</EmptyText>
          </EmptyContainer>
        ) : (
          items?.map((el) => <TopicCard key={el.topicId} {...el} />)
        )}
        <LoaderWrapper>
          <Spin spinning={loadingNext} />
        </LoaderWrapper>
      </Wrapper>
    </>
  )
}

export default TopicList
