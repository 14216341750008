import { DownArrow } from 'components/business/Main/Shiurim/ShiurimFilter/styled'
import Search from 'components/ui/FormElements/Search'
import Loader from 'components/ui/Loader'
import {
  getSeriesWithPagination,
  getSeriesWithPaginationVariables,
  getSeriesWithPagination_getSeriesWithPagination_items,
} from 'graphql/query/__generated__/getSeriesWithPagination'
import { GET_SERIES_WITH_PAGINATION } from 'graphql/query/getSeriesWithPagination'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { ChangeEvent, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import { EmptyContainer, EmptyText } from '../../../Home/styled'
import { ContentWrapper, Image, Item, List, ShowMoreText, ShowMoreWrapper, Title } from './styled'

type Props = { onSelect: (series: getSeriesWithPagination_getSeriesWithPagination_items) => void }

const initialPagination = {
  take: 5,
  page: 1,
}

function Content({ onSelect }: Props) {
  const [search, setSearch] = useState('')
  const searchDebounced = useDebouncedValue(search, 300)
  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  useEffect(() => {
    setPage(initialPagination.page)
  }, [search])

  const { data, loading, fetchMore } = useQuery<getSeriesWithPagination, getSeriesWithPaginationVariables>(
    GET_SERIES_WITH_PAGINATION,
    {
      variables: {
        title: searchDebounced,
        ...initialPagination,
      },
    }
  )

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getSeriesWithPagination: {
            ...previousQueryResult.getSeriesWithPagination,
            ...fetchMoreResult.getSeriesWithPagination,
            items: [
              ...previousQueryResult.getSeriesWithPagination.items,
              ...fetchMoreResult.getSeriesWithPagination.items,
            ],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const items = data?.getSeriesWithPagination?.items
  return (
    <ContentWrapper>
      <Search
        placeholder="Search series..."
        value={search}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <List>
        {loading ? (
          <Loader />
        ) : !items?.length ? (
          <EmptyContainer>
            <EmptyText>No series found</EmptyText>
          </EmptyContainer>
        ) : (
          <>
            {items?.map((series) => (
              <Item key={series.seriesId} onClick={() => onSelect(series)}>
                <Image src={`${process.env.REACT_APP_AWS_S3_URL}${series.coverUrl}`} />
                <Title>{series.title}</Title>
              </Item>
            ))}
            {loadingNext ? (
              <Loader noHeight />
            ) : (
              data?.getSeriesWithPagination?.meta?.next && (
                <ShowMoreWrapper onClick={onFetchMore}>
                  <ShowMoreText>Show more</ShowMoreText>
                  <DownArrow />
                </ShowMoreWrapper>
              )
            )}
          </>
        )}
      </List>
    </ContentWrapper>
  )
}

export default Content
