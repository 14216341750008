import { Flex } from 'components/common/Flex'
import ActionModal from 'components/ui/Modal/ActionModal'
import { ErrorText } from 'pages/Auth/styled'
import { MutableRefObject, useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { Wrapper } from './styled'

type Props = {
  open: boolean
  isMobile?: boolean
  cardForm: MutableRefObject<CardForm>
  loading: boolean
  onClose?: () => void
  nextStep: (result: CardFormResult, captchaValue: string) => Promise<void>
}

function CardInput({ open, isMobile, cardForm, loading, onClose, nextStep }: Props) {
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [captchaValue, setCaptchaValue] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (!cardForm.current) return

    cardForm.current.mount('#donation-card-input')
    if (isMobile) cardForm.current.setStyles({ container: `display: flex; flex-direction: column;` })
    return () => {
      cardForm.current.destroy()
    }
  }, [cardForm])

  cardForm.current.on('input', async ({ result }) => {
    if (
      result.maskedCard.length >= 15 &&
      result.maskedCvv2.length >= 3 &&
      result.expiryMonth >= 1 &&
      result.expiryMonth <= 12 &&
      result.expiryYear.toString().length === 4
    ) {
      setIsFormFilled(true)
    } else {
      setIsFormFilled(false)
    }
  })

  const onBtnClick = async () => {
    try {
      const finalResult = await cardForm.current.getNonceToken()
      await nextStep(finalResult, captchaValue)
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <ActionModal
      open={open}
      title="Card Input"
      width={isMobile ? 320 : 400}
      btnText="SEND"
      btnLoading={loading}
      disabled={!isFormFilled || !captchaValue}
      onBtnClick={onBtnClick}
      onCancel={onClose}
      closable={isMobile ? false : true}
    >
      <Wrapper isMobile={isMobile} id="donation-card-input" />
      <Flex justifyCenter>
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={setCaptchaValue} />
      </Flex>
      {error && <ErrorText>{error}</ErrorText>}
    </ActionModal>
  )
}

export default CardInput
