import styled from 'styled-components'

import Modal from '../index'

export const ANTDModal = styled(Modal)`
  .ant-modal-header {
    display: none;
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-body {
    padding: 40px;
  }

  .ant-modal-footer {
    display: none;
  }
`
