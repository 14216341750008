import { ReactComponent as LocationIconPrimary } from 'assets/icons/location-primary.svg'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptopL} {
    align-items: center;
  }
`

export const Name = styled.p`
  width: 350px;
  margin-bottom: 14px;

  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;

  color: ${COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${device.laptopL} {
    width: auto;
    text-align: center;
    white-space: normal;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;

  @media ${device.laptopL} {
    flex-direction: column;
  }
`

export const Subtitle = styled.p<{ cropped?: boolean }>`
  font-size: 16px;
  line-height: 1.2;
  color: #425486;

  ${({ cropped }) =>
    cropped &&
    css`
      width: 150px;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}

  @media ${device.laptopL} {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
`

export const Divider = styled.div`
  width: 1px;
  height: 20px;
  margin: 0 12px;

  background-color: #d9ecf6;
  border-radius: 1px;

  @media ${device.laptopL} {
    display: none;
  }
`

export const LocationIcon = styled(LocationIconPrimary)`
  margin-right: 6px;
`
