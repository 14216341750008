import backgroundSrc from 'assets/img/greeting-background.png'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  padding: 32px;
  background-image: url(${backgroundSrc});
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0.5px solid #d9ecf6;
  border-radius: 12px;

  @media ${device.laptop} {
    height: auto;
    padding: 18px;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  margin-bottom: 18px;

  @media ${device.laptop} {
    font-size: 24px;
    margin-bottom: 8px;

    text-align: center;
  }
`

export const DateSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    padding-right: 8px;
    margin-right: 8px;

    border-right: 1px solid #d8f2ff;
  }

  @media ${device.laptop} {
    justify-content: center;
  }
`

export const DateItem = styled.p`
  font-size: 20px;
  line-height: 1.2;
  color: #425486;

  @media ${device.laptop} {
    font-size: 18px;
  }
`
