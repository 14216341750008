import TopicList from 'components/business/Main/Lists/TopicList'
import Header from 'components/ui/Header'
import { TopicsActive } from 'components/ui/Sidebar/icons'
import { getAllTopics, getAllTopicsVariables } from 'graphql/query/__generated__/getAllTopics'
import { GET_ALL_TOPICS } from 'graphql/query/getAllTopics'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { Wrapper } from 'pages/Main/styled'
import { ChangeEvent, useState } from 'react'

import { useQuery } from '@apollo/client'

const initialPagination = {
  take: 50,
  page: 1,
}

function Topics() {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 300)

  const searchProps = {
    searchValue: search,
    onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    searchPlaceholder: 'Search topics...',
  }

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore } = useQuery<getAllTopics, getAllTopicsVariables>(GET_ALL_TOPICS, {
    variables: { title: debouncedSearch, ...initialPagination },
  })

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getAllTopics: {
            ...previousQueryResult.getAllTopics,
            ...fetchMoreResult.getAllTopics,
            items: [...previousQueryResult.getAllTopics.items, ...fetchMoreResult.getAllTopics.items],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  return (
    <>
      <Header icon={<TopicsActive />} title="Topics" searchProps={searchProps} />
      <Wrapper id="scrollable-wrapper">
        <TopicList
          items={data?.getAllTopics?.items}
          next={data?.getAllTopics?.meta?.next}
          onFetchMore={onFetchMore}
          loading={loading}
          loadingNext={loadingNext}
        />
      </Wrapper>
    </>
  )
}

export default Topics
