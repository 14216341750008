import { TextAreaProps } from 'antd/es/input'
import React from 'react'

import { TextAreaStyled } from './styled'

type Props = TextAreaProps

function TextArea(props: Props) {
  return <TextAreaStyled {...props} />
}

export default TextArea
