import { Switch } from 'antd'
import { Flex } from 'components/common/Flex'
import { deviceWidth } from 'constants/responsive'
import { useAuth } from 'hooks/useAuth'
import { usePlayer } from 'hooks/usePlayer'
import { memo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import {
  AutomaticallyPlayText,
  AutomaticallyPlayWrapper,
  ClearButton,
  CloseButton,
  Description,
  Header,
  HeaderText,
  Image,
  Item,
  List,
  ListTitle,
  ListTitleRow,
  Number as OrderNumber,
  Popover,
  RemoveButton,
  SeriesTitle,
  ShiurWrapper,
  Subtitle,
  Title,
  TitleRow,
} from './styled'

type Props = { children: React.ReactNode }

const PlayerQueuePopover = ({ children }: Props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { shiur, isAutoPlayEnabled, queue, positionInQueue, toggleAutoPlay, clearQueue, removeFromQueue } = usePlayer()

  const [open, setOpen] = useState(false)
  const [isAutoPlayLoading, setIsAutoPlayLoading] = useState(false)

  const goToSeries = (shiurId: string) => {
    navigate(`/series/${shiurId}`)

    setOpen(false)
  }

  const onToggleAutoPlay = async () => {
    setIsAutoPlayLoading(true)
    await toggleAutoPlay()
    setIsAutoPlayLoading(false)
  }

  const shownQueue = queue.slice(positionInQueue)

  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <Popover
      open={user ? open : false}
      onOpenChange={setOpen}
      trigger="click"
      placement={isMobile ? undefined : 'bottomLeft'}
      content={
        <>
          <Header>
            <HeaderText>Now playing</HeaderText>
            <CloseButton onClick={() => setOpen(false)} />
          </Header>
          <AutomaticallyPlayWrapper>
            <AutomaticallyPlayText>Automatically play next</AutomaticallyPlayText>
            <Switch
              disabled={pathname === ROUTE_PATH.HISTORY}
              checked={isAutoPlayEnabled}
              loading={isAutoPlayLoading}
              onChange={onToggleAutoPlay}
            />
          </AutomaticallyPlayWrapper>
          <ShiurWrapper>
            <Image src={`${process.env.REACT_APP_AWS_S3_URL}${shiur.series.coverUrl}`} />
            <div>
              <Title>{shiur.title}</Title>
              {shiur.subtitle && <Subtitle>{shiur.subtitle}</Subtitle>}
              <Description>
                <SeriesTitle onClick={() => goToSeries(shiur.series.seriesId)} color={shiur.series.titleColor}>
                  {shiur.series.title}
                </SeriesTitle>
                {`∙${shiur.topic.title}`}
              </Description>
            </div>
          </ShiurWrapper>
          <ListTitleRow>
            <ListTitle>Next in queue</ListTitle>
            {!!shownQueue.length && !isAutoPlayEnabled && <ClearButton onClick={clearQueue}>Clear queue</ClearButton>}
          </ListTitleRow>
          <List>
            {!shownQueue.length ? (
              <Flex justifyCenter alignCenter>
                <Title>No shiurim added to queue</Title>
              </Flex>
            ) : (
              shownQueue.map((el) => (
                <Item key={el.shiurId}>
                  <ShiurWrapper small>
                    <Image small src={`${process.env.REACT_APP_AWS_S3_URL}${el.series.coverUrl}`} />
                    <div>
                      <TitleRow>
                        {!!el.orderedNum && <OrderNumber>Shiur {el.orderedNum}.</OrderNumber>}
                        <Title small>{el.title}</Title>
                      </TitleRow>
                      <Description small>
                        <SeriesTitle onClick={() => goToSeries(el.series.seriesId)} color={el.series.titleColor}>
                          {el.series.title}
                        </SeriesTitle>
                        {`∙${shiur.topic.title}`}
                      </Description>
                    </div>
                  </ShiurWrapper>
                  {!isAutoPlayEnabled && <RemoveButton onClick={() => removeFromQueue(el.shiurId)} />}
                </Item>
              ))
            )}
          </List>
        </>
      }
    >
      {children}
    </Popover>
  )
}

export default memo(PlayerQueuePopover)
