import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

export const List = styled.div<{ grid: boolean }>`
  ${({ grid }) =>
    grid &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-row-gap: 14px;
      grid-column-gap: 14px;

      @media ${device.laptop} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media ${device.tablet} {
        grid-template-columns: 1fr;
      }
    `}
`
