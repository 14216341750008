import { Form as ANTDForm, Upload as ANTDUpload, Radio } from 'antd'
import { COLORS } from 'constants/colors'
import styled, { css } from 'styled-components'

export const Header = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  text-align: center;

  margin-bottom: 24px;
`

export const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background-color: #eaf7fe;

  margin-bottom: 24px;
`

export const DescriptionTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  color: #425486;
  text-align: center;

  margin-bottom: 4px;
`

export const DescriptionSubtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: #677fa9;
`

export const Upload = styled(ANTDUpload)`
  margin-bottom: 12px;

  &.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
    width: unset;

    .ant-upload.ant-upload-select {
      width: 100px;
      height: 100px;
      background-color: #eaf7fe;
      border: 2px solid #ffffff;

      margin: 0;
    }
  }
`

export const UploadLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: ${COLORS.primary.medium};

  margin-bottom: 24px;

  cursor: pointer;
`

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;

  border-radius: 50%;
`

export const Form = styled(ANTDForm)`
  width: 100%;
`

export const FormItem = styled(ANTDForm.Item)<{ isButton?: boolean }>`
  ${({ isButton }) =>
    isButton &&
    css`
      .ant-form-item-control,
      .ant-input-affix-wrapper,
      .ant-input {
        background-color: #ffffff;
        color: ${COLORS.text.default};
        cursor: pointer;
      }
      .ant-input,
      .ant-input-affix-wrapper {
        &:hover {
          border-color: #239cd9;
          border-inline-end-width: 1px;
        }
      }
    `}

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:last-child {
    margin: 0;
  }
`

export const RadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    display: flex;
    align-items: center;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-radio-button-wrapper {
    width: 100%;

    font-weight: 600;
    font-size: 14px;
    color: ${COLORS.text.default};
    text-align: center;

    border: 1px solid #d2dde2;
    border-radius: 40px;

    &:hover {
      border-color: ${COLORS.primary.medium};
    }

    &:first-child {
      margin-right: 12px;
    }

    &.ant-radio-button-wrapper-checked {
      color: #ffffff;
      background-color: ${COLORS.primary.medium};
      border-color: ${COLORS.primary.medium};
    }
  }
`
