import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 274px;
  height: 130px;
  padding: 24px;

  border-radius: 12px;
  border: 1px solid #d9ecf6;
  background: #f4fbff;

  transition: all 0.2s;
  &:hover {
    border-color: ${COLORS.primary.light};
  }

  cursor: pointer;

  @media ${device.laptopL} {
    width: 100%;
  }
`

export const Title = styled.p`
  font-size: 20px;
  line-height: 1.2;
  color: ${COLORS.text.default};
`

export const Count = styled.p`
  font-size: 18px;
  line-height: 1.2;
  color: #677fa9;
`
