import { Upload as ANTDUpload } from 'antd'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const AvatarWrapper = styled.div`
  position: relative;

  margin-right: 18px;

  @media ${device.laptopL} {
    margin-right: 0;
    margin-bottom: 10px;
  }
`

export const EditButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;

  background-color: ${COLORS.primary.medium};
  border: 2px solid #eaf7fe;
  border-radius: 50%;

  cursor: pointer;
`

export const Upload = styled(ANTDUpload)`
  &.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
    width: unset;

    .ant-upload.ant-upload-select {
      width: 120px;
      height: 120px;
      background-color: #eaf7fe;
      border: 2px solid #ffffff;

      margin: 0;
    }
  }
`

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;

  border-radius: 50%;
`
