import AllTab from 'components/business/Main/Browse/AllTab'
import SeriesTab from 'components/business/Main/Browse/SeriesTab'
import ShiurimTab from 'components/business/Main/Browse/ShiurimTab'
import Tabs from 'components/business/Main/Browse/Tabs'
import TopicsTab from 'components/business/Main/Browse/TopicsTab'
import ShiurimFilter, { ShiurimFilters } from 'components/business/Main/Shiurim/ShiurimFilter'
import Header from 'components/ui/Header'
import { ReactComponent as BrowseIcon } from 'components/ui/Sidebar/icons/active/browse.svg'
import { deviceWidth } from 'constants/responsive'
import { getAllShiurims, getAllShiurimsVariables } from 'graphql/query/__generated__/getAllShiurims'
import { getAllTopics, getAllTopicsVariables } from 'graphql/query/__generated__/getAllTopics'
import {
  getSeriesOrderedByShiurCount,
  getSeriesOrderedByShiurCountVariables,
} from 'graphql/query/__generated__/getSeriesOrderedByShiurCount'
import { GET_ALL_SHIURIMS } from 'graphql/query/getAllShiurims'
import { GET_ALL_TOPICS } from 'graphql/query/getAllTopics'
import { GET_SERIES_ORDERED_BY_SHIUR_COUNT } from 'graphql/query/getSeriesOrderedByShiurCount'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'

import { Wrapper } from '../styled'
import { FiltersWrapper, TabsRow } from './styled'

export enum BROWSE_TABS {
  ALL = 'ALL',
  SERIES = 'SERIES',
  SHIURIM = 'SHIURIM',
  TOPICS = 'TOPICS',
}

function Browse() {
  const { setQueryInfo } = useQueryInfo()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 300)

  const [selectedTab, setSelectedTab] = useState<BROWSE_TABS>(BROWSE_TABS.ALL)

  const [shiurimFilters, setShiurimFilters] = useState<ShiurimFilters>({})

  const searchProps = {
    searchValue: search,
    onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    searchPlaceholder: 'Search topics, Series or Shiurim...',
  }

  const { data: topicData, loading: topicLoading } = useQuery<getAllTopics, getAllTopicsVariables>(GET_ALL_TOPICS, {
    variables: { take: 20, page: 1, title: debouncedSearch },
  })

  const { data: seriesData, loading: seriesLoading } = useQuery<
    getSeriesOrderedByShiurCount,
    getSeriesOrderedByShiurCountVariables
  >(GET_SERIES_ORDERED_BY_SHIUR_COUNT, { variables: { take: 20, page: 1, title: debouncedSearch } })

  const shiurVariables = useMemo(
    () => ({ take: 6, page: 1, searchParams: { title: debouncedSearch } }),
    [debouncedSearch]
  )
  const { data: shiurimData, loading: shiurimLoading } = useQuery<getAllShiurims, getAllShiurimsVariables>(
    GET_ALL_SHIURIMS,
    { variables: shiurVariables }
  )

  useEffect(() => {
    setQueryInfo({ query: GET_ALL_SHIURIMS, variables: shiurVariables })
  }, [setQueryInfo, shiurVariables])

  const results = {
    [BROWSE_TABS.ALL]:
      topicData?.getAllTopics?.meta?.total +
        seriesData?.getSeriesOrderedByShiurCount?.meta?.total +
        shiurimData?.getAllShiurims?.meta?.total || 0,
    [BROWSE_TABS.SERIES]: seriesData?.getSeriesOrderedByShiurCount?.meta?.total || 0,
    [BROWSE_TABS.SHIURIM]: shiurimData?.getAllShiurims?.meta?.total || 0,
    [BROWSE_TABS.TOPICS]: topicData?.getAllTopics?.meta?.total || 0,
  }

  const renderCurrentTab = () => {
    switch (selectedTab) {
      case BROWSE_TABS.ALL:
        return (
          <AllTab
            showCount={!!search}
            topics={topicData?.getAllTopics}
            series={seriesData?.getSeriesOrderedByShiurCount}
            shiurims={shiurimData?.getAllShiurims}
            topicsLoading={topicLoading}
            seriesLoading={seriesLoading}
            shiurimLoading={shiurimLoading}
          />
        )

      case BROWSE_TABS.TOPICS:
        return <TopicsTab search={debouncedSearch} />

      case BROWSE_TABS.SERIES:
        return <SeriesTab search={debouncedSearch} />

      case BROWSE_TABS.SHIURIM:
        return <ShiurimTab filters={shiurimFilters} search={debouncedSearch} />

      default:
        break
    }
  }

  const isFilterAvailable = selectedTab === BROWSE_TABS.SHIURIM
  const isMobile = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])
  return (
    <>
      <Header icon={<BrowseIcon />} title="Browse" searchProps={searchProps} />
      <Wrapper id="scrollable-wrapper">
        <TabsRow>
          <Tabs selectedTab={selectedTab} onChangeTab={setSelectedTab} results={search && results} />
          {isFilterAvailable && !isMobile && (
            <ShiurimFilter filters={shiurimFilters} onChangeFilters={setShiurimFilters} />
          )}
        </TabsRow>
        {isFilterAvailable && isMobile && (
          <FiltersWrapper>
            <ShiurimFilter filters={shiurimFilters} onChangeFilters={setShiurimFilters} />
          </FiltersWrapper>
        )}
        {renderCurrentTab()}
      </Wrapper>
    </>
  )
}

export default withQueryInfo(Browse)
