import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Header = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  text-align: center;

  margin-bottom: 32px;
`

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 136px);
  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
`
