import { ReactComponent as Icon } from 'assets/icons/message.svg'
import InfoModal from 'components/ui/Modal/InfoModal'

type Props = { open: boolean; onComplete: () => void }

function VerificationCodeSentModal({ open, onComplete }: Props) {
  return (
    <InfoModal
      open={open}
      onBtnClick={onComplete}
      btnText={'GOT IT'}
      icon={<Icon />}
      title="Check your email"
      description="We've sent you an email with verification code"
    />
  )
}

export default VerificationCodeSentModal
