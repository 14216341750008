import { Popover as ANTDPopover, PopoverProps } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

const MyPopover = ({ className, ...props }: PopoverProps) => <ANTDPopover {...props} overlayClassName={className} />

export const Popover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    width: 210px;
    padding: 0;
    border: 1px solid #ebebeb;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
`

export const PopoverItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;

  transition: all 0.2s;

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }

  &:hover {
    color: ${COLORS.primary.medium};
  }

  & > svg {
    color: ${COLORS.primary.medium};
  }

  cursor: pointer;
`

export const PopoverText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  margin-left: 10px;
`

export const PlaybackRateText = styled.p<{ isChanged: boolean }>`
  width: 25px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  color: ${({ isChanged }) => (isChanged ? COLORS.primary.medium : COLORS.text.default)};
`
