import { gql } from '@apollo/client'

export const GET_SPONSORSHIP_HISTORY = gql`
  query getSponsorshipHistory($take: Int!, $page: Int!) {
    getSponsorshipHistory(take: $take, page: $page) {
      items {
        sponsorshipId
        amount
        startDate
        endDate
        period
        type
        isActive
        inMemoryOf
        comment
        series {
          seriesId
          title
          titleColor
        }
        createdAt
      }
      meta {
        total
        next
      }
    }
  }
`
