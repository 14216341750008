import { Drawer } from 'antd'
import { ReactComponent as BlockquoteUp } from 'assets/icons/quote.svg'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled(Drawer)`
  border-radius: 12px 0px 0px 12px;

  .ant-drawer-body {
    position: relative;

    padding: 0;
  }

  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-footer {
    display: none;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 265px;

  object-fit: cover;
  object-position: center;
`

export const CloseWrapper = styled.div`
  position: fixed;
  top: 24px;
  right: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  background-color: #ffffff;
  border-radius: 50%;

  cursor: pointer;
`

export const Content = styled.div`
  padding: 24px 40px 40px;

  font-size: 16px;
  line-height: 1.2;
  color: #696b7b;
`

export const Title = styled.p`
  font-size: 24px;
  line-height: 1.3;
  font-weight: 500;
  color: ${COLORS.text.default};

  margin-bottom: 12px;
`

export const DurationWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 4px 8px;
  margin-right: 12px;

  color: ${COLORS.primary.medium};

  border-radius: 16px;
  background: #eaf7fe;
`

export const DurationText = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  margin-left: 4px;
`

export const DateText = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: #696b7b;
`

export const Divider = styled.div`
  height: 1px;

  margin: 16px 0;

  background-color: #ebebeb;
`

export const IMG = styled.img`
  width: 100%;
  height: 288px;

  object-fit: cover;
  object-position: center;

  border-radius: 12px;
`

export const H1 = styled.h1`
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: ${COLORS.text.default};

  margin-bottom: 16px;
`

export const H2 = styled.h2`
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  color: ${COLORS.text.default};

  margin-bottom: 16px;
`

export const H3 = styled.h3`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: ${COLORS.text.default};

  margin-bottom: 8px;
`

export const H4 = styled.h4`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: ${COLORS.text.default};

  margin-bottom: 8px;
`

export const H5 = styled.h5`
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  color: ${COLORS.text.default};

  margin-bottom: 8px;
`

export const P = styled.p`
  margin-bottom: 16px;
`

export const STRONG = styled.strong`
  color: ${COLORS.text.default};
`

export const OL = styled.ol`
  list-style: decimal;

  padding-left: 16px;
  margin-bottom: 16px;
`

export const UL = styled.ul`
  list-style: disc;

  padding-left: 20px;
  margin-bottom: 16px;
`

export const A = styled.a`
  color: ${COLORS.primary.medium};
`

export const BlockquoteWrapper = styled.div`
  position: relative;

  padding: 0 42px;
`

export const BlockquoteLeft = styled(BlockquoteUp)`
  position: absolute;
  top: 0;
  left: 0;
`

export const BlockquoteRight = styled(BlockquoteUp)`
  position: absolute;
  bottom: 0;
  right: 0;

  transform: rotate(180deg);
`
