import ActionModal from 'components/ui/Modal/ActionModal'
import WeekCalendar from 'components/ui/WeekCalendar'
import { deviceWidth } from 'constants/responsive'
import dayjs from 'dayjs'
import { useState } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  selectedDates?: [dayjs.Dayjs, dayjs.Dayjs]
  onChangeDate: (date: [dayjs.Dayjs, dayjs.Dayjs]) => void
}

function SelectWeekModal({ open, onClose, selectedDates, onChangeDate }: Props) {
  const [newSelectedDates, setNewSelectedDate] = useState(selectedDates)

  const onSave = () => {
    onChangeDate(newSelectedDates)
  }

  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <ActionModal
      width={isMobile ? 350 : 425}
      open={open}
      onCancel={onClose}
      onBtnClick={onSave}
      disabled={!newSelectedDates}
      btnText="SELECT"
      title="Select a Week"
    >
      <WeekCalendar rangeDates={newSelectedDates} onChangeDate={setNewSelectedDate} future />
    </ActionModal>
  )
}

export default SelectWeekModal
