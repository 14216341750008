import { SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_ALL_USER_FAVORITE_SHIURS = gql`
  ${SHIUR_FRAGMENT}
  query getAllUserFavoriteShiurs($take: Int!, $page: Int!, $title: String) {
    getAllUserFavoriteShiurs(take: $take, page: $page, title: $title) {
      items {
        ...ShiurimInfo
      }
      meta {
        total
        next
      }
    }
  }
`
