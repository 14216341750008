import { Layout } from 'antd'
import { device } from 'constants/responsive'
import styled from 'styled-components'

// Reusable for each page
export const Wrapper = styled.div`
  overflow: auto;

  padding: 8px 27px 40px;
`

export const MainWrapper = styled(Layout)<{ isPlayerShown?: boolean; headerHeight?: number }>`
  position: relative;
  height: 100dvh;
  background-color: #f4fbff;

  ${Wrapper} {
    height: ${({ headerHeight }) => `calc(100% - ${headerHeight}px)`}; // full height - header height: ;

    ${({ isPlayerShown }) => isPlayerShown && 'padding: 8px 27px 134px'};

    @media ${device.laptopL} {
      padding: 8px 14px 20px;
      ${({ isPlayerShown }) => isPlayerShown && 'padding: 8px 14px 88px'};
      height: ${({ headerHeight }) => `calc(100% - ${headerHeight}px)`}; // full height - header height: ;
    }
  }
`

export const Content = styled(Layout.Content)`
  width: 100%;
  background-color: #ffffff;
  box-shadow: -20px 0px 80px rgba(185, 208, 236, 0.13);
  border: 1px solid #eaf7fe;
  border-radius: 24px 0px 0px 24px;

  @media ${device.laptopL} {
    border-radius: 0;
  }
  z-index: 1;
`
