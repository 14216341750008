import { Country } from '__generated__/globalTypes'
import { Input } from 'antd'
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import ActionModal from 'components/ui/Modal/ActionModal'
import { formatText } from 'helpers/formatText'
import { ChangeEvent, useState } from 'react'

import { Item, List } from './styled'

type Props = { open: boolean; onClose: () => void; onSelect: (country: string) => void }

function LocationModal({ open, onClose, onSelect }: Props) {
  const [search, setSearch] = useState('')

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)
  const countries = Object.values(Country)

  const items = search ? countries.filter((str) => str.toLowerCase().includes(search.toLowerCase())) : []

  return (
    <ActionModal open={open} onCancel={onClose} title="Change country" noFooter>
      <Input value={search} onChange={onChange} prefix={<SearchIcon />} placeholder="Search by country..." />
      <List>
        {items.map((el) => (
          <Item key={el} onClick={() => onSelect(el)}>
            <LocationIcon />
            {el === Country.USA || el === Country.UK ? el : formatText(el)}
          </Item>
        ))}
      </List>
    </ActionModal>
  )
}

export default LocationModal
