import { ReactComponent as DonationIcon } from 'assets/icons/donate-small.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/tick.svg'
import { Flex } from 'components/common/Flex'
import {
  toggleSeriesFollowStatus,
  toggleSeriesFollowStatusVariables,
} from 'graphql/mutation/__generated__/toggleSeriesFollowStatus'
import { TOGGLE_SERIES_FOLLOW_STATUS } from 'graphql/mutation/toggleSeriesFollowStatus'
import { getOneSeries, getOneSeriesVariables } from 'graphql/query/__generated__/getOneSeries'
import { GET_ONE_SERIES } from 'graphql/query/getOneSeries'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useMutation, useQuery } from '@apollo/client'

import SeriesSponsor from '../SeriesSponsor'
import {
  Divider,
  FollowButton,
  Image,
  MainBanner,
  MainContent,
  SponsorButton,
  Subtitle,
  Title,
  Wrapper,
} from './styled'

type Props = { openRegisterToContinueModal: () => void }

function SeriesTopBanners({ openRegisterToContinueModal }: Props) {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { id } = useParams()

  const [isToggleFollowLoading, setIsToggleFollowLoading] = useState(false)

  const { data, loading, refetch } = useQuery<getOneSeries, getOneSeriesVariables>(GET_ONE_SERIES, {
    variables: { seriesId: id },
  })
  const series = data?.getOneSeries

  const [toggleFollow] = useMutation<toggleSeriesFollowStatus, toggleSeriesFollowStatusVariables>(
    TOGGLE_SERIES_FOLLOW_STATUS
  )

  const isFollowed = series?.followInfo?.isFollowed

  const onToggleFollow = async () => {
    setIsToggleFollowLoading(true)
    await toggleFollow({ variables: { seriesId: id, follow: !isFollowed } })
    await refetch()
    setIsToggleFollowLoading(false)
  }

  const navigateToDonatePage = () => navigate(ROUTE_PATH.DONATE)

  return (
    <Wrapper>
      <MainBanner>
        {!loading && (
          <>
            <MainContent>
              <Image src={`${process.env.REACT_APP_AWS_S3_URL}${series?.coverUrl}`} />
              <Divider />
              <div>
                <Title>{series?.title}</Title>
                <Subtitle>{series?.subtitle}</Subtitle>
              </div>
            </MainContent>
            <Flex alignCenter>
              <FollowButton
                followed={isFollowed}
                loading={isToggleFollowLoading}
                onClick={user ? onToggleFollow : openRegisterToContinueModal}
              >
                {!isToggleFollowLoading && (isFollowed ? <SuccessIcon /> : <PlusIcon />)}
                {isFollowed ? 'Following' : 'Follow'}
              </FollowButton>

              <SponsorButton onClick={navigateToDonatePage}>
                <DonationIcon /> Sponsor
              </SponsorButton>
            </Flex>
          </>
        )}
      </MainBanner>
      <SeriesSponsor />
    </Wrapper>
  )
}

export default SeriesTopBanners
