import MainPage from 'components/business/Main/Community/MainPage'
import Header from 'components/ui/Header'
import { CommunityActive } from 'components/ui/Sidebar/icons'

import { Wrapper } from '../styled'

function Community() {
  return (
    <>
      <Header icon={<CommunityActive />} title="Community" />
      <Wrapper>
        <MainPage />
      </Wrapper>
    </>
  )
}

export default Community
