import { device } from 'constants/responsive'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled, { css } from 'styled-components'

export const Wrapper = styled(InfiniteScroll)<{ grid: boolean }>`
  overflow: visible !important;
  position: relative;

  padding-bottom: 45px;

  ${({ grid }) =>
    grid &&
    css`
      display: grid;
      grid-template-columns: repeat(auto-fill, 274px);
      grid-template-rows: 1fr;
      grid-column-gap: 14px;
      grid-row-gap: 28px;

      @media ${device.laptopL} {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 14px;
      }

      @media ${device.tablet} {
        grid-template-columns: 1fr;
      }
    `}
`

export const LoaderWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  justify-content: center;
`
