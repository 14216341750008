import { useState } from 'react'

import { ActionText, MainText } from './styled'

type Props = { text: string }

function Comment({ text }: Props) {
  const [collapsed, setCollapsed] = useState(true)

  const collapsedText = text.slice(0, 50)
  const isTextCollapsed = collapsed && text.length > 50
  return (
    <MainText>
      {isTextCollapsed ? `${collapsedText}...` : text}{' '}
      <ActionText onClick={() => setCollapsed(!collapsed)}>
        {text.length > 50 ? (collapsed ? 'view all' : 'hide') : null}
      </ActionText>
    </MainText>
  )
}

export default Comment
