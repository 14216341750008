import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: hidden;
  width: 274px;

  background: #eaf7fe;
  border: 0.5px solid #d9ecf6;
  border-radius: 12px;

  @media ${device.laptop} {
    width: 100%;
  }
`

export const Header = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${COLORS.primary.medium};

  padding: 16px 0;
  background: #d2f0ff;

  @media ${device.laptop} {
    padding: 8px 0;
  }
`

export const Content = styled.div`
  height: 102px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    height: 80px;
  }
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: ${COLORS.text.default};
`

export const Description = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: #677fa9;

  margin-top: 8px;
`
