import { Radio } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const RadioButtonStyled = styled(Radio)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  padding: 20px 0px;
  margin: 0;

  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }

  &.ant-radio-wrapper span.ant-radio + * {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  &.ant-radio-wrapper .ant-radio-inner {
    background-color: unset;
    border-width: 1.5px;
  }

  &.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
    background-color: ${COLORS.primary.medium};
  }

  &::after {
    display: none;
  }
`
