import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

export const Item = styled.div`
  &:nth-child(n + 2) {
    margin-top: 40px;
  }
`

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 14px;

  @media ${device.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`

export const Date = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  &:not(:first-child) {
    margin-top: 40px;
    margin-bottom: 24px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-bottom: 24px;
`

export const LoaderMoreWrapper = styled.div<{ top?: boolean; bottom?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ top }) =>
    top &&
    css`
      position: absolute;
      top: 12px;
      left: 0px;
      width: 100%;
    `};

  ${({ bottom }) =>
    bottom &&
    css`
      position: absolute;
      left: 0px;
      width: 100%;
    `};
`

export const LoaderWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  background-color: #ffffff;

  z-index: 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
