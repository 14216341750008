import { MutableRefObject, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { OnProgressProps } from 'react-player/base'

import { usePlayer } from './usePlayer'

const defaultVolume = 50
export const defaultPlaybackRate = 1.0

export const usePlayerOptions = (playerRef: MutableRefObject<ReactPlayer>) => {
  const { isPlaying, pause, play, updatePlayerState } = usePlayer()

  const [duration, setDuration] = useState(0)
  const [progress, setProgress] = useState<OnProgressProps>({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  })
  const [volume, setVolume] = useState(defaultVolume)
  const [playbackRate, setPlaybackRate] = useState(defaultPlaybackRate)

  useEffect(() => {
    updatePlayerState(duration, progress)
  }, [duration, isPlaying, progress])

  const onPlay = async () => {
    await play()
  }
  const onPause = () => {
    pause()
  }

  const onMoveBack = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 15)
  }
  const onMoveForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 15)
  }

  const moveTo = (seconds: number) => {
    playerRef.current.seekTo(seconds)
  }

  const changePlaybackRate = (playbackRate: number) => {
    setPlaybackRate(playbackRate)
  }
  const resetPlaybackRate = () => {
    setPlaybackRate(defaultPlaybackRate)
  }

  return {
    duration,
    setDuration,
    progress,
    setProgress,
    volume,
    setVolume,
    playbackRate,
    onPlay,
    onPause,
    onMoveBack,
    onMoveForward,
    moveTo,
    changePlaybackRate,
    resetPlaybackRate,
  }
}
