import Account from './Account'
import PersonalInformation from './PersonalInformation'
import { MainWrapper } from './styled'

function ProfileSettings() {
  return (
    <MainWrapper>
      <PersonalInformation />
      <Account />
    </MainWrapper>
  )
}

export default ProfileSettings
