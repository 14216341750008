import Calendar from 'components/ui/Calendar'
import ActionModal from 'components/ui/Modal/ActionModal'
import { deviceWidth } from 'constants/responsive'
import dayjs from 'dayjs'
import { useState } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  selectedDate?: dayjs.Dayjs
  onChangeDate: (date: dayjs.Dayjs) => void
}

function SelectDayModal({ open, onClose, selectedDate, onChangeDate }: Props) {
  const [newSelectedDate, setNewSelectedDate] = useState(selectedDate)

  const onSave = () => {
    onChangeDate(newSelectedDate)
  }

  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <ActionModal
      width={isMobile ? 350 : 425}
      open={open}
      onCancel={onClose}
      onBtnClick={onSave}
      disabled={!newSelectedDate}
      btnText="SELECT"
      title="Select a Day"
    >
      <Calendar selectedDate={newSelectedDate} onChangeDate={setNewSelectedDate} future />
    </ActionModal>
  )
}

export default SelectDayModal
