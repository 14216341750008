import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: hidden;
  padding: 16px;

  border-radius: 12px;
  border: 1px solid #ebebeb;

  transition: all 0.2s;
  &:hover {
    border-color: ${COLORS.primary.light};
  }

  cursor: pointer;

  @media ${device.laptopL} {
    padding: 12px;
  }
`

export const ShiurWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;

  @media ${device.laptopL} {
    margin-bottom: 12px;
  }
`

export const ShiurContainer = styled.div`
  display: flex;
  width: calc(100% - 150px); // 100% - width of date elem

  @media ${device.laptopL} {
    width: 100%;
  }
`

export const DateText = styled.p`
  font-size: 14px;
  line-height: 1.3;

  white-space: nowrap;

  @media ${device.laptopL} {
    margin-top: 4px;
    text-align: end;
  }
`

export const ShiurTimeCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin-left: 10px;

  background-color: #eaf7fe;
  border-radius: 16px;

  @media ${device.laptopL} {
    margin-left: 0;
  }
`

export const ShiurTimeCodeText = styled.p`
  font-size: 12px;
  line-height: 1.3;

  color: ${COLORS.text.default};
`

export const Image = styled.img`
  height: 50px;
  width: 50px;

  margin-right: 12px;

  object-fit: cover;
  object-position: center;

  border-radius: 8px;

  @media ${device.laptopL} {
    margin-right: 6px;
  }
`

export const ContentWrapper = styled.div`
  flex-grow: 1;
  width: calc(100% - 65px); // 100% - width of image
`

export const TitleRow = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`

export const Number = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-right: 2px;

  white-space: nowrap;

  @media ${device.laptopL} {
    font-size: 16px;
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${device.laptopL} {
    font-size: 16px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${device.laptopL} {
    font-size: 14px;
    width: auto;
  }
`

export const SeriesTitle = styled.span<{ color?: string }>`
  color: ${({ color }) => color || COLORS.text.default};

  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    opacity: 0.7;
  }
`

export const Text = styled.p`
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;

  @media ${device.laptopL} {
    margin-bottom: 8px;
  }
`
