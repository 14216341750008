import { Input } from 'antd'
import { COLORS } from 'constants/colors'
import styled, { css } from 'styled-components'

export const SearchInput = styled(Input)<{ header?: boolean }>`
  height: 40px;
  width: 100%;
  max-width: 536px;
  padding: 12px 15px;

  font-size: 14px;
  color: ${COLORS.text.default};

  ${({ header }) =>
    header &&
    css`
      border-color: #d9ecf6;
      background: #f4fbff;
    `}

  input {
    background-color: transparent;

    &::placeholder {
      color: #425486;
    }
  }

  .ant-input-prefix {
    margin-inline-end: 8px;

    ${({ header }) => header && ' color: ${COLORS.primary.medium}'};
  }
`
