import { ReactComponent as NoDataIcon } from 'assets/icons/history-big.svg'
import { ReactComponent as ClearIcon } from 'assets/icons/trash.svg'
import ShiurimListByDate from 'components/business/Main/Lists/ShiurimListByDate'
import Header from 'components/ui/Header'
import Loader from 'components/ui/Loader'
import NoData from 'components/ui/NoData'
import { ReactComponent as HistoryIcon } from 'components/ui/Sidebar/icons/active/history.svg'
import { clearUserListeningHistory } from 'graphql/mutation/__generated__/clearUserListeningHistory'
import { CLEAR_USER_LISTENING_HISTORY } from 'graphql/mutation/clearUserListeningHistory'
import {
  getAllUserShiurListenings,
  getAllUserShiurListeningsVariables,
} from 'graphql/query/__generated__/getAllUserShiurListenings'
import { GET_ALL_USER_SHIUR_LISTENINGS } from 'graphql/query/getAllUserShiurListenings'
import { useAuth } from 'hooks/useAuth'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { ChangeEvent, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'

import { Wrapper } from '../styled'

const initialPagination = {
  take: 30,
  page: 1,
}

function History() {
  const { user } = useAuth()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 300)

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)
  const [clearLoading, setClearLoading] = useState(false)

  const { data, loading, fetchMore, refetch } = useQuery<getAllUserShiurListenings, getAllUserShiurListeningsVariables>(
    GET_ALL_USER_SHIUR_LISTENINGS,
    { variables: { title: debouncedSearch, ...initialPagination }, fetchPolicy: 'network-only', skip: !user }
  )

  const [clearUserListeningHistory] = useMutation<clearUserListeningHistory>(CLEAR_USER_LISTENING_HISTORY)

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getAllUserShiurListenings: {
            ...previousQueryResult.getAllUserShiurListenings,
            ...fetchMoreResult.getAllUserShiurListenings,
            items: [
              ...previousQueryResult.getAllUserShiurListenings.items,
              ...fetchMoreResult.getAllUserShiurListenings.items,
            ],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const searchProps = {
    searchValue: search,
    onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    searchPlaceholder: 'Search shiurim...',
  }

  const clearProps = {
    icon: <ClearIcon />,
    text: 'Clear listening history',
    onClick: async () => {
      setClearLoading(true)

      await clearUserListeningHistory()
      setPage(initialPagination.page)
      await refetch()

      setClearLoading(false)
    },
  }

  const itemsLength = data?.getAllUserShiurListenings?.items?.length

  return (
    <>
      <Header
        icon={<HistoryIcon />}
        title="History"
        searchProps={searchProps}
        clearProps={itemsLength ? clearProps : undefined}
      />
      <Wrapper id="scrollable-wrapper">
        {(!itemsLength && loading) || clearLoading ? (
          <Loader />
        ) : !itemsLength ? (
          <NoData
            icon={<NoDataIcon />}
            title={user ? 'You have no listening history' : 'Nothing here... yet'}
            description={
              user
                ? 'Your listened to shiurim will be stored here'
                : 'Create an account to track your listening history'
            }
          />
        ) : (
          <ShiurimListByDate
            history
            items={data?.getAllUserShiurListenings?.items}
            next={data?.getAllUserShiurListenings?.meta?.next}
            loadingNext={loadingNext}
            refetch={refetch}
            onFetchMore={onFetchMore}
            loading={loading}
          />
        )}
      </Wrapper>
    </>
  )
}

export default History
