import { useAuth } from 'hooks/useAuth'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { Description, JoinButton, Title, Wrapper } from './styled'

type Props = {
  icon: ReactNode
  title: string
  description: string
  buttonProps?: { onClick: () => void; buttonText: string }
  height?: string
}

function NoData({ icon, title, description, buttonProps, height }: Props) {
  const navigate = useNavigate()
  const { user } = useAuth()

  const onJoinClick = () => {
    navigate(ROUTE_PATH.LOGIN)
  }

  return (
    <Wrapper height={height}>
      {icon}
      <Title>{title}</Title>
      <Description>{description}</Description>
      {!user ||
        (buttonProps && (
          <JoinButton type="primary" onClick={buttonProps ? buttonProps.onClick : onJoinClick}>
            {buttonProps ? buttonProps.buttonText : 'JOIN PATH4LIFE'}
          </JoinButton>
        ))}
    </Wrapper>
  )
}

export default NoData
