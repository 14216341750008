import { Country } from '__generated__/globalTypes'
import { Input } from 'antd'
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import ActionModal from 'components/ui/Modal/ActionModal'
import { formatText } from 'helpers/formatText'
import { useAuth } from 'hooks/useAuth'
import { ChangeEvent, useState } from 'react'

import { Item, List } from './styled'

type Props = { open: boolean; onClose: () => void }

function ChangeLocationModal({ open, onClose }: Props) {
  const { user, updateUserInfo } = useAuth()

  const [country, setCountry] = useState<Country>()
  const [city, setCity] = useState('')
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)
  const onChangeCity = (e: ChangeEvent<HTMLInputElement>) => setCity(e.target.value)

  const countries = Object.values(Country)
  const items = search ? countries.filter((str) => str.toLowerCase().includes(search.toLowerCase())) : []

  const onSave = async () => {
    setLoading(true)

    await updateUserInfo({ username: user.username, gender: user.gender, country: country as Country, city: city })
    onClose()

    setLoading(false)
  }

  const saveButtonProps = country ? { onBtnClick: onSave, btnText: 'SAVE', btnLoading: loading } : {}
  return (
    <ActionModal
      width={400}
      open={open}
      onCancel={onClose}
      title="Change country"
      noFooter={!country}
      {...saveButtonProps}
    >
      {!country ? (
        <>
          <Input value={search} onChange={onChangeSearch} prefix={<SearchIcon />} placeholder="Search by country..." />
          <List>
            {items.map((el) => (
              <Item key={el} onClick={() => setCountry(el)}>
                <LocationIcon />
                {el === Country.USA || el === Country.UK ? el : formatText(el)}
              </Item>
            ))}
          </List>
        </>
      ) : (
        <Input maxLength={100} value={city} onChange={onChangeCity} placeholder="Enter city..." />
      )}
    </ActionModal>
  )
}

export default ChangeLocationModal
