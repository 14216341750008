import { device } from 'constants/responsive'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

export const Wrapper = styled(InfiniteScroll)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-template-rows: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 14px;

  @media ${device.laptopL} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 30px;

  img {
    width: 32px;
    height: 32px;
  }
`
