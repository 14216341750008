import { ModalProps } from 'antd'

import { ANTDModal } from './styled'

type Props = ModalProps

function BlankModal(props: Props) {
  return <ANTDModal {...props} />
}

export default BlankModal
