import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100dvh;
  overflow: auto;
  padding: 30px;
`

export const Title = styled.h1`
  font-size: 24px;
  line-height: 1.3;

  color: ${COLORS.text.default};

  margin-bottom: 20px;
`

export const Content = styled.div`
  height: calc(100% - 51px);
  display: flex;
  justify-content: center;
  align-items: center;
`
