import { UploadProps } from 'antd'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import AvatarFallback from 'assets/img/avatar-fallback.jpg'
import { getSignedUrls, getSignedUrlsVariables } from 'graphql/query/__generated__/getSignedUrls'
import { GET_SIGNED_URLS } from 'graphql/query/getSignedUrls'
import { useAuth } from 'hooks/useAuth'
import { useRef } from 'react'

import { useLazyQuery } from '@apollo/client'

import { AvatarImage, AvatarWrapper, EditButton, Upload } from './styled'

function Avatar() {
  const { user, updateUserInfo } = useAuth()
  const { username, avatarUrl, gender } = user || {}

  const uploadRef = useRef(null)

  const [getSignedUrls] = useLazyQuery<getSignedUrls, getSignedUrlsVariables>(GET_SIGNED_URLS)

  const onChangeAvatar: UploadProps['onChange'] = async ({ fileList }) => {
    const { data } = await getSignedUrls({
      variables: {
        query: {
          fileName: 'userAvatar',
          path: user.userId,
          contentType: fileList[0].originFileObj.type,
          method: 'putObject',
        },
      },
    })

    await fetch(data.getSignedUrls, {
      method: 'PUT',
      body: fileList[0].originFileObj,
    })

    await updateUserInfo({
      username,
      avatarUrl: `${user.userId}/userAvatar?${Date.now()}`,
      gender,
    })
  }

  return (
    <AvatarWrapper>
      <Upload
        ref={uploadRef}
        customRequest={() => null}
        maxCount={1}
        showUploadList={false}
        accept="image/jpg, image/jpeg, image/png"
        listType="picture-circle"
        onChange={onChangeAvatar}
      >
        <AvatarImage src={avatarUrl ? `${process.env.REACT_APP_AWS_S3_URL}${user.avatarUrl}` : AvatarFallback} />
      </Upload>
      <EditButton onClick={() => uploadRef.current.upload.uploader.onClick()}>
        <EditIcon />
      </EditButton>
    </AvatarWrapper>
  )
}

export default Avatar
