import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const ContentWrapper = styled.div`
  position: relative;
  width: 300px;
  padding: 16px 16px 0 16px;
`

export const List = styled.ul`
  overflow: auto;
  height: 300px;
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 25px 12px 0px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ebebeb;
  }

  cursor: pointer;
`

export const Image = styled.img`
  width: 46px;
  height: 46px;
  margin-right: 10px;

  object-fit: cover;
  object-position: center;

  border-radius: 50%;
`

export const Title = styled.p`
  color: ${COLORS.text.default};
  font-size: 14px;
  line-height: 1.3;
`

export const ShowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

  cursor: pointer;
`

export const ShowMoreText = styled.p`
  margin-right: 4px;

  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: ${COLORS.text.default};
`
