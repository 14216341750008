import { SERIES_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_ONE_SERIES = gql`
  ${SERIES_FRAGMENT}
  query getOneSeries($seriesId: String!) {
    getOneSeries(seriesId: $seriesId) {
      ...SeriesInfo
    }
  }
`
