import { Form, Input } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import ActionModal from 'components/ui/Modal/ActionModal'
import { useAuth } from 'hooks/useAuth'
import { ErrorText } from 'pages/Auth/styled'
import { useState } from 'react'

import { FormItem } from '../styled'

type Props = { open: boolean; onClose: () => void; nextStep: (email: string) => void }

function EmailInput({ open, onClose, nextStep }: Props) {
  const { resetPassword } = useAuth()
  const [form] = useForm()
  const email = useWatch('email', form)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const [isErrors, setIsErrors] = useState(false)

  const validateAndUpdateState = () => {
    form
      .validateFields()
      .then(() => setIsErrors(false))
      .catch(() => {
        setIsErrors(true)
      })
  }

  const onBtnClick = async () => {
    setIsLoading(true)
    setError('')
    try {
      await resetPassword(email)
      nextStep(email)
    } catch (e) {
      setError(e.message)
    }
    setIsLoading(false)
  }

  const isBtnDisabled = isErrors || !email

  return (
    <ActionModal
      open={open}
      title="Forgot password"
      description="Enter your registered email address and we’ll send you instructions on how to reset your password."
      width={400}
      btnText="SEND"
      onBtnClick={onBtnClick}
      onCancel={onClose}
      btnLoading={isLoading}
      disabled={isBtnDisabled}
    >
      <Form form={form} layout="vertical" requiredMark={false} onValuesChange={() => setError('')}>
        <FormItem
          name="email"
          label="Email"
          validateTrigger={form.getFieldError('email').length ? 'onChange' : 'onBlur'}
          required
          rules={[
            { required: true, message: '' },
            {
              type: 'email',
              message: 'Incorrect email',
            },
          ]}
        >
          <Input
            placeholder="Enter your email"
            onChange={form.getFieldError('email').length ? validateAndUpdateState : undefined}
            onBlur={form.getFieldError('email').length ? undefined : validateAndUpdateState}
            status={error ? 'error' : undefined}
          />
        </FormItem>
        {error && <ErrorText>{error}</ErrorText>}
      </Form>
    </ActionModal>
  )
}

export default EmailInput
