import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const TitleRow = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 24px;
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
  color: ${COLORS.text.default};
`

export const TitleDivider = styled.div`
  width: 1px;
  height: 24px;
  margin: 0 12px;
  background: #d9ecf6;

  border-radius: 1px;
`

export const ViewAllLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${COLORS.primary.medium};
`

export const ViewAllFakeLink = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${COLORS.primary.medium};

  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #69b1ff;
  }
`

export const List = styled.div<{ grid: boolean }>`
  height: 100%;

  ${({ grid }) =>
    grid &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 14px;
      grid-row-gap: 14px;

      @media (min-width: 1920px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media ${device.tablet} {
        grid-template-columns: repeat(1, 1fr);
      }
    `}
`
