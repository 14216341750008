import { Button, Input } from 'antd'
import BlankModal from 'components/ui/Modal/BlankModal'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const ANTDModal = styled(BlankModal)<{ isFooterVisible: boolean }>`
  .ant-modal-header {
    display: block;
    padding-bottom: 20px;
    margin: 0px 24px;
    margin-bottom: 0;

    border-bottom: 1px solid #ebebeb;
  }

  .ant-modal-content {
    padding: 20px 0px 16px 0px;
  }

  .ant-modal-body {
    height: 300px;
    padding: 20px 24px 20px 24px;
  }

  .ant-modal-footer {
    display: block;
    padding: 16px 16px 0px 16px;
    height: 66px;
    ${({ isFooterVisible }) => isFooterVisible && 'box-shadow: 0px 0px 12px 0px #0000000F'};
    margin: 0;
  }

  .ant-modal-close {
    top: 20px;
    inset-inline-end: 22px;
  }
`

export const Title = styled.p`
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
  color: ${COLORS.text.default};
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  margin: 20px 0;

  background-color: #ebebeb;
`

export const TextArea = styled(Input.TextArea)`
  &.ant-input {
    height: 100% !important;
    padding: 0;
    font-size: 16px;
    line-height: 1.2;
    border: none;
    border-radius: 0;
    box-shadow: none;
    resize: none;
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;
`
