import { Gender } from '__generated__/globalTypes'
import { Input, Radio, UploadFile, UploadProps } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import { ReactComponent as RightArrowIcon } from 'assets/icons/right-arrow.svg'
import { ReactComponent as AvatarIcon } from 'assets/icons/user.svg'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import TextArea from 'components/ui/FormElements/TextArea'
import { getSignedUrls, getSignedUrlsVariables } from 'graphql/query/__generated__/getSignedUrls'
import { GET_SIGNED_URLS } from 'graphql/query/getSignedUrls'
import { formatText } from 'helpers/formatText'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useLazyQuery } from '@apollo/client'

import {
  FullWidthPageContainer,
  FullWidthPageContent,
  FullWidthPageFooter,
  FullWidthPageFooterButton,
  FullWidthPageHeader,
  FullWidthPageWrapper,
} from '../styled'
import LocationModal from './LocationModal'
import {
  AvatarImage,
  DescriptionSubtitle,
  DescriptionTitle,
  DescriptionWrapper,
  Form,
  FormItem,
  Header,
  RadioGroup,
  Upload,
  UploadLabel,
} from './styled'

function AccountSetup() {
  const { user, updateUserInfo } = useAuth()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [isErrors, setIsErrors] = useState(false)
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const uploadRef = useRef(null)

  const [form] = useForm()
  const username = useWatch('username', { form })
  const gender = useWatch('gender', { form })
  const bio = useWatch('bio', { form })
  const country = useWatch('country', { form })
  const city = useWatch('city', { form })

  const [getSignedUrls] = useLazyQuery<getSignedUrls, getSignedUrlsVariables>(GET_SIGNED_URLS)

  useEffect(() => {
    if (username) {
      form
        .validateFields()
        .then(() => setIsErrors(false))
        .catch(() => {
          setIsErrors(true)
        })
    }
  }, [username, form])

  const onChangeAvatar: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList)

  const onLocationInputClick = () => setIsLocationModalOpen(true)
  const onCloseLocationModal = () => setIsLocationModalOpen(false)

  const onSelectLocation = (country: string) => {
    form.setFieldValue('country', country)
    onCloseLocationModal()
  }

  const onSubmit = async () => {
    setLoading(true)

    let avatarUrl

    if (fileList[0]) {
      const { data } = await getSignedUrls({
        variables: {
          query: {
            fileName: 'userAvatar',
            path: user.userId,
            contentType: fileList[0].originFileObj.type,
            method: 'putObject',
          },
        },
      })

      await fetch(data.getSignedUrls, {
        method: 'PUT',
        body: fileList[0].originFileObj,
      })

      avatarUrl = `${user.userId}/userAvatar?${Date.now()}`
    }

    await updateUserInfo({
      username,
      avatarUrl,
      gender,
      bio,
      country,
      city,
    })

    navigate(ROUTE_PATH.FOLLOW_SERIES)
    setLoading(false)
  }

  const preview = fileList.length ? URL.createObjectURL(fileList[0].originFileObj) : null
  const isContinueButtonDisabled = !username || !gender || isErrors
  return (
    <>
      <FullWidthPageWrapper>
        <FullWidthPageHeader>
          <Logo />
        </FullWidthPageHeader>
        <FullWidthPageContainer>
          <FullWidthPageContent>
            <Header>Account setup</Header>
            <DescriptionWrapper>
              <DescriptionTitle>Tell us about yourself!</DescriptionTitle>
              <DescriptionSubtitle>
                All information will stay private and not be
                <br />
                shared with any other users or outside sources
              </DescriptionSubtitle>
            </DescriptionWrapper>
            <Upload
              ref={uploadRef}
              customRequest={() => null}
              maxCount={1}
              showUploadList={false}
              accept="image/jpg, image/jpeg, image/png"
              listType="picture-circle"
              fileList={fileList}
              onChange={onChangeAvatar}
            >
              {fileList.length ? <AvatarImage src={preview} /> : <AvatarIcon />}
            </Upload>
            <UploadLabel onClick={() => uploadRef.current.upload.uploader.onClick()}>
              Upload profile image (optional)
            </UploadLabel>
            <Form form={form} layout="vertical">
              <FormItem
                name="username"
                label="Full name"
                validateTrigger={form.getFieldError('username').length ? 'onChange' : 'onBlur'}
                required={false}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  { pattern: new RegExp('^[-\\sa-zA-Z]+$'), message: 'Incorrect full name' },
                ]}
              >
                <Input placeholder="Enter your full name" maxLength={100} />
              </FormItem>
              <FormItem name="gender" label="Select how to address">
                <RadioGroup>
                  <Radio.Button value={Gender.MALE}>Mr.</Radio.Button>
                  <Radio.Button value={Gender.FEMALE}>Mrs.</Radio.Button>
                </RadioGroup>
              </FormItem>
              <FormItem name="bio" label="Bio (optional)">
                <TextArea maxLength={180} showCount rows={4} />
              </FormItem>
              <FormItem name="country" label="Country (optional)" isButton>
                <div onClick={onLocationInputClick}>
                  <Input
                    placeholder="Select country"
                    suffix={<RightArrowIcon />}
                    value={country && formatText(country)}
                    style={{ pointerEvents: 'none' }}
                    disabled
                  />
                </div>
              </FormItem>
              {country && (
                <FormItem name="city" label="City (optional)">
                  <Input maxLength={100} placeholder="Enter city" />
                </FormItem>
              )}
            </Form>
          </FullWidthPageContent>
        </FullWidthPageContainer>
        <FullWidthPageFooter>
          <FullWidthPageFooterButton
            type="primary"
            loading={loading}
            disabled={isContinueButtonDisabled}
            onClick={onSubmit}
          >
            CONTINUE
          </FullWidthPageFooterButton>
        </FullWidthPageFooter>
      </FullWidthPageWrapper>
      {isLocationModalOpen && (
        <LocationModal open={isLocationModalOpen} onClose={onCloseLocationModal} onSelect={onSelectLocation} />
      )}
    </>
  )
}

export default AccountSetup
