import { Radio } from 'antd'
import BlankModal from 'components/ui/Modal/BlankModal'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const ANTDModal = styled(BlankModal)`
  .ant-modal-header {
    display: block;
    padding-bottom: 20px;
    margin: 0;

    border-bottom: 1px solid #ebebeb;
  }

  .ant-modal-content {
    padding: 20px 24px 24px 24px;
  }

  .ant-modal-body {
    padding: 24px 0px 0px 0px;
  }

  .ant-modal-footer {
    display: none;
  }

  pointer-events: none;
`

export const Header = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;

  color: ${COLORS.text.default};
`

export const MainInfo = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 20px;
`

export const Image = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 8px;

  object-fit: cover;
  object-position: center;

  border-radius: 12px;
`

export const TitleRow = styled.span`
  width: 270px;
  display: flex;
  align-items: center;

  margin-bottom: 6px;
`

export const Number = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #696b7b;

  white-space: nowrap;

  margin-right: 2px;
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Description = styled.p`
  width: 270px;
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.hint};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const SeriesTitle = styled.span<{ color?: string }>`
  color: ${({ color }) => color || COLORS.text.default};

  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    opacity: 0.7;
  }
`

export const RadioGroup = styled(Radio.Group)`
  width: 100%;
  margin-bottom: 32px;
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;

  background-color: #eaf7fe;
  border-radius: 50%;
`

export const ButtonText = styled.p`
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
  color: ${COLORS.text.default};

  transition: all 0.2s;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: ${COLORS.text.default};

  cursor: pointer;

  &:hover {
    ${ButtonText} {
      color: ${COLORS.primary.medium};
    }
  }
`
