import { Spin } from 'antd'
import { addShiurToFavorite, addShiurToFavoriteVariables } from 'graphql/mutation/__generated__/addShiurToFavorite'
import {
  removeShiurFromFavorite,
  removeShiurFromFavoriteVariables,
} from 'graphql/mutation/__generated__/removeShiurFromFavorite'
import { ADD_SHIUR_TO_FAVORITE } from 'graphql/mutation/addShiurToFavorite'
import { REMOVE_SHIUR_FROM_FAVORITE } from 'graphql/mutation/removeShiurFromFavorite'
import { getAllShiurims_getAllShiurims_items } from 'graphql/query/__generated__/getAllShiurims'
import { useId } from 'react'

import { useMutation } from '@apollo/client'

import { EmptyContainer, EmptyText } from '../styled'
import ShiurListItem from './ShiurListItem'
import { LoaderWrapper, ScrollableWrapper, Wrapper } from './styled'

type Props = {
  items?: getAllShiurims_getAllShiurims_items[]
  next?: boolean
  loading: boolean
  loadingNext: boolean
  onFetchMore?: () => void
  openRegisterToContinueModal?: () => void
}

function ShiurimList({ items, next, loading, loadingNext, onFetchMore, openRegisterToContinueModal }: Props) {
  const randomId = useId()

  const [addShiurToFavorite, { loading: addShiurToFavoriteLoading }] = useMutation<
    addShiurToFavorite,
    addShiurToFavoriteVariables
  >(ADD_SHIUR_TO_FAVORITE)
  const [removeShiurFromFavorite, { loading: removeShiurFromFavoriteLoading }] = useMutation<
    removeShiurFromFavorite,
    removeShiurFromFavoriteVariables
  >(REMOVE_SHIUR_FROM_FAVORITE)

  const onToggleFavorite = async (shiurId: string, favorite: boolean) => {
    if (addShiurToFavoriteLoading || removeShiurFromFavoriteLoading) return

    if (favorite) {
      await removeShiurFromFavorite({ variables: { shiurId } })
    } else {
      await addShiurToFavorite({ variables: { shiurId } })
    }
  }

  return (
    <ScrollableWrapper id={`shiurim-list-scrollable-wrapper-${randomId}`} loading={loading}>
      {loading ? (
        <EmptyContainer>
          <Spin />
        </EmptyContainer>
      ) : items?.length ? (
        <Wrapper
          dataLength={items?.length || 0}
          next={onFetchMore}
          hasMore={next}
          loader={null}
          scrollableTarget={`shiurim-list-scrollable-wrapper-${randomId}`}
        >
          {items.map((shiur, i) => {
            return (
              <ShiurListItem
                key={shiur.shiurId}
                index={i}
                {...shiur}
                openRegisterToContinueModal={openRegisterToContinueModal}
                onToggleFavorite={onToggleFavorite}
              />
            )
          })}
          {loadingNext && (
            <LoaderWrapper>
              <Spin size="small" />
            </LoaderWrapper>
          )}
        </Wrapper>
      ) : (
        <EmptyContainer>
          <EmptyText>No shiurim</EmptyText>
        </EmptyContainer>
      )}
    </ScrollableWrapper>
  )
}

export default ShiurimList
