import { ReactComponent as ProfileIcon } from 'assets/icons/profile-active.svg'
import ProfileBanner from 'components/business/Main/Profile/ProfileBanner'
import ProfileSettings from 'components/business/Main/Profile/ProfileSettings'
import Header from 'components/ui/Header'

import { Wrapper } from '../styled'

function Profile() {
  return (
    <>
      <Header icon={<ProfileIcon />} title="Profile" />
      <Wrapper>
        <ProfileBanner />
        <ProfileSettings />
      </Wrapper>
    </>
  )
}

export default Profile
