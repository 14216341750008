import { SponsorshipPeriod } from '__generated__/globalTypes'

import { Button, Title, TitleWrapper, Value, ValueWrapper, Wrapper } from './styled'

export const donateTypeToLabel = {
  [SponsorshipPeriod.day]: 'day',
  [SponsorshipPeriod.weekly]: 'week',
  [SponsorshipPeriod.monthly]: 'month',
  [SponsorshipPeriod.custom]: 'custom',
}

export const donateTypeToPriceApp = {
  [SponsorshipPeriod.day]: 500,
  [SponsorshipPeriod.weekly]: 1800,
  [SponsorshipPeriod.monthly]: 5000,
  [SponsorshipPeriod.custom]: 'X',
}

export const donateTypeToPriceShiur = {
  [SponsorshipPeriod.day]: 180,
  [SponsorshipPeriod.weekly]: 750,
  [SponsorshipPeriod.monthly]: 2000,
  [SponsorshipPeriod.custom]: 'X',
}

type Props = { value: SponsorshipPeriod; onChange: (value: SponsorshipPeriod) => void; isApp?: boolean }

export default function DonateTypeSelect({ value, onChange, isApp }: Props) {
  const donateOptions = [
    {
      donateType: SponsorshipPeriod.day,
      price: isApp ? donateTypeToPriceApp[SponsorshipPeriod.day] : donateTypeToPriceShiur[SponsorshipPeriod.day],
    },
    {
      donateType: SponsorshipPeriod.weekly,
      price: isApp ? donateTypeToPriceApp[SponsorshipPeriod.weekly] : donateTypeToPriceShiur[SponsorshipPeriod.weekly],
    },
    {
      donateType: SponsorshipPeriod.monthly,
      price: isApp
        ? donateTypeToPriceApp[SponsorshipPeriod.monthly]
        : donateTypeToPriceShiur[SponsorshipPeriod.monthly],
    },
    { donateType: SponsorshipPeriod.custom, price: 'X' },
  ]

  return (
    <Wrapper>
      {donateOptions.map(({ donateType, price }) => (
        <Button key={donateType} onClick={() => onChange(donateType)}>
          <TitleWrapper selected={value === donateType}>
            <Title selected={value === donateType}>{donateTypeToLabel[donateType].toUpperCase()}</Title>
          </TitleWrapper>
          <ValueWrapper selected={value === donateType}>
            <Value selected={value === donateType}>${price}</Value>
          </ValueWrapper>
        </Button>
      ))}
    </Wrapper>
  )
}
