import { deviceWidth } from 'constants/responsive'
import { getNotesWithPagination_getNotesWithPagination_items } from 'graphql/query/__generated__/getNotesWithPagination'
import { getDateWithoutTimezone } from 'helpers/getDateWithoutTimezone'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ContentWrapper,
  DateText,
  Description,
  Image,
  Number as OrderedNumber,
  SeriesTitle,
  ShiurContainer,
  ShiurWrapper,
  Text,
  Title,
  TitleRow,
  Wrapper,
} from './styled'

type Props = getNotesWithPagination_getNotesWithPagination_items & { onClick?: () => void }

function NoteCard({ shiur, text, createdAt, onClick }: Props) {
  const navigate = useNavigate()

  const isMobile = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])
  return (
    <Wrapper onClick={onClick}>
      <ShiurWrapper>
        <ShiurContainer>
          <Image src={`${process.env.REACT_APP_AWS_S3_URL}${shiur.series.coverUrl}`} />
          <ContentWrapper>
            <TitleRow>
              {!!shiur.orderedNum && <OrderedNumber>Shiur {shiur.orderedNum}.</OrderedNumber>}
              <Title>{shiur.title}</Title>
            </TitleRow>
            <Description>
              <SeriesTitle onClick={() => navigate(`/series/${shiur.series.seriesId}`)} color={shiur.series.titleColor}>
                {shiur.series.title}
              </SeriesTitle>
              {`∙${shiur.topic.title}`}
            </Description>
          </ContentWrapper>
        </ShiurContainer>
        {!isMobile && (
          <DateText>
            {getDateWithoutTimezone(createdAt).toDate().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </DateText>
        )}
      </ShiurWrapper>
      <Text>{text}</Text>
      {isMobile && (
        <DateText>
          {getDateWithoutTimezone(createdAt).toDate().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </DateText>
      )}
    </Wrapper>
  )
}

export default memo(NoteCard)
