import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ noHeight?: boolean; fullHeight?: boolean; color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${({ fullHeight, noHeight }) => (fullHeight ? '100dvh' : noHeight ? 'auto' : '100%')};

  ${({ color }) =>
    color &&
    css`
      .ant-spin .ant-spin-dot-item {
        background-color: ${color};
      }
    `}
`
