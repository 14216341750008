import { Button } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

import Modal from '../index'

export const ANTDModal = styled(Modal)`
  .ant-modal-content {
    padding: 40px 24px 24px 24px;
  }

  .ant-modal-header {
    text-align: center;
  }

  .ant-modal-footer {
    margin-top: 40px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 28px;
`

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;

  color: ${COLORS.primary.dark};
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;

  color: #696b7b;

  margin-top: 12px;
`

export const FooterButton = styled(Button)`
  width: 100%;
`
