import { gql } from '@apollo/client'

export const GET_ALL_SUBTOPICS = gql`
  query getAllSubTopics($take: Int!, $page: Int!, $searchParams: SubtopicSearchParams) {
    getAllSubTopics(take: $take, page: $page, searchParams: $searchParams) {
      items {
        subtopicId
        title
      }
      meta {
        total
        next
      }
    }
  }
`
