import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const MainText = styled.p``

export const ActionText = styled.span`
  color: ${COLORS.primary.medium};

  cursor: pointer;
`
