import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { FullWidthPageContainer, FullWidthPageContent, FullWidthPageHeader, FullWidthPageWrapper } from '../styled'
import Active from './Active'
import Completed from './Completed'

function ForgotPassword() {
  const navigate = useNavigate()
  const location = useLocation()

  const onSubmit = () => {
    navigate(`${ROUTE_PATH.FORGOT_PASSWORD}?completed=yes`, {})
  }

  const isCompleted = new URLSearchParams(location.search).has('completed')

  return (
    <FullWidthPageWrapper>
      <FullWidthPageHeader>
        <Logo />
      </FullWidthPageHeader>
      <FullWidthPageContainer>
        <FullWidthPageContent>{isCompleted ? <Completed /> : <Active onSubmit={onSubmit} />}</FullWidthPageContent>
      </FullWidthPageContainer>
    </FullWidthPageWrapper>
  )
}

export default ForgotPassword
