import FollowedSeriesList from 'components/business/Main/FollowedSeries/FollowedSeriesList'
import Header from 'components/ui/Header'
import { ReactComponent as FollowedIcon } from 'components/ui/Sidebar/icons/active/follow.svg'
import { ROUTE_PATH } from 'routes'

import { Wrapper } from '../styled'

function FollowedSeries() {
  return (
    <>
      <Header icon={<FollowedIcon />} viewAllProps={{ to: ROUTE_PATH.SERIES }} title="Followed Series" />
      <Wrapper id="scrollable-wrapper">
        <FollowedSeriesList />
      </Wrapper>
    </>
  )
}

export default FollowedSeries
