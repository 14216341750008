import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

export const InfiniteScrollStyled = styled(InfiniteScroll)`
  overflow: visible !important;
  position: relative;

  padding-bottom: 45px;
`

export const Item = styled.div`
  &:not(:first-child) {
    margin-top: 40px;
  }
`

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 14px;

  @media ${device.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`

export const DateText = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  &:not(:first-child) {
    margin-top: 40px;
    margin-bottom: 24px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-bottom: 24px;
`

export const LoaderWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  justify-content: center;
`
