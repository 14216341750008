import { gql } from '@apollo/client'

export const TOGGLE_SERIES_FOLLOW_STATUS = gql`
  mutation toggleSeriesFollowStatus($seriesId: String!, $follow: Boolean!) {
    toggleSeriesFollowStatus(seriesId: $seriesId, follow: $follow) {
      seriesId
      followInfo {
        isFollowed
      }
    }
  }
`
