/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnnouncementType {
  EVENT = "EVENT",
  LECTURE = "LECTURE",
  PODCAST = "PODCAST",
}

export enum Country {
  AFGHANISTAN = "AFGHANISTAN",
  ALAND_ISLANDS = "ALAND_ISLANDS",
  ALBANIA = "ALBANIA",
  ALGERIA = "ALGERIA",
  AMERICAN_SAMOA = "AMERICAN_SAMOA",
  ANDORRA = "ANDORRA",
  ANGOLA = "ANGOLA",
  ANGUILLA = "ANGUILLA",
  ANTARCTICA = "ANTARCTICA",
  ANTIGUA_AND_BARBUDA = "ANTIGUA_AND_BARBUDA",
  ARGENTINA = "ARGENTINA",
  ARMENIA = "ARMENIA",
  ARUBA = "ARUBA",
  AUSTRALIA = "AUSTRALIA",
  AUSTRIA = "AUSTRIA",
  AZERBAIJAN = "AZERBAIJAN",
  BAHAMAS = "BAHAMAS",
  BAHRAIN = "BAHRAIN",
  BANGLADESH = "BANGLADESH",
  BARBADOS = "BARBADOS",
  BELARUS = "BELARUS",
  BELGIUM = "BELGIUM",
  BELIZE = "BELIZE",
  BENIN = "BENIN",
  BERMUDA = "BERMUDA",
  BHUTAN = "BHUTAN",
  BOLIVIA = "BOLIVIA",
  BOSNIA_AND_HERZEGOVINA = "BOSNIA_AND_HERZEGOVINA",
  BOTSWANA = "BOTSWANA",
  BOUVET_ISLAND = "BOUVET_ISLAND",
  BRAZIL = "BRAZIL",
  BRITISH_INDIAN_OCEAN_TERRITORY = "BRITISH_INDIAN_OCEAN_TERRITORY",
  BRITISH_VIRGIN_ISLANDS = "BRITISH_VIRGIN_ISLANDS",
  BRUNEI = "BRUNEI",
  BULGARIA = "BULGARIA",
  BURKINA_FASO = "BURKINA_FASO",
  BURUNDI = "BURUNDI",
  CAMBODIA = "CAMBODIA",
  CAMEROON = "CAMEROON",
  CANADA = "CANADA",
  CAPE_VERDE = "CAPE_VERDE",
  CARIBBEAN_NETHERLANDS = "CARIBBEAN_NETHERLANDS",
  CAYMAN_ISLANDS = "CAYMAN_ISLANDS",
  CENTRAL_AFRICAN_REPUBLIC = "CENTRAL_AFRICAN_REPUBLIC",
  CHAD = "CHAD",
  CHILE = "CHILE",
  CHINA = "CHINA",
  CHRISTMAS_ISLAND = "CHRISTMAS_ISLAND",
  COCOS_KEEL_ISLANDS = "COCOS_KEEL_ISLANDS",
  COLOMBIA = "COLOMBIA",
  COMOROS = "COMOROS",
  CONGO_BRAZZAVILLE = "CONGO_BRAZZAVILLE",
  CONGO_KINSHASA = "CONGO_KINSHASA",
  COOK_ISLANDS = "COOK_ISLANDS",
  COSTA_RICA = "COSTA_RICA",
  COTE_DIVOIRE = "COTE_DIVOIRE",
  CROATIA = "CROATIA",
  CUBA = "CUBA",
  CURACAO = "CURACAO",
  CYPRUS = "CYPRUS",
  CZECHIA = "CZECHIA",
  DENMARK = "DENMARK",
  DJIBOUTI = "DJIBOUTI",
  DOMINICA = "DOMINICA",
  DOMINICAN_REPUBLIC = "DOMINICAN_REPUBLIC",
  ECUADOR = "ECUADOR",
  EGYPT = "EGYPT",
  EL_SALVADOR = "EL_SALVADOR",
  EQUATORIAL_GUINEA = "EQUATORIAL_GUINEA",
  ERITREA = "ERITREA",
  ESTONIA = "ESTONIA",
  ESWATINI = "ESWATINI",
  ETHIOPIA = "ETHIOPIA",
  FALKLAND_ISLANDS = "FALKLAND_ISLANDS",
  FAROE_ISLANDS = "FAROE_ISLANDS",
  FIJI = "FIJI",
  FINLAND = "FINLAND",
  FRANCE = "FRANCE",
  FRENCH_GUIANA = "FRENCH_GUIANA",
  FRENCH_POLYNESIA = "FRENCH_POLYNESIA",
  FRENCH_SOUTHERN_TERRITORIES = "FRENCH_SOUTHERN_TERRITORIES",
  GABON = "GABON",
  GAMBIA = "GAMBIA",
  GEORGIA = "GEORGIA",
  GERMANY = "GERMANY",
  GHANA = "GHANA",
  GIBRALTAR = "GIBRALTAR",
  GREECE = "GREECE",
  GREENLAND = "GREENLAND",
  GRENADA = "GRENADA",
  GUADELOUPE = "GUADELOUPE",
  GUAM = "GUAM",
  GUATEMALA = "GUATEMALA",
  GUERNSEY = "GUERNSEY",
  GUINEA = "GUINEA",
  GUINEA_BISSAU = "GUINEA_BISSAU",
  GUYANA = "GUYANA",
  HAITI = "HAITI",
  HEARD_AND_MCDONALD_ISLANDS = "HEARD_AND_MCDONALD_ISLANDS",
  HONDURAS = "HONDURAS",
  HONG_KONG_SAR_CHINA = "HONG_KONG_SAR_CHINA",
  HUNGARY = "HUNGARY",
  ICELAND = "ICELAND",
  INDIA = "INDIA",
  INDONESIA = "INDONESIA",
  IRAN = "IRAN",
  IRAQ = "IRAQ",
  IRELAND = "IRELAND",
  ISLE_OF_MAN = "ISLE_OF_MAN",
  ISRAEL = "ISRAEL",
  ITALY = "ITALY",
  JAMAICA = "JAMAICA",
  JAPAN = "JAPAN",
  JERSEY = "JERSEY",
  JORDAN = "JORDAN",
  KAZAKHSTAN = "KAZAKHSTAN",
  KENYA = "KENYA",
  KIRIBATI = "KIRIBATI",
  KUWAIT = "KUWAIT",
  KYRGYZSTAN = "KYRGYZSTAN",
  LAOS = "LAOS",
  LATVIA = "LATVIA",
  LEBANON = "LEBANON",
  LESOTHO = "LESOTHO",
  LIBERIA = "LIBERIA",
  LIBYA = "LIBYA",
  LIECHTENSTEIN = "LIECHTENSTEIN",
  LITHUANIA = "LITHUANIA",
  LUXEMBOURG = "LUXEMBOURG",
  MACAO_SAR_CHINA = "MACAO_SAR_CHINA",
  MADAGASCAR = "MADAGASCAR",
  MALAWI = "MALAWI",
  MALAYSIA = "MALAYSIA",
  MALDIVES = "MALDIVES",
  MALI = "MALI",
  MALTA = "MALTA",
  MARSHALL_ISLANDS = "MARSHALL_ISLANDS",
  MARTINIQUE = "MARTINIQUE",
  MAURITANIA = "MAURITANIA",
  MAURITIUS = "MAURITIUS",
  MAYOTTE = "MAYOTTE",
  MEXICO = "MEXICO",
  MICRONESIA = "MICRONESIA",
  MOLDOVA = "MOLDOVA",
  MONACO = "MONACO",
  MONGOLIA = "MONGOLIA",
  MONTENEGRO = "MONTENEGRO",
  MONTSERRAT = "MONTSERRAT",
  MOROCCO = "MOROCCO",
  MOZAMBIQUE = "MOZAMBIQUE",
  MYANMAR = "MYANMAR",
  NAMIBIA = "NAMIBIA",
  NAURU = "NAURU",
  NEPAL = "NEPAL",
  NETHERLANDS = "NETHERLANDS",
  NEW_CALEDONIA = "NEW_CALEDONIA",
  NEW_ZEALAND = "NEW_ZEALAND",
  NICARAGUA = "NICARAGUA",
  NIGER = "NIGER",
  NIGERIA = "NIGERIA",
  NIUE = "NIUE",
  NORFOLK_ISLAND = "NORFOLK_ISLAND",
  NORTHERN_MARIANA_ISLANDS = "NORTHERN_MARIANA_ISLANDS",
  NORTH_KOREA = "NORTH_KOREA",
  NORTH_MACEDONIA = "NORTH_MACEDONIA",
  NORWAY = "NORWAY",
  OMAN = "OMAN",
  PAKISTAN = "PAKISTAN",
  PALAU = "PALAU",
  PALESTINIAN_TERRITORIES = "PALESTINIAN_TERRITORIES",
  PANAMA = "PANAMA",
  PAPUA_NEW_GUINEA = "PAPUA_NEW_GUINEA",
  PARAGUAY = "PARAGUAY",
  PERU = "PERU",
  PHILIPPINES = "PHILIPPINES",
  PITCAIRN_ISLANDS = "PITCAIRN_ISLANDS",
  POLAND = "POLAND",
  PORTUGAL = "PORTUGAL",
  PUERTO_RICO = "PUERTO_RICO",
  QATAR = "QATAR",
  REUNION = "REUNION",
  ROMANIA = "ROMANIA",
  RUSSIA = "RUSSIA",
  RWANDA = "RWANDA",
  SAMOA = "SAMOA",
  SAN_MARINO = "SAN_MARINO",
  SAO_TOME_AND_PRINCIPE = "SAO_TOME_AND_PRINCIPE",
  SAUDI_ARABIA = "SAUDI_ARABIA",
  SENEGAL = "SENEGAL",
  SERBIA = "SERBIA",
  SEYCHELLES = "SEYCHELLES",
  SIERRA_LEONE = "SIERRA_LEONE",
  SINGAPORE = "SINGAPORE",
  SINT_MAARTEN = "SINT_MAARTEN",
  SLOVAKIA = "SLOVAKIA",
  SLOVENIA = "SLOVENIA",
  SOLOMON_ISLANDS = "SOLOMON_ISLANDS",
  SOMALIA = "SOMALIA",
  SOUTH_AFRICA = "SOUTH_AFRICA",
  SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS = "SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS",
  SOUTH_KOREA = "SOUTH_KOREA",
  SOUTH_SUDAN = "SOUTH_SUDAN",
  SPAIN = "SPAIN",
  SRI_LANKA = "SRI_LANKA",
  ST_BARTHELEMY = "ST_BARTHELEMY",
  ST_HELENA = "ST_HELENA",
  ST_KITTS_AND_NEVIS = "ST_KITTS_AND_NEVIS",
  ST_LUCIA = "ST_LUCIA",
  ST_MARTIN = "ST_MARTIN",
  ST_PIERRE_AND_MIQUELON = "ST_PIERRE_AND_MIQUELON",
  ST_VINCENT_AND_THE_GRENADINES = "ST_VINCENT_AND_THE_GRENADINES",
  SUDAN = "SUDAN",
  SURINAME = "SURINAME",
  SVALBARD_AND_JAN_MAYEN = "SVALBARD_AND_JAN_MAYEN",
  SWEDEN = "SWEDEN",
  SWITZERLAND = "SWITZERLAND",
  SYRIA = "SYRIA",
  TAIWAN = "TAIWAN",
  TAJIKISTAN = "TAJIKISTAN",
  TANZANIA = "TANZANIA",
  THAILAND = "THAILAND",
  TIMOR_LESTE = "TIMOR_LESTE",
  TOGO = "TOGO",
  TOKELAU = "TOKELAU",
  TONGA = "TONGA",
  TRINIDAD_AND_TOBAGO = "TRINIDAD_AND_TOBAGO",
  TUNISIA = "TUNISIA",
  TURKEY = "TURKEY",
  TURKMENISTAN = "TURKMENISTAN",
  TURKS_AND_CAICOS_ISLANDS = "TURKS_AND_CAICOS_ISLANDS",
  TUVALU = "TUVALU",
  UGANDA = "UGANDA",
  UK = "UK",
  UKRAINE = "UKRAINE",
  UNITED_ARAB_EMIRATES = "UNITED_ARAB_EMIRATES",
  UNITED_KINGDOM = "UNITED_KINGDOM",
  UNITED_STATES = "UNITED_STATES",
  URUGUAY = "URUGUAY",
  USA = "USA",
  US_OUTLYING_ISLANDS = "US_OUTLYING_ISLANDS",
  US_VIRGIN_ISLANDS = "US_VIRGIN_ISLANDS",
  UZBEKISTAN = "UZBEKISTAN",
  VANUATU = "VANUATU",
  VATICAN_CITY = "VATICAN_CITY",
  VENEZUELA = "VENEZUELA",
  VIETNAM = "VIETNAM",
  WALLIS_AND_FUTUNA = "WALLIS_AND_FUTUNA",
  WESTERN_SAHARA = "WESTERN_SAHARA",
  YEMEN = "YEMEN",
  ZAMBIA = "ZAMBIA",
  ZIMBABWE = "ZIMBABWE",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NOT_SELECTED = "NOT_SELECTED",
}

export enum SponsorshipPeriod {
  custom = "custom",
  day = "day",
  monthly = "monthly",
  weekly = "weekly",
}

export enum SponsorshipType {
  path4life = "path4life",
  shiur = "shiur",
}

export interface CardInfoInputDto {
  expiryMonth: number;
  expiryYear: number;
  nonce: string;
  avs_zip?: string | null;
}

export interface PaymentInputDto {
  cardInfo: CardInfoInputDto;
  sponsorInfo: SponsorInfoInputDto;
}

export interface S3FileInput {
  fileName?: string | null;
  path?: string | null;
  contentType?: string | null;
  method?: string | null;
}

export interface ShiurSearchParams {
  startDate?: any | null;
  endDate?: any | null;
  title?: string | null;
  series?: string[] | null;
  topics?: string[] | null;
  subtopics?: string[] | null;
  shiurims?: string[] | null;
  startOrderedNum?: number | null;
  endOrderedNum?: number | null;
  orderType?: string | null;
  dateOrderType?: string | null;
}

export interface SponsorInfoInputDto {
  sponsorshipPeriod: SponsorshipPeriod;
  sponsorshipType: SponsorshipType;
  startDate: any;
  endDate: any;
  amount: number;
  comment?: string | null;
  isAnonymous: boolean;
  isInMemory: boolean;
  inMemoryOf?: string | null;
  fullName?: string | null;
  email?: string | null;
  series?: string | null;
}

export interface SubtopicSearchParams {
  title?: string | null;
  topics?: string[] | null;
}

export interface UserInputDto {
  userId?: string | null;
  email?: string | null;
  username?: string | null;
  avatarUrl?: string | null;
  gender?: Gender | null;
  country?: Country | null;
  bio?: string | null;
  city?: string | null;
  allowNotify?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
