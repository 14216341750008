import { Button, Input } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  margin: 0 0 20px;

  background-color: #ebebeb;
`

export const TextArea = styled(Input.TextArea)`
  &.ant-input {
    height: 100% !important;
    font-size: 16px;
    border-radius: 12px;
    line-height: 1.2;
    resize: none;
  }

  margin-bottom: 20px;
`

export const SubmitButton = styled(Button)`
  margin-top: auto;

  width: 100%;
`
