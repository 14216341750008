import { gql } from '@apollo/client'

export const UPDATE_ONE_NOTE = gql`
  mutation updateOneNote($noteId: String!, $text: String!) {
    updateOneNote(noteId: $noteId, text: $text) {
      noteId
      text
    }
  }
`
