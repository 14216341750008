import { ReactComponent as MinusIcon } from 'assets/icons/minus-player.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus-player.svg'
import { Flex } from 'components/common/Flex'
import { useState } from 'react'

import {
  ANTDModal,
  ActionButtonWrapper,
  ActionsRow,
  PreferredOption,
  PreferredOptionWrapper,
  PreferredOptionsRow,
  Rate,
  ResetButton,
  SubmitButton,
  Title,
} from './styled'

type Props = {
  open: boolean
  onClose: () => void
  selectedPlaybackRate: number
  onChangePlaybackRate: (speed: number) => void
  onResetPlaybackRate: () => void
}

const preferredRates = [0.8, 1.0, 1.2, 1.5, 2.0]

function PlaybackRateModal({ open, onClose, selectedPlaybackRate, onChangePlaybackRate, onResetPlaybackRate }: Props) {
  const [playbackRate, setPlaybackRate] = useState(selectedPlaybackRate)

  const onIncrease = () => setPlaybackRate((prev) => prev + 0.1)
  const onDecrease = () => setPlaybackRate((prev) => prev - 0.1)

  const onSubmit = () => onChangePlaybackRate(playbackRate)
  return (
    <ANTDModal
      open={open}
      onCancel={onClose}
      width={300}
      closable={false}
      destroyOnClose={true}
      title={<Title>Playback speed</Title>}
      footer={
        <Flex alignCenter>
          <SubmitButton type="primary" onClick={onSubmit}>
            APPLY
          </SubmitButton>
          <ResetButton onClick={onResetPlaybackRate}>RESET</ResetButton>
        </Flex>
      }
    >
      <ActionsRow>
        <ActionButtonWrapper>
          <MinusIcon onClick={playbackRate > 0.6 ? onDecrease : undefined} />
        </ActionButtonWrapper>
        <Rate isChanged={playbackRate !== 1.0}>{playbackRate.toFixed(1)}x</Rate>
        <ActionButtonWrapper>
          <PlusIcon onClick={playbackRate < 3.5 ? onIncrease : undefined} />
        </ActionButtonWrapper>
      </ActionsRow>
      <PreferredOptionsRow>
        {preferredRates.map((el) => (
          <PreferredOptionWrapper key={el} onClick={() => setPlaybackRate(el)}>
            <PreferredOption>{el.toFixed(1)}</PreferredOption>
          </PreferredOptionWrapper>
        ))}
      </PreferredOptionsRow>
    </ANTDModal>
  )
}

export default PlaybackRateModal
