import { RadioButtonProps } from 'antd/es/radio/radioButton'

import { RadioButtonStyled } from './styled'

type Props = RadioButtonProps

function RadioButton(props: Props) {
  return <RadioButtonStyled {...props} />
}

export default RadioButton
