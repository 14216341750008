import { Spin } from 'antd'
import { getNotifications_getNotifications_items } from 'graphql/query/__generated__/getNotifications'

import NotificationCard from '../../Cards/NotificationCard'
import { LoaderWrapper, ScrollableWrapper, Wrapper } from './styled'

type Props = {
  items: getNotifications_getNotifications_items[]
  onFetchMore: () => void
  next: boolean
  loadingNext: boolean
}

function NotificationsList({ items, onFetchMore, next, loadingNext }: Props) {
  return (
    <ScrollableWrapper id="notification-scrollable-wrapper">
      <Wrapper
        dataLength={items?.length || 0}
        next={onFetchMore}
        hasMore={next}
        loader={null}
        scrollableTarget="notification-scrollable-wrapper"
      >
        {items.map((el) => (
          <NotificationCard key={el.notificationId} {...el} />
        ))}

        <LoaderWrapper>
          <Spin spinning={loadingNext} />
        </LoaderWrapper>
      </Wrapper>
    </ScrollableWrapper>
  )
}

export default NotificationsList
