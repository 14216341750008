/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLocation } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

export const useGetRedirectFunctionToMarketIfMobile = () => {
  const { pathname } = useLocation()

  const redirect = () => {
    if (pathname === ROUTE_PATH.DONATE_MOBILE) return

    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return
    }

    if (/android/i.test(userAgent)) {
      window.location.href = process.env.REACT_APP_GOOGLE_PLAY_LINK
      return
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = process.env.REACT_APP_APP_STORE_LINK
      return
    }
  }

  return redirect
}
