import { PopoverProps } from 'antd'
import { deviceWidth } from 'constants/responsive'
import dayjs from 'dayjs'

import Calendar from '../Calendar'
import { CalendarPopover } from './styled'

type Props = PopoverProps & { selectedDate: dayjs.Dayjs; onChangeDate: (date: dayjs.Dayjs) => void }

function PopoverCalendar({ selectedDate, onChangeDate, ...props }: Props) {
  const isMobile = window.innerWidth < Number(deviceWidth.laptop.match(/(\d+)/)[0])
  return (
    <CalendarPopover
      placement={isMobile ? undefined : 'bottomLeft'}
      trigger="click"
      {...props}
      content={<Calendar selectedDate={selectedDate} onChangeDate={onChangeDate} />}
    />
  )
}

export default PopoverCalendar
