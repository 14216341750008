import { ModalProps } from 'antd'
import { ReactNode } from 'react'

import { ANTDModal, Description, IconWrapper, PrimaryButton, SecondaryButton, Title } from './styled'

type Props = ModalProps & {
  icon?: ReactNode
  onPrimaryBtnClick: () => void
  onSecondaryBtnClick: () => void
  primaryBtnText: string
  secondaryBtnText: string
  description?: string
  disabled?: boolean
}
type FooterProps = {
  onPrimaryBtnClick: () => void
  onSecondaryBtnClick: () => void
  primaryBtnText: string
  secondaryBtnText: string
  confirmLoading?: boolean
}

function Footer({
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  primaryBtnText,
  secondaryBtnText,
  confirmLoading,
}: FooterProps) {
  return (
    <>
      <SecondaryButton onClick={onSecondaryBtnClick}>{secondaryBtnText}</SecondaryButton>
      <PrimaryButton type="primary" loading={confirmLoading} onClick={onPrimaryBtnClick}>
        {primaryBtnText}
      </PrimaryButton>
    </>
  )
}

function AlertModal({
  children,
  icon,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  primaryBtnText,
  secondaryBtnText,
  title,
  description,
  confirmLoading,
  ...props
}: Props) {
  return (
    <ANTDModal
      footer={
        <Footer
          onPrimaryBtnClick={onPrimaryBtnClick}
          onSecondaryBtnClick={onSecondaryBtnClick}
          primaryBtnText={primaryBtnText}
          secondaryBtnText={secondaryBtnText}
          confirmLoading={confirmLoading}
        />
      }
      title={
        <>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </>
      }
      width={375}
      closable={false}
      maskClosable={true}
      {...props}
    >
      {children}
    </ANTDModal>
  )
}

export default AlertModal
