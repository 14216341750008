import ShiurimList from 'components/business/Main/Lists/ShiurimList'
import Header from 'components/ui/Header'
import Loader from 'components/ui/Loader'
import AlertModal from 'components/ui/Modal/AlertModal'
import {
  getRecommendedShiurim,
  getRecommendedShiurimVariables,
} from 'graphql/query/__generated__/getRecommendedShiurim'
import { GET_RECOMMENDED_SHIURIM } from 'graphql/query/getRecommendedShiurim'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useQuery } from '@apollo/client'

import { Wrapper } from '../../styled'

const initialPagination = {
  take: 30,
  page: 1,
}

function Recommended() {
  const navigate = useNavigate()
  const { setQueryInfo } = useQueryInfo()

  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)
  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore } = useQuery<getRecommendedShiurim, getRecommendedShiurimVariables>(
    GET_RECOMMENDED_SHIURIM,
    {
      variables: initialPagination,
    }
  )

  useEffect(() => {
    setQueryInfo({ query: GET_RECOMMENDED_SHIURIM, variables: initialPagination })
  }, [setQueryInfo])

  const onFetchMore = async () => {
    setLoadingNext(true)

    const variables = { take: initialPagination.take, page: page + 1 }

    await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getRecommendedShiurim: {
            ...previousQueryResult.getRecommendedShiurim,
            ...fetchMoreResult.getRecommendedShiurim,
            items: [...previousQueryResult.getRecommendedShiurim.items, ...fetchMoreResult.getRecommendedShiurim.items],
          },
        }
      },
    })

    setQueryInfo({ query: GET_RECOMMENDED_SHIURIM, variables })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)
  return (
    <>
      <Header isBackButton title="Recommended" />
      <Wrapper id="scrollable-wrapper">
        {!data?.getRecommendedShiurim?.items?.length && loading ? (
          <Loader />
        ) : (
          <ShiurimList
            items={data?.getRecommendedShiurim?.items}
            next={data?.getRecommendedShiurim?.meta?.next}
            openRegisterToContinueModal={openRegisterToContinueModal}
            onFetchMore={onFetchMore}
            loading={loading}
            loadingNext={loadingNext}
          />
        )}

        <AlertModal
          open={isRegisterToContinueModalOpen}
          onCancel={closeRegisterToContinueModal}
          onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
          onSecondaryBtnClick={closeRegisterToContinueModal}
          title="Register to Continue"
          description="To unlock all features you need to create an account"
          primaryBtnText="REGISTER"
          secondaryBtnText="NOT NOW"
        />
      </Wrapper>
    </>
  )
}

export default withQueryInfo(Recommended)
