import { SERIES_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_SERIES_WITH_PAGINATION = gql`
  ${SERIES_FRAGMENT}
  query getSeriesWithPagination($take: Int!, $page: Int!, $title: String) {
    getSeriesWithPagination(take: $take, page: $page, title: $title) {
      items {
        ...SeriesInfo
      }
      meta {
        total
        next
      }
    }
  }
`
