import { AnnouncementType } from '__generated__/globalTypes'
import LiveEventsTab from 'components/business/Main/Community/LiveEventsTab'
import PublicLectureTab from 'components/business/Main/Community/PublicLectureTab'
import SpecialPodcastsTab from 'components/business/Main/Community/SpecialPodcastsTab'
import Tabs from 'components/business/Main/Community/Tabs'
import Header from 'components/ui/Header'
import { Wrapper } from 'pages/Main/styled'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export enum ANNOUNCEMENTS_TABS {
  LIVE_EVENT = 'LIVE EVENT',
  SPECIAL_PODCASTS = 'SPECIAL PODCASTS',
  PUBLIC_LECTURE = 'PUBLIC LECTURE',
}

export const announcementTypeToTab = {
  [AnnouncementType.EVENT]: ANNOUNCEMENTS_TABS.LIVE_EVENT,
  [AnnouncementType.PODCAST]: ANNOUNCEMENTS_TABS.SPECIAL_PODCASTS,
  [AnnouncementType.LECTURE]: ANNOUNCEMENTS_TABS.PUBLIC_LECTURE,
}

function Announcements() {
  const [searchParams] = useSearchParams()
  const sharedAnnouncementType = searchParams.get('type')

  const initialTab = Object.keys(announcementTypeToTab).includes(sharedAnnouncementType)
    ? announcementTypeToTab[sharedAnnouncementType as AnnouncementType]
    : ANNOUNCEMENTS_TABS.LIVE_EVENT

  const [selectedTab, setSelectedTab] = useState<ANNOUNCEMENTS_TABS>(initialTab)

  const renderCurrentTab = () => {
    switch (selectedTab) {
      case ANNOUNCEMENTS_TABS.LIVE_EVENT:
        return <LiveEventsTab />

      case ANNOUNCEMENTS_TABS.SPECIAL_PODCASTS:
        return <SpecialPodcastsTab />

      case ANNOUNCEMENTS_TABS.PUBLIC_LECTURE:
        return <PublicLectureTab />

      default:
        break
    }
  }

  return (
    <>
      <Header isBackButton title="Announcements" />
      <Wrapper id="scrollable-wrapper">
        <Tabs selectedTab={selectedTab} onChangeTab={setSelectedTab} />
        {renderCurrentTab()}
      </Wrapper>
    </>
  )
}

export default Announcements
