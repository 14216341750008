import { ReactComponent as NotificationIcon } from 'assets/icons/notification-big.svg'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { JoinButton, NoItemsDescription, NoItemsTitle, Wrapper } from './styled'

function NoData() {
  const navigate = useNavigate()
  const { user } = useAuth()

  const onJoinButtonClick = () => navigate(ROUTE_PATH.REGISTRATION)
  return (
    <Wrapper>
      <NotificationIcon />
      <NoItemsTitle>No new notifications</NoItemsTitle>
      <NoItemsDescription>
        {user ? 'You will get updates regarding new podcasts,' : 'Create an account to stay up to'}
        <br />
        {user ? 'replies from Rabbi and comments here.' : 'date on new shiurim'}
      </NoItemsDescription>
      {!user && (
        <JoinButton type="primary" onClick={onJoinButtonClick}>
          JOIN PATH4LIFE
        </JoinButton>
      )}
    </Wrapper>
  )
}

export default NoData
