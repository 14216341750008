import Loader from 'components/ui/Loader'
import { getNotifications, getNotificationsVariables } from 'graphql/query/__generated__/getNotifications'
import { GET_NOTIFICATIONS } from 'graphql/query/getNotifications'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'

import { useQuery } from '@apollo/client'

import NotificationsList from '../../Lists/NotificationsList'
import NoData from '../NoData'
import { CloseIcon, Heading, Wrapper } from './styled'

type Props = { onClose: () => void }

const initialPagination = {
  take: 10,
  page: 1,
}

function NotificationsContent({ onClose }: Props) {
  const { user } = useAuth()

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore } = useQuery<getNotifications, getNotificationsVariables>(GET_NOTIFICATIONS, {
    variables: initialPagination,
    skip: !user,
  })

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getNotifications: {
            ...previousQueryResult.getNotifications,
            ...fetchMoreResult.getNotifications,
            items: [...previousQueryResult.getNotifications.items, ...fetchMoreResult.getNotifications.items],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  return (
    <Wrapper>
      <CloseIcon onClick={onClose} />
      <Heading>Notifications</Heading>
      {loading ? (
        <Loader />
      ) : !data?.getNotifications?.items?.length ? (
        <NoData />
      ) : (
        <NotificationsList
          items={data.getNotifications.items}
          next={data.getNotifications.meta.next}
          loadingNext={loadingNext}
          onFetchMore={onFetchMore}
        />
      )}
    </Wrapper>
  )
}

export default NotificationsContent
