import { COLORS } from 'constants/colors'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ borderless?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 300px;

  ${({ borderless }) =>
    !borderless &&
    css`
      padding: 16px;

      border-radius: 12px;
      border: 1px solid #ebebeb;
    `}
`

export const Title = styled.p`
  width: 300px;

  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  color: ${COLORS.text.default};

  margin-top: 12px;
  margin-bottom: 10px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.3;
  color: #696b7b;

  margin-bottom: 16px;

  text-overflow: ellipsis;
  overflow: hidden;
`

export const DateText = styled.p`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1.3;
  color: #696b7b;
`

export const LabelWrapper = styled.div`
  padding: 4px 8px;

  color: ${COLORS.primary.medium};

  border-radius: 16px;
  background: #d9ecf6;
`

export const LabelText = styled.p`
  font-size: 12px;
  line-height: 1.3;
  color: ${COLORS.text.default};
`

export const ShiurWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ShiurImage = styled.img`
  height: 50px;
  width: 50px;

  margin-right: 12px;

  object-fit: cover;
  object-position: center;

  border-radius: 8px;
`

export const ShiurContentWrapper = styled.div`
  flex-grow: 1;
`

export const ShiurTitleRow = styled.div`
  width: 200px;
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`

export const ShiurNumber = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-right: 2px;

  white-space: nowrap;
`

export const ShiurTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ShiurDescription = styled.p`
  width: 200px;
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const PlayButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 8px;

  background: ${COLORS.primary.dark};
  box-shadow: 0px 0px 20px rgba(42, 76, 96, 0.1);
  border-radius: 50%;

  cursor: pointer;
`
