import { useState } from 'react'

export const useHandleDonationModals = () => {
  const [isSelectDateModalOpen, setIsSelectDateModalOpen] = useState(false)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)

  const openSelectDateModal = () => setIsSelectDateModalOpen(true)
  const closeSelectDateModal = () => setIsSelectDateModalOpen(false)

  const openPaymentModal = () => setIsPaymentModalOpen(true)
  const closePaymentModal = () => setIsPaymentModalOpen(false)

  return {
    isSelectDateModalOpen,
    isPaymentModalOpen,
    openSelectDateModal,
    closeSelectDateModal,
    openPaymentModal,
    closePaymentModal,
  }
}
