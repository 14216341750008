import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import VerificationCodeSentModal from '../VerificationCodeSentModal'
import EmailInput from './EmailInput'

type Props = { open: boolean; onClose: () => void }

enum STEPS {
  EMAIL_INPUT = 'email_input',
  EMAIL_SENT = 'email_sent',
}

function ForgotPasswordModal(props: Props) {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [step, setStep] = useState<STEPS>(STEPS.EMAIL_INPUT)

  const nextStep = (email: string) => {
    setEmail(email)
    setStep(STEPS.EMAIL_SENT)
  }

  const goToCreateNewPassword = async () => {
    navigate(ROUTE_PATH.FORGOT_PASSWORD, { state: { email } })
  }

  if (step === STEPS.EMAIL_INPUT) return <EmailInput nextStep={nextStep} {...props} />

  if (step === STEPS.EMAIL_SENT) return <VerificationCodeSentModal {...props} onComplete={goToCreateNewPassword} />
}

export default ForgotPasswordModal
