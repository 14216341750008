import { ModalProps } from 'antd'
import { ReactNode } from 'react'

import { ANTDModal, Description, FooterButton, IconWrapper, Title } from './styled'

type Props = ModalProps & {
  onBtnClick: () => void
  btnText: string
  description?: string | ReactNode
  icon: ReactNode
  noFooter?: boolean
  disabled?: boolean
}
type FooterProps = { onBtnClick: () => void; btnText: string }

function Footer({ onBtnClick, btnText }: FooterProps) {
  return (
    <FooterButton type="primary" onClick={onBtnClick}>
      {btnText}
    </FooterButton>
  )
}

function InfoModal({ children, onBtnClick, btnText, title, description, icon, noFooter, ...props }: Props) {
  return (
    <ANTDModal
      footer={noFooter ? null : <Footer btnText={btnText} onBtnClick={onBtnClick} />}
      title={
        <>
          <IconWrapper>{icon}</IconWrapper>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </>
      }
      width={400}
      closable={false}
      {...props}
    >
      {children}
    </ANTDModal>
  )
}

export default InfoModal
