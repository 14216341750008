import { Input } from 'antd'
import styled from 'styled-components'

export const TextAreaStyled = styled(Input.TextArea)`
  &,
  &.ant-input-affix-wrapper {
    overflow: hidden;
    border-radius: 12px;

    textarea.ant-input {
      padding-bottom: 25px;
      border-radius: 12px;
      resize: none;
    }
  }

  &.ant-input-textarea-show-count .ant-input-data-count {
    bottom: 5px;
    left: unset;
    right: 25px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    padding: 0 3px;
  }
`
