import styled from 'styled-components'

export const ArrowIconTogglerWrapper = styled.div<{ isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    transition: all 0.2s;
    ${({ isOpen }) => isOpen && 'transform: rotate(180deg)'};

    cursor: pointer;
  }
`
