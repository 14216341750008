import { gql } from '@apollo/client'

export const GET_ONE_ARTICLE = gql`
  query getOneArticle($id: String!) {
    getOneArticle(id: $id) {
      articleId
      title
      text
      previewText
      publicationDate
      image
      minutesToRead
    }
  }
`
