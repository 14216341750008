import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Title = styled.p`
  margin-bottom: 8px;

  font-size: 14px;
  line-height: 1.3;
  color: #677fa9;

  @media ${device.laptopL} {
    display: none;
  }
`

export const Text = styled.p`
  width: 500px;

  color: ${COLORS.text.default};
  font-size: 16px;
  line-height: 1.2;

  text-overflow: ellipsis;
  overflow: hidden;

  @media ${device.laptopL} {
    width: auto;
    text-align: center;
  }
`
