import ShiurimListBidirectionalScroll from 'components/business/Main/Lists/ShiurimListBidirectionalScroll'
import CalendarSection from 'components/business/Main/NewShiurim/CalendarSection'
import Header from 'components/ui/Header'
import AlertModal from 'components/ui/Modal/AlertModal'
import dayjs from 'dayjs'
import {
  getShiurimOrderedByDate,
  getShiurimOrderedByDateVariables,
} from 'graphql/query/__generated__/getShiurimOrderedByDate'
import { GET_SHIURIM_ORDERED_BY_DATE } from 'graphql/query/getShiurimOrderedByDate'
import { useHeaderHeight } from 'hooks/useHeaderHeight'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useQuery } from '@apollo/client'

import { Wrapper } from './styled'

function NewShiurim() {
  const { setQueryInfo } = useQueryInfo()
  const [searchParams, setSearchParams] = useSearchParams()
  const sharedDate = searchParams.get('date')

  const navigate = useNavigate()
  const { headerHeight } = useHeaderHeight()

  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)

  const [selectedDate, setSelectedDate] = useState(dayjs().startOf('D'))
  const [isSelectedDateChangedByScroll, setIsSelectedDateChangedByScroll] = useState(false)

  const nextLastFetchedDay = useRef(dayjs().startOf('D'))
  const prevLastFetchedDay = useRef(dayjs().startOf('D'))

  const [isPrevLoading, setIsPrevLoading] = useState(false)
  const [isNextLoading, setIsNextLoading] = useState(false)
  const [isNewDateLoading, setIsNewDateLoading] = useState(false)

  const isPrevLoadingRef = useRef(false)
  const isNextLoadingRef = useRef(false)

  // Clear search params after loading
  useEffect(() => {
    if (sharedDate) {
      setSelectedDate(dayjs(sharedDate))
    }

    searchParams.delete('date')
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams, sharedDate])

  useEffect(() => {
    isPrevLoadingRef.current = isPrevLoading
  }, [isPrevLoading])

  useEffect(() => {
    isNextLoadingRef.current = isNextLoading
  }, [isNextLoading])

  const variables = useMemo(() => ({ date: dayjs().toDate().toDateString(), take: 50, page: 1 }), [])
  const { data, loading, refetch, fetchMore } = useQuery<getShiurimOrderedByDate, getShiurimOrderedByDateVariables>(
    GET_SHIURIM_ORDERED_BY_DATE,
    { variables }
  )

  useEffect(() => {
    setQueryInfo({
      query: GET_SHIURIM_ORDERED_BY_DATE,
      variables,
    })
  }, [setQueryInfo, variables])

  const onFetchNext = async () => {
    setIsNextLoading(true)

    nextLastFetchedDay.current = nextLastFetchedDay.current.add(1, 'day')

    const variables = { date: nextLastFetchedDay.current.toDate().toDateString(), take: 50, page: 1 }

    const { data: fetchMoreData } = await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getShiurimOrderedByDate: {
            ...previousQueryResult.getShiurimOrderedByDate,
            ...fetchMoreResult.getShiurimOrderedByDate,
            items: [
              ...(fetchMoreResult.getShiurimOrderedByDate?.items || []),
              ...(previousQueryResult.getShiurimOrderedByDate?.items || []),
            ],
          },
        }
      },
    })

    setQueryInfo({
      query: GET_SHIURIM_ORDERED_BY_DATE,
      variables,
    })

    // If no data received, fetch until new data received
    if (!fetchMoreData.getShiurimOrderedByDate.items.length && !nextLastFetchedDay.current.isToday()) {
      await onFetchNext()
    }

    setIsNextLoading(false)
  }

  const onFetchPrev = async () => {
    setIsPrevLoading(true)

    prevLastFetchedDay.current = prevLastFetchedDay.current.add(-1, 'day')

    const variables = {
      date: prevLastFetchedDay.current.toDate().toDateString(),
      take: 50,
      page: 1,
    }

    await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getShiurimOrderedByDate: {
            ...previousQueryResult.getShiurimOrderedByDate,
            ...fetchMoreResult.getShiurimOrderedByDate,
            items: [
              ...(previousQueryResult.getShiurimOrderedByDate?.items || []),
              ...(fetchMoreResult.getShiurimOrderedByDate?.items || []),
            ],
          },
        }
      },
    })

    setQueryInfo({
      query: GET_SHIURIM_ORDERED_BY_DATE,
      variables,
    })

    setIsPrevLoading(false)
  }

  const onUnloadedDateChange = async (date: dayjs.Dayjs) => {
    setIsNewDateLoading(true)

    const variables = { date: date.toDate().toDateString(), take: 50, page: 1 }

    await refetch(variables)

    setQueryInfo({
      query: GET_SHIURIM_ORDERED_BY_DATE,
      variables,
    })

    prevLastFetchedDay.current = date
    nextLastFetchedDay.current = date
    setIsNewDateLoading(false)
  }

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)
  return (
    <>
      <Header isBackButton title="Daily Shiurim" />
      <CalendarSection
        selectedDate={selectedDate}
        onChangeDate={(date) => {
          setSelectedDate(date)
          setIsSelectedDateChangedByScroll(false)
        }}
      />
      <Wrapper id="scrollable-wrapper" headerHeight={headerHeight}>
        <ShiurimListBidirectionalScroll
          items={data?.getShiurimOrderedByDate?.items ? [...data.getShiurimOrderedByDate.items] : []}
          openRegisterToContinueModal={openRegisterToContinueModal}
          onFetchNext={onFetchNext}
          onFetchPrev={onFetchPrev}
          selectedDate={selectedDate}
          isSelectedDateChangedByScroll={isSelectedDateChangedByScroll}
          prevLastFetchedDay={prevLastFetchedDay}
          nextLastFetchedDay={nextLastFetchedDay}
          onChangeDate={(date) => {
            setSelectedDate(date)
            setIsSelectedDateChangedByScroll(true)
          }}
          onUnloadedDateChange={onUnloadedDateChange}
          prevLoadingRef={isPrevLoadingRef}
          nextLoadingRef={isNextLoadingRef}
          prevLoading={isPrevLoading}
          nextLoading={isNextLoading}
          loading={isNewDateLoading || loading}
        />

        <AlertModal
          open={isRegisterToContinueModalOpen}
          onCancel={closeRegisterToContinueModal}
          onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
          onSecondaryBtnClick={closeRegisterToContinueModal}
          title="Register to Continue"
          description="To unlock all features you need to create an account"
          primaryBtnText="REGISTER"
          secondaryBtnText="NOT NOW"
        />
      </Wrapper>
    </>
  )
}

export default withQueryInfo(NewShiurim)
