import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

export const Item = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px 16px 0;

  & > svg {
    cursor: pointer;
  }

  cursor: pointer;
`

export const Wrapper = styled(InfiniteScroll)`
  position: relative;

  padding-bottom: 35px;
`

export const ScrollableWrapper = styled.div<{ loading: boolean }>`
  overflow: auto;
  flex: 1;

  ${({ loading }) => loading && 'height: 100%'};

  & ${Item} {
    &:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  justify-content: center;
`
