import { COLORS } from 'constants/colors'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
caption,
table,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  vertical-align: initial;
}
html{
  /* overflow: hidden; */
}
a {
  color: inherit;
  text-decoration: none;
}
img {
  border: 0;
  vertical-align: middle;
}
div{
  box-sizing: border-box;
}
blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}

a:focus {
  outline: 0;
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}
button{
  padding: 0;
}
button:focus {
  outline: none;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: 'General Sans', sans-serif;
  color: #000000;
  background-color: #fff;
  font-display: swap;
}

.ant-btn{
  font-weight: 500;
  letter-spacing: 0.02em;
}

.ant-message .ant-message-notice .ant-message-notice-content{
  padding: 16px 16px 16px 12px;
  color: #ffffff;
  background-color: ${COLORS.primary.medium};

  & .ant-message-custom-content{
    display: flex;
    align-items: center;

    & > span{
      width: 100%;
      padding-left: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

*::-webkit-scrollbar{
  width: 6px;
}
*::-webkit-scrollbar-track{
  border-radius: 10px;
  background: #D9ECF6;
}
*::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: #80A7BC;  
}

.ant-menu-inline-collapsed-tooltip{
  display: none;
}
`

export default GlobalStyle
