import Avatar from './Avatar'
import Bio from './Bio'
import PersonalInfo from './PersonalInfo'
import { Container, Divider, Wrapper } from './styled'

function ProfileBanner() {
  return (
    <Wrapper>
      <Container>
        <Avatar />
        <PersonalInfo />
      </Container>
      <Divider />
      <Bio />
    </Wrapper>
  )
}

export default ProfileBanner
