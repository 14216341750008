import PopularSeries from 'components/business/Main/SeriesDetailed/PopularSeries'
import PopularShiurim from 'components/business/Main/SeriesDetailed/PopularShiurim'
import SeriesTopBanners from 'components/business/Main/SeriesDetailed/SeriesTopBanners'
import ShiurimsWithFilter from 'components/business/Main/SeriesDetailed/ShiurimsWithFilter'
import { Flex } from 'components/common/Flex'
import Header from 'components/ui/Header'
import AlertModal from 'components/ui/Modal/AlertModal'
import { deviceWidth } from 'constants/responsive'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { Wrapper } from 'pages/Main/styled'
import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { LeftContainer, RightContainer } from './styled'

function SeriesDetailed() {
  const navigate = useNavigate()

  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 300)

  const searchProps = {
    searchValue: search,
    onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    searchPlaceholder: 'Search shiurim...',
  }

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)

  const isMobile = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])
  return (
    <>
      <Header title="Series" searchProps={isMobile ? searchProps : undefined} isBackButton />
      <Wrapper id="scrollable-wrapper">
        <SeriesTopBanners openRegisterToContinueModal={openRegisterToContinueModal} />
        <Flex>
          <LeftContainer>
            <ShiurimsWithFilter
              search={debouncedSearch}
              searchProps={searchProps}
              openRegisterToContinueModal={openRegisterToContinueModal}
            />
          </LeftContainer>
          {!isMobile && (
            <RightContainer>
              <PopularShiurim openRegisterToContinueModal={openRegisterToContinueModal} />
              <PopularSeries />
            </RightContainer>
          )}
        </Flex>
      </Wrapper>
      <AlertModal
        open={isRegisterToContinueModalOpen}
        onCancel={closeRegisterToContinueModal}
        onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
        onSecondaryBtnClick={closeRegisterToContinueModal}
        title="Register to Continue"
        description="To unlock all features you need to create an account"
        primaryBtnText="REGISTER"
        secondaryBtnText="NOT NOW"
      />
    </>
  )
}

export default SeriesDetailed
