import { Button, Input } from 'antd'
import BlankModal from 'components/ui/Modal/BlankModal'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const ANTDModal = styled(BlankModal)<{ isFooterVisible: boolean }>`
  .ant-modal-header {
    display: block;
    padding-bottom: 20px;
    margin: 0px 24px;
    margin-bottom: 0;

    border-bottom: 1px solid #ebebeb;
  }

  .ant-modal-content {
    padding: 20px 0px 16px 0px;
  }

  .ant-modal-body {
    height: 300px;
    padding: 14px 24px 14px 24px;
  }

  .ant-modal-footer {
    display: block;
    padding: 16px 16px 0px 16px;
    height: 66px;
    ${({ isFooterVisible }) => isFooterVisible && 'box-shadow: 0px 0px 12px 0px #0000000F'};
    margin: 0;
  }

  .ant-modal-close {
    top: 22px;
    inset-inline-end: 22px;
  }
`

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderButton = styled(Button)`
  height: 32px;
  font-size: 12px;
  line-height: 1px;
  &.ant-btn-link {
    color: ${COLORS.text.default};
  }
`

export const Label = styled.p<{ isNote?: boolean }>`
  margin-bottom: ${({ isNote }) => (isNote ? '10px' : '17px')};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;

  color: ${COLORS.text.default};
`

export const TimeCodeSection = styled.div`
  margin-bottom: 24px;
`

export const ShiurInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ShiurImage = styled.img`
  width: 46px;
  height: 46px;
  margin-right: 8px;

  object-fit: cover;
  object-position: center;

  border-radius: 6px;
`

export const ShiurTitle = styled.p`
  width: 160px;
  margin-bottom: 4px;

  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  color: ${COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ShiurDescription = styled.p`
  width: 160px;
  font-size: 12px;
  line-height: 1.3;
  color: ${COLORS.text.hint};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ShiurSeriesTitle = styled.span<{ color?: string }>`
  color: ${({ color }) => color || COLORS.text.default};
`

export const ShiurTimeCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;

  background-color: #eaf7fe;
  border-radius: 16px;
`

export const ShiurTimeCodeText = styled.p`
  font-size: 12px;
  line-height: 1.3;

  color: ${COLORS.text.default};
`

export const TextArea = styled(Input.TextArea)<{ isNote?: boolean }>`
  &.ant-input {
    height: ${({ isNote }) => (isNote ? '100%' : '140px')} !important;
    padding: 0;
    font-size: 16px;
    line-height: 1.2;
    border: none;
    border-radius: 0;
    box-shadow: none;
    resize: none;
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;
`
