/* eslint-disable react/no-unescaped-entities */
import { notification } from 'antd'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import notificationsPreviewImageSrc from 'assets/img/notifications-preview.jpg'
import {
  userNotificationsManage,
  userNotificationsManageVariables,
} from 'graphql/mutation/__generated__/userNotificationsManage'
import { USER_NOTIFICATIONS_MANAGE } from 'graphql/mutation/userNotificationsManage'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useMutation } from '@apollo/client'

import { FullWidthPageContainer, FullWidthPageContent, FullWidthPageHeader, FullWidthPageWrapper } from '../styled'
import { Description, Image, LaterButton, SubmitButton, Title } from './styled'

function EmailNotifications() {
  const { checkAuth } = useAuth()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [manageNotifications] = useMutation<userNotificationsManage, userNotificationsManageVariables>(
    USER_NOTIFICATIONS_MANAGE
  )

  const onEnable = async () => {
    setLoading(true)

    try {
      await manageNotifications({ variables: { allowNotify: true } })
      await checkAuth()

      navigate(ROUTE_PATH.HOME)
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
      })
    }

    setLoading(false)
  }

  const onLater = async () => {
    try {
      await manageNotifications({ variables: { allowNotify: false } })
      await checkAuth()

      navigate(ROUTE_PATH.HOME)
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
      })
    }
  }

  return (
    <FullWidthPageWrapper>
      <FullWidthPageHeader>
        <Logo />
      </FullWidthPageHeader>
      <FullWidthPageContainer>
        <FullWidthPageContent big>
          <Image width={450} height={314} src={notificationsPreviewImageSrc} />
          <Title>Turn on email notification</Title>
          <Description>
            Don't miss out on anything important.
            <br />
            Allow Path4Life to send you notifications
          </Description>
          <SubmitButton type="primary" loading={loading} onClick={onEnable}>
            TURN ON
          </SubmitButton>
          <LaterButton onClick={onLater}>Maybe later</LaterButton>
        </FullWidthPageContent>
      </FullWidthPageContainer>
    </FullWidthPageWrapper>
  )
}

export default EmailNotifications
