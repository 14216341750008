import { ReactComponent as MoreIcon } from 'assets/icons/dots.svg'
import { ReactComponent as PlayIcon } from 'assets/icons/play-small.svg'
import { ReactComponent as PlayingIcon } from 'assets/icons/playing.svg'
import { ReactComponent as PlayedIcon } from 'assets/icons/tick-circle.svg'
import { ShiurimAutoPlayInfo } from 'components/business/Main/Cards/ShiurimCard'
import ShiurActionsPopover from 'components/business/Main/Shiurim/ShiurActionsPopover'
import { Flex } from 'components/common/Flex'
import { deviceWidth } from 'constants/responsive'
import dayjs from 'dayjs'
import { getAllShiurims_getAllShiurims_items } from 'graphql/query/__generated__/getAllShiurims'
import { convertMsInSecs } from 'helpers/convertMsInSecs'
import { getDateWithoutTimezone } from 'helpers/getDateWithoutTimezone'
import { useAuth } from 'hooks/useAuth'
import { usePlayer } from 'hooks/usePlayer'
import { useQueryInfo } from 'hooks/useQueryInfo'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Item } from '../styled'
import {
  ContentWrapper,
  Description,
  Image,
  MainInfo,
  Number as OrderedNumber,
  PlayButtonWrapper,
  PlayerDescription,
  SeriesTitle,
  Title,
  TitleRow,
} from './styled'

type Props = getAllShiurims_getAllShiurims_items & {
  index: number
  onToggleFavorite: (shiurId: string, favorite: boolean) => Promise<void>
  openRegisterToContinueModal?: () => void
}

function ShiurListItem({ openRegisterToContinueModal, onToggleFavorite, index, ...shiur }: Props) {
  const navigate = useNavigate()

  const { queryInfo } = useQueryInfo()
  const [shiurQueryInfo] = useState(queryInfo)

  const moreButtonElRef = useRef<HTMLDivElement>()

  const { user } = useAuth()
  const { id, isPlaying, duration, progress, playerRef, play, pause } = usePlayer()

  const isCurrentInitialized = id === shiur.shiurId
  const isCurrentPlaying = isPlaying && isCurrentInitialized

  const shiurimAutoPlayInfo: ShiurimAutoPlayInfo = { ...shiurQueryInfo, index }

  useEffect(() => {
    if (
      shiur.listenedDuration &&
      dayjs.duration(shiur.duration - shiur.listenedDuration).asSeconds() > 1 &&
      isCurrentInitialized &&
      playerRef?.current
    ) {
      playerRef.current.seekTo(Math.round(convertMsInSecs(shiur.listenedDuration)), 'seconds')
    }
  }, [isCurrentInitialized, playerRef])

  const togglePlayer = async (e: MouseEvent, shiur: getAllShiurims_getAllShiurims_items) => {
    if (
      moreButtonElRef.current &&
      (moreButtonElRef.current.children[0] === e.target || moreButtonElRef.current === e.target)
    )
      return

    if (isCurrentPlaying) {
      return pause()
    }

    if (isCurrentInitialized && !isPlaying) {
      return await play()
    }

    if (!isCurrentInitialized) {
      await play(shiur, shiurimAutoPlayInfo)
    }
  }

  const onSaveToFavorite = async () => {
    if (!user) return openRegisterToContinueModal()

    await onToggleFavorite(shiur.shiurId, shiur.favoriteInfo.isFavorite)
  }

  const onAdditionalActionsClick = (e: Event) => {
    if (!user) {
      e.stopPropagation()
      openRegisterToContinueModal()
    }
  }

  const goToSeries = (e: MouseEvent) => {
    e.stopPropagation()

    navigate(`/series/${shiur.series.seriesId}`)
  }

  const isMobile = window.innerWidth < Number(deviceWidth.laptop.match(/(\d+)/)[0])
  return (
    <Item key={shiur.shiurId} onClick={async (e: MouseEvent) => await togglePlayer(e, shiur)}>
      <MainInfo>
        <Image src={`${process.env.REACT_APP_AWS_S3_URL}${shiur.series.coverUrl}`} />
        <ContentWrapper>
          <TitleRow>
            {!!shiur.orderedNum && <OrderedNumber>Shiur {shiur.orderedNum}.</OrderedNumber>}
            <Title>{shiur.title}</Title>
          </TitleRow>
          <Description>
            <SeriesTitle onClick={goToSeries} color={shiur.series.titleColor}>
              {shiur.series.title}
            </SeriesTitle>
            {`∙${shiur.topic.title}`}
          </Description>
        </ContentWrapper>
      </MainInfo>
      <Flex alignCenter>
        <PlayButtonWrapper>{isPlaying && id === shiur.shiurId ? <PlayingIcon /> : <PlayIcon />}</PlayButtonWrapper>
        {!isMobile && (
          <PlayerDescription>
            {dayjs.duration(shiur.duration - shiur.listenedDuration).asSeconds() < 1 &&
            (!isCurrentPlaying || (duration - progress?.playedSeconds === 0 && progress?.playedSeconds !== 0)) ? (
              <Flex alignCenter>
                Played&nbsp;
                <PlayedIcon />
              </Flex>
            ) : (
              <>
                {getDateWithoutTimezone(shiur.publicationDate).toDate().toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}{' '}
                ·{' '}
                {isCurrentInitialized
                  ? `${dayjs
                      .duration(duration - progress?.playedSeconds || convertMsInSecs(shiur.duration), 's')
                      .format('m:ss')} min left`
                  : shiur.listenedDuration
                  ? `${dayjs
                      .duration(convertMsInSecs(shiur.duration - shiur.listenedDuration), 'seconds')
                      .format('m:ss')} min left`
                  : `${dayjs.duration(convertMsInSecs(shiur.duration), 'seconds').format('m:ss')} min`}
              </>
            )}
          </PlayerDescription>
        )}
      </Flex>
      {!isMobile && (
        <ShiurActionsPopover shiur={shiur} index={index} full onSaveToFavorite={onSaveToFavorite}>
          <MoreIcon ref={moreButtonElRef} onClick={onAdditionalActionsClick} />
        </ShiurActionsPopover>
      )}
    </Item>
  )
}

export default ShiurListItem
