import { Button } from 'antd'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Image = styled.img`
  width: 450px;

  @media ${device.laptopL} {
    width: 100%;

    object-fit: contain;
  }
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  text-align: center;
  color: ${COLORS.text.default};

  margin-top: 25px;
  margin-bottom: 16px;
`

export const Description = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  color: ${COLORS.text.hint};

  margin-bottom: 40px;
`

export const SubmitButton = styled(Button)`
  width: 340px;

  margin-bottom: 20px;
`

export const LaterButton = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  text-align: center;
  color: ${COLORS.text.default};

  cursor: pointer;

  transition: all 0.2s;
  &:hover {
    color: ${COLORS.primary.medium};
  }
`
