import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

import { InfoIcon } from '../icons'

export const ShiurWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Conatiner = styled.div`
  width: calc(100% - 66px - 12px); // 100% - image width - margin

  @media ${device.laptopL} {
    width: calc(100% - 40px - 8px); // 100% - image width - margin
  }
`

export const Image = styled.img`
  height: 66px;
  width: 66px;
  margin-right: 12px;

  border-radius: 8px;

  object-fit: cover;
  object-position: center;

  @media ${device.laptopL} {
    width: 40px;
    height: 40px;

    margin-right: 8px;
  }
`

export const TitleRow = styled.div`
  display: flex;

  @media ${device.laptopL} {
    width: auto;
  }
`

export const Number = styled.p`
  flex-shrink: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-right: 2px;

  white-space: nowrap;
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  white-space: nowrap;
`

export const SubtitleRow = styled.div`
  display: flex;
  align-items: flex-end;
`

export const Info = styled(InfoIcon)`
  color: #677fa9;

  cursor: pointer;

  transition: all 0.2s;
  &:hover {
    color: ${COLORS.primary.medium};
  }
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-right: 14px;

  white-space: nowrap;

  @media ${device.laptopL} {
    display: none;
  }
`
