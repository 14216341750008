import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg'
import { Flex } from 'components/common/Flex'
import { deviceWidth } from 'constants/responsive'
import { useState } from 'react'

import QuestionsAndCommentsModal from '../../Modals/QuestionsAndCommentsModal'
import { ActionButton, IconWrapper, RightArrow, Subtitle, Title, Wrapper } from './styled'

function Banner() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <>
      <Wrapper onClick={isMobile ? openModal : undefined}>
        <Flex alignCenter>
          <IconWrapper>
            <QuestionIcon />
          </IconWrapper>
          <div>
            <Title>Questions and Comments</Title>
            <Subtitle>Ask us anything</Subtitle>
          </div>
        </Flex>
        {isMobile ? (
          <RightArrow />
        ) : (
          <ActionButton type="primary" onClick={openModal}>
            Write to us
          </ActionButton>
        )}
      </Wrapper>
      {isModalOpen && <QuestionsAndCommentsModal open={isModalOpen} onClose={closeModal} />}
    </>
  )
}

export default Banner
