import { monthNamesArr } from 'constants/dates'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'

import {
  Day,
  DayWrapper,
  DaysWrapper,
  Header,
  MainWrapper,
  MonthWrapper,
  Title,
  WeekDay,
  WeekDaysRow,
  WeekWrapper,
} from './styled'

type CalendarData = {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: dayjs.Dayjs
      }
    }
  }
}

type Props = {
  rangeDates?: [dayjs.Dayjs, dayjs.Dayjs]
  onChangeDate: (date: [dayjs.Dayjs, dayjs.Dayjs]) => void
  future?: boolean
}

function getWeekOfMonth(date: dayjs.Dayjs) {
  const startOfMonth = date.startOf('month')
  const weekOfYear = date.week()
  const weekOfYearStart = startOfMonth.week()

  return weekOfYear - weekOfYearStart + 1
}

const getDaysUntil2020 = () => {
  const arr: dayjs.Dayjs[] = []
  for (let i = 0; dayjs().startOf('D').add(i, 'day').isAfter(dayjs().set('year', 2019).endOf('year')); i--) {
    arr.push(dayjs().add(i, 'day').startOf('D'))
  }

  return arr.reverse()
}

const getDaysUntilEndOfNextYear = () => {
  const arr: dayjs.Dayjs[] = []
  for (let i = 0; dayjs().startOf('D').add(i, 'day').isBefore(dayjs().add(1, 'year').endOf('year')); i++) {
    arr.push(dayjs().add(1, 'week').startOf('week').add(i, 'day').startOf('D'))
  }

  return arr.reverse()
}

function WeekCalendar({ rangeDates, onChangeDate, future }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [calendar, setCalendar] = useState<CalendarData>()

  useEffect(() => {
    const days = future ? getDaysUntilEndOfNextYear() : getDaysUntil2020()

    const newCalendar = days.reduce<CalendarData>((acc, el) => {
      const year = el.year()
      const month = el.toDate().toLocaleDateString('en-US', { month: 'long' })
      const week = getWeekOfMonth(el)
      const weekDay = el.weekday()

      const newObj = {
        ...acc,
        [year]: {
          ...acc[year],
          [month]: {
            ...(acc?.[year]?.[month] || {}),
            [week]: {
              ...(acc?.[year]?.[month]?.[week] || {}),
              [weekDay]: el,
            },
          },
        },
      }

      return (acc = newObj)
    }, {})

    setCalendar(newCalendar)
  }, [])

  const renderCalendar = () => {
    const renderArr = Object.keys(calendar)

    return <>{renderArr.map((el) => renderYear(el))}</>
  }

  const renderYear = (year: string) => {
    const currentYear = calendar[year]

    const renderArr = Object.keys(currentYear).reverse()
    return <>{renderArr.map((el) => renderMonth(el, year))}</>
  }

  const renderMonth = (month: string, year: string) => {
    const currentMonth = calendar[year][month]

    const renderArr = Object.keys(currentMonth)
    return (
      <MonthWrapper>
        <Title>
          {month}, {year}
        </Title>
        <DaysWrapper>{renderArr.map((el) => renderWeek(el, month, year))}</DaysWrapper>
      </MonthWrapper>
    )
  }

  const renderWeek = (week: string, month: string, year: string) => {
    const currentWeek = calendar[year][month][week]
    if (!currentWeek) return

    const renderArr = []
    for (let i = 0; i < 7; i++) {
      const el = currentWeek[i]
      renderArr.push(el)
    }

    const startOfCurrentWeek = dayjs()
      .set('year', Number(year))
      .set('month', monthNamesArr.indexOf(month))
      .startOf('month')
      .add(Number(week) - 1, 'week')
      .startOf('week')

    const onSelectWeek = () => {
      onChangeDate([startOfCurrentWeek, startOfCurrentWeek.endOf('week')])
    }

    const isSelected =
      rangeDates?.[0].isSame(startOfCurrentWeek) && rangeDates?.[1].isSame(startOfCurrentWeek.endOf('week'))

    return (
      <WeekWrapper selected={isSelected} onClick={onSelectWeek}>
        {renderArr.map((el, i) => (
          <DayWrapper key={el?.toISOString() || i}>
            <Day disabled={!el?.date() && true}>{el?.date() || startOfCurrentWeek.add(i, 'day').date()}</Day>
          </DayWrapper>
        ))}
      </WeekWrapper>
    )
  }

  return (
    <div>
      <Header>
        <WeekDaysRow>
          <WeekDay>S</WeekDay>
          <WeekDay>M</WeekDay>
          <WeekDay>T</WeekDay>
          <WeekDay>W</WeekDay>
          <WeekDay>T</WeekDay>
          <WeekDay>F</WeekDay>
          <WeekDay>S</WeekDay>
        </WeekDaysRow>
      </Header>
      <MainWrapper ref={wrapperRef}>{calendar && renderCalendar()}</MainWrapper>
    </div>
  )
}

export default WeekCalendar
