import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: start;

  @media ${device.tablet} {
    display: block;

    & > div:first-child {
      margin-bottom: 14px;
    }
  }
`
