import { SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_BOOKMARKS_WITH_PAGINATION = gql`
  ${SHIUR_FRAGMENT}
  query getBookmarksWithPagination($take: Int!, $page: Int!, $text: String) {
    getBookmarksWithPagination(take: $take, page: $page, text: $text) {
      items {
        bookmarkId
        text
        timecode
        createdAt
        shiur {
          ...ShiurimInfo
        }
      }
      meta {
        total
        next
      }
    }
  }
`
