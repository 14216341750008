import { SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_ALL_SHIURIMS = gql`
  ${SHIUR_FRAGMENT}
  query getAllShiurims($take: Int!, $page: Int!, $offset: Boolean, $searchParams: ShiurSearchParams) {
    getAllShiurims(take: $take, page: $page, offset: $offset, searchParams: $searchParams) {
      items {
        ...ShiurimInfo
      }
      meta {
        total
        next
      }
    }
  }
`
