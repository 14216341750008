import { SponsorshipType } from '__generated__/globalTypes'
import { ReactComponent as CompletedIcon } from 'assets/icons/completed.svg'
import InfoModal from 'components/ui/Modal/InfoModal'
import { deviceWidth } from 'constants/responsive'
import { initPayment, initPaymentVariables } from 'graphql/mutation/__generated__/initPayment'
import { INIT_PAYMENT } from 'graphql/mutation/initPayment'
import { getDateWithoutTimezoneInStringFormat } from 'helpers/getDateWithoutTimezone'
import { MutableRefObject, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useMutation } from '@apollo/client'

import { Values } from '../SponsorApp'
import CardInput from './CardInput'

type Props = {
  open: boolean
  isApp: boolean
  values: Values & { series?: string }
  onClose?: () => void
  cardForm: MutableRefObject<CardForm>
}

enum STEPS {
  CARD_INPUT = 'card_input',
  COMPLETED = 'completed',
}

function PaymentModal({ values, isApp, ...props }: Props) {
  const { pathname } = useLocation()

  const [step, setStep] = useState<STEPS>(STEPS.CARD_INPUT)

  const [initPayment, { loading }] = useMutation<initPayment, initPaymentVariables>(INIT_PAYMENT)

  const nextStep = async (result: CardFormResult, captchaValue: string) => {
    await initPayment({
      variables: {
        capchaKey: captchaValue,
        paymentInput: {
          cardInfo: {
            expiryMonth: result.expiryMonth,
            expiryYear: result.expiryYear,
            nonce: result.nonce,
            avs_zip: null,
          },
          sponsorInfo: {
            sponsorshipPeriod: values.donationType,
            sponsorshipType: isApp ? SponsorshipType.path4life : SponsorshipType.shiur,
            startDate: getDateWithoutTimezoneInStringFormat(values.date[0]),
            endDate: getDateWithoutTimezoneInStringFormat(values.date[1]),
            amount: values.amount,
            comment: values.comment,
            isAnonymous: values.isAnonymous,
            isInMemory: values.isInMemory,
            inMemoryOf: values.inMemoryOf,
            fullName: values.fullName,
            email: values.email,
            series: values.series,
          },
        },
      },
    })
    setStep(STEPS.COMPLETED)
  }

  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  const isMobileDonate = pathname === ROUTE_PATH.DONATE_MOBILE

  if (step === STEPS.CARD_INPUT)
    return <CardInput isMobile={isMobile} nextStep={nextStep} loading={loading} {...props} />

  if (step === STEPS.COMPLETED)
    return (
      <InfoModal
        icon={<CompletedIcon />}
        width={isMobile ? 320 : 400}
        title={
          <>
            ${values.amount}
            <br />
            Donation completed!
          </>
        }
        noFooter={isMobileDonate}
        description={
          <>
            Thank you for your donation
            <br />
            To view additional information - open donation history
          </>
        }
        btnText="DONE"
        open={props.open}
        onBtnClick={props.onClose}
      />
    )
}

export default PaymentModal
