import dayjs from 'dayjs'
import { useState } from 'react'

import { HDate } from '@hebcal/core'

import { MonthText, MonthWrapper, MonthsWrapper, NextButton, PrevButton, YearText, YearWrapper } from './styled'

type Props = {
  rangeDates?: [dayjs.Dayjs, dayjs.Dayjs]
  onChangeDate: (date: [dayjs.Dayjs, dayjs.Dayjs]) => void
  future?: boolean
}

const getMonthsDatesByYearHebrew = (year: HDate) => {
  const arr = []

  const hebrewDate = year
  const hebrewYear = hebrewDate.getFullYear()
  const amountOfMonths = HDate.monthsInYear(hebrewYear)

  for (let i = 1; amountOfMonths >= i; i++) {
    arr.push(new HDate(1, i, hebrewYear))
  }

  return arr
}

function MonthCalendar({ rangeDates, onChangeDate, future }: Props) {
  const [selectedYear, setSelectedYear] = useState(new HDate())

  const onPrevYear = () => setSelectedYear(selectedYear.add(-1, 'year'))
  const onNextYear = () => setSelectedYear(selectedYear.add(1, 'year'))

  const isPrevDisabled = future ? selectedYear.getFullYear() <= new HDate().getFullYear() : false
  const isNextDisabled = future ? false : selectedYear.getFullYear() >= new HDate().getFullYear()
  const monthsArrHebrew = getMonthsDatesByYearHebrew(selectedYear)

  return (
    <div>
      <YearWrapper>
        <PrevButton disabled={isPrevDisabled} onClick={!isPrevDisabled ? onPrevYear : undefined} />
        <YearText>{selectedYear.getFullYear()}</YearText>
        <NextButton disabled={isNextDisabled} onClick={!isNextDisabled ? onNextYear : undefined} />
      </YearWrapper>
      <MonthsWrapper>
        {monthsArrHebrew.map((el) => {
          const amountOfDaysInMonth = el.daysInMonth()

          const startOfMonth = dayjs(el.greg())
          const endOfMonth = dayjs(el.add(amountOfDaysInMonth - 1, 'days').greg())

          const isSelected = rangeDates?.[0].isSame(startOfMonth) && rangeDates?.[1].isSame(endOfMonth)
          const isDisabled = future
            ? el.getMonth() <= new HDate().getMonth() && el.getFullYear() === new HDate().getFullYear()
            : el.getMonth() >= new HDate().getMonth() && el.getFullYear() === new HDate().getFullYear()

          return (
            <MonthWrapper
              disabled={isDisabled}
              selected={isSelected}
              key={el.getMonthName()}
              onClick={() => !isDisabled && onChangeDate([startOfMonth, endOfMonth])}
            >
              <MonthText selected={isSelected}>{el.getMonthName()}</MonthText>
            </MonthWrapper>
          )
        })}
      </MonthsWrapper>
    </div>
  )
}

export default MonthCalendar
