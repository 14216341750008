import { Button, Popover, PopoverProps } from 'antd'
import { COLORS } from 'constants/colors'
import styled, { css } from 'styled-components'

const MyPopover = ({ className, ...props }: PopoverProps) => <Popover {...props} overlayClassName={className} />

export const QuickFilterPopover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    overflow: hidden;
    padding: 0;
    border: 1px solid #d9ecf6;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
`

export const ContentWrapper = styled.div<{ isButtonsShown?: boolean }>`
  position: relative;
  width: 330px;
  padding: 16px 16px ${({ isButtonsShown }) => (isButtonsShown ? '82px' : 0)} 16px;
`

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 24px;

  background-color: #ffffff;
  border-radius: 12px 12px 0px 0px;
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.06));
`

export const QuickFilterButton = styled(Button)<{ isActive?: boolean }>`
  height: 42px;
  display: flex;
  align-items: center;
  margin-left: 8px;

  color: ${COLORS.text.default};

  svg {
    margin-left: 4px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: #ffffff;
      background-color: ${COLORS.primary.dark};
      border-color: ${COLORS.primary.dark};

      &:hover {
        color: #ffffff !important;
        background-color: ${COLORS.primary.dark};
        border-color: ${COLORS.primary.dark} !important;
      }
    `}
`
