import { Popover as ANTDPopover, PopoverProps } from 'antd'
import { ReactComponent as CloseIcon } from 'assets/icons/cross.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

const MyPopover = ({ className, ...props }: PopoverProps) => <ANTDPopover {...props} overlayClassName={className} />

export const Popover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    width: 400px;
    padding: 20px 0px 24px;
    border: 1px solid #d9ecf6;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    @media ${device.tablet} {
      width: 350px;
    }
  }
`

export const Header = styled.div`
  position: relative;
  padding-bottom: 20px;
  margin: 0px 24px 24px;

  border-bottom: 1px solid #ebebeb;
`

export const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 0px;
  right: 0px;

  cursor: pointer;
`

export const HeaderText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;

  color: ${COLORS.text.default};
`

export const AutomaticallyPlayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 24px;
  margin-bottom: 24px;
`

export const AutomaticallyPlayText = styled.p`
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  color: ${COLORS.text.default};
`

export const ShiurWrapper = styled.div<{ small?: boolean }>`
  display: flex;
  align-items: center;

  ${({ small }) =>
    !small &&
    css`
      padding: 0px 24px;
      margin-bottom: 24px;
    `};
`

export const Image = styled.img<{ small?: boolean }>`
  width: ${({ small }) => (small ? '36px' : '68px')};
  height: ${({ small }) => (small ? '36px' : '68px')};
  margin-right: ${({ small }) => (small ? '8px' : '10px')};

  object-fit: cover;
  object-position: center;

  border-radius: 8px;
`

export const TitleRow = styled.div`
  width: 220px;
  display: flex;
  align-items: center;

  margin-bottom: 4px;
`

export const Number = styled.p<{ small?: boolean }>`
  font-weight: 600;
  font-size: ${({ small }) => (small ? '14px' : '16px')};
  line-height: 1.2;
  color: ${COLORS.text.hint};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Title = styled.p<{ small?: boolean }>`
  ${({ small }) => !small && 'width: 220px'};
  font-weight: 600;
  font-size: ${({ small }) => (small ? '14px' : '16px')};
  line-height: 1.2;
  color: ${COLORS.text.default};

  margin-bottom: ${({ small }) => (small ? '0px' : '6px')};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Subtitle = styled.p`
  width: 220px;
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.hint};

  margin-bottom: 8px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Description = styled.p<{ small?: boolean }>`
  width: 220px;
  font-size: ${({ small }) => (small ? '10px' : '12px')};
  line-height: 1.3;
  color: ${COLORS.text.hint};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const SeriesTitle = styled.span<{ color?: string }>`
  color: ${({ color }) => color || COLORS.text.default};

  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    opacity: 0.7;
  }
`

export const ListTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  margin-bottom: 20px;
`

export const ListTitle = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.hint};
`

export const ClearButton = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: ${COLORS.primary.medium};
  }
`

export const List = styled.div`
  overflow: auto;
  height: 300px;
  padding: 0px 24px;
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
`

export const RemoveButton = styled(TrashIcon)`
  color: ${COLORS.text.hint};

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: ${COLORS.primary.medium};
  }
`
