import { ReactComponent as PlayIcon } from 'assets/icons/play-small.svg'
import { ReactComponent as PlayingIcon } from 'assets/icons/playing.svg'
import { Flex } from 'components/common/Flex'
import dayjs from 'dayjs'
import { deleteOneBookmark, deleteOneBookmarkVariables } from 'graphql/mutation/__generated__/deleteOneBookmark'
import { deleteOneNote, deleteOneNoteVariables } from 'graphql/mutation/__generated__/deleteOneNote'
import { updateOneBookmark, updateOneBookmarkVariables } from 'graphql/mutation/__generated__/updateOneBookmark'
import { updateOneNote, updateOneNoteVariables } from 'graphql/mutation/__generated__/updateOneNote'
import { DELETE_ONE_BOOKMARK } from 'graphql/mutation/deleteOneBookmark'
import { DELETE_ONE_NOTE } from 'graphql/mutation/deleteOneNote'
import { UPDATE_ONE_BOOKMARK } from 'graphql/mutation/updateOneBookmark'
import { UPDATE_ONE_NOTE } from 'graphql/mutation/updateOneNote'
import { getBookmarksWithPagination_getBookmarksWithPagination_items } from 'graphql/query/__generated__/getBookmarksWithPagination'
import { convertMsInSecs } from 'helpers/convertMsInSecs'
import { usePlayer } from 'hooks/usePlayer'
import { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'

import {
  ANTDModal,
  FakeLinkButton,
  HeaderRow,
  PlayButtonWrapper,
  ShiurDescription,
  ShiurImage,
  ShiurInfo,
  ShiurSeriesTitle,
  ShiurTimeCodeText,
  ShiurTimeCodeWrapper,
  ShiurTitle,
  SubmitButton,
  TextArea,
  TimeCodeSection,
  Title,
  TitleDivider,
} from './styled'

type Props = {
  open: boolean
  id?: string
  text?: string
  timecode?: number
  shiur?: getBookmarksWithPagination_getBookmarksWithPagination_items['shiur']
  isNote?: boolean
  onClose: (updated?: boolean) => void
}

function EditNoteBookmarkModal({ open, id, text, timecode, shiur, isNote, onClose }: Props) {
  const { playerRef, id: playingShiurId, isInitialized, isPlaying, progress, play, pause } = usePlayer()

  const [newText, setNewText] = useState(text)

  useEffect(() => {
    setNewText(text)
  }, [text])

  const isCurrentShiurInitialized = shiur?.shiurId === playingShiurId && isInitialized
  useEffect(() => {
    if (!isNote && isCurrentShiurInitialized && playerRef?.current) {
      playerRef.current.seekTo(Math.round(convertMsInSecs(timecode)), 'seconds')
    }
  }, [isCurrentShiurInitialized, isNote, playerRef, timecode])

  const [deleteNote] = useMutation<deleteOneNote, deleteOneNoteVariables>(DELETE_ONE_NOTE)
  const [deleteBookmark] = useMutation<deleteOneBookmark, deleteOneBookmarkVariables>(DELETE_ONE_BOOKMARK)
  const [updateOneNote, { loading: noteLoading }] = useMutation<updateOneNote, updateOneNoteVariables>(UPDATE_ONE_NOTE)
  const [updateOneBookmark, { loading: bookmarkLoading }] = useMutation<updateOneBookmark, updateOneBookmarkVariables>(
    UPDATE_ONE_BOOKMARK
  )

  const togglePlay = async () => {
    if (isPlaying && isCurrentShiurInitialized) {
      pause()
    }
    if (!isPlaying && isCurrentShiurInitialized) {
      await play()
    }
    if (!isCurrentShiurInitialized) {
      await play(shiur)
    }
  }

  const onSubmit = async () => {
    if (isNote) {
      await updateOneNote({ variables: { noteId: id, text: newText } })
    } else {
      await updateOneBookmark({
        variables: { bookmarkId: id, text: newText },
      })
    }

    onClose()
  }

  const onDelete = async () => {
    if (isNote) {
      await deleteNote({ variables: { noteId: id } })
    } else {
      await deleteBookmark({
        variables: { bookmarkId: id },
      })
    }

    onClose(true)
  }

  return (
    <ANTDModal
      open={open}
      onCancel={() => onClose()}
      width={375}
      destroyOnClose={true}
      isFooterVisible={newText && newText !== text}
      title={
        <HeaderRow>
          <Title>{isNote ? 'Note' : 'Bookmark'}</Title>
          <TitleDivider />
          <FakeLinkButton onClick={onDelete}>Delete</FakeLinkButton>
        </HeaderRow>
      }
      footer={
        newText && newText !== text ? (
          <SubmitButton type="primary" loading={noteLoading || bookmarkLoading} onClick={onSubmit}>
            {!isNote ? 'SAVE BOOKMARK' : 'SAVE NOTE'}
          </SubmitButton>
        ) : (
          <></>
        )
      }
    >
      <TimeCodeSection>
        <ShiurInfo>
          <Flex alignCenter>
            <ShiurImage src={`${process.env.REACT_APP_AWS_S3_URL}${shiur?.series?.coverUrl}`} />
            <div>
              <ShiurTitle>{shiur?.title}</ShiurTitle>

              <ShiurDescription>
                <ShiurSeriesTitle color={shiur?.series?.titleColor}>{shiur?.series?.title}</ShiurSeriesTitle>
                {`∙${shiur?.topic?.title}`}
              </ShiurDescription>
            </div>
          </Flex>
          {!isNote && (
            <ShiurTimeCodeWrapper>
              <PlayButtonWrapper onClick={togglePlay}>
                {isCurrentShiurInitialized && isPlaying ? <PlayingIcon /> : <PlayIcon />}
              </PlayButtonWrapper>
              <ShiurTimeCodeText>
                {dayjs
                  .duration(
                    isCurrentShiurInitialized
                      ? progress?.playedSeconds || convertMsInSecs(timecode)
                      : convertMsInSecs(timecode),
                    's'
                  )
                  .format('mm:ss')}
              </ShiurTimeCodeText>
            </ShiurTimeCodeWrapper>
          )}
        </ShiurInfo>
      </TimeCodeSection>
      <TextArea
        maxLength={150}
        placeholder={!isNote ? 'Leave a note on a specific portion of the Shiur...' : 'Add a note about this Shiur...'}
        value={newText}
        onChange={(e) => setNewText(e.target.value)}
      />
    </ANTDModal>
  )
}

export default EditNoteBookmarkModal
