import { ModalProps } from 'antd'

import { ANTDModal, Description, FooterButton, FooterWrapper, Title } from './styled'

type Props = ModalProps & {
  onBtnClick?: () => void
  btnText?: string
  description?: string
  disabled?: boolean
  btnLoading?: boolean
  noFooter?: boolean
}
type FooterProps = { onBtnClick: () => void; btnText: string; disabled?: boolean; btnLoading?: boolean }

function Footer({ onBtnClick, btnText, disabled, btnLoading }: FooterProps) {
  return (
    <FooterWrapper>
      <FooterButton type="primary" disabled={disabled} loading={btnLoading} onClick={onBtnClick}>
        {btnText}
      </FooterButton>
    </FooterWrapper>
  )
}

function ActionModal({
  children,
  onBtnClick,
  btnText,
  title,
  description,
  disabled,
  noFooter,
  btnLoading,
  ...props
}: Props) {
  return (
    <ANTDModal
      footer={
        noFooter ? null : (
          <Footer btnText={btnText} onBtnClick={onBtnClick} disabled={disabled} btnLoading={btnLoading} />
        )
      }
      title={
        <>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </>
      }
      {...props}
    >
      {children}
    </ANTDModal>
  )
}

export default ActionModal
