import { Flex } from 'components/common/Flex'
import { useAuth } from 'hooks/useAuth'

import { Text, Title } from './styled'

function Bio() {
  const { user } = useAuth()
  return (
    <Flex flexOne column>
      <Title>Bio</Title>
      <Text>{user?.bio}</Text>
    </Flex>
  )
}

export default Bio
