import authSlideBackground from 'assets/img/auth-slider-background.png'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const SwiperStyled = styled(Swiper)`
  overflow: hidden;
  width: 50%;
  height: 100%;

  background-color: ${COLORS.primary.medium};
  background-image: url(${authSlideBackground});
  background-position: right bottom;
  background-repeat: no-repeat;

  border-radius: 12px;

  @media ${device.laptopL} {
    display: none;
  }

  .swiper-pagination {
    bottom: 10% !important;
  }

  .swiper-pagination-bullet {
    background: #ffffff;
  }
`

export const SlideContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const Image = styled.img`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);

  width: 80%;
  height: 50%;

  object-fit: contain;
  object-position: center;
`

export const TextWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);

  width: 85%;
  max-width: 600px;
`

export const Title = styled.h1`
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  color: #ffffff;

  margin-bottom: 24px;
`

export const Subtitle = styled.h2`
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
  color: #d9ecf6;
`
