import { gql } from '@apollo/client'

export const GET_ALL_TOPICS = gql`
  query getAllTopics($take: Int!, $page: Int!, $title: String) {
    getAllTopics(take: $take, page: $page, title: $title) {
      items {
        topicId
        title
        shiurimCount
      }
      meta {
        total
        next
      }
    }
  }
`
