import SponsorApp from 'components/business/Main/Donate/SponsorApp'
import SponsorShiur from 'components/business/Main/Donate/SponsorShiur'
import Header from 'components/ui/Header'
import { ReactComponent as DonateIcon } from 'components/ui/Sidebar/icons/active/donate.svg'

import { Wrapper } from '../styled'
import { Container } from './styled'

function Donate() {
  return (
    <>
      <Header icon={<DonateIcon />} title="Donate" />
      <Wrapper>
        <Container>
          <SponsorApp />
          <SponsorShiur />
        </Container>
      </Wrapper>
    </>
  )
}

export default Donate
