import { Flex } from 'components/common/Flex'
import { getAnnouncementsWithPagination_getAnnouncementsWithPagination_items } from 'graphql/query/__generated__/getAnnouncementsWithPagination'

import { DateText, LabelText, LabelWrapper, Text, Title, Wrapper } from './styled'

type Props = getAnnouncementsWithPagination_getAnnouncementsWithPagination_items & { showLabel?: boolean }

function PublicLectureCard({ title, text, publicationDate, showLabel }: Props) {
  return (
    <Wrapper borderless={showLabel}>
      <Flex justifyBetween alignCenter>
        {showLabel && (
          <LabelWrapper>
            <LabelText>Public lecture</LabelText>
          </LabelWrapper>
        )}
        <DateText>
          {new Date(publicationDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </DateText>
      </Flex>

      <Title>{title}</Title>
      <Text>{text}</Text>
    </Wrapper>
  )
}

export default PublicLectureCard
