import { Layout, Popover, PopoverProps } from 'antd'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Search from '../FormElements/Search'

const MyPopover = ({ className, ...props }: PopoverProps) => <Popover {...props} overlayClassName={className} />

export const Wrapper = styled(Layout.Header)<{ isSearch?: boolean }>`
  height: 88px;
  padding: 28px 28px 20px 28px;
  line-height: inherit;

  @media ${device.laptopL} {
    height: ${({ isSearch }) => (isSearch ? '115px' : '60px')};
    padding: 14px 14px 10px 14px;
  }

  background-color: #ffffff;
`

export const IconWrapper = styled.div<{ clickable?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  margin-right: 12px;

  background: #f4fbff;
  border: 0.5px solid #d9ecf6;
  border-radius: 12px;

  ${({ clickable }) => clickable && 'cursor: pointer'};

  @media ${device.laptopL} {
    width: 35px;
    height: 35px;
    margin-right: 6px;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  color: #0f1653;

  white-space: nowrap;

  @media ${device.laptopL} {
    font-size: 22px;
  }
`

export const TitleDivider = styled.div`
  width: 1px;
  height: 30px;
  margin: 0 12px;
  background: #d9ecf6;

  border-radius: 1px;
`

export const ViewAllLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${COLORS.primary.medium};
`

export const SearchStyled = styled(Search)<{ isMobile?: boolean }>`
  margin-left: ${({ isMobile }) => !isMobile && '40px'};
  margin-top: ${({ isMobile }) => isMobile && '10px'};

  @media ${device.laptopL} {
    max-width: 100%;
  }
`

export const Divider = styled.div`
  height: 24px;
  width: 1px;
  margin: 0 20px;

  background-color: #d9ecf6;
  border-radius: 1px;

  @media ${device.laptopL} {
    margin: 0 10px;
  }
`

export const ClearWrapper = styled.div`
  display: flex;
  align-items: center;

  color: ${COLORS.primary.medium};

  cursor: pointer;
`

export const ClearText = styled.p`
  margin-left: 4px;

  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: ${COLORS.primary.medium};
`

export const NotificationWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 12px;

  background: #f4fbff;
  border: 0.5px solid #d9ecf6;
  border-radius: 50%;

  cursor: pointer;

  @media ${device.laptopL} {
    margin-right: 6px;
  }
`

export const NotificationsPopover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0;
    border: 1px solid #d9ecf6;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
`

export const MobileNavigationPopover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    overflow: auto;
    width: 220px;
    height: ${window.innerHeight < 960 ? 'calc(100dvh - 60px)' : '100%'};
    padding: 0;
    border: 1px solid #ebebeb;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
`

export const AvatarPopover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    width: 240px;
    padding: 0;
    border: 1px solid #ebebeb;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
`

export const AvatarPopoverItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;

  transition: all 0.2s;

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }

  &:hover {
    color: ${COLORS.primary.medium};
  }

  cursor: pointer;
`

export const AvatarPopoverText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  margin-left: 10px;
`

export const ProfileWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 4px;

  color: ${COLORS.primary.medium};

  background: #f4fbff;
  border: 0.5px solid #d9ecf6;
  border-radius: 40px;

  cursor: pointer;

  @media ${device.laptopL} {
    padding: 4px 6px 4px 4px;
  }
`

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;

  object-fit: cover;
  object-position: center;

  border: 1px solid #ffffff;
  border-radius: 50%;

  @media ${device.laptopL} {
    margin-right: 6px;
  }
`

export const AvatarText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: #0082cd;
`
