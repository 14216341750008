import { gql } from '@apollo/client'

export const UPDATE_ONE_BOOKMARK = gql`
  mutation updateOneBookmark($bookmarkId: String!, $text: String!) {
    updateOneBookmark(bookmarkId: $bookmarkId, text: $text) {
      bookmarkId
      text
    }
  }
`
