import { ReactComponent as Icon } from 'assets/icons/message.svg'
import AlertModal from 'components/ui/Modal/AlertModal'
import { useGetRedirectFunctionToMarketIfMobile } from 'hooks/useGetRedirectFunctionToMarketIfMobile'

type Props = { open: boolean; onClose: (noRepeat: boolean) => void }

function GetMobileAppModal({ open, onClose }: Props) {
  const redirect = useGetRedirectFunctionToMarketIfMobile()

  const onDownloadApp = () => {
    redirect()

    onClose(false)
  }

  return (
    <AlertModal
      open={open}
      onCancel={() => onClose(false)}
      primaryBtnText={'DOWNLOAD'}
      onPrimaryBtnClick={onDownloadApp}
      secondaryBtnText={"DON'T SHOW AGAIN"}
      onSecondaryBtnClick={() => onClose(true)}
      icon={<Icon />}
      title="Get a Better UX with Mobile App"
      closable
    />
  )
}

export default GetMobileAppModal
