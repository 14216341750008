import { Country, Gender } from '__generated__/globalTypes'
import { ReactComponent as EditIcon } from 'assets/icons/edit-primary.svg'
import ChangeLocationModal from 'components/business/Main/Modals/ChangeLocationModal'
import EditBioModal from 'components/business/Main/Modals/EditBioModal'
import EditNameModal from 'components/business/Main/Modals/EditNameModal'
import { formatText } from 'helpers/formatText'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'

import { ActionWrapper, EditButton, Item, ItemContent, ItemLabel, ItemValue, Title, Wrapper } from '../styled'

function PersonalInformation() {
  const { user } = useAuth()
  const { username, gender, country, city, bio } = user || {}

  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false)
  const [isChangeLocationModalOpen, setIsChangeLocationModalOpen] = useState(false)
  const [isEditBioModalOpen, setIsEditBioModalOpen] = useState(false)

  const onOpenEditNameModal = () => setIsEditNameModalOpen(true)
  const onCloseEditNameModal = () => setIsEditNameModalOpen(false)

  const onOpenChangeLocationModal = () => setIsChangeLocationModalOpen(true)
  const onCloseChangeLocationModal = () => setIsChangeLocationModalOpen(false)

  const onOpenEditBioModal = () => setIsEditBioModalOpen(true)
  const onCloseEditBioModal = () => setIsEditBioModalOpen(false)

  return (
    <>
      <Wrapper>
        <Title>Personal Information</Title>
        <ul>
          <Item>
            <ItemContent>
              <ItemLabel>Full Name</ItemLabel>
              <ItemValue>{`${gender === Gender.MALE ? 'Mr.' : 'Mrs.'} ${username}`}</ItemValue>
            </ItemContent>
            <ActionWrapper>
              <EditButton onClick={onOpenEditNameModal}>
                <EditIcon />
              </EditButton>
            </ActionWrapper>
          </Item>
          <Item>
            <ItemContent>
              <ItemLabel>Location</ItemLabel>
              <ItemValue>{`${country === Country.USA || country === Country.UK ? country : formatText(country)} ${
                city ? `- ${city}` : ''
              }`}</ItemValue>
            </ItemContent>
            <ActionWrapper>
              <EditButton onClick={onOpenChangeLocationModal}>
                <EditIcon />
              </EditButton>
            </ActionWrapper>
          </Item>
          <Item>
            <ItemContent>
              <ItemLabel>Bio</ItemLabel>
              <ItemValue bio>{bio}</ItemValue>
            </ItemContent>
            <ActionWrapper>
              <EditButton onClick={onOpenEditBioModal}>
                <EditIcon />
              </EditButton>
            </ActionWrapper>
          </Item>
        </ul>
      </Wrapper>
      {isEditNameModalOpen && <EditNameModal open={isEditNameModalOpen} onClose={onCloseEditNameModal} />}
      {isChangeLocationModalOpen && (
        <ChangeLocationModal open={isChangeLocationModalOpen} onClose={onCloseChangeLocationModal} />
      )}
      {isEditBioModalOpen && <EditBioModal open={isEditBioModalOpen} onClose={onCloseEditBioModal} />}
    </>
  )
}

export default PersonalInformation
