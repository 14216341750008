import { Form, Input } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import ActionModal from 'components/ui/Modal/ActionModal'
import { useAuth } from 'hooks/useAuth'
import { ErrorText } from 'pages/Auth/styled'
import { useState } from 'react'

import { FormItem } from '../styled'

type Props = { open: boolean; email: string; onClose: () => void; nextStep: (verificationCode: string) => void }

function VerificationCodeInput({ open, email, onClose, nextStep }: Props) {
  const { user, updateUserInfo } = useAuth()
  const { confirmChangeEmail } = useAuth()
  const [form] = useForm()
  const verificationCode = useWatch('verificationCode', form)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const onBtnClick = async () => {
    setIsLoading(true)
    setError('')
    try {
      await confirmChangeEmail(verificationCode)
      await updateUserInfo({ username: user.username, gender: user.gender, email })
      nextStep(verificationCode)
    } catch (e) {
      setError(e.message)
    }
    setIsLoading(false)
  }

  const isBtnDisabled = !verificationCode

  return (
    <ActionModal
      open={open}
      title="Change email"
      width={400}
      btnText="SEND"
      onBtnClick={onBtnClick}
      onCancel={onClose}
      btnLoading={isLoading}
      disabled={isBtnDisabled}
    >
      <Form form={form} layout="vertical" requiredMark={false} onValuesChange={() => setError('')}>
        <FormItem
          name="verificationCode"
          label="Verification Code"
          validateTrigger={form.getFieldError('verificationCode').length ? 'onChange' : 'onBlur'}
          required
        >
          <Input placeholder="Enter your verification code" status={error ? 'error' : undefined} />
        </FormItem>
        {error && <ErrorText>{error}</ErrorText>}
      </Form>
    </ActionModal>
  )
}

export default VerificationCodeInput
