import { useAuth } from 'hooks/useAuth'
import { PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

function AuthorizedRoute({ children }: PropsWithChildren) {
  const navigate = useNavigate()
  const { user, isLoading } = useAuth()

  useEffect(() => {
    if (!user && !isLoading) navigate(ROUTE_PATH.HOME)
  }, [isLoading, navigate, user])

  return <>{children}</>
}

export default AuthorizedRoute
