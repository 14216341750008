import { SponsorshipPeriod, SponsorshipType } from '__generated__/globalTypes'
import { TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { getSponsorshipHistory_getSponsorshipHistory_items } from 'graphql/query/__generated__/getSponsorshipHistory'
import { getDateWithoutTimezone } from 'helpers/getDateWithoutTimezone'
import { useNavigate } from 'react-router-dom'

import { getDateValue } from '../Donate/getDateValue'
import Comment from './Comment'
import { SeriesText, StatusText, StyledTable } from './styled'

type Props = {
  pagination: TablePaginationConfig
  items: getSponsorshipHistory_getSponsorshipHistory_items[]
  loading: boolean
  onChangePagination: (pagination: TablePaginationConfig) => void
}

export const sponsorshipTypeToLabel = {
  [SponsorshipType.path4life]: 'Sponsor Path4Life',
  [SponsorshipType.shiur]: 'Sponsor a Shiur',
}

export const periodTypeToLabel = {
  [SponsorshipPeriod.day]: 'Day',
  [SponsorshipPeriod.weekly]: 'Week',
  [SponsorshipPeriod.monthly]: 'Month',
  [SponsorshipPeriod.custom]: 'Custom',
}

function DonationHistoryTable({ pagination, items, loading, onChangePagination }: Props) {
  const navigate = useNavigate()

  const columns: ColumnsType<getSponsorshipHistory_getSponsorshipHistory_items> = [
    {
      title: 'Date',
      key: 'createdAt',
      render: (_, { createdAt }) =>
        getDateWithoutTimezone(createdAt).toDate().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
    },
    {
      title: 'Sponsorship',
      key: 'type',
      render: (_, { type }) => sponsorshipTypeToLabel[type],
    },
    {
      title: 'Series',
      key: 'series',
      render: (_, { series }) =>
        series ? (
          <SeriesText onClick={() => navigate(`/series/${series.seriesId}`)} color={series.titleColor}>
            {series.title}
          </SeriesText>
        ) : (
          '—'
        ),
    },
    {
      title: 'Period',
      key: 'period',
      render: (_, { period, startDate, endDate }) => (
        <>
          {periodTypeToLabel[period]}
          <br />
          {getDateValue(period, [dayjs(startDate), dayjs(endDate)])}
        </>
      ),
    },
    {
      title: 'In memory of',
      dataIndex: 'inMemoryOf',
      key: 'inMemoryOf',
      render: (_, { inMemoryOf }) => inMemoryOf || '—',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, { amount }) => <b>{`$${amount}`}</b>,
    },
    {
      title: 'Your comment',
      dataIndex: 'comment',
      key: 'comment',
      width: 250,
      render: (_, { comment }) => (comment ? <Comment text={comment} /> : '—'),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      width: 125,
      render: (_, { isActive }) => <StatusText success={isActive}>{isActive ? 'Success' : 'Processing'}</StatusText>,
    },
  ]
  return (
    <div id="table-wrapper">
      <StyledTable
        pagination={{ ...pagination, hideOnSinglePage: true }}
        onChange={onChangePagination}
        columns={columns}
        dataSource={items}
        loading={loading}
        scroll={{ x: true }}
      />
    </div>
  )
}

export default DonationHistoryTable
