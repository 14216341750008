import { AnnouncementType } from '__generated__/globalTypes'
import {
  getAnnouncementsWithPagination,
  getAnnouncementsWithPaginationVariables,
} from 'graphql/query/__generated__/getAnnouncementsWithPagination'
import { GET_ANNOUNCEMENTS_WITH_PAGINATION } from 'graphql/query/getAnnouncementsWithPagination'
import { useState } from 'react'

import { useQuery } from '@apollo/client'

import LiveEventsList from '../../Lists/LiveEventsList'

const initialPagination = {
  take: 50,
  page: 1,
}

function LiveEventsTab() {
  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore } = useQuery<
    getAnnouncementsWithPagination,
    getAnnouncementsWithPaginationVariables
  >(GET_ANNOUNCEMENTS_WITH_PAGINATION, {
    variables: { ...initialPagination, type: AnnouncementType.EVENT },
  })

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getAnnouncementsWithPagination: {
            ...previousQueryResult.getAnnouncementsWithPagination,
            ...fetchMoreResult.getAnnouncementsWithPagination,
            items: [
              ...previousQueryResult.getAnnouncementsWithPagination.items,
              ...fetchMoreResult.getAnnouncementsWithPagination.items,
            ],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  return (
    <LiveEventsList
      items={data?.getAnnouncementsWithPagination?.items}
      next={data?.getAnnouncementsWithPagination?.meta?.next}
      onFetchMore={onFetchMore}
      loading={loading}
      loadingNext={loadingNext}
    />
  )
}

export default LiveEventsTab
