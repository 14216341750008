import {
  getRecommendedShiurim,
  getRecommendedShiurimVariables,
} from 'graphql/query/__generated__/getRecommendedShiurim'
import { GET_RECOMMENDED_SHIURIM } from 'graphql/query/getRecommendedShiurim'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import ShiurimList from '../../Home/ShiurimList'
import { Title, Wrapper } from './styled'

type Props = { openRegisterToContinueModal: () => void }

const initialPagination = {
  take: 10,
  page: 1,
}

function PopularShiurim({ openRegisterToContinueModal }: Props) {
  const { id } = useParams()

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore } = useQuery<getRecommendedShiurim, getRecommendedShiurimVariables>(
    GET_RECOMMENDED_SHIURIM,
    {
      variables: { seriesId: id, ...initialPagination },
    }
  )

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1, seriesId: id },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getRecommendedShiurim: {
            ...previousQueryResult.getRecommendedShiurim,
            ...fetchMoreResult.getRecommendedShiurim,
            items: [...previousQueryResult.getRecommendedShiurim.items, ...fetchMoreResult.getRecommendedShiurim.items],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  return (
    <Wrapper loading={loading}>
      <Title>Popular Shiurim</Title>
      <ShiurimList
        items={data?.getRecommendedShiurim?.items}
        next={data?.getRecommendedShiurim?.meta?.next}
        loading={loading}
        loadingNext={loadingNext}
        onFetchMore={onFetchMore}
        openRegisterToContinueModal={openRegisterToContinueModal}
      />
    </Wrapper>
  )
}

export default PopularShiurim
