import VerificationCodeSentModal from 'components/business/Auth/Modals/VerificationCodeSentModal'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { Auth } from '@aws-amplify/auth'

import Completed from './Completed'
import PasswordsInput from './PasswordsInput'

type Props = { open: boolean; onClose: () => void }

enum STEPS {
  PASSWORDS_INPUT = 'passwords_input',
  EMAIL_SENT = 'email_sent',
  VERIFICATION_CODE_INPUT = 'verification_code_input',
  COMPLETED = 'completed',
}

function ChangePasswordModal(props: Props) {
  const navigate = useNavigate()
  const [step, setStep] = useState<STEPS>(STEPS.PASSWORDS_INPUT)

  const showVerificationCodeSent = () => {
    setStep(STEPS.EMAIL_SENT)
  }

  const showCompleted = () => {
    setStep(STEPS.COMPLETED)
  }

  const showForgotPassword = async () => {
    const user = await Auth.currentAuthenticatedUser()
    navigate(ROUTE_PATH.FORGOT_PASSWORD, { state: { email: user.username } })
  }

  if (step === STEPS.PASSWORDS_INPUT)
    return <PasswordsInput nextStep={showCompleted} showVerificationCodeSent={showVerificationCodeSent} {...props} />

  if (step === STEPS.EMAIL_SENT) return <VerificationCodeSentModal {...props} onComplete={showForgotPassword} />

  if (step === STEPS.COMPLETED) return <Completed {...props} onComplete={props.onClose} />
}

export default ChangePasswordModal
