import { Spin } from 'antd'
import {
  getSeriesFollowedByUser,
  getSeriesFollowedByUserVariables,
} from 'graphql/query/__generated__/getSeriesFollowedByUser'
import { GET_SERIES_FOLLOWED_BY_USER } from 'graphql/query/getSeriesFollowedByUser'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'
import { Swiper } from 'swiper/react'

import { useQuery } from '@apollo/client'

import { EmptyContainer, EmptyText } from '../styled'
import { Heading, HeadingRow, Image, ListWrapper, Slide, ViewAll, Wrapper } from './styled'

function FollowedSeriesSlider() {
  const navigate = useNavigate()

  const { data, loading } = useQuery<getSeriesFollowedByUser, getSeriesFollowedByUserVariables>(
    GET_SERIES_FOLLOWED_BY_USER,
    {
      variables: {
        take: 30,
        page: 1,
      },
    }
  )

  const onViewAll = () => navigate(ROUTE_PATH.FOLLOWED_SERIES)
  return (
    <Wrapper>
      <HeadingRow>
        <Heading>FOLLOWED SERIES</Heading>
        <ViewAll onClick={onViewAll}>View all</ViewAll>
      </HeadingRow>
      <ListWrapper>
        {loading ? (
          <EmptyContainer>
            <Spin />
          </EmptyContainer>
        ) : data?.getSeriesFollowedByUser?.items?.length ? (
          <Swiper slidesPerView="auto" spaceBetween={14}>
            {data?.getSeriesFollowedByUser?.items?.map((el) => (
              <Slide key={el.seriesId}>
                <Image
                  onClick={() => navigate(`/series/${el.seriesId}`)}
                  src={`${process.env.REACT_APP_AWS_S3_URL}${el.coverUrl}`}
                />
              </Slide>
            ))}
          </Swiper>
        ) : (
          <EmptyContainer>
            <EmptyText>No following series</EmptyText>
          </EmptyContainer>
        )}
      </ListWrapper>
    </Wrapper>
  )
}

export default FollowedSeriesSlider
