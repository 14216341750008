import { SponsorshipPeriod } from '__generated__/globalTypes'
import dayjs from 'dayjs'

import { donateTypeToPriceApp, donateTypeToPriceShiur } from './DonateTypeSelect'
import { calculateAmountOfDays } from './calculateAmountOfDays'

export const getDaysForCustom = (amount: number, isApp: boolean) => {
  const donateTypeToPrice = isApp ? donateTypeToPriceApp : donateTypeToPriceShiur

  if (amount < donateTypeToPrice[SponsorshipPeriod.day]) return [dayjs().startOf('D'), dayjs().endOf('D')]

  const startDate = dayjs().add(1, 'day').startOf('D')
  const endDate = dayjs()
    .add(calculateAmountOfDays(amount, isApp) + 1, 'day')
    .startOf('D')

  return [startDate, endDate]
}
