import { Button } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

import Modal from '../index'

export const ANTDModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
  }

  .ant-modal-header {
    text-align: center;
  }

  .ant-modal-footer {
    display: flex;
    margin-top: 32px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 28px;
`

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;

  color: ${COLORS.primary.dark};
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;

  color: ${COLORS.text.hint};

  margin-top: 12px;
`

export const PrimaryButton = styled(Button)`
  width: 100%;
`

export const SecondaryButton = styled(Button)`
  width: 100%;
  margin-right: 10px;

  border: 1px solid transparent;
  color: ${COLORS.primary.medium};
  background: #eaf7fe;
`
