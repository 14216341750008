import { COLORS } from 'constants/colors'
import styled, { css } from 'styled-components'
import { SwiperSlide as _SwiperSlide } from 'swiper/react'

export const CalendarWrapper = styled.div`
  margin-top: 20px;

  border-bottom: 1px solid #ebebeb;
`

export const SwiperSlide = styled(_SwiperSlide)`
  display: flex;
  justify-content: center;
`

export const WeekDay = styled.p`
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: #696b7b;

  margin-bottom: 8px;
`

export const Day = styled.p`
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
`

export const ItemWrapper = styled.div<{ selected?: boolean; today?: boolean }>`
  position: relative;
  height: 62px;
  width: 80px;

  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      &::after {
        position: absolute;
        bottom: 0px;
        left: 0px;
        content: '';
        width: 100%;
        height: 4px;
        background: #0082cd;
        border-radius: 4px 4px 0px 0px;
      }

      ${WeekDay},${Day} {
        color: ${COLORS.primary.medium};
      }
    `}

  ${({ today }) =>
    today &&
    css`
      &::before {
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);
        width: 4px;
        height: 4px;
        content: '';

        background: #0082cd;
        border-radius: 50%;
      }
    `}
`
