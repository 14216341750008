import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: auto;
  display: flex;
  align-items: center;

  margin-bottom: 40px;
`

export const Item = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: 12px 18px;
  margin-right: 4px;

  border-radius: 40px;
  background-color: ${({ selected }) => (selected ? COLORS.primary.medium : 'transparent')};
  color: ${({ selected }) => (selected ? '#ffffff' : COLORS.text.default)};

  cursor: pointer;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 0.28px;

  white-space: nowrap;
`
