import { gql } from '@apollo/client'

export const GET_APP_SPONSORS = gql`
  query getAppSponsors {
    getAppSponsors {
      day {
        sponsorshipId
        isAnonymous
        fullName
        inMemoryOf
      }
      week {
        sponsorshipId
        isAnonymous
        fullName
        inMemoryOf
      }
      month {
        sponsorshipId
        isAnonymous
        fullName
        inMemoryOf
      }
    }
  }
`
