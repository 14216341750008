import styled from 'styled-components'

export const Wrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 18px;
    font-weight: 400;
  }

  p {
    margin: 0 0 20px;
  }

  ul,
  li {
    margin: initial;
    padding: inherit;
    list-style: initial;
  }

  ul {
    margin-bottom: 20px;
  }

  a {
    color: #0082cd;
  }

  strong {
    font-weight: 600;
  }
`

export const Page = styled.div`
  padding: 25px;
`

export const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    & {
      width: 750px;
    }
  }

  @media (min-width: 992px) {
    & {
      width: 970px;
    }
  }

  @media (min-width: 1200px) {
    & {
      width: 1170px;
    }
  }
`

export const Row = styled.div`
  margin-left: -15px;
  margin-right: -15px;
`

export const Content = styled.div`
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 992px) {
    & {
      float: left;
      width: 100%;
    }
  }
`
