import { Button } from 'antd'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

export const ButtonsWrapper = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
`

export const Divider = styled.div`
  width: 1px;
  height: 20px;
  margin: 0 12px;
  background: #d9ecf6;

  border-radius: 1px;
`

export const ResetButton = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: ${COLORS.primary.medium};

  white-space: nowrap;

  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    color: #69b1ff;
  }
`

export const FilterButton = styled(Button)<{ isActive?: boolean }>`
  height: 42px;
  display: flex;
  align-items: center;
  color: ${COLORS.text.default};

  svg {
    margin-right: 4px;

    @media ${device.laptopL} {
      margin-right: 0px;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: #ffffff;
      background-color: ${COLORS.primary.dark};
      border-color: ${COLORS.primary.dark};

      &:hover {
        color: #ffffff !important;
        background-color: ${COLORS.primary.dark};
        border-color: ${COLORS.primary.dark} !important;
      }
    `}
`
