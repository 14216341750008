/* eslint-disable eqeqeq */
import ShiurimCard from 'components/business/Main/Cards/ShiurimCard'
import { EmptyContainer, EmptyText } from 'components/business/Main/Home/styled'
import Loader from 'components/ui/Loader'
import AlertModal from 'components/ui/Modal/AlertModal'
import { addShiurToFavorite, addShiurToFavoriteVariables } from 'graphql/mutation/__generated__/addShiurToFavorite'
import {
  removeShiurFromFavorite,
  removeShiurFromFavoriteVariables,
} from 'graphql/mutation/__generated__/removeShiurFromFavorite'
import { ADD_SHIUR_TO_FAVORITE } from 'graphql/mutation/addShiurToFavorite'
import { REMOVE_SHIUR_FROM_FAVORITE } from 'graphql/mutation/removeShiurFromFavorite'
import { getAllShiurims_getAllShiurims } from 'graphql/query/__generated__/getAllShiurims'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useMutation } from '@apollo/client'

import { Title, TitleDivider, TitleRow, ViewAllLink } from '../styled'
import { List } from './styled'

type Props = { data?: getAllShiurims_getAllShiurims; showCount: boolean; loading: boolean }

function Shiurim({ data, showCount, loading }: Props) {
  const navigate = useNavigate()

  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)

  const [addShiurToFavorite, { loading: addShiurToFavoriteLoading }] = useMutation<
    addShiurToFavorite,
    addShiurToFavoriteVariables
  >(ADD_SHIUR_TO_FAVORITE)
  const [removeShiurFromFavorite, { loading: removeShiurFromFavoriteLoading }] = useMutation<
    removeShiurFromFavorite,
    removeShiurFromFavoriteVariables
  >(REMOVE_SHIUR_FROM_FAVORITE)

  const onToggleFavorite = async (shiurId: string, favorite: boolean) => {
    if (addShiurToFavoriteLoading || removeShiurFromFavoriteLoading) return

    if (favorite) {
      await removeShiurFromFavorite({ variables: { shiurId } })
    } else {
      await addShiurToFavorite({ variables: { shiurId } })
    }
  }

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)

  const isItems = !loading && !!data?.items?.length

  return (
    <>
      <div>
        <TitleRow>
          <Title>Shiurim {data?.meta?.total != null && showCount ? `・ ${data.meta.total}` : ''}</Title>
          <TitleDivider />
          <ViewAllLink to={ROUTE_PATH.SHIURIM}>View all</ViewAllLink>
        </TitleRow>
        <List grid={isItems}>
          {loading ? (
            <Loader />
          ) : !data?.items?.length ? (
            <EmptyContainer>
              <EmptyText>No shiurim</EmptyText>
            </EmptyContainer>
          ) : (
            data.items.map((el, i) => (
              <ShiurimCard
                openRegisterToContinueModal={openRegisterToContinueModal}
                onToggleFavorite={onToggleFavorite}
                key={el.shiurId}
                index={i}
                {...el}
              />
            ))
          )}
        </List>
      </div>
      <AlertModal
        open={isRegisterToContinueModalOpen}
        onCancel={closeRegisterToContinueModal}
        onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
        onSecondaryBtnClick={closeRegisterToContinueModal}
        title="Register to Continue"
        description="To unlock all features you need to create an account"
        primaryBtnText="REGISTER"
        secondaryBtnText="NOT NOW"
      />
    </>
  )
}

export default Shiurim
