import { message } from 'antd'
import { OPTIONS } from 'components/business/Main/Modals/ShareShiurimModal'
import { ShareSaved } from 'components/business/Main/Player/icons'
import { SuccessMessageTitle } from 'components/business/Main/Player/styled'
import { useState } from 'react'

import { useNoteBookmarkModal } from './useNoteBookmarkModal'

export const configureMessagePlacement = () => {
  message.config({
    maxCount: 1,
    top: window.innerHeight - 170,
    getContainer: () => document.querySelector('#root'),
  })
}

export const usePlayerModals = () => {
  const { isAddNoteBookmarkModalOpen, onOpenAddNoteBookmarkModal, onCloseAddNoteBookmarkModal } = useNoteBookmarkModal()

  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isPlaybackRateModalOpen, setIsPlaybackRateModalOpen] = useState(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)

  const onOpenInfoModal = () => setIsInfoModalOpen(true)
  const onCloseInfoModal = () => setIsInfoModalOpen(false)

  const onOpenPlaybackRateModal = () => setIsPlaybackRateModalOpen(true)
  const onClosePlaybackRateModal = () => setIsPlaybackRateModalOpen(false)

  const onOpenShareModal = () => setIsShareModalOpen(true)
  const onCloseShareModal = (updated?: boolean, options?: OPTIONS) => {
    setIsShareModalOpen(false)

    if (updated) {
      configureMessagePlacement()
      message.open({
        icon: <ShareSaved />,
        content: (
          <SuccessMessageTitle>
            {options === OPTIONS.LINK
              ? 'Link successfully copied to clipboard'
              : 'You can share the file from your device using native settings'}
          </SuccessMessageTitle>
        ),
      })
    }
  }

  return {
    isRegisterToContinueModalOpen,
    openRegisterToContinueModal,
    closeRegisterToContinueModal,
    isInfoModalOpen,
    onOpenInfoModal,
    onCloseInfoModal,
    isPlaybackRateModalOpen,
    onOpenPlaybackRateModal,
    onClosePlaybackRateModal,
    isAddNoteBookmarkModalOpen,
    onOpenAddNoteBookmarkModal,
    onCloseAddNoteBookmarkModal,
    isShareModalOpen,
    onOpenShareModal,
    onCloseShareModal,
  }
}
