import BookmarksList from 'components/business/Main/Lists/BookmarksList'
import Header from 'components/ui/Header'
import { ReactComponent as BookmarksIcon } from 'components/ui/Sidebar/icons/active/bookmarks.svg'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { ChangeEvent, useState } from 'react'

import { Wrapper } from '../styled'

function Bookmarks() {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 300)

  const searchProps = {
    searchValue: search,
    onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    searchPlaceholder: 'Search bookmarks...',
  }

  return (
    <>
      <Header icon={<BookmarksIcon />} title="Bookmarks" searchProps={searchProps} />
      <Wrapper id="scrollable-wrapper">
        <BookmarksList search={debouncedSearch} />
      </Wrapper>
    </>
  )
}

export default Bookmarks
