import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;

  & > div:first-child {
    margin-right: 14px;

    @media ${device.laptop} {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  @media ${device.laptop} {
    display: block;

    margin-bottom: 20px;
  }
`
