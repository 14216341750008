import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 6px;
  }
`

export const Button = styled.div`
  overflow: hidden;
  width: 100%;

  border-radius: 8px;
  border: 0.5px solid #d9ecf6;

  cursor: pointer;
`

export const TitleWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;

  background-color: ${({ selected }) => (selected ? COLORS.primary.medium : '#eaf7fe')};
  ${({ selected }) => !selected && 'border-bottom: 0.5px solid #d9ecf6'};
`

export const Title = styled.p<{ selected: boolean }>`
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 0.28px;
  color: ${({ selected }) => (selected ? '#ffffff' : COLORS.text.default)};
`

export const ValueWrapper = styled.div<{ selected: boolean }>`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ selected }) => (selected ? '#EAF7FE' : '#ffffff')};
`

export const Value = styled.p<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? COLORS.primary.medium : COLORS.text.default)};
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
`
