import { ReactComponent as Icon } from 'assets/icons/music.svg'
import { Flex } from 'components/common/Flex'
import dayjs from 'dayjs'
import { getNotifications_getNotifications_items } from 'graphql/query/__generated__/getNotifications'
import { convertMsInSecs } from 'helpers/convertMsInSecs'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import {
  Content,
  CountLeftText,
  CountLeftWrapper,
  IconWrapper,
  ShiurimDuration,
  ShiurimImage,
  ShiurimSubtitle,
  ShiurimTitle,
  Subtitle,
  Title,
  TitleAccent,
  Wrapper,
} from './styled'

type Props = getNotifications_getNotifications_items

function NotificationCard({ shiurs, title, text, createdAt, article, announcement }: Props) {
  const navigate = useNavigate()

  const [isAllShiursShown, setIsAllShiursShown] = useState(false)

  const showAllShiurs = () => setIsAllShiursShown(true)

  const openShiur = (id: string) => navigate(`${ROUTE_PATH.SHIURIM}?id=${id}`, { replace: true })

  const openAllShiurs = () =>
    navigate(`${ROUTE_PATH.SHIURIM}?id=${shiurs.map((el) => el.shiurId).join(',')}`, { replace: true })

  const openArticle = () => navigate(`${ROUTE_PATH.KNOWLEDGE_BASE}?id=${article.articleId}`)

  const openAnnouncement = () => navigate(`${ROUTE_PATH.ANNOUNCEMENTS}?type=${announcement.type}`)

  const firstShiur = shiurs[0]

  const shiursList = isAllShiursShown ? shiurs : shiurs.slice(0, 5)
  const shiursLeft = shiurs.length - shiursList.length

  if (announcement)
    return (
      <Wrapper onClick={openAnnouncement}>
        <Flex>
          <IconWrapper>
            <Icon />
          </IconWrapper>
          <Content>
            <Title>
              <TitleAccent>{title}</TitleAccent>
            </Title>
            <Title>{text}</Title>
            <Subtitle isLast>{dayjs(createdAt).fromNow()}</Subtitle>
          </Content>
        </Flex>
      </Wrapper>
    )

  if (article)
    return (
      <Wrapper onClick={openArticle}>
        <Flex>
          <IconWrapper>
            <Icon />
          </IconWrapper>
          <Content>
            <Title>
              <TitleAccent>{title}</TitleAccent>
            </Title>
            <Title>{text}</Title>
            <Subtitle isLast>{dayjs(createdAt).fromNow()}</Subtitle>
          </Content>
        </Flex>
      </Wrapper>
    )

  if (shiurs.length)
    return (
      <Wrapper onClick={openAllShiurs}>
        <Flex>
          <IconWrapper>
            <Icon />
          </IconWrapper>
          <Content>
            <Title>
              <TitleAccent>{shiurs.length} new Shiurim</TitleAccent> are available
            </Title>
            <Subtitle>{dayjs(createdAt).fromNow()}</Subtitle>
            {shiurs.length === 1 && (
              <Flex justifyBetween alignEnd>
                <Flex alignCenter>
                  <ShiurimImage
                    src={`${process.env.REACT_APP_AWS_S3_URL}${firstShiur.series.coverUrl}`}
                    onClick={() => openShiur(firstShiur.shiurId)}
                  />
                  <div>
                    <ShiurimTitle>{firstShiur.title}</ShiurimTitle>
                    {firstShiur.subtitle && <ShiurimSubtitle>{firstShiur.subtitle}</ShiurimSubtitle>}
                  </div>
                </Flex>
                <ShiurimDuration>
                  {dayjs.duration(convertMsInSecs(firstShiur.duration), 's').format('m:ss')} min
                </ShiurimDuration>
              </Flex>
            )}
          </Content>
        </Flex>
        {shiurs.length !== 1 && (
          <Flex wrap>
            {shiursList.map((el) => (
              <ShiurimImage
                onClick={() => openShiur(el.shiurId)}
                key={el.shiurId}
                src={`${process.env.REACT_APP_AWS_S3_URL}${el.series.coverUrl}`}
              />
            ))}
            {shiursLeft > 0 && (
              <CountLeftWrapper onClick={showAllShiurs}>
                <CountLeftText>+{shiursLeft}</CountLeftText>
              </CountLeftWrapper>
            )}
          </Flex>
        )}
      </Wrapper>
    )

  return (
    <Wrapper>
      <Flex>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <Content>
          <Title>
            <TitleAccent>{title}</TitleAccent>
          </Title>
          <Title>{text}</Title>
          <Subtitle isLast>{dayjs(createdAt).fromNow()}</Subtitle>
        </Content>
      </Flex>
    </Wrapper>
  )
}

export default NotificationCard
