import { ReactComponent as RightArrow } from 'assets/icons/right-arrow-big.svg'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const YearWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 12px;
  margin-bottom: 24px;

  border-radius: 40px;
  border: 1px solid #ebebeb;
`

export const YearText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: ${COLORS.text.default};

  user-select: none;
`

export const PrevButton = styled(RightArrow)<{ disabled: boolean }>`
  transform: rotate(180deg);

  ${({ disabled }) => disabled && 'color: #ebebeb'};

  cursor: pointer;
`

export const NextButton = styled(RightArrow)<{ disabled: boolean }>`
  cursor: pointer;
`

export const MonthsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-items: center;
  align-items: center;
`

export const MonthWrapper = styled.div<{ selected: boolean; disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 16px;

  border-radius: 40px;
  border: 1px solid ${({ selected }) => (selected ? '#D9ECF6' : '#ebebeb')};
  background-color: ${({ selected, disabled }) =>
    selected ? COLORS.primary.medium : disabled ? '#ebebeb' : '#ffffff'};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const MonthText = styled.p<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? '#ffffff' : COLORS.text.default)};
  font-size: 14px;
  line-height: 1.3;

  user-select: none;
`
