export const deviceWidth = {
  mobileS: '319px',
  mobileM: '374px',
  mobileL: '424px',
  tablet: '767px',
  laptop: '1023px',
  laptopL: '1439px',
  desktop: '1919px',
  desktopL: '2559px',
}

export const device = {
  mobileS: `(max-width: ${deviceWidth.mobileS})`,
  mobileM: `(max-width: ${deviceWidth.mobileM})`,
  mobileL: `(max-width: ${deviceWidth.mobileL})`,
  tablet: `(max-width: ${deviceWidth.tablet})`,
  laptop: `(max-width: ${deviceWidth.laptop})`,
  laptopL: `(max-width: ${deviceWidth.laptopL})`,
  desktop: `(max-width: ${deviceWidth.desktop})`,
  desktopL: `(max-width: ${deviceWidth.desktopL})`,
}
