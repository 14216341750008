import SeriesList from 'components/business/Main/Lists/SeriesList'
import Header from 'components/ui/Header'
import { SeriesActive } from 'components/ui/Sidebar/icons'
import {
  getSeriesWithPagination,
  getSeriesWithPaginationVariables,
} from 'graphql/query/__generated__/getSeriesWithPagination'
import { GET_SERIES_WITH_PAGINATION } from 'graphql/query/getSeriesWithPagination'
import { useAuth } from 'hooks/useAuth'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { Wrapper } from 'pages/Main/styled'
import { ChangeEvent, useState } from 'react'

import { useQuery } from '@apollo/client'

const initialPagination = {
  take: 50,
  page: 1,
}

function Series() {
  const { user } = useAuth()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 300)

  const searchProps = {
    searchValue: search,
    onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    searchPlaceholder: 'Search series...',
  }

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore } = useQuery<getSeriesWithPagination, getSeriesWithPaginationVariables>(
    GET_SERIES_WITH_PAGINATION,
    { variables: { title: debouncedSearch, ...initialPagination } }
  )

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getSeriesWithPagination: {
            ...previousQueryResult.getSeriesWithPagination,
            ...fetchMoreResult.getSeriesWithPagination,
            items: [
              ...previousQueryResult.getSeriesWithPagination.items,
              ...fetchMoreResult.getSeriesWithPagination.items,
            ],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  return (
    <>
      <Header icon={<SeriesActive />} title="Series" searchProps={searchProps} />
      <Wrapper id="scrollable-wrapper">
        <SeriesList
          size="huge"
          showCount
          showAddBtn={user ? true : false}
          items={data?.getSeriesWithPagination?.items}
          next={data?.getSeriesWithPagination?.meta?.next}
          onFetchMore={onFetchMore}
          loading={loading}
          loadingNext={loadingNext}
        />
      </Wrapper>
    </>
  )
}

export default Series
