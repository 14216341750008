import loaderSrc from 'assets/icons/loader.gif'
import { ReactComponent as NoDataIcon } from 'assets/icons/notes-big.svg'
import Loader from 'components/ui/Loader'
import NoData from 'components/ui/NoData'
import {
  getNotesWithPagination,
  getNotesWithPaginationVariables,
  getNotesWithPagination_getNotesWithPagination_items,
} from 'graphql/query/__generated__/getNotesWithPagination'
import { GET_NOTES_WITH_PAGINATION } from 'graphql/query/getNotesWithPagination'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'

import { useQuery } from '@apollo/client'

import NoteCard from '../../Cards/NoteCard'
import EditNoteBookmarkModal from '../../Modals/EditNoteBookmarkModal'
import { LoaderWrapper, Wrapper } from './styled'

type Props = { search: string }

type Note = getNotesWithPagination_getNotesWithPagination_items

const initialPagination = {
  take: 30,
  page: 1,
}

function NotesList({ search }: Props) {
  const { user } = useAuth()

  const [page, setPage] = useState(initialPagination.page)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [openedNote, setOpenedNote] = useState<Note>()

  const { data, loading, fetchMore, refetch } = useQuery<getNotesWithPagination, getNotesWithPaginationVariables>(
    GET_NOTES_WITH_PAGINATION,
    { variables: { ...initialPagination, text: search }, fetchPolicy: 'network-only', skip: !user }
  )

  const onFetchMore = () => {
    fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getNotesWithPagination: {
            ...previousQueryResult.getNotesWithPagination,
            ...fetchMoreResult.getNotesWithPagination,
            items: [
              ...previousQueryResult.getNotesWithPagination.items,
              ...fetchMoreResult.getNotesWithPagination.items,
            ],
          },
        }
      },
    })
    setPage((prev) => prev + 1)
  }

  const openModal = (note: Note) => {
    setIsModalOpen(true)
    setOpenedNote(note)
  }

  const closeModal = async (deleted?: boolean) => {
    setIsModalOpen(false)
    setOpenedNote(undefined)

    if (deleted) {
      setPage(initialPagination.page)
      await refetch()
    }
  }

  const itemsLength = data?.getNotesWithPagination?.items?.length

  if (!itemsLength && loading) return <Loader />
  if (!itemsLength)
    return (
      <NoData
        icon={<NoDataIcon />}
        title={user ? (search ? 'Nothing found' : "You haven't added any notes yet") : 'Nothing here... yet'}
        description={
          user
            ? 'To create a note, open the lecture and add a note to it'
            : 'Create an account to add and edit notes about the lectures'
        }
      />
    )

  return (
    <>
      <Wrapper
        dataLength={itemsLength || 0}
        next={onFetchMore}
        hasMore={data?.getNotesWithPagination?.meta?.next}
        loader={null}
        scrollableTarget="scrollable-wrapper"
      >
        {data?.getNotesWithPagination?.items?.map((el) => (
          <NoteCard key={el.noteId} onClick={() => openModal(el)} {...el} />
        ))}
      </Wrapper>
      {loading && (
        <LoaderWrapper>
          <img src={loaderSrc} />
        </LoaderWrapper>
      )}
      <EditNoteBookmarkModal
        isNote
        open={isModalOpen}
        id={openedNote?.noteId}
        text={openedNote?.text}
        shiur={openedNote?.shiur}
        onClose={closeModal}
      />
    </>
  )
}

export default NotesList
