/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd'
import dayjs from 'dayjs'
import throttle from 'helpers/throttle'
import useFade from 'hooks/useFade'
import { Ref, useEffect, useRef, useState } from 'react'
import { Swiper, SwiperRef } from 'swiper/react'

import { CalendarWrapper, Day, ItemWrapper, LoaderWrapper, SwiperSlide, WeekDay } from './styled'

type Props = {
  selectedDate: dayjs.Dayjs
  onChangeDate: (date: dayjs.Dayjs) => void
}

const getPreviousDays = (sourceDay: dayjs.Dayjs, count = 30) => {
  const arr: dayjs.Dayjs[] = []
  for (let i = 0; i > -count; i--) {
    arr.push(sourceDay.add(i, 'day').startOf('D'))
  }

  return arr.reverse()
}

function SliderCalendar({ selectedDate, onChangeDate }: Props) {
  const swiperRef: Ref<SwiperRef> = useRef()
  const [isFirstMount, setIsFirstMount] = useState(true)
  const [days, setDays] = useState<dayjs.Dayjs[]>([])
  const [isLoadMoreInProgress, setIsLoadMoreInProgress] = useState(false)
  const [isVisible, show, setShow, fadeProps] = useFade(false)

  // Show/hide loader
  useEffect(() => {
    if (isLoadMoreInProgress) return setShow(true)
    setShow(false)
  }, [isLoadMoreInProgress])

  // Load and slide to date when selectedDate changed
  useEffect(() => {
    setIsFirstMount(false)
    if (isFirstMount) return

    const selectedDateIndex = days.findIndex((el) => el.isSame(selectedDate, 'date'))
    if (selectedDateIndex > 0) swiperRef.current.swiper.slideTo(selectedDateIndex - 7)

    if (selectedDateIndex === -1) {
      const fetchCount = Math.abs(selectedDate.diff(days[0], 'd')) + 14

      generatePreviousDaysAndSlideTo(fetchCount, 7, undefined, 600)
    }
  }, [selectedDate])

  // Initial load
  useEffect(() => {
    const initialSlides = getPreviousDays(dayjs().startOf('D'))
    setDays(initialSlides)
  }, [])

  const generatePreviousDaysAndSlideTo = (count?: number, slideTo?: number, slideDuration?: number, wait?: number) => {
    setIsLoadMoreInProgress(true)

    setTimeout(() => {
      const newDays = getPreviousDays(days[0].add(-1, 'day'), count)
      setDays((prev) => [...newDays, ...prev])
    }, 300) // Wait until loader overlap content to hide flicking

    setTimeout(() => {
      swiperRef.current.swiper.slideTo(slideTo, slideDuration, false)
      setIsLoadMoreInProgress(false)
    }, wait) // Wait until all items render and slide to them
  }

  // Load items when last loaded item reached
  const onReachBeginning = throttle(() => {
    const currentSlideIndex = swiperRef.current.swiper.activeIndex

    if (currentSlideIndex <= 7 && !isLoadMoreInProgress) {
      generatePreviousDaysAndSlideTo(30, 30, 0, 600)
    }
  })

  return (
    <CalendarWrapper>
      <Swiper ref={swiperRef} initialSlide={30} slidesPerView={8} spaceBetween={10} onReachBeginning={onReachBeginning}>
        {days.map((el, i) => (
          <SwiperSlide key={el.toISOString()} virtualIndex={i}>
            <ItemWrapper
              onClick={() => onChangeDate(el)}
              selected={selectedDate.isSame(el, 'date')}
              today={el.isToday()}
            >
              <WeekDay>{el.toDate().toLocaleDateString('en-US', { weekday: 'short' })}</WeekDay>
              <Day>{el.date()}</Day>
            </ItemWrapper>
          </SwiperSlide>
        ))}
      </Swiper>

      {isVisible && (
        <LoaderWrapper isVisible={isVisible && show} {...fadeProps}>
          <Spin />
        </LoaderWrapper>
      )}
    </CalendarWrapper>
  )
}

export default SliderCalendar
