import { ReactComponent as LinkIcon } from 'assets/icons/link.svg'
import { Flex } from 'components/common/Flex'
import RadioButton from 'components/ui/FormElements/RadioButton'
import { COLORS } from 'constants/colors'
import { getShiurimOrderedByDate_getShiurimOrderedByDate_items } from 'graphql/query/__generated__/getShiurimOrderedByDate'
import { DOWNLOADING_STATES, useDownloadAudio } from 'hooks/useDownloadAudio'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { CancelIcon, DownloadProgress, DownloadProgressWrapper } from '../../Player/PlayerAdditionalActions/styled'
import { DownloadIcon } from '../../Player/icons'
import {
  ANTDModal,
  Button,
  ButtonText,
  ButtonWrapper,
  Description,
  Image,
  MainInfo,
  Number,
  RadioGroup,
  SeriesTitle,
  Title,
  TitleRow,
} from './styled'

export enum OPTIONS {
  LINK = 'LINK',
  FILE = 'FILE',
}

type Props = {
  open: boolean
  onClose: (updated?: boolean, options?: OPTIONS) => void
} & getShiurimOrderedByDate_getShiurimOrderedByDate_items

function ShareShiurimModal({ open, onClose, shiurId, orderedNum, title, audioUrl, series, topic }: Props) {
  const navigate = useNavigate()
  const { downloadState, downloadingProgress, download, abortDownload } = useDownloadAudio(title, audioUrl)
  const [selectedOption, setSelectedOption] = useState(OPTIONS.LINK)

  const copyLink = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}${ROUTE_PATH.SHIURIM}?id=${shiurId}`)
    onClose(true, selectedOption)
  }

  const onDownload = async () => {
    await download()
    onClose(true, selectedOption)
  }

  const goToSeries = () => {
    navigate(`/series/${series.seriesId}`)
  }

  const isLinkSelected = selectedOption === OPTIONS.LINK
  return (
    <ANTDModal
      open={open}
      onCancel={() => onClose()}
      width={375}
      destroyOnClose={true}
      title={<Title>Share Shiurim</Title>}
      wrapProps={{ onClick: (e: Event) => e.stopPropagation() }}
    >
      <MainInfo>
        <Image src={`${process.env.REACT_APP_AWS_S3_URL}${series.coverUrl}`} />
        <div>
          <TitleRow>
            {!!orderedNum && <Number>Shiur {orderedNum}.</Number>}
            <Title>{title}</Title>
          </TitleRow>
          <Description>
            <SeriesTitle onClick={goToSeries} color={series.titleColor}>
              {series.title}
            </SeriesTitle>
            {`∙${topic.title}`}
          </Description>
        </div>
      </MainInfo>
      <RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
        <RadioButton value={OPTIONS.LINK}>Share link</RadioButton>
        <RadioButton value={OPTIONS.FILE}>Download and share</RadioButton>
      </RadioGroup>
      <Flex justifyCenter>
        <ButtonWrapper>
          <Button
            onClick={
              isLinkSelected ? copyLink : downloadState === DOWNLOADING_STATES.DOWNLOADING ? abortDownload : onDownload
            }
          >
            {isLinkSelected ? (
              <LinkIcon />
            ) : downloadState === DOWNLOADING_STATES.DOWNLOADING ? (
              <DownloadProgressWrapper>
                <DownloadProgress
                  type="circle"
                  strokeWidth={10}
                  strokeColor={COLORS.primary.medium}
                  size={24}
                  percent={downloadingProgress}
                  showInfo={false}
                />
                <CancelIcon />
              </DownloadProgressWrapper>
            ) : (
              <DownloadIcon />
            )}
          </Button>
          <ButtonText>{isLinkSelected ? 'Copy link' : 'Download'}</ButtonText>
        </ButtonWrapper>
      </Flex>
    </ANTDModal>
  )
}

export default ShareShiurimModal
