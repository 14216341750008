import Loader from 'components/ui/Loader'
import BlankModal from 'components/ui/Modal/BlankModal'
import { deviceWidth } from 'constants/responsive'
import { getSeriesSponsors, getSeriesSponsorsVariables } from 'graphql/query/__generated__/getSeriesSponsors'
import { getShiurimOrderedByDate_getShiurimOrderedByDate_items } from 'graphql/query/__generated__/getShiurimOrderedByDate'
import { GET_SERIES_SPONSORS } from 'graphql/query/getSeriesSponsors'
import { formatSubtopics } from 'helpers/formatSubtopics'
import { getDateWithoutTimezone } from 'helpers/getDateWithoutTimezone'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import {
  Content,
  Image,
  ImageContainer,
  Item,
  Label,
  List,
  Number as OrderNumber,
  SeriesValue,
  SponsorInHonor,
  SponsorLabel,
  SponsorLabelWrapper,
  SponsorName,
  SponsorNameWrapper,
  SponsorsSection,
  Subtitle,
  Title,
  TitleRow,
  Value,
  Wrapper,
} from './styled'

type Props = { open: boolean; onClose: () => void } & getShiurimOrderedByDate_getShiurimOrderedByDate_items

enum DONATION_PERIOD {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

function ShiurimInfoModal({
  open,
  onClose,
  orderedNum,
  title,
  subtitle,
  series,
  topic,
  subtopicTitles,
  publicationDate,
}: Props) {
  const navigate = useNavigate()
  const [currentSponsorType, setCurrentSponsorType] = useState(DONATION_PERIOD.DAY)
  const [currentSponsorIndex, setCurrentSponsorIndex] = useState(0)

  const { data, loading } = useQuery<getSeriesSponsors, getSeriesSponsorsVariables>(GET_SERIES_SPONSORS, {
    variables: { series_id: series.seriesId },
    fetchPolicy: 'network-only',
  })

  const currentSponsor = data?.getSeriesSponsors?.[currentSponsorType]?.[currentSponsorIndex]

  useEffect(() => {
    if (
      !data?.getSeriesSponsors ||
      (!data?.getSeriesSponsors?.day.length &&
        !data?.getSeriesSponsors?.week.length &&
        !data?.getSeriesSponsors?.month.length)
    )
      return
    if (!currentSponsor) {
      switch (currentSponsorType) {
        case DONATION_PERIOD.DAY:
          setCurrentSponsorType(DONATION_PERIOD.WEEK)
          break

        case DONATION_PERIOD.WEEK:
          setCurrentSponsorType(DONATION_PERIOD.MONTH)
          break

        default:
          setCurrentSponsorType(DONATION_PERIOD.DAY)
      }
      return
    }

    const interval = setInterval(() => {
      if (
        data.getSeriesSponsors[currentSponsorType].length === 0 ||
        currentSponsorIndex === data.getSeriesSponsors[currentSponsorType].length - 1
      ) {
        switch (currentSponsorType) {
          case DONATION_PERIOD.DAY:
            setCurrentSponsorType(DONATION_PERIOD.WEEK)
            break

          case DONATION_PERIOD.WEEK:
            setCurrentSponsorType(DONATION_PERIOD.MONTH)
            break

          default:
            setCurrentSponsorType(DONATION_PERIOD.DAY)
        }

        return setCurrentSponsorIndex(0)
      }

      setCurrentSponsorIndex((prev) => prev + 1)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [currentSponsorIndex, currentSponsorType, data?.getSeriesSponsors])

  const goToSeries = () => {
    navigate(`/series/${series.seriesId}`)
    onClose()
  }

  const isMobile = window.innerWidth < Number(deviceWidth.laptop.match(/(\d+)/)[0])
  return (
    <BlankModal open={open} onCancel={onClose} centered={isMobile} width={isMobile ? 350 : 800}>
      <Wrapper>
        <ImageContainer>
          <Image src={`${process.env.REACT_APP_AWS_S3_URL}${series.coverUrl}`} />
          <SponsorsSection>
            <SponsorLabelWrapper>
              <SponsorLabel>
                SHIUR
                <br />
                SPONSOR
              </SponsorLabel>
            </SponsorLabelWrapper>
            <SponsorNameWrapper>
              {loading ? (
                <Loader color="#FFFFFF" />
              ) : !currentSponsor ? (
                <SponsorName></SponsorName>
              ) : (
                <>
                  <SponsorName>{currentSponsor.isAnonymous ? 'Anonymous' : currentSponsor.fullName}</SponsorName>
                  {currentSponsor.inMemoryOf && <SponsorInHonor>{currentSponsor.inMemoryOf}</SponsorInHonor>}
                </>
              )}
            </SponsorNameWrapper>
          </SponsorsSection>
        </ImageContainer>
        <Content>
          <TitleRow>
            {!!orderedNum && <OrderNumber>Shiur {orderedNum}.</OrderNumber>}
            <Title>{title}</Title>
          </TitleRow>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          <List>
            <Item>
              <Label>Date published</Label>
              <Value>
                {getDateWithoutTimezone(publicationDate).toDate().toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </Value>
            </Item>
            <Item>
              <Label>Series</Label>
              <SeriesValue onClick={goToSeries} color={series.titleColor}>
                {series.title}
              </SeriesValue>
            </Item>
            <Item>
              <Label>Topic</Label>
              <Value>{topic.title}</Value>
            </Item>
            <Item>
              <Label>Sub-topics</Label>
              <Value>{formatSubtopics(subtopicTitles)}</Value>
            </Item>
          </List>
        </Content>
      </Wrapper>
    </BlankModal>
  )
}

export default ShiurimInfoModal
