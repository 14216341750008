/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-empty-function */
import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'
import { apolloClient } from 'graphql/apolloClient'
import { AuthProvider } from 'hooks/useAuth'
import { HeightProvider } from 'hooks/useHeaderHeight'
import { PlayerProvider } from 'hooks/usePlayer'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/virtual'
import 'utils/amplify'

import { ApolloProvider } from '@apollo/client'

import App from './App'
import GlobalStyle from './globalStyle'
import theme from './theme'

dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(isBetween)
dayjs.extend(duration)
dayjs.extend(weekOfYear)
dayjs.extend(weekday)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', { weekStart: 0 })

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <PlayerProvider>
          <HeightProvider>
            <ConfigProvider theme={theme}>
              <App />
            </ConfigProvider>
          </HeightProvider>
          <GlobalStyle />
        </PlayerProvider>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
)
