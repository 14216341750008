import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const EmptyContainer = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const EmptyText = styled.p`
  font-size: 16px;
  font-weight: 500;

  color: ${COLORS.text.default};
`
