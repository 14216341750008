import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'

import { Day, DayWrapper, DaysWrapper, Header, MainWrapper, MonthWrapper, Title, WeekDay, WeekDaysRow } from './styled'

type CalendarData = {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: dayjs.Dayjs
      }
    }
  }
}

type Props = {
  rangeDates: dayjs.Dayjs[]
  onChangeDate: (date: dayjs.Dayjs) => void
}

function getWeekOfMonth(date: dayjs.Dayjs) {
  const startOfMonth = date.startOf('month')
  const weekOfYear = date.week()
  const weekOfYearStart = startOfMonth.week()

  return weekOfYear - weekOfYearStart + 1
}

const getDaysUntil2020 = () => {
  const arr: dayjs.Dayjs[] = []
  for (let i = 0; dayjs().startOf('D').add(i, 'day').isAfter(dayjs().set('year', 2019).endOf('year')); i--) {
    arr.push(dayjs().add(i, 'day').startOf('D'))
  }

  return arr.reverse()
}

function RangeCalendar({ rangeDates, onChangeDate }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [calendar, setCalendar] = useState<CalendarData>()

  useEffect(() => {
    const days = getDaysUntil2020()

    const newCalendar = days.reduce<CalendarData>((acc, el) => {
      const year = el.year()
      const month = el.toDate().toLocaleDateString('en-US', { month: 'long' })
      const week = getWeekOfMonth(el)
      const weekDay = el.weekday()

      const newObj = {
        ...acc,
        [year]: {
          ...acc[year],
          [month]: {
            ...(acc?.[year]?.[month] || {}),
            [week]: {
              ...(acc?.[year]?.[month]?.[week] || {}),
              [weekDay]: el,
            },
          },
        },
      }

      return (acc = newObj)
    }, {})

    setCalendar(newCalendar)
  }, [])

  useEffect(() => {
    if (calendar) wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
  }, [calendar])

  const renderCalendar = () => {
    const renderArr = Object.keys(calendar)

    return <>{renderArr.map((el) => renderYear(el))}</>
  }

  const renderYear = (year: string) => {
    const currentYear = calendar[year]

    const renderArr = Object.keys(currentYear)
    return <>{renderArr.map((el) => renderMonth(el, year))}</>
  }

  const renderMonth = (month: string, year: string) => {
    const currentMonth = calendar[year][month]

    const renderArr = Object.keys(currentMonth)
    return (
      <MonthWrapper>
        <Title>
          {month}, {year}
        </Title>
        <DaysWrapper>{renderArr.map((el) => renderWeek(el, month, year))}</DaysWrapper>
      </MonthWrapper>
    )
  }

  const isStart = (el: dayjs.Dayjs) => {
    if (!rangeDates[1]) return
    if (rangeDates[0]?.isSame(rangeDates[1], 'date')) return

    return rangeDates[0]?.isSame(el, 'date')
  }

  const isEnd = (el: dayjs.Dayjs) => {
    if (rangeDates[0]?.isSame(rangeDates[1], 'date')) return

    return rangeDates[1]?.isSame(el, 'date')
  }

  const isBetween = (el: dayjs.Dayjs) => {
    if (!rangeDates[0] || !rangeDates[1]) return

    return el.isBetween(rangeDates[0], rangeDates[1])
  }

  const isCurrentSelected = (el: dayjs.Dayjs) => {
    if (rangeDates[1]?.isSame(el, 'date')) return true
    if (rangeDates[1]) return

    return rangeDates[0]?.isSame(el, 'date')
  }

  const renderWeek = (week: string, month: string, year: string) => {
    const currentWeek = calendar[year][month][week]
    if (!currentWeek) return

    const renderArr = []
    for (let i = 0; i < 7; i++) {
      const el = currentWeek[i]
      renderArr.push(el)
    }

    return (
      <>
        {renderArr.map((el, i) => (
          <DayWrapper
            key={el?.toISOString() || i}
            start={el?.date() ? isStart(el) : undefined}
            end={el?.date() ? isEnd(el) : undefined}
            between={el?.date() ? isBetween(el) : undefined}
            selected={el?.date() ? isCurrentSelected(el) : undefined}
            onClick={el?.date() ? () => onChangeDate(el) : undefined}
          >
            <Day>{el?.date() || ''}</Day>
          </DayWrapper>
        ))}
      </>
    )
  }

  return (
    <div>
      <Header>
        <WeekDaysRow>
          <WeekDay>S</WeekDay>
          <WeekDay>M</WeekDay>
          <WeekDay>T</WeekDay>
          <WeekDay>W</WeekDay>
          <WeekDay>T</WeekDay>
          <WeekDay>F</WeekDay>
          <WeekDay>S</WeekDay>
        </WeekDaysRow>
      </Header>
      <MainWrapper ref={wrapperRef}>{calendar && renderCalendar()}</MainWrapper>
    </div>
  )
}

export default RangeCalendar
