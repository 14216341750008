import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  min-width: 300px;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid #ebebeb;

  transition: all 0.2s;
  &:hover {
    border-color: ${COLORS.primary.light};
  }

  cursor: pointer;
`

export const Image = styled.img`
  width: 100%;
  height: 150px;

  object-fit: cover;
  object-position: center;
`

export const Content = styled.div`
  padding: 16px;
`

export const Title = styled.p`
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  color: ${COLORS.text.default};

  margin-bottom: 10px;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: #696b7b;

  margin-bottom: 16px;
`

export const DurationWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 4px 8px;
  margin-right: 12px;

  color: ${COLORS.primary.medium};

  border-radius: 16px;
  background: #eaf7fe;
`

export const DurationText = styled.p`
  font-size: 12px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  margin-left: 4px;
`

export const DateText = styled.p`
  font-size: 12px;
  line-height: 1.3;
  color: #696b7b;
`
