import FollowedSeriesSlider from 'components/business/Main/Home/FollowedSeriesSlider'
import ShiurimListByDate from 'components/business/Main/Lists/ShiurimListByDate'
import Header from 'components/ui/Header'
import {
  getShiurimFromFollowed,
  getShiurimFromFollowedVariables,
} from 'graphql/query/__generated__/getShiurimFromFollowed'
import { GET_SHIURIM_FROM_FOLLOWED } from 'graphql/query/getShiurimFromFollowed'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import { Wrapper } from '../../styled'

const initialPagination = {
  take: 30,
  page: 1,
  searchParams: {
    dateOrderType: 'DESC',
  },
}

function Following() {
  const { setQueryInfo } = useQueryInfo()

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore } = useQuery<getShiurimFromFollowed, getShiurimFromFollowedVariables>(
    GET_SHIURIM_FROM_FOLLOWED,
    { variables: initialPagination }
  )

  useEffect(() => {
    setQueryInfo({ query: GET_SHIURIM_FROM_FOLLOWED, variables: initialPagination })
  }, [setQueryInfo])

  const onFetchMore = async () => {
    setLoadingNext(true)

    const variables = { ...initialPagination, page: page + 1 }

    await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getShiurimFromFollowed: {
            ...previousQueryResult.getShiurimFromFollowed,
            ...fetchMoreResult.getShiurimFromFollowed,
            items: [
              ...previousQueryResult.getShiurimFromFollowed.items,
              ...fetchMoreResult.getShiurimFromFollowed.items,
            ],
          },
        }
      },
    })

    setQueryInfo({ query: GET_SHIURIM_FROM_FOLLOWED, variables })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  return (
    <>
      <Header isBackButton title="Following" />
      <Wrapper id="scrollable-wrapper">
        <FollowedSeriesSlider />
        <ShiurimListByDate
          items={data?.getShiurimFromFollowed?.items}
          next={data?.getShiurimFromFollowed?.meta?.next}
          loadingNext={loadingNext}
          onFetchMore={onFetchMore}
          loading={loading}
          isPlayAllTodayAvailable
        />
      </Wrapper>
    </>
  )
}

export default withQueryInfo(Following)
