import { gql } from '@apollo/client'

export const GET_SERIES_SPONSORS = gql`
  query getSeriesSponsors($series_id: String!) {
    getSeriesSponsors(series_id: $series_id) {
      day {
        sponsorshipId
        isAnonymous
        fullName
        inMemoryOf
      }
      week {
        sponsorshipId
        isAnonymous
        fullName
        inMemoryOf
      }
      month {
        sponsorshipId
        isAnonymous
        fullName
        inMemoryOf
      }
    }
  }
`
