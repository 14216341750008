import { Button } from 'antd'
import BlankModal from 'components/ui/Modal/BlankModal'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const ANTDModal = styled(BlankModal)`
  .ant-modal-header {
    display: block;
    padding-bottom: 20px;

    border-bottom: 1px solid #ebebeb;
  }

  .ant-modal-content {
    padding: 20px 24px 24px 24px;
  }

  .ant-modal-body {
    padding: 32px 0px 28px 0px;
  }

  .ant-modal-footer {
    display: block;
    margin: 0;
  }
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  text-align: center;

  color: ${COLORS.text.default};

  user-select: none;
`

export const ActionsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 32px;
`

export const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Rate = styled.p<{ isChanged: boolean }>`
  font-size: 32px;
  line-height: 1.2;
  text-align: center;
  color: ${({ isChanged }) => (isChanged ? COLORS.primary.medium : COLORS.text.default)};

  width: 80px;
  margin: 0px 45px;

  user-select: none;
`

export const PreferredOptionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PreferredOptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  border-radius: 50%;
  border: 1px solid #ebebeb;

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border: 1px solid ${COLORS.primary.medium};
  }
`

export const PreferredOption = styled.p`
  font-size: 12px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  user-select: none;
`

export const SubmitButton = styled(Button)`
  width: 180px;

  margin: 0;
  margin-right: 24px;
`

export const ResetButton = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: ${COLORS.text.default};

  margin: 0;

  cursor: pointer;
  transition: all 0.2s;
  user-select: none;

  &:hover {
    color: ${COLORS.primary.medium};
  }
`
