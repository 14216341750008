import {
  getArticlesWithPagination,
  getArticlesWithPaginationVariables,
} from 'graphql/query/__generated__/getArticlesWithPagination'
import { getOneArticle, getOneArticleVariables } from 'graphql/query/__generated__/getOneArticle'
import { GET_ARTICLES_WITH_PAGINATION } from 'graphql/query/getArticlesWithPagination'
import { GET_ONE_ARTICLE } from 'graphql/query/getOneArticle'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import KnowledgeBaseList from '../../Lists/KnowledgeBaseList'
import KnowledgeBaseDrawer from '../KnowledgeBaseDrawer'

const initialPagination = {
  take: 50,
  page: 1,
}

function KnowledgeBaseMain() {
  const [searchParams, setSearchParams] = useSearchParams()
  const sharedArticleId = searchParams.get('id')

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore } = useQuery<getArticlesWithPagination, getArticlesWithPaginationVariables>(
    GET_ARTICLES_WITH_PAGINATION,
    {
      variables: initialPagination,
    }
  )

  const { data: getOneArticleData } = useQuery<getOneArticle, getOneArticleVariables>(GET_ONE_ARTICLE, {
    variables: { id: sharedArticleId },
    skip: !sharedArticleId,
  })

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getArticlesWithPagination: {
            ...previousQueryResult.getArticlesWithPagination,
            ...fetchMoreResult.getArticlesWithPagination,
            items: [
              ...previousQueryResult.getArticlesWithPagination.items,
              ...fetchMoreResult.getArticlesWithPagination.items,
            ],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const onCloseSharedArticle = () => {
    searchParams.delete('id')
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <>
      <KnowledgeBaseList
        items={data?.getArticlesWithPagination?.items}
        next={data?.getArticlesWithPagination?.meta?.next}
        onFetchMore={onFetchMore}
        loading={loading}
        loadingNext={loadingNext}
      />
      {sharedArticleId && getOneArticleData && (
        <KnowledgeBaseDrawer {...getOneArticleData?.getOneArticle} open onClose={onCloseSharedArticle} />
      )}
    </>
  )
}

export default KnowledgeBaseMain
