import { device } from 'constants/responsive'
import styled from 'styled-components'

export const LeftContainer = styled.div`
  width: 60%;
  margin-right: 14px;

  @media ${device.laptopL} {
    width: 100%;
    margin: 0;
  }
`

export const RightContainer = styled.div`
  width: 40%;
  min-width: 485px;

  @media ${device.laptopL} {
    display: none;
  }
`
