import { Spin } from 'antd'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

type Sizes = { small?: boolean; big?: boolean; huge?: boolean }

export const Image = styled.img<Sizes>`
  width: 100%;
  aspect-ratio: 1 / 1;

  object-fit: cover;
  object-position: center;

  border: 2px solid transparent;
  border-radius: 50%;

  transition: all 0.2s;
`

export const Wrapper = styled.div<Sizes>`
  width: 100%;
  position: relative;

  &:hover ${Image} {
    border-color: ${COLORS.primary.light};
  }

  cursor: pointer;

  @media ${device.mobileL} {
    max-width: 170px;
  }
`

export const AddButton = styled.div<{ isCompleted?: boolean } & Sizes>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${({ small, big, huge }) => (small ? '70px' : big ? '55%' : huge ? '58%' : '50%')};
  right: ${({ small, big, huge }) => (small ? '0px' : big || huge ? '3%' : '2%')};
  width: 25%;
  aspect-ratio: 1 / 1;

  background: ${({ isCompleted }) => (isCompleted ? '#EAF7FE' : COLORS.primary.medium)};

  border: 2px solid #ffffff;
  border-radius: 50%;

  overflow: hidden;
  cursor: pointer;

  @media ${device.laptopL} {
    width: 25%;
  }

  @media ${device.tablet} {
    top: 55%;
  }

  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
  }

  .ant-spin-nested-loading .ant-spin-blur {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`

export const Title = styled.p<Sizes>`
  width: 100%;
  margin-top: 12px;

  font-weight: 600;
  font-size: ${({ small, big, huge }) => (small ? '14px' : big || huge ? '18px' : '16px')};
  line-height: 1.2;
  color: ${COLORS.text.default};

  text-align: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Subtitle = styled.p<Sizes>`
  width: 100%;
  margin-top: 6px;

  font-size: ${({ small, big, huge }) => (small ? '12px' : big || huge ? '16px' : '14px')};
  line-height: 1.2;
  color: ${COLORS.text.hint};

  text-align: center;
`

export const PlusIconBig = styled(PlusIcon)`
  transform: scale(1.5);
`

export const AddButtonSpin = styled(Spin)`
  width: 100%;
`
