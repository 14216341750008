import { gql } from '@apollo/client'

export const GET_USER = gql`
  query getUser {
    getUser {
      userId
      email
      username
      avatarUrl
      gender
      country
      bio
      city
      allowNotify
    }
  }
`
