import { Gender } from '__generated__/globalTypes'
import logoSrc from 'assets/img/logo.svg'
import OnboardingSlider from 'components/business/Auth/OnboardingSlider'
import Loader from 'components/ui/Loader'
import { useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { Container, Content, Logo, LogoContainer, LogoDivider, LogoLink, Wrapper } from './styled'

function Auth() {
  const { isLoading, user } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isUserSetupFinished =
    user?.username &&
    (user?.gender === Gender.MALE || user?.gender === Gender.FEMALE) &&
    user?.allowNotify !== null &&
    user?.isSeriesFollowed

  useEffect(() => {
    if (isLoading) return

    if (!user) {
      return
    }

    if (!user?.username || user?.gender === Gender.NOT_SELECTED) {
      return navigate(ROUTE_PATH.ACCOUNT_SETUP)
    }

    if (!user?.isSeriesFollowed) {
      return navigate(ROUTE_PATH.FOLLOW_SERIES)
    }

    if (user?.allowNotify === null) {
      return navigate(ROUTE_PATH.NOTIFICATIONS_SETUP)
    }

    if (isUserSetupFinished && pathname !== '/forgot-password') {
      navigate(ROUTE_PATH.HOME)
    }
  }, [isLoading, isUserSetupFinished, navigate, pathname, user])

  if ((!isUserSetupFinished && !isLoading) || (isUserSetupFinished && pathname === '/forgot-password')) {
    if (pathname !== '/registration' && pathname !== '/login') return <Outlet />

    return (
      <Wrapper>
        <Container>
          <LogoContainer>
            <Logo src={logoSrc} onClick={() => navigate(ROUTE_PATH.HOME)} />
            <LogoDivider />
            <LogoLink to={ROUTE_PATH.HOME}>Back to home</LogoLink>
          </LogoContainer>

          <Content>
            <Outlet />
          </Content>
          <OnboardingSlider />
        </Container>
      </Wrapper>
    )
  }

  return <Loader fullHeight />
}

export default Auth
