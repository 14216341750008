import styled from 'styled-components'
import { SwiperSlide as _SwiperSlide } from 'swiper/react'

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 8px;
  }
`

export const SliderButton = styled.div<{ disabled?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #f4fbff;
  border: 0.5px solid #d9ecf6;
  border-radius: 100px;

  ${({ disabled }) => !disabled && 'cursor: pointer'};

  ${({ disabled }) => disabled && 'opacity: 0.5'};
`

export const ListWrapper = styled.div`
  height: 240px;
`

export const SwiperSlide = styled(_SwiperSlide)`
  max-width: 178px;
`
