import { gql } from '@apollo/client'

export const UPDATE_USER = gql`
  mutation updateUser($createUserData: UserInputDto!) {
    updateUser(createUserData: $createUserData) {
      userId
      email
      username
      avatarUrl
      gender
      country
      bio
      city
      allowNotify
    }
  }
`
