import { Spin } from 'antd'

import { Wrapper } from './styled'

type Props = { noHeight?: boolean; fullHeight?: boolean; color?: string }

function Loader({ fullHeight, noHeight, color }: Props) {
  return (
    <Wrapper noHeight={noHeight} fullHeight={fullHeight} color={color}>
      <Spin />
    </Wrapper>
  )
}

export default Loader
