import { ANNOUNCEMENTS_TABS } from 'pages/Main/Community/Announcements'

import { Item, Text, Wrapper } from './styled'

type Props = {
  selectedTab: ANNOUNCEMENTS_TABS
  onChangeTab: (tab: ANNOUNCEMENTS_TABS) => void
}

function Tabs({ selectedTab, onChangeTab }: Props) {
  const tabs = Object.values(ANNOUNCEMENTS_TABS)
  return (
    <Wrapper>
      {tabs.map((el) => (
        <Item onClick={() => onChangeTab(el)} selected={selectedTab === el} key={el}>
          <Text>{el}</Text>
        </Item>
      ))}
    </Wrapper>
  )
}

export default Tabs
