import { SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_NOTES_WITH_PAGINATION = gql`
  ${SHIUR_FRAGMENT}
  query getNotesWithPagination($take: Int!, $page: Int!, $text: String) {
    getNotesWithPagination(take: $take, page: $page, text: $text) {
      items {
        noteId
        text
        createdAt
        shiur {
          ...ShiurimInfo
        }
      }
      meta {
        total
        next
      }
    }
  }
`
