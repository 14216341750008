import { ReactComponent as Icon } from 'assets/icons/message.svg'
import InfoModal from 'components/ui/Modal/InfoModal'

import { EmailText } from './styled'

type Props = { open: boolean; email: string; onClose: () => void; onComplete: () => void }

function Completed({ open, email, onClose, onComplete }: Props) {
  return (
    <InfoModal
      open={open}
      onCancel={onClose}
      onBtnClick={onComplete}
      btnText={'GO TO COMMUNITY'}
      icon={<Icon />}
      title="Your message has been sent"
      description={
        <>
          We will send you a reply to your email address <EmailText>{email}</EmailText>
        </>
      }
    />
  )
}

export default Completed
