import { Country, Gender } from '__generated__/globalTypes'
import { Flex } from 'components/common/Flex'
import { formatText } from 'helpers/formatText'
import { useAuth } from 'hooks/useAuth'

import { Container, Divider, LocationIcon, Name, Subtitle, Wrapper } from './styled'

function PersonalInfo() {
  const { user } = useAuth()
  const { username, email, gender, country, city } = user || {}
  return (
    <Wrapper>
      <Name>{`${gender === Gender.MALE ? 'Mr.' : 'Mrs.'} ${username}`}</Name>
      <Container>
        <Subtitle>{email}</Subtitle>
        <Divider />
        <Flex alignCenter>
          <LocationIcon />
          <Subtitle cropped>{`${country === Country.USA || country === Country.UK ? country : formatText(country)} ${
            city ? `- ${city}` : ''
          }`}</Subtitle>
        </Flex>
      </Container>
    </Wrapper>
  )
}

export default PersonalInfo
