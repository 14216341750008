import { PropsWithChildren, createContext, useContext, useState } from 'react'

type Context = { headerHeight: number; setHeaderHeight: (height: number) => void }

const HeightContext = createContext<Context>({ headerHeight: null, setHeaderHeight: () => void 0 })

export const HeightProvider = ({ children }: PropsWithChildren) => {
  const [headerHeight, setHeaderHeight] = useState(null)

  return <HeightContext.Provider value={{ headerHeight, setHeaderHeight }}>{children}</HeightContext.Provider>
}

export const useHeaderHeight = () => {
  const context = useContext(HeightContext)
  if (!context) {
    throw new Error('useHeaderHeight must be used within a HeightProvider')
  }
  return context
}
