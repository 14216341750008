import { FormInstance } from 'antd'
import dayjs from 'dayjs'

export const useHandleDonationSelectDate = (form: FormInstance, closeModal: () => void) => {
  const onSelectDay = (date: dayjs.Dayjs) => {
    form.setFieldValue('date', [date, date])
    closeModal()
  }
  const onSelectWeek = (dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
    form.setFieldValue('date', dates)
    closeModal()
  }
  const onSelectMonth = (dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
    form.setFieldValue('date', dates)
    closeModal()
  }

  return { onSelectDay, onSelectWeek, onSelectMonth }
}
