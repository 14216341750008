import KnowledgeBaseCard from 'components/business/Main/Cards/KnowledgeBaseCard'
import Loader from 'components/ui/Loader'
import { deviceWidth } from 'constants/responsive'
import {
  getArticlesWithPagination,
  getArticlesWithPaginationVariables,
} from 'graphql/query/__generated__/getArticlesWithPagination'
import { GET_ARTICLES_WITH_PAGINATION } from 'graphql/query/getArticlesWithPagination'
import { useAuth } from 'hooks/useAuth'
import { ROUTE_PATH } from 'routes'

import { useQuery } from '@apollo/client'

import { List, Title, TitleDivider, TitleRow, ViewAllFakeLink, ViewAllLink } from './styled'

type Props = { openRegisterToContinueModal: () => void }

function KnowledgeBase({ openRegisterToContinueModal }: Props) {
  const { user } = useAuth()

  const isWideScreen = window.innerWidth >= 1920
  const isTablet = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])
  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])

  const { data, loading } = useQuery<getArticlesWithPagination, getArticlesWithPaginationVariables>(
    GET_ARTICLES_WITH_PAGINATION,
    { variables: { page: 1, take: isMobile ? 1 : isTablet ? 2 : isWideScreen ? 9 : 8 } }
  )
  return (
    <div>
      <TitleRow>
        <Title>Knowledge Base</Title>
        <TitleDivider />
        {user ? (
          <ViewAllLink to={ROUTE_PATH.KNOWLEDGE_BASE}>View all</ViewAllLink>
        ) : (
          <ViewAllFakeLink onClick={openRegisterToContinueModal}>View all</ViewAllFakeLink>
        )}
      </TitleRow>
      <List grid={!loading}>
        {loading ? (
          <Loader />
        ) : (
          data?.getArticlesWithPagination?.items.map((el) => <KnowledgeBaseCard key={el.articleId} {...el} />)
        )}
      </List>
    </div>
  )
}

export default KnowledgeBase
