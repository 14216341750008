import { Button } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => height || '100%'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.p`
  margin-top: 40px;
  margin-bottom: 16px;

  font-size: 24px;
  line-height: 1.3;
  font-weight: 500;
  text-align: center;
  color: ${COLORS.text.default};
`

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: ${COLORS.text.hint};
`

export const JoinButton = styled(Button)`
  min-width: 200px;
  margin-top: 40px;
`
