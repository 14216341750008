import { ReactComponent as TimeCodeIcon } from 'assets/icons/timecode-player.svg'
import { Flex } from 'components/common/Flex'
import dayjs from 'dayjs'
import { createOneBookmark, createOneBookmarkVariables } from 'graphql/mutation/__generated__/createOneBookmark'
import { createOneNote, createOneNoteVariables } from 'graphql/mutation/__generated__/createOneNote'
import { CREATE_ONE_BOOKMARK } from 'graphql/mutation/createOneBookmark'
import { CREATE_ONE_NOTE } from 'graphql/mutation/createOneNote'
import { convertSecsInMs } from 'helpers/convertSecsInMs'
import { usePlayer } from 'hooks/usePlayer'
import { useState } from 'react'

import { useMutation } from '@apollo/client'

import {
  ANTDModal,
  HeaderButton,
  HeaderRow,
  Label,
  ShiurDescription,
  ShiurImage,
  ShiurInfo,
  ShiurSeriesTitle,
  ShiurTimeCodeText,
  ShiurTimeCodeWrapper,
  ShiurTitle,
  SubmitButton,
  TextArea,
  TimeCodeSection,
} from './styled'

type Props = {
  open: boolean
  onClose: (updated?: boolean, page?: PAGES) => void
}

export enum PAGES {
  NOTE = 'NOTE',
  BOOKMARK = 'BOOKMARK',
}

function AddNoteBookmarkModal({ open, onClose }: Props) {
  const { progress, shiur } = usePlayer()
  const [playedSeconds] = useState(progress.playedSeconds)
  const [activePage, setActivePage] = useState(PAGES.NOTE)
  const [text, setText] = useState('')

  const [createOneNote, { loading: noteLoading }] = useMutation<createOneNote, createOneNoteVariables>(CREATE_ONE_NOTE)
  const [createOneBookmark, { loading: bookmarkLoading }] = useMutation<createOneBookmark, createOneBookmarkVariables>(
    CREATE_ONE_BOOKMARK
  )

  const onSubmit = async () => {
    if (activePage === PAGES.NOTE) {
      await createOneNote({ variables: { shiurId: shiur.shiurId, text } })
    } else {
      await createOneBookmark({
        variables: { shiurId: shiur.shiurId, text, timecode: Math.round(convertSecsInMs(playedSeconds)) },
      })
    }

    onClose(true, activePage)
  }

  return (
    <ANTDModal
      open={open}
      onCancel={() => onClose()}
      width={375}
      destroyOnClose={true}
      isFooterVisible={!!text}
      title={
        <HeaderRow>
          <HeaderButton type={activePage === PAGES.NOTE ? 'primary' : 'link'} onClick={() => setActivePage(PAGES.NOTE)}>
            {PAGES.NOTE}
          </HeaderButton>
          <HeaderButton
            type={activePage === PAGES.BOOKMARK ? 'primary' : 'link'}
            onClick={() => setActivePage(PAGES.BOOKMARK)}
          >
            {PAGES.BOOKMARK}
          </HeaderButton>
        </HeaderRow>
      }
      footer={
        text ? (
          <SubmitButton type="primary" loading={noteLoading || bookmarkLoading} onClick={onSubmit}>
            {activePage === PAGES.BOOKMARK ? 'SAVE BOOKMARK' : 'SAVE NOTE'}
          </SubmitButton>
        ) : (
          <></>
        )
      }
    >
      {activePage === PAGES.BOOKMARK && (
        <TimeCodeSection>
          <Label>Timecode</Label>
          <ShiurInfo>
            <Flex alignCenter>
              <ShiurImage src={`${process.env.REACT_APP_AWS_S3_URL}${shiur.series.coverUrl}`} />
              <div>
                <ShiurTitle>{shiur.title}</ShiurTitle>

                <ShiurDescription>
                  <ShiurSeriesTitle color={shiur.series.titleColor}>{shiur.series.title}</ShiurSeriesTitle>
                  {`∙${shiur.topic.title}`}
                </ShiurDescription>
              </div>
            </Flex>
            <ShiurTimeCodeWrapper>
              <TimeCodeIcon />
              <ShiurTimeCodeText>{dayjs.duration(playedSeconds, 's').format('mm:ss')}</ShiurTimeCodeText>
            </ShiurTimeCodeWrapper>
          </ShiurInfo>
        </TimeCodeSection>
      )}
      {activePage === PAGES.BOOKMARK && <Label isNote>Note</Label>}
      <TextArea
        isNote={activePage === PAGES.NOTE}
        maxLength={150}
        placeholder={
          activePage === PAGES.BOOKMARK
            ? 'Leave a note on a specific portion of the Shiur...'
            : 'Add a note about this Shiur...'
        }
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
    </ANTDModal>
  )
}

export default AddNoteBookmarkModal
