import { COLORS } from 'constants/colors'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ borderless?: boolean }>`
  min-width: 300px;

  ${({ borderless }) =>
    !borderless &&
    css`
      padding: 16px;

      border-radius: 12px;
      border: 1px solid #ebebeb;
    `}
`

export const Title = styled.p`
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  color: ${COLORS.text.default};

  margin-top: 12px;
  margin-bottom: 10px;
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.3;
  color: #696b7b;

  text-overflow: ellipsis;
  overflow: hidden;
`

export const DateText = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: #696b7b;
`

export const LabelWrapper = styled.div`
  padding: 4px 8px;

  color: ${COLORS.primary.medium};

  border-radius: 16px;
  background: #d9ecf6;
`

export const LabelText = styled.p`
  font-size: 12px;
  line-height: 1.3;
  color: ${COLORS.text.default};
`
