import { ReactComponent as NoDataIcon } from 'assets/icons/followed-big.svg'
import loaderSrc from 'assets/icons/loader.gif'
import Loader from 'components/ui/Loader'
import AlertModal from 'components/ui/Modal/AlertModal'
import NoData from 'components/ui/NoData'
import {
  toggleSeriesFollowStatus,
  toggleSeriesFollowStatusVariables,
} from 'graphql/mutation/__generated__/toggleSeriesFollowStatus'
import { TOGGLE_SERIES_FOLLOW_STATUS } from 'graphql/mutation/toggleSeriesFollowStatus'
import { getSeriesFollowedByUser } from 'graphql/query/__generated__/getSeriesFollowedByUser'
import { GET_SERIES_FOLLOWED_BY_USER } from 'graphql/query/getSeriesFollowedByUser'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'

import SeriesCard from '../../Cards/SeriesCard'
import { LoaderWrapper, Wrapper } from './styled'

const initialPagination = {
  take: 50,
  page: 1,
}

function FollowedSeriesList() {
  const { user } = useAuth()
  const [page, setPage] = useState(initialPagination.page)
  const [isUnfollowModalOpen, setIsUnfollowModalOpen] = useState(false)
  const [selectedSeriesId, setSelectedSeriesId] = useState<string>()

  const [toggleSeriesFollow, { loading: toggleSeriesFollowLoading }] = useMutation<
    toggleSeriesFollowStatus,
    toggleSeriesFollowStatusVariables
  >(TOGGLE_SERIES_FOLLOW_STATUS)

  const { data, loading, fetchMore, refetch } = useQuery<getSeriesFollowedByUser>(GET_SERIES_FOLLOWED_BY_USER, {
    variables: initialPagination,
    fetchPolicy: 'network-only',
    skip: !user,
  })

  const onFetchMore = () => {
    fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getSeriesFollowedByUser: {
            ...previousQueryResult.getSeriesFollowedByUser,
            ...fetchMoreResult.getSeriesFollowedByUser,
            items: [
              ...previousQueryResult.getSeriesFollowedByUser.items,
              ...fetchMoreResult.getSeriesFollowedByUser.items,
            ],
          },
        }
      },
    })
    setPage((prev) => prev + 1)
  }

  const openUnfollowModal = (seriesId: string) => {
    setSelectedSeriesId(seriesId)
    setIsUnfollowModalOpen(true)
  }

  const closeUnfollowModal = () => {
    setSelectedSeriesId(undefined)
    setIsUnfollowModalOpen(false)
  }

  const onUnfollow = async () => {
    await toggleSeriesFollow({ variables: { seriesId: selectedSeriesId, follow: false } })
    await refetch()

    closeUnfollowModal()
  }

  const itemsLength = data?.getSeriesFollowedByUser?.items?.length

  if (!itemsLength && loading) return <Loader />
  if (!itemsLength)
    return (
      <NoData
        icon={<NoDataIcon />}
        title={user ? "You haven't followed any Series yet" : 'Nothing here... yet'}
        description={
          user
            ? 'Find the Series you like the most and follow them'
            : 'Create an account to follow your favorite Series'
        }
      />
    )

  return (
    <>
      <Wrapper
        dataLength={itemsLength || 0}
        next={onFetchMore}
        hasMore={data?.getSeriesFollowedByUser?.meta?.next}
        loader={null}
        scrollableTarget="scrollable-wrapper"
      >
        {data?.getSeriesFollowedByUser?.items?.map((el) => (
          <SeriesCard
            key={el.seriesId}
            size="huge"
            selected={el.followInfo.isFollowed}
            showAddBtn
            onAddBtnClick={() => openUnfollowModal(el.seriesId)}
            {...el}
          />
        ))}
      </Wrapper>
      {loading && (
        <LoaderWrapper>
          <img src={loaderSrc} />
        </LoaderWrapper>
      )}
      <AlertModal
        open={isUnfollowModalOpen}
        onCancel={closeUnfollowModal}
        onPrimaryBtnClick={onUnfollow}
        onSecondaryBtnClick={closeUnfollowModal}
        confirmLoading={toggleSeriesFollowLoading || loading}
        title="Unfollow?"
        description="You can follow the Series back later again"
        primaryBtnText="UNFOLLOW"
        secondaryBtnText="CANCEL"
      />
    </>
  )
}

export default FollowedSeriesList
