import { Spin, notification } from 'antd'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import SeriesCard from 'components/business/Main/Cards/SeriesCard'
import { Flex } from 'components/common/Flex'
import {
  userBulkFollowSeries,
  userBulkFollowSeriesVariables,
} from 'graphql/mutation/__generated__/userBulkFollowSeries'
import { USER_BULK_FOLLOW_SERIES } from 'graphql/mutation/userBulkFollowSeries'
import {
  getSeriesWithPagination,
  getSeriesWithPaginationVariables,
} from 'graphql/query/__generated__/getSeriesWithPagination'
import { GET_SERIES_WITH_PAGINATION } from 'graphql/query/getSeriesWithPagination'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useMutation, useQuery } from '@apollo/client'

import {
  FullWidthPageContainer,
  FullWidthPageContent,
  FullWidthPageFooter,
  FullWidthPageFooterButton,
  FullWidthPageHeader,
  FullWidthPageWrapper,
} from '../styled'
import { Header, List } from './styled'

function FollowSeries() {
  const { checkAuth } = useAuth()
  const navigate = useNavigate()

  const [submitLoading, setSubmitLoading] = useState(false)
  const [selectedArray, setSelectedArray] = useState([])

  const { data, loading } = useQuery<getSeriesWithPagination, getSeriesWithPaginationVariables>(
    GET_SERIES_WITH_PAGINATION,
    {
      variables: { page: 1, take: 15 },
    }
  )
  const [followSeries] = useMutation<userBulkFollowSeries, userBulkFollowSeriesVariables>(USER_BULK_FOLLOW_SERIES)

  const onSubmit = async () => {
    setSubmitLoading(true)

    try {
      await followSeries({ variables: { seriesIds: selectedArray } })
      await checkAuth()

      navigate(ROUTE_PATH.NOTIFICATIONS_SETUP)
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
      })
    }

    setSubmitLoading(false)
  }

  const onAddBtnClick = (id: string, selected: boolean) => {
    if (selected) {
      return setSelectedArray((prev) => [...prev, id])
    }

    setSelectedArray((prev) => prev.filter((el) => el !== id))
  }

  const isContinueButtonDisabled = !selectedArray.length
  return (
    <FullWidthPageWrapper>
      <FullWidthPageHeader>
        <Logo />
      </FullWidthPageHeader>
      <FullWidthPageContainer>
        <FullWidthPageContent big>
          {loading ? (
            <Flex justifyCenter>
              <Spin />
            </Flex>
          ) : (
            <>
              <Header>Follow series</Header>
              <List>
                {data?.getSeriesWithPagination?.items?.map((el) => (
                  <SeriesCard
                    key={el.seriesId}
                    onAddBtnClick={(selected: boolean) => onAddBtnClick(el.seriesId, selected)}
                    selected={selectedArray.includes(el.seriesId)}
                    showAddBtn
                    noClick
                    {...el}
                  />
                ))}
              </List>
            </>
          )}
        </FullWidthPageContent>
      </FullWidthPageContainer>
      <FullWidthPageFooter>
        <FullWidthPageFooterButton
          type="primary"
          disabled={isContinueButtonDisabled}
          loading={submitLoading}
          onClick={onSubmit}
        >
          CONTINUE
        </FullWidthPageFooterButton>
      </FullWidthPageFooter>
    </FullWidthPageWrapper>
  )
}

export default FollowSeries
