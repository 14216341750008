import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

import { Wrapper as ItemWrapper } from '../../Cards/NotificationCard/styled'

export const ScrollableWrapper = styled.div`
  height: calc(100% - 25px);
  overflow: auto;

  padding-top: 25px;
`

export const Wrapper = styled(InfiniteScroll)`
  position: relative;

  padding-bottom: 45px;

  & > ${ItemWrapper}:not(:first-child) {
    margin-top: 12px;
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  justify-content: center;
`
