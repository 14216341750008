import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;

  @media ${device.laptopL} {
    display: block;
  }
`

export const LeftContainer = styled.div`
  width: 70%;
  margin-right: 28px;

  @media ${device.laptopL} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 14px;
  }
`

export const RightContainer = styled.div`
  width: 30%;

  padding: 24px;

  border-radius: 12px;
  border: 1px solid #d9ecf6;
  background-color: #f4fbff;

  @media ${device.laptopL} {
    width: 100%;
  }
`
