import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { Day, DayWrapper, DaysWrapper, Header, MainWrapper, MonthWrapper, Title, WeekDay, WeekDaysRow } from './styled'

type CalendarData = {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: dayjs.Dayjs
      }
    }
  }
}

type Props = {
  selectedDate?: dayjs.Dayjs
  onChangeDate: (date: dayjs.Dayjs) => void
  future?: boolean
}

function getWeekOfMonth(date: dayjs.Dayjs) {
  const startOfMonth = date.startOf('month')
  const weekOfYear = date.week()
  const weekOfYearStart = startOfMonth.week()

  return weekOfYear - weekOfYearStart + 1
}

const getDaysUntil2020 = () => {
  const arr: dayjs.Dayjs[] = []
  for (let i = 0; dayjs().startOf('D').add(i, 'day').isAfter(dayjs().set('year', 2019).endOf('year')); i--) {
    arr.push(dayjs().add(i, 'day').startOf('D'))
  }

  return arr.reverse()
}

const getDaysUntilEndOfNextYear = () => {
  const arr: dayjs.Dayjs[] = []
  for (let i = 1; dayjs().startOf('D').add(i, 'day').isBefore(dayjs().add(1, 'year').endOf('year')); i++) {
    arr.push(dayjs().add(i, 'day').startOf('D'))
  }

  return arr.reverse()
}

function Calendar({ selectedDate, onChangeDate, future }: Props) {
  const [calendar, setCalendar] = useState<CalendarData>()

  useEffect(() => {
    const days = future ? getDaysUntilEndOfNextYear() : getDaysUntil2020()

    const newCalendar = days.reduce<CalendarData>((acc, el) => {
      const year = el.year()
      const month = el.toDate().toLocaleDateString('en-US', { month: 'long' })
      const week = getWeekOfMonth(el)
      const weekDay = el.weekday()

      const newObj = {
        ...acc,
        [year]: {
          ...acc[year],
          [month]: {
            ...(acc?.[year]?.[month] || {}),
            [week]: {
              ...(acc?.[year]?.[month]?.[week] || {}),
              [weekDay]: el,
            },
          },
        },
      }

      return (acc = newObj)
    }, {})

    setCalendar(newCalendar)
  }, [])

  const renderCalendar = () => {
    const calendarKeys = Object.keys(calendar)
    const renderArr = future ? calendarKeys : calendarKeys.reverse()

    return <>{renderArr.map((el) => renderYear(el))}</>
  }

  const renderYear = (year: string) => {
    const currentYear = calendar[year]

    const renderArr = Object.keys(currentYear).reverse()
    return <>{renderArr.map((el) => renderMonth(el, year))}</>
  }

  const renderMonth = (month: string, year: string) => {
    const currentMonth = calendar[year][month]

    const renderArr = Object.keys(currentMonth)
    return (
      <MonthWrapper>
        <Title>
          {month}, {year}
        </Title>
        <DaysWrapper>{renderArr.map((el) => renderWeek(el, month, year))}</DaysWrapper>
      </MonthWrapper>
    )
  }

  const renderWeek = (week: string, month: string, year: string) => {
    const currentWeek = calendar[year][month][week]
    if (!currentWeek) return

    const renderArr = []
    for (let i = 0; i < 7; i++) {
      const el = currentWeek[i]
      renderArr.push(el)
    }

    return (
      <>
        {renderArr.map((el, i) => (
          <DayWrapper
            key={el?.toISOString() || i}
            selected={el && selectedDate ? selectedDate.isSame(el, 'date') : undefined}
            onClick={el ? () => onChangeDate(el) : undefined}
          >
            <Day>{el?.date() || ''}</Day>
          </DayWrapper>
        ))}
      </>
    )
  }

  const formattedDate = selectedDate?.isToday()
    ? selectedDate?.format('[Today,] MMMM')
    : selectedDate?.isYesterday()
    ? selectedDate?.format('[Yesterday,] MMMM')
    : selectedDate?.format('MMMM D, YYYY')
  return (
    <div>
      <Header noTopPadding={future}>
        <Title heading>{formattedDate}</Title>
        <WeekDaysRow>
          <WeekDay>S</WeekDay>
          <WeekDay>M</WeekDay>
          <WeekDay>T</WeekDay>
          <WeekDay>W</WeekDay>
          <WeekDay>T</WeekDay>
          <WeekDay>F</WeekDay>
          <WeekDay>S</WeekDay>
        </WeekDaysRow>
      </Header>
      <MainWrapper>{calendar && renderCalendar()}</MainWrapper>
    </div>
  )
}

export default Calendar
