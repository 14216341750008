import { device } from 'constants/responsive'
import { Wrapper as _Wrapper } from 'pages/Main/styled'
import styled from 'styled-components'

export const Wrapper = styled(_Wrapper)<{ headerHeight?: number }>`
  height: ${({ headerHeight }) =>
    headerHeight &&
    `calc(100% - ${headerHeight}px - 42px - 40px)`}; // full height - header height - filter section height - filter section margin
`

export const FiltersWrapper = styled.div`
  padding: 0px 27px;
  margin-bottom: 40px;

  @media ${device.laptopL} {
    padding: 0px 14px;
    margin-bottom: 20px;
    overflow: auto;
  }
`
