import Greeting from '../Greeting'
import Sponsor from '../Sponsor'
import { Wrapper } from './styled'

function TopBanners() {
  return (
    <Wrapper>
      <Greeting />
      <Sponsor />
    </Wrapper>
  )
}

export default TopBanners
