import { Button } from 'antd'
import { Flex } from 'components/common/Flex'
import { deviceWidth } from 'constants/responsive'
import { MenuInfo } from 'rc-menu/lib/interface'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import {
  AppStoreIcon,
  Bookmarks,
  BookmarksActive,
  Browse,
  BrowseActive,
  Community,
  CommunityActive,
  Donate,
  DonateActive,
  FollowSeries,
  FollowSeriesActive,
  History,
  HistoryActive,
  Home,
  HomeActive,
  Notes,
  NotesActive,
  PlayMarketIcon,
  SavedShiurim,
  SavedShiurimActive,
  Series,
  SeriesActive,
  Shiurim,
  ShiurimActive,
  Topics,
  TopicsActive,
} from '../Sidebar/icons'
import { BottomText, BottomTextLink, ButtonsContainer, Divider, Label, Menu, MenuItem } from './styled'

export enum NavigationMenuKeys {
  HOME = '/home',
  BROWSE = '/browse',
  SERIES = '/series',
  SHIURIM = '/shiurim',
  TOPICS = '/topics',
  NOTES = '/notes',
  BOOKMARKS = '/bookmarks',
  SAVED_SHIURIM = '/saved-shiurim',
  FOLLOWED_SERIES = '/followed-series',
  HISTORY = '/history',
  COMMUNITY = '/community',
  DONATE = '/donate',
}

function NavigationMenu() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [activeMenu, changeMenu] = useState<NavigationMenuKeys | string>(NavigationMenuKeys.HOME)

  useEffect(() => {
    const pathnameArray = pathname.split('/')
    switch (pathnameArray[1]) {
      case 'home':
        return changeMenu(NavigationMenuKeys.HOME)
      case 'browse':
        return changeMenu(NavigationMenuKeys.BROWSE)
      case 'series':
        return changeMenu(NavigationMenuKeys.SERIES)
      case 'shiurim':
        return changeMenu(NavigationMenuKeys.SHIURIM)
      case 'topics':
        return changeMenu(NavigationMenuKeys.TOPICS)
      case 'notes':
        return changeMenu(NavigationMenuKeys.NOTES)
      case 'bookmarks':
        return changeMenu(NavigationMenuKeys.BOOKMARKS)
      case 'saved-shiurim':
        return changeMenu(NavigationMenuKeys.SAVED_SHIURIM)
      case 'followed-series':
        return changeMenu(NavigationMenuKeys.FOLLOWED_SERIES)
      case 'history':
        return changeMenu(NavigationMenuKeys.HISTORY)
      case 'community':
        return changeMenu(NavigationMenuKeys.COMMUNITY)
      case 'announcements':
        return changeMenu(NavigationMenuKeys.COMMUNITY)
      case 'knowledge-base':
        return changeMenu(NavigationMenuKeys.COMMUNITY)
      case 'donate':
        return changeMenu(NavigationMenuKeys.DONATE)
      case 'donation-history':
        return changeMenu(NavigationMenuKeys.DONATE)

      default:
        return changeMenu(NavigationMenuKeys.HOME)
    }
  }, [pathname])

  function onClickMenu({ key }: MenuInfo) {
    navigate(key)
    changeMenu(key)
  }

  const isCollapsed = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])
  return (
    <Menu mode="inline" selectedKeys={[activeMenu]} onClick={onClickMenu}>
      <MenuItem key={NavigationMenuKeys.HOME} icon={activeMenu === NavigationMenuKeys.HOME ? <HomeActive /> : <Home />}>
        Home
      </MenuItem>
      <MenuItem
        key={NavigationMenuKeys.BROWSE}
        icon={activeMenu === NavigationMenuKeys.BROWSE ? <BrowseActive /> : <Browse />}
      >
        Browse
      </MenuItem>
      <Divider bottom />
      <MenuItem
        key={NavigationMenuKeys.SERIES}
        icon={activeMenu === NavigationMenuKeys.SERIES ? <SeriesActive /> : <Series />}
      >
        Series
      </MenuItem>
      <MenuItem
        key={NavigationMenuKeys.SHIURIM}
        icon={activeMenu === NavigationMenuKeys.SHIURIM ? <ShiurimActive /> : <Shiurim />}
      >
        Shiurim
      </MenuItem>
      <MenuItem
        key={NavigationMenuKeys.TOPICS}
        icon={activeMenu === NavigationMenuKeys.TOPICS ? <TopicsActive /> : <Topics />}
      >
        Topics
      </MenuItem>

      <Divider />
      <Label>HIGHLIGHTS</Label>

      <MenuItem
        key={NavigationMenuKeys.NOTES}
        icon={activeMenu === NavigationMenuKeys.NOTES ? <NotesActive /> : <Notes />}
      >
        Notes
      </MenuItem>
      <MenuItem
        key={NavigationMenuKeys.BOOKMARKS}
        icon={activeMenu === NavigationMenuKeys.BOOKMARKS ? <BookmarksActive /> : <Bookmarks />}
      >
        Bookmarks
      </MenuItem>
      <MenuItem
        key={NavigationMenuKeys.SAVED_SHIURIM}
        icon={activeMenu === NavigationMenuKeys.SAVED_SHIURIM ? <SavedShiurimActive /> : <SavedShiurim />}
      >
        Saved shiurim
      </MenuItem>
      <MenuItem
        key={NavigationMenuKeys.FOLLOWED_SERIES}
        icon={activeMenu === NavigationMenuKeys.FOLLOWED_SERIES ? <FollowSeriesActive /> : <FollowSeries />}
      >
        Followed series
      </MenuItem>
      <MenuItem
        key={NavigationMenuKeys.HISTORY}
        icon={activeMenu === NavigationMenuKeys.HISTORY ? <HistoryActive /> : <History />}
      >
        History
      </MenuItem>

      {!isCollapsed && <Divider bottom />}

      <MenuItem
        key={NavigationMenuKeys.COMMUNITY}
        icon={activeMenu === NavigationMenuKeys.COMMUNITY ? <CommunityActive /> : <Community />}
      >
        Community
      </MenuItem>
      <MenuItem
        key={NavigationMenuKeys.DONATE}
        icon={activeMenu === NavigationMenuKeys.DONATE ? <DonateActive /> : <Donate />}
      >
        Donate
      </MenuItem>

      {!isCollapsed && (
        <>
          <Label bottom>GET THE APP</Label>
          <ButtonsContainer>
            <Button onClick={() => window.open(process.env.REACT_APP_APP_STORE_LINK)}>
              <AppStoreIcon />
            </Button>
            <Button onClick={() => window.open(process.env.REACT_APP_GOOGLE_PLAY_LINK)}>
              <PlayMarketIcon />
            </Button>
          </ButtonsContainer>
        </>
      )}

      <>
        <Divider bottom />
        <Flex justifyCenter>
          <BottomText>
            <BottomTextLink to={ROUTE_PATH.TERMS_OF_USE}>Terms of use</BottomTextLink> and{' '}
            <BottomTextLink to={ROUTE_PATH.PRIVACY_POLICY}>Privacy Policy</BottomTextLink>
          </BottomText>
        </Flex>
      </>
    </Menu>
  )
}

export default NavigationMenu
