import { Button } from 'antd'
import { ReactComponent as DownArrowIcon } from 'assets/icons/down-arrow.svg'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const DownArrow = styled(DownArrowIcon)<{ isActive: boolean }>`
  transition: transform 0.2s;

  ${({ isActive }) => isActive && 'transform: rotate(180deg)'};
`

export const SubmitButton = styled(Button)`
  width: 100%;

  margin: 0;
  margin-right: 24px;
`

export const ResetButton = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: ${COLORS.text.default};
  white-space: nowrap;

  margin: 0;

  cursor: pointer;
  transition: all 0.2s;
  user-select: none;

  &:hover {
    color: ${COLORS.primary.medium};
  }
`
