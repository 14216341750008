import { Layout } from 'antd'
import { device } from 'constants/responsive'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const { Sider: ANTDSider } = Layout

export const Sider = styled(ANTDSider)<{ isPlayerShown?: boolean }>`
  overflow: auto;
  height: ${({ isPlayerShown }) => (isPlayerShown ? 'calc(100dvh - 94px)' : '100dvh')};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  &.ant-layout-sider {
    background-color: #f4fbff;
  }
`

export const LogoLink = styled(Link)`
  display: block;
  padding-top: 32px;
  padding-left: 32px;
  padding-bottom: 24px;

  @media ${device.laptopL} {
    padding-top: 14px;
    padding-left: 16px;
    padding-bottom: 12px;
  }
`
