import AlertModal from 'components/ui/Modal/AlertModal'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import Banner from '../Banner'
import Announcements from './Announcements'
import KnowledgeBase from './KnowledgeBase'
import { LeftContainer, RightContainer, Wrapper } from './styled'

function MainPage() {
  const navigate = useNavigate()
  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)
  return (
    <>
      <Wrapper>
        <LeftContainer>
          <Banner />
          <KnowledgeBase openRegisterToContinueModal={openRegisterToContinueModal} />
        </LeftContainer>
        <RightContainer>
          <Announcements openRegisterToContinueModal={openRegisterToContinueModal} />
        </RightContainer>
      </Wrapper>
      <AlertModal
        open={isRegisterToContinueModalOpen}
        onCancel={closeRegisterToContinueModal}
        onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
        onSecondaryBtnClick={closeRegisterToContinueModal}
        title="Register to Continue"
        description="To unlock all features you need to create an account"
        primaryBtnText="REGISTER"
        secondaryBtnText="NOT NOW"
      />
    </>
  )
}

export default MainPage
