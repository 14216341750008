import { ThemeConfig } from 'antd'
import { COLORS } from 'constants/colors'

export default {
  token: {
    fontFamily: 'General Sans',
    colorPrimary: COLORS.primary.medium,
    colorErrorBorder: COLORS.error,
    controlHeight: 50,
    controlInteractiveSize: 16,
    fontSize: 14,
    colorTextBase: COLORS.primary.dark,
    colorTextPlaceholder: COLORS.text.hint,
    colorTextLabel: COLORS.text.default,
  },
  components: {
    Input: {
      borderRadius: 40,
      paddingSM: 16,
      colorIcon: COLORS.primary.medium,
    },
    InputNumber: {
      borderRadius: 40,
      paddingSM: 16,
      colorIcon: COLORS.primary.medium,
    },
    Button: {
      borderRadius: 40,
      colorBgContainerDisabled: COLORS.primary.light,
      colorTextDisabled: '#ffffff',
    },
    Modal: { borderRadiusLG: 12, colorBgMask: 'rgba(15, 22, 83, 0.5)' },
    Checkbox: { controlHeight: 30 },
  },
} as ThemeConfig
