import { Button as ANTDButton, Spin } from 'antd'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ isPage?: boolean }>`
  display: flex;
  align-items: center;

  margin-left: ${({ isPage }) => (isPage ? '15px' : '30px')};

  @media ${device.tablet} {
    ${({ isPage }) =>
      !isPage &&
      css`
        margin: 0;
        margin-top: 10px;
      `}
  }
`

export const Button = styled(ANTDButton)<{ isPage?: boolean }>`
  height: ${({ isPage }) => (isPage ? '44px' : '32px')};

  display: flex;
  align-items: center;

  & > span {
    margin-right: 8px;
  }
`

export const Loader = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #ffffff;
  }
`
