import { ReactComponent as Icon } from 'assets/icons/message.svg'
import { Flex } from 'components/common/Flex'

import { SubmitButton } from '../InputEmailAndText/styled'
import { Description, EmailText, IconWrapper, Title } from './styled'

type Props = { email: string; onCompleted: () => void }

function Completed({ email, onCompleted }: Props) {
  return (
    <Flex column justifyCenter alignCenter>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Title>Your message has been sent</Title>
      <Description>
        We will send you a reply to your email address <EmailText>{email}</EmailText>
      </Description>
      <SubmitButton type="primary" onClick={onCompleted}>
        BACK TO SUPPORT
      </SubmitButton>
    </Flex>
  )
}

export default Completed
