import { SERIES_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_SERIES_FOLLOWED_BY_USER = gql`
  ${SERIES_FRAGMENT}
  query getSeriesFollowedByUser($take: Int!, $page: Int!) {
    getSeriesFollowedByUser(take: $take, page: $page) {
      items {
        ...SeriesInfo
      }
      meta {
        total
        next
      }
    }
  }
`
