import { gql } from '@apollo/client'

export const REMOVE_SHIUR_FROM_FAVORITE = gql`
  mutation removeShiurFromFavorite($shiurId: String!) {
    removeShiurFromFavorite(shiurId: $shiurId) {
      shiurId
      favoriteInfo {
        isFavorite
      }
    }
  }
`
