import { Spin } from 'antd'
import Loader from 'components/ui/Loader'
import { addShiurToFavorite, addShiurToFavoriteVariables } from 'graphql/mutation/__generated__/addShiurToFavorite'
import {
  removeShiurFromFavorite,
  removeShiurFromFavoriteVariables,
} from 'graphql/mutation/__generated__/removeShiurFromFavorite'
import { ADD_SHIUR_TO_FAVORITE } from 'graphql/mutation/addShiurToFavorite'
import { REMOVE_SHIUR_FROM_FAVORITE } from 'graphql/mutation/removeShiurFromFavorite'
import { getShiurimOrderedByDate_getShiurimOrderedByDate_items } from 'graphql/query/__generated__/getShiurimOrderedByDate'

import { useMutation } from '@apollo/client'

import ShiurimCard, { ShiurimSize } from '../../Cards/ShiurimCard'
import { EmptyContainer, EmptyText } from '../../Home/styled'
import { LoaderWrapper, Wrapper } from './styled'

type Props = {
  items?: getShiurimOrderedByDate_getShiurimOrderedByDate_items[]
  size?: ShiurimSize
  oneColumn?: boolean
  openRegisterToContinueModal?: () => void
  refetch?: (variables?: any) => Promise<any>
  onFetchMore?: () => void
  next?: boolean
  loading: boolean
  loadingNext: boolean
}

function ShiurimList({
  items,
  size,
  oneColumn,
  openRegisterToContinueModal,
  refetch,
  onFetchMore,
  next,
  loading,
  loadingNext,
}: Props) {
  const [addShiurToFavorite, { loading: addShiurToFavoriteLoading }] = useMutation<
    addShiurToFavorite,
    addShiurToFavoriteVariables
  >(ADD_SHIUR_TO_FAVORITE)
  const [removeShiurFromFavorite, { loading: removeShiurFromFavoriteLoading }] = useMutation<
    removeShiurFromFavorite,
    removeShiurFromFavoriteVariables
  >(REMOVE_SHIUR_FROM_FAVORITE)

  const onToggleFavorite = async (shiurId: string, favorite: boolean) => {
    if (addShiurToFavoriteLoading || removeShiurFromFavoriteLoading) return

    if (favorite) {
      await removeShiurFromFavorite({ variables: { shiurId } })
    } else {
      await addShiurToFavorite({ variables: { shiurId } })
    }

    if (refetch) await refetch()
  }

  const isItems = !!items?.length

  if (loading) return <Loader noHeight />

  return (
    <>
      <Wrapper
        dataLength={items?.length || 0}
        next={onFetchMore}
        hasMore={next}
        loader={null}
        grid={isItems}
        oneColumn={oneColumn}
        scrollableTarget="scrollable-wrapper"
      >
        {!isItems ? (
          <EmptyContainer>
            <EmptyText>No shiurim</EmptyText>
          </EmptyContainer>
        ) : (
          items?.map((el, i) => (
            <ShiurimCard
              key={el.shiurId}
              index={i}
              size={size}
              onToggleFavorite={onToggleFavorite}
              openRegisterToContinueModal={openRegisterToContinueModal}
              {...el}
            />
          ))
        )}
        {loadingNext && (
          <LoaderWrapper>
            <Spin />
          </LoaderWrapper>
        )}
      </Wrapper>
    </>
  )
}

export default ShiurimList
