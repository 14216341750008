import { Button } from 'antd'
import backgroundSrc from 'assets/img/unlock-all-features-background.png'
import { device } from 'constants/responsive'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;

  & > div:first-child {
    margin-right: 14px;

    @media ${device.laptop} {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  @media ${device.laptop} {
    display: block;
  }
`

export const MainBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  height: 150px;
  padding: 32px;

  background-image: url(${backgroundSrc});
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;

  border: 0.5px solid #d9ecf6;
  border-radius: 12px;

  @media ${device.laptop} {
    flex-direction: column;
    height: auto;
    min-height: 254px;
    padding: 18px;
  }
`

export const MainContent = styled.div`
  display: flex;
  align-items: center;

  @media ${device.laptop} {
    flex-direction: column;
    margin-bottom: 8px;
  }
`

export const Image = styled.img`
  width: 102px;
  height: 102px;

  object-fit: cover;
  object-position: center;

  border-radius: 12px;

  @media ${device.laptop} {
    margin-bottom: 8px;
  }
`

export const Divider = styled.div`
  height: 102px;
  width: 1px;
  margin: 0 30px;

  background-color: rgba(255, 255, 255, 0.1);

  @media ${device.laptop} {
    display: none;
  }
`

export const Title = styled.p`
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  color: #ffffff;

  margin-bottom: 8px;

  @media ${device.laptop} {
    font-size: 24px;

    text-align: center;
  }
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.8);

  @media ${device.laptop} {
    text-align: center;
  }
`

export const CustomButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 116px;
  height: 42px;

  & > svg {
    margin-right: 4px;
  }
`

export const FollowButton = styled(CustomButton)<{ followed: boolean }>`
  margin-right: 14px;

  transition: all 0.2s;

  ${({ followed }) =>
    !followed &&
    css`
      color: #ffffff;

      border-color: transparent;
      background-color: rgba(255, 255, 255, 0.3);

      &:hover {
        color: #ffffff !important;
      }
    `}
`

export const SponsorButton = styled(CustomButton)`
  color: #ffffff;

  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.3);

  &:hover {
    color: #ffffff !important;
  }
`
