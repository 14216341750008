import { SHIUR_FRAGMENT } from 'graphql/fragments'

import { gql } from '@apollo/client'

export const GET_RECOMMENDED_SHIURIM = gql`
  ${SHIUR_FRAGMENT}
  query getRecommendedShiurim($take: Int!, $page: Int!, $offset: Boolean, $seriesId: String) {
    getRecommendedShiurim(take: $take, page: $page, offset: $offset, seriesId: $seriesId) {
      items {
        ...ShiurimInfo
      }
      meta {
        total
        next
      }
    }
  }
`
