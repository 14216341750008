import { ReactComponent as SearchIcon } from 'assets/icons/search-big.svg'
import NoData from 'components/ui/NoData'
import { getAllShiurims_getAllShiurims } from 'graphql/query/__generated__/getAllShiurims'
import { getAllTopics_getAllTopics } from 'graphql/query/__generated__/getAllTopics'
import { getSeriesOrderedByShiurCount_getSeriesOrderedByShiurCount } from 'graphql/query/__generated__/getSeriesOrderedByShiurCount'

import Series from './Series'
import Shiurim from './Shiurim'
import Topics from './Topics'
import { Wrapper } from './styled'

type Props = {
  topics?: getAllTopics_getAllTopics
  series?: getSeriesOrderedByShiurCount_getSeriesOrderedByShiurCount
  shiurims?: getAllShiurims_getAllShiurims
  topicsLoading: boolean
  seriesLoading: boolean
  shiurimLoading: boolean
  showCount: boolean
}

function AllTab({ topics, series, shiurims, topicsLoading, seriesLoading, shiurimLoading, showCount }: Props) {
  const isItems = topics?.items?.length || series?.items?.length || shiurims?.items?.length
  const isLoading = topicsLoading || seriesLoading || shiurimLoading

  if (!isItems && !isLoading)
    return (
      <NoData
        height="calc(100% - 82px)"
        icon={<SearchIcon />}
        title="Sorry, we couldn't find any matches"
        description="Try searching again using a different spelling or keyword"
      />
    )
  return (
    <Wrapper>
      <Topics data={topics} loading={topicsLoading} showCount={showCount} />
      <Series data={series} loading={seriesLoading} showCount={showCount} />
      <Shiurim data={shiurims} loading={shiurimLoading} showCount={showCount} />
    </Wrapper>
  )
}

export default AllTab
