import { Switch as ANTDSwitch } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const NotificationsTitle = styled.p`
  margin-top: 2px;

  font-size: 16px;
  line-height: 1.6;
  font-weight: 500;
  color: ${COLORS.text.default};
`

export const NotificationsDescription = styled.p`
  margin-top: 2px;

  font-size: 14px;
  line-height: 1.3;
  color: #696b7b;
`

export const Switch = styled(ANTDSwitch)``
