import { useState } from 'react'

import ButtonsAndQuickFilters from './ButtonsAndQuickFilters'
import FilterDrawer from './FilterDrawer'
import { PERIOD_OPTIONS } from './PeriodFilter'

export type ShiurimFilters = {
  period?: { option: PERIOD_OPTIONS; startDate: Date; endDate: Date }
  series?: { title: string; id: string }[]
  topics?: { title: string; id: string }[]
  subtopics?: { title: string; id: string }[]
}

type Props = {
  filters: ShiurimFilters
  noTopics?: boolean
  noSeries?: boolean
  topicId?: string
  withQuickFilters?: boolean
  onChangeFilters?: (filters: ShiurimFilters) => void
}

function ShiurimFilter({ filters, noTopics, noSeries, withQuickFilters, onChangeFilters }: Props) {
  const [newFilters, setNewFilters] = useState(filters)
  const [open, setOpen] = useState(false)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onApply = () => {
    onChangeFilters(newFilters)

    onClose()
  }

  const onReset = () => {
    onChangeFilters({})
    setNewFilters({})

    onClose()
  }

  const onResetOne = (filterName: keyof ShiurimFilters) => {
    const updatedFilter = { ...filters }
    delete updatedFilter[filterName]

    onChangeFilters(updatedFilter)
    setNewFilters(updatedFilter)
  }

  const filterCount = filters.period?.startDate
    ? 1
    : 0 + filters.series?.length
    ? 1
    : 0 + filters.topics?.length
    ? 1
    : 0 + filters.subtopics?.length
    ? 1
    : 0

  return (
    <>
      <ButtonsAndQuickFilters
        filters={filters}
        newFilters={newFilters}
        filtersCount={filterCount}
        withQuickFilters={withQuickFilters}
        noTopics={noTopics}
        noSeries={noSeries}
        onOpen={onOpen}
        onResetOne={onResetOne}
        onReset={onReset}
        onApply={onApply}
        onChangeFilters={setNewFilters}
      />
      <FilterDrawer
        filters={newFilters}
        noTopics={noTopics}
        noSeries={noSeries}
        open={open}
        onClose={onClose}
        onReset={onReset}
        onApply={onApply}
        onChangeFilters={setNewFilters}
      />
    </>
  )
}

export default ShiurimFilter
