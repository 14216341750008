import { gql } from '@apollo/client'

export const ADD_SHIUR_TO_FAVORITE = gql`
  mutation addShiurToFavorite($shiurId: String!) {
    addShiurToFavorite(shiurId: $shiurId) {
      shiurId
      favoriteInfo {
        isFavorite
      }
    }
  }
`
